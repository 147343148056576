import { PropsWithChildren } from "react";

import {
  CardContainer,
  CardContainerActions,
} from "@/modules/common/cardContainer/CardContainer";
import { PrimaryActionItemProps } from "@/modules/common/cardContainer/cardContainerTypes";
import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";

interface CardSectionProps extends PropsWithChildren {
  title: string;
  primaryAction?: PrimaryActionItemProps;
  menuItems?: ActionMenuItemProps[];
  isEmpty: boolean;
}

export const CardSection = ({
  primaryAction,
  menuItems,
  isEmpty,
  children,
  title,
}: CardSectionProps) => (
  <CardContainer
    primaryAction={primaryAction}
    isEmpty={isEmpty}
    menuItems={menuItems}
    variant={"section"}>
    <div
      data-testid="card-section-container"
      className={"flex flex-col space-y-3"}>
      <div className={"flex items-center justify-between"}>
        <span className={"text-body-600 text-grey-900"}>{title}</span>
        <CardContainerActions />
      </div>
      {children}
    </div>
  </CardContainer>
);
