import { useEffect, useState } from "react";

import {
  CourseDto,
  CreateEnrolmentRequestInner,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

import { useStudentSelect } from "../../data/useStudentSelect";
import { createEnrolmentStartDate } from "../../enrolmentUtils";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { DatePickerValue } from "../../forms/formComponentProps";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { dayjs } from "../../lib/dayjs";
import { getPlatformFunctions } from "../../platformSpecific";
import { pricingPlanIcons, pricingPlanLabels } from "../../pricingPlanUtils";
import { EnrolmentKey } from "../enrolments/UpdateEnrolmentStartForm";
import { useFormActions } from "../FormActionsProvider";
import { CourseSelectValue } from "../forms/CourseSelect";

export type CreateFormActionSchema = {
  courseId?: string;
  season?: SeasonDto;
  studentIds?: string[];
};

type AddSchema = Omit<CreateEnrolmentRequestInner, "studentId"> & {
  studentIds: string[];
  pricingPlanId: string;
};

type UpdateSchema = {
  date: string;
};

type FormDefinitionProps = {
  classId?: string;
  season?: SeasonDto;
  showStudentSelector?: boolean;
};

const fetchPricingPlans = async (courseId: string | null) => {
  if (!courseId) {
    return [];
  }
  const { api } = getPlatformFunctions();
  const course = await api.courses.getCourse({ id: courseId });
  const season = await api.seasons.getSeason({
    id: course.entity.seasonId,
  });

  return season.pricingPlans.filter(plan => plan.enabled);
};

const useFormDefinition = ({
  classId,
  showStudentSelector = true,
}: FormDefinitionProps) => {
  const [pricingPlans, setPricingPlans] = useState<SeasonDto["pricingPlans"]>(
    [],
  );
  const [selectedClassId, setSelectedClassId] = useState<string | undefined>(
    classId,
  );
  const [seasonChange, setSeasonChange] = useState<boolean>(false);
  const [seasonStartAt, setSeasonStartAt] = useState<DatePickerValue>();
  const [seasonEndAt, setSeasonEndAt] = useState<DatePickerValue>();
  const [startAt, setStartAt] = useState<DatePickerValue>();
  const [endAt, setEndAt] = useState<DatePickerValue>();
  const { setAllowCreateAdditional, setCreateButtonText } = useFormActions();
  const { studentList } = useStudentSelect({
    classId: selectedClassId,
    checkWaitingList: false,
  });

  useEffect(() => {
    if (classId) {
      // Do our initial fetch of pricing plans, if we have a classId
      fetchPricingPlans(classId).then(setPricingPlans);
    }
  }, [classId]);

  useEffect(() => {
    setAllowCreateAdditional(false);
    setCreateButtonText("Add");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const builder = new FormDefinitionBuilder<AddSchema>()
    .group("Class details", ["classId"])
    .group("Enrollment dates", ["startAt", "endAt"]);

  if (showStudentSelector) {
    builder.group("Students", ["studentIds"]);
  }

  builder.conditional(["studentIds"], ["studentIds"], () => {
    return showStudentSelector;
  });

  const handleSeasonSelect = (season: SeasonDto) => {
    setPricingPlans(season.pricingPlans.filter(plan => plan.enabled));
    setSeasonChange(true);
    setSeasonStartAt(createEnrolmentStartDate(season));
    setSeasonEndAt(season.endAt);
  };

  const handleSeasonClear = () => {
    setSeasonChange(true);
    setSeasonStartAt(undefined);
    setSeasonEndAt(undefined);
  };

  if (!classId) {
    builder.courseId("classId", {
      required: true,
      onSeasonSelect: season => {
        return season ? handleSeasonSelect(season) : handleSeasonClear();
      },
    });
    builder.conditional(["pricingPlanId"], ["classId"], v => {
      return !!v.classId;
    });
  }

  builder
    .multiSelect("studentIds", {
      label: "Select students",
      data: studentList,
      localSearch: true,
      required: true,
    })
    .bigRadio("pricingPlanId", {
      label: "Payment option",
      cols: 2,
      required: true,
      options: pricingPlans.map(plan => ({
        label: pricingPlanLabels[plan.type],
        value: plan.id,
        icon: pricingPlanIcons[plan.type],
      })),
    })
    .date("startAt", {
      label: "Start date",
      maxDate: endAt,
      required: true,
    })
    .date("endAt", {
      label: "End date",
      minDate: startAt,
      required: true,
    })
    .onChange((form, formHandlers) => {
      const courseId = form.classId as CourseSelectValue;

      if (seasonChange) {
        if (pricingPlans.length === 1) {
          formHandlers.setValue("pricingPlanId", pricingPlans[0].id);
        }
        formHandlers.setValue("startAt", seasonStartAt ?? "");
        formHandlers.setValue("endAt", seasonEndAt ?? "");

        setSeasonChange(false);
        return;
      }

      if (selectedClassId !== courseId) {
        setSelectedClassId(courseId ?? undefined);
      }
      if (form.startAt && startAt !== form.startAt) {
        setStartAt(form.startAt);
      }
      if (form.endAt && endAt !== form.endAt) {
        setEndAt(form.endAt);
      }
    });

  return builder.getDefinition() as FormDefinition<AddSchema>;
};

export const useUpdateStartFormDefinition = () => {
  type Schema = UpdateSchema;
  const { setAllowCreateAdditional } = useFormActions();

  setAllowCreateAdditional(false);

  const builder = new FormDefinitionBuilder<Schema>().date("date", {
    label: "Start date",
    required: true,
  });

  return builder.getDefinition() as FormDefinition<Schema>;
};

export const useUpdateEndFormDefinition = () => {
  type Schema = UpdateSchema;
  const { setAllowCreateAdditional } = useFormActions();

  setAllowCreateAdditional(false);

  const builder = new FormDefinitionBuilder<Schema>().date("date", {
    label: "End date",
    required: true,
  });

  return builder.getDefinition() as FormDefinition<Schema>;
};

export const makeEnrolmentAddRequest = (
  classId: CourseDto["id"] | undefined,
  season: SeasonDto | undefined,
) => {
  const { api } = getPlatformFunctions();

  return (data: AddSchema) => {
    if (!data.startAt && season) {
      data.startAt = createEnrolmentStartDate(season);
    }

    if (!data.endAt && season) {
      data.endAt = season.endAt;
    }

    if (dayjs(data.startAt) > dayjs(data.endAt)) {
      return Promise.reject({
        statusCode: 422,
        messages: ["You can't start the enrollment after it ends"],
        validationErrors: {
          startAt: ["Start of enrollment must be before end"],
          endAt: ["End of enrollment must be after start"],
        },
      });
    }

    const enrolments: Array<CreateEnrolmentRequestInner> = data.studentIds.map(
      studentId => ({
        classId: data.classId ?? classId,
        studentId,
        startAt: data.startAt,
        endAt: data.endAt,
        pricingPlanId:
          data.pricingPlanId ?? season?.pricingPlans[0]?.id ?? undefined,
      }),
    );

    return api.enrolments.createEnrolment({
      createEnrolmentRequestInner: enrolments,
    });
  };
};

export const makeDelayStartDateRequest = ({
  classId,
  studentId,
}: Omit<EnrolmentKey, "date">) => {
  const { api } = getPlatformFunctions();
  return ({ date }: UpdateSchema) =>
    api.enrolmentAdjustments.delayedStartEnrolmentEnrolmentAdjustment({
      delayedStartEnrolment: [
        {
          date,
          classId,
          studentId,
        },
      ],
    });
};

export const makeCancellationRequest = ({
  classId,
  studentId,
}: {
  classId: string;
  studentId: string;
}) => {
  const { api } = getPlatformFunctions();
  return ({ date }: UpdateSchema) =>
    api.enrolmentAdjustments.cancelEnrolmentEnrolmentAdjustment({
      enrolmentCancellation: [
        {
          date,
          classId,
          studentId,
        },
      ],
    });
};

export const makeEnrolmentAddForm = (props: FormDefinitionProps) => () =>
  useFormDefinition(props);
