import {
  FeeDto,
  PricingModel,
  PricingPlanDto,
  PricingPlanType,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

import { showAlert } from "../../../../alertState";
import { useAuthState } from "../../../../auth/useAuthState";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { localAdditionalFeeIdPrefix } from "../../../additionalFees/useAdditionalFeeId";
import {
  SeasonFormDefinition,
  useSeasonFormSeasonDetailsDefinition,
} from "../../../formDefinitions/useSeasonFormSeasonDetailsDefinition";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { Sheet } from "../../../interfaces";
import { AlertDialog } from "../../../interfaces/alertDialog";

interface Props {
  season?: SeasonDto;
  goToSeasonDetails: (seasonId: SeasonDto["id"]) => void;
  openSheet: Sheet["openSheet"];
  closeSheet: Sheet["closeSheet"];
  showAlertDialog: AlertDialog;
}

export const SeasonPageForm = ({
  season,
  goToSeasonDetails,
  openSheet,
  closeSheet,
  showAlertDialog,
}: Props) => {
  const { account } = useAuthState();
  const { api } = getPlatformFunctions();
  const { GenericForm, View } = useGenericComponents();
  const { formDefinition, stateValues } = useSeasonFormSeasonDetailsDefinition(
    openSheet,
    closeSheet,
    showAlertDialog,
    season,
  );

  async function submitForm(form: SeasonFormDefinition) {
    await validateForm(form);

    const upfrontPricingPlan = season?.pricingPlans.find(
      plan => plan.type === PricingPlanType.OneOff,
    );
    const monthlyPricingPlan = season?.pricingPlans.find(
      plan => plan.type === PricingPlanType.Monthly,
    );

    const upfrontPricingPlanForRequestData = {
      ...upfrontPricingPlan,
      id: upfrontPricingPlan?.id ?? undefined,
      type: PricingPlanType.OneOff,
      firstPaymentDate: stateValues.paymentStartDate || form.startAt,
      discount: stateValues.discountPercent || form.discountPercent || 0,
      enabled: form.allowUpfrontPricingPlan,
      // we need to coerce this because we're not including all properties
    } as PricingPlanDto;

    const monthlyPricingPlanForRequestData = {
      ...monthlyPricingPlan,
      id: monthlyPricingPlan?.id ?? undefined,
      type: PricingPlanType.Monthly,
      numberOfInstallments:
        stateValues.customNumberOfInstallments ??
        stateValues.numberOfInstallments,
      enabled: form.allowMonthlyPricingPlan,
      paymentDay:
        stateValues.billingDayOfMonth ||
        account!.company!.settings.billing.billingDayOfMonth,
      billingTiming:
        stateValues.billingTiming ||
        account!.company!.settings.billing.billingTiming,
    } as PricingPlanDto;

    const requestData = {
      ...form,
      // todo: remove this default pricing model once we've updated the rest of the form
      pricingModel: PricingModel.Month,
      additionalFees: stateValues.additionalFees.map(fee => {
        if (fee.id.startsWith(localAdditionalFeeIdPrefix)) {
          // Allow the API to create new fees by not providing and ID
          return {
            ...fee,
            id: undefined,
          };
        }
        return fee;
      }) as FeeDto[],
      pricingPlans: [
        upfrontPricingPlanForRequestData,
        monthlyPricingPlanForRequestData,
      ],
    };

    if (season !== undefined) {
      // we're editing an existing season
      return api.seasons.updateSeason({
        id: season.id,
        updateSeasonRequest: requestData,
      });
    }

    return api.seasons.createSeason({ updateSeasonRequest: requestData });
  }

  function submitFormSuccess(result: SeasonDto) {
    const content =
      season !== undefined
        ? t("alert.season.updated", { name: result.name })
        : t("alert.season.created", { name: result.name });

    showAlert({
      content,
    });
    goToSeasonDetails(result.id);
  }

  return (
    <View className={"md:px-24"}>
      <GenericForm
        apiRequest={submitForm}
        defaultValues={{
          enableRegistrationFees: true,
          pricingModel: PricingModel.Month,
          ...makeFormDefaultValues(season),
        }}
        formDefinitionHook={() => formDefinition}
        onSuccess={submitFormSuccess}
      />
    </View>
  );
};

function makeFormDefaultValues(
  season?: SeasonDto,
): Partial<SeasonFormDefinition> {
  if (!season) {
    // the form is likely being used to Create a season
    return {};
  }

  // transform the pricing plan data into form default values
  const upfrontPricingPlan = season.pricingPlans.find(
    plan => plan.type === "one-off",
  );
  const monthlyPricingPlan = season.pricingPlans.find(
    plan => plan.type === "monthly",
  );

  return {
    ...season,

    allowUpfrontPricingPlan: upfrontPricingPlan?.enabled === true,
    discountPercent: upfrontPricingPlan?.discount ?? null,

    allowMonthlyPricingPlan: monthlyPricingPlan?.enabled === true,
    numberOfInstallments: monthlyPricingPlan?.numberOfInstallments ?? null,
    paymentDay: monthlyPricingPlan?.paymentDay ?? null,
    billingTiming: monthlyPricingPlan?.billingTiming ?? null,
  };
}

function validateForm(form: SeasonFormDefinition) {
  const validationErrors: Partial<
    Record<keyof SeasonFormDefinition, string[]>
  > = {};

  if (!form.allowMonthlyPricingPlan && !form.allowUpfrontPricingPlan) {
    validationErrors.allowMonthlyPricingPlan = [
      "Select at least one payment term.",
    ];
  }

  if (form.pricingModel === PricingModel.Month && !form.pricingScheme) {
    validationErrors.pricingScheme = [
      "Select a calculation method for monthly payments.",
    ];
  }

  if (Object.keys(validationErrors).length > 0) {
    return Promise.reject({
      statusCode: 422,
      validationErrors,
    });
  }
}
