import { PropsWithChildren, ReactNode } from "react";

import { SeasonDto } from "@justraviga/classmanager-sdk";

import { FormActionsProvider } from "@shared/components/FormActionsProvider";
import { useGenericComponents } from "@shared/components/GenericComponentsProvider";
import { SeasonPageForm } from "@shared/components/modules/company/seasons/SeasonPageForm";
import { UpdateSeasonFooter } from "@shared/components/modules/company/seasons/UpdateSeasonFooter";
import { Breadcrumb } from "@shared/components/ui/Breadcrumbs";

import { useApi } from "@/lib/api/apiClient";
import { useAlertDialog } from "@/modules/common/overlays/alertDialog/AlertDialogContext";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { PageErrorPlaceholder } from "@/modules/common/ui/PageErrorPlaceholder";
import {
  CompanyContent,
  CompanyLayout,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { Router } from "@/routing/router";

interface UpdateSeasonPageWrapperProps {
  breadcrumbs?: Breadcrumb[];
  goToSeasonDetails: () => void;
  children: ReactNode;
}

interface SeasonUpdatePageProps {
  seasonId: SeasonDto["id"];
}

export const SeasonUpdatePage = ({ seasonId }: SeasonUpdatePageProps) => {
  const { showAlert: showAlertDialog } = useAlertDialog();
  const { openSheet, closeSheet } = useSheet();

  const {
    data: season,
    isLoading,
    isError,
  } = useApi("getSeason", { id: seasonId });

  function goToSeasonDetails() {
    Router.push("SeasonDetails", { id: seasonId });
  }

  return (
    <FormActionsProvider source="SeasonUpdatePage">
      <UpdateSeasonPageWrapper goToSeasonDetails={goToSeasonDetails}>
        <LoadingWrapper isLoading={isLoading}>
          <ErrorWrapper isError={isError}>
            <SeasonPageForm
              season={season}
              goToSeasonDetails={goToSeasonDetails}
              openSheet={openSheet}
              closeSheet={closeSheet}
              showAlertDialog={showAlertDialog}
            />
          </ErrorWrapper>
        </LoadingWrapper>
      </UpdateSeasonPageWrapper>
    </FormActionsProvider>
  );
};

const LoadingWrapper = ({
  isLoading,
  children,
}: PropsWithChildren<{
  isLoading: boolean;
}>) => (isLoading ? <LoadingPlaceholder /> : children);

const ErrorWrapper = ({
  isError,
  children,
}: PropsWithChildren<{
  isError: boolean;
}>) => (isError ? <PageErrorPlaceholder /> : children);

const UpdateSeasonPageWrapper = ({
  goToSeasonDetails,
  children,
}: UpdateSeasonPageWrapperProps) => {
  const { View } = useGenericComponents();

  return (
    <CompanyLayout
      footer={<UpdateSeasonFooter goToSeasonDetails={goToSeasonDetails} />}>
      <View
        className={
          "flex flex-row items-center justify-between pb-5 md:px-8 md:py-5"
        }>
        <CompanyTitleBarTitle title={t("pageTitle.seasonUpdate")} />
      </View>
      <CompanyContent>{children}</CompanyContent>
    </CompanyLayout>
  );
};
