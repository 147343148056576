import { UserAccountDto, UserDto } from "@justraviga/classmanager-sdk"; // The core auth data that is stored on login

// The core auth data that is stored on login
export interface AuthState {
  user: UserDto | null;
  account: UserAccountDto | null;
  token: string | null;
  isLoggedIn: boolean;
}

// The interface that is exposed to non-React code (e.g. library code, SDK middleware, etc)
export interface AuthStateInterface {
  load: () => Promise<AuthState>;
  addListener: (name: string, listener: Listener) => void;
  removeListener: (name: string) => void;
  setUser: (user: UserDto, token: string) => void;
  setAccount: (account: UserAccountDto | null) => void;
  clearAuthState: () => void;
}

type OnLoginFn = () => void;

// The interface that is exposed inside React components when using useAuth()
export interface AuthContextInterface extends AuthState {
  setUser: (
    user: UserDto,
    token: string,
    account?: UserAccountDto | null,
  ) => void;
  setAccount: (account: UserAccountDto | null) => void;
  clearAuthState: () => void;
  isLoading: boolean;
  accounts: UserAccountDto[];
  accountsLoading: boolean;
  onLogin: OnLoginFn | undefined;
  setOnLogin: (newOnLogin: OnLoginFn | undefined) => void;
}

export const createEmptyAuthState = (): AuthState => ({
  user: null,
  account: null,
  token: null,
  isLoggedIn: false,
});

type Retrieve = () => Promise<AuthState | null>;
type Persist = (data: AuthState) => void;
type Listener = (state: AuthState) => void;

// Each platform will call this to instantiate a new auth state, passing in functions to
// retrieve and persist the auth state to the platform's storage mechanism (e.g. async storage, local storage, etc)
export const createAuthState = (
  retrieve: Retrieve,
  persist: Persist,
): AuthStateInterface => {
  /**
   * Keep a local copy of the auth state to avoid unnecessary async storage reads
   */
  const authState: AuthState = createEmptyAuthState();
  let loaded = false;

  const listeners = new Map<string, Listener>();

  const notifyListeners = (state: AuthState) => {
    listeners.forEach(listener => listener(state));
  };

  const load = (): Promise<AuthState> => {
    return new Promise(resolve => {
      // By default, we return the local copy of the auth state which is faster
      // than reading from async storage
      if (loaded) {
        return resolve(authState);
      } else {
        retrieve()
          .then(data => {
            const state = data ?? createEmptyAuthState();
            setAuthState(state);
            resolve(state);
          })
          .finally(() => {
            loaded = true;
          });
      }
    });
  };

  const setAuthState = (state: Partial<AuthState>) => {
    Object.assign(authState, state);
    notifyListeners(authState);
    persist(authState);
  };

  const addListener = (name: string, listener: Listener) => {
    if (!listeners.has(name)) {
      listeners.set(name, listener);
    }
  };

  const removeListener = (name: string) => {
    listeners.delete(name);
  };

  const setUser = (
    user: UserDto,
    token: string,
    account: UserAccountDto | null = null,
  ) => {
    setAuthState({ user, token, account, isLoggedIn: true });
  };

  const setAccount = (account: UserAccountDto | null) => {
    setAuthState({ account });
  };

  const clearAuthState = () => {
    setAuthState(createEmptyAuthState());
  };

  return {
    addListener,
    removeListener,
    load,
    setUser,
    setAccount,
    clearAuthState,
  };
};
