import {
  Breadcrumb,
  useEnrolmentsActions,
  useStudentEnrolmentsDatatable,
} from "shared/components";
import {
  getEnrolmentEndDate,
  getEnrolmentStartDate,
  getFullName,
  useStudentEnrolmentsData,
} from "shared/lib";

import { api, useApi } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { Tabs } from "@/modules/common/nav/Tabs";
import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { Button } from "@/modules/common/ui/button/Button";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";
import {
  CompanyBreadCrumbs,
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const StudentEnrolmentsPage = ({ studentId }: { studentId: string }) => {
  const actions = useEnrolmentsActions();

  const { currentFilter, student, tabItems } = useStudentEnrolmentsData({
    studentId,
    useApi,
  });

  const { datatable } = useStudentEnrolmentsDatatable({
    Datatable,
    api,
    studentId,
    filter: currentFilter,
    goToClass: id => Router.push("ClassDetails", { id }),
    showUpdateStartForm: item =>
      actions.showUpdateStartForm({
        classId: item.class.entity.id,
        studentId: item.student.id,
        date: getEnrolmentStartDate(item),
      }),
    showUpdateEndForm: item =>
      actions.showUpdateEndForm({
        classId: item.class.entity.id,
        studentId: item.student.id,
        date: getEnrolmentEndDate(item),
      }),
  });

  if (!student) {
    return <LoadingPlaceholder />;
  }

  const breadcrumbs: Breadcrumb[] = [
    {
      text: "Students",
      onClick: () => Router.push("StudentList"),
    },
    {
      text: getFullName(student),
      onClick: () => Router.push("StudentDetails", { id: student.id }),
    },
    {
      text: "Enrollments",
    },
  ];

  return (
    <CompanyLayout datatablePage>
      <CompanyBreadCrumbs crumbs={breadcrumbs} />
      <CompanyTitleBar isTopLevel={true}>
        <CompanyTitleBarTitle title={"Enrollments"} />
        <div>
          <ProtectedOverlay permission="enrolments:manage">
            <Button
              text={"Enroll"}
              size="sm"
              onClick={() =>
                actions.showCreateForm({
                  studentIds: [studentId],
                })
              }
              variant="brand"
            />
          </ProtectedOverlay>
        </div>
      </CompanyTitleBar>
      <Tabs items={tabItems} />
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "enrolments:view",
  StudentEnrolmentsPage,
  "Student enrolments",
);

export { PermissionChecked as StudentEnrolmentsPage };
