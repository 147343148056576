import { useEffect } from "react";

import { SortSchema, TrialDto } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { getFullName } from "../../personUtil";
import { getPlatformFunctions } from "../../platformSpecific";
import { useFormActions } from "../FormActionsProvider";
import { LessonSelectValue } from "../forms/LessonSelect";

type Schema = {
  studentIds?: string[];
  lessonDateTime: LessonSelectValue;
};
type Action = "create" | "update";

const useDefinition = ({
  mode,
  studentIds,
}: {
  mode: Action;
  studentIds?: string[];
}) => {
  const { useApi } = getPlatformFunctions();
  const formActions = useFormActions();
  const { data: students } = useApi(
    "listStudent",
    {
      selectAll: true,
      sort: {
        firstname: SortSchema.Asc,
        lastname: SortSchema.Asc,
      },
    },
    { enabled: mode === "create" },
  );

  useEffect(() => {
    if (mode === "create") {
      formActions.setCreateButtonText("Save");
      formActions.setAllowCreateAdditional(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const builder = new FormDefinitionBuilder<Schema>();

  builder
    .lessonDateTime("lessonDateTime", {
      required: true,
    })
    .group("Lesson details", ["lessonDateTime"]);

  if (mode === "create" && !studentIds) {
    builder
      .multiSelect("studentIds", {
        label: "Select students",
        required: true,
        localSearch: true,
        data: (students?.data ?? []).map(student => ({
          label: getFullName(student),
          value: student.id,
        })),
      })
      .group("Students", ["studentIds"]);
  }

  return builder.getDefinition() as FormDefinition<Schema>;
};

export const makeTrialCreateFormDefinition =
  ({ studentIds }: { studentIds?: string[] }) =>
  () =>
    useDefinition({ mode: "create", studentIds });

export const makeTrialUpdateFormDefinition = () => () =>
  useDefinition({ mode: "update" });

export const makeTrialCreateRequest =
  ({ api, studentIds }: { api: Api; studentIds?: string[] }) =>
  (data: Schema) => {
    const [courseId, date, time] = data.lessonDateTime!;
    const innerStudentIds = studentIds || data?.studentIds || [];

    return api.trials.createTrial({
      createTrialRequestInner: innerStudentIds.map(studentId => ({
        studentId,
        classId: courseId!,
        trialAt: date!,
        time: time!,
      })),
    });
  };

export const makeTrialUpdateRequest =
  ({ api, trial }: { api: Api; trial: TrialDto }) =>
  (data: Schema) => {
    const [courseId, date, time] = data.lessonDateTime!;
    return api.trials.updateTrial({
      id: trial.id,
      updateTrialRequest: {
        classId: courseId!,
        trialAt: date!,
        time: time!,
      },
    });
  };
