import { UpdateHolidayRequest } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

// I think the intention is that the API uses the same request for creating and updating
type CreateSchema = UpdateHolidayRequest;
type UpdateSchema = UpdateHolidayRequest;

const useDefinition = () => {
  type Schema = CreateSchema & UpdateSchema;

  const builder = new FormDefinitionBuilder<Schema>()
    .text("name", {
      label: "Name",
      required: true,
      description: "This will be displayed on the Family Portal",
    })
    .date("startAt", { label: "Start date", required: true })
    .date("endAt", { label: "End date", required: true });

  return builder.getDefinition();
};

export const makeHolidayCreateFormDefinition = () => () => useDefinition();

export const makeHolidayUpdateFormDefinition = () => () => useDefinition();

export const makeHolidayCreateRequest =
  ({ api }: { api: Api }) =>
  (formData: CreateSchema) => {
    return api.holidays.createHoliday({
      updateHolidayRequest: formData,
    });
  };

export const makeHolidayUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateSchema) => {
    return api.holidays.updateHoliday({
      id,
      updateHolidayRequest: formData,
    });
  };
