import { TransactionItem } from "./TransactionItem";
import { TransactionItemsList } from "./TransactionItemsList";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const TransactionDetails = ({
  transaction,
}: {
  transaction: {
    discountTotal: number;
    items: {
      description: string;
      subtotal: number;
    }[];
  };
}) => {
  const { HorizontalSeparator, View } = useGenericComponents();
  return (
    <View className={"space-y-1"}>
      <TransactionItemsList items={transaction.items} />
      {transaction.discountTotal < 0 ? (
        <View>
          <HorizontalSeparator spacing={2} />
          <TransactionItem
            description={"Discounts"}
            total={transaction.discountTotal}
            discount={true}
          />
        </View>
      ) : null}
    </View>
  );
};
