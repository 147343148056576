import {
  ErrorContext,
  ErrorResponse,
  FetchParams,
  RequestContext,
} from "@justraviga/classmanager-sdk";

import { publicCompanyId } from "./publicCompanyContext";
import { showAlert } from "../alertState";
import { AuthStateInterface } from "../authState";
import { rateLimiter } from "../lib/common/callbackUtils/rateLimiter";
import { queryClient } from "../tanstackQuery";

/**
 * Make sure cookies are never sent or received - requests should be stateless
 */
export const noCookies =
  () =>
  (context: RequestContext): Promise<FetchParams | void> => {
    // Never send or receive cookies
    context.init.credentials = "omit";

    return Promise.resolve(context);
  };

/**
 * Add the auth token to the request headers
 */
export const addAuthHeader =
  (authState: AuthStateInterface) =>
  async (context: RequestContext): Promise<FetchParams | void> => {
    if (context.init.headers) {
      const token = (await authState.load()).token;
      if (token) {
        // @ts-expect-error We know that this header isn't defined on the spec
        context.init.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    return Promise.resolve(context);
  };

/**
 * Add the account ID to the request headers in order to make the request in the context of a particular company
 */
export const addAccountHeader =
  (authState: AuthStateInterface) =>
  async (context: RequestContext): Promise<FetchParams | void> => {
    if (context.init.headers) {
      const accountId = (await authState.load()).account?.id;
      if (accountId) {
        // @ts-expect-error We know that this header isn't defined on the spec
        context.init.headers["x-account-id"] = accountId;
      }
      if (publicCompanyId) {
        // @ts-expect-error We know that this header isn't defined on the spec
        context.init.headers["x-company-id"] = publicCompanyId;
      }
    }

    return Promise.resolve(context);
  };

/**
 * If we receive any 401 Unauthorized responses, redirect the user to the login page
 */
export const logoutIfNotAuthed =
  (authState: AuthStateInterface, onLogout: () => void) =>
  (context: ErrorContext) => {
    // When we encounter a 401 Unauthorized response, show a custom message
    // then redirect to the login screen
    const error = context.error as ErrorResponse | undefined;
    if (error?.statusCode === 401) {
      // Clear our local auth state
      authState.clearAuthState();
      // Clear Tanstack query caches
      queryClient.clear();
      // Redirect to the login page
      onLogout();

      rateLimiter({ key: "not.logged.in.message", delay: 300 }, () =>
        showAlert({ content: "You are not logged in", variant: "error" }),
      );

      // Prevent propagation of the error to other middleware
      throw new Error("You are not logged in");
    }

    return Promise.resolve(context.response);
  };

/**
 * By default, display any non-field-specific errors returned by the API.
 * This is useful for things like network errors, or if the API returns an
 * action-level exception.
 *
 * This avoids us having to manually remember to do this for every API call
 * or form submission.
 */
export const showGenericApiErrors = () => (context: ErrorContext) => {
  const error = context.error as ErrorResponse | undefined;
  // Check if this looks like a recognisable error response
  if (error && "messages" in error) {
    // Show any action-level errors as an alert
    if (Array.isArray(error.messages) && error.messages.length > 0) {
      showAlert({
        content: error.messages[0], // for the time being, we're just returning single error messages from the API
        variant: "error",
        dismissible: true,
        timeout: 4000,
      });
    }
  } else {
    // We didn't get a proper error response from the API
    // This is likely a network error
    showAlert({
      content:
        "There was a problem connecting to the server. Please check your internet connection and try again.",
      variant: "critical",
      dismissible: true,
      timeout: 4000,
    });
  }

  return Promise.resolve(context.response);
};
