import {
  LocationDto,
  RoomDto,
  StaffDto,
  WeekDay,
} from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { DatatableFilter, enumLabel } from "shared/lib";

import { useGenericComponents } from "../../../../GenericComponentsProvider";
import { ClearableChip } from "../../../../ui/ClearableChip";

export const SeasonScheduleFilterChips = ({
  scheduleFilters,
  onRemoveFilter,
  onUpdateFilterValue,
  staffMembers,
  rooms,
  locations,
}: {
  scheduleFilters: DatatableFilter[];
  onRemoveFilter: (field: string) => void;
  onUpdateFilterValue: (field: string, value: string[]) => void;
  staffMembers: StaffDto[];
  rooms: RoomDto[];
  locations: LocationDto[];
}) => {
  const { View } = useGenericComponents();
  const chips = scheduleFilters
    .map(filter => {
      const makeLabel = (label: string) => ({
        label,
        action: () => {
          onRemoveFilter(filter.field);
        },
      });
      return match(filter)
        .with({ field: "dayOfWeek" }, () => {
          const selectedWeekDays = filter.value as WeekDay[];
          return selectedWeekDays.map(dayOfWeek => {
            const newWeekList = selectedWeekDays.filter(v => v !== dayOfWeek);
            return {
              label: enumLabel("weekDays", dayOfWeek),
              action: () => onUpdateFilterValue(filter.field, newWeekList),
            };
          });
        })
        .with({ field: "staffId" }, () => {
          const staff = staffMembers.find(s => s.id === filter.value);
          return staff
            ? makeLabel(`${staff.firstname} ${staff.lastname}`)
            : null;
        })
        .with({ field: "roomId" }, () => {
          const roomId = Array.isArray(filter.value)
            ? filter.value[0]
            : undefined;

          const room = rooms.find(r => r.id === roomId);
          const location = room
            ? locations.find(l => l.id === room.locationId)
            : undefined;

          return location ? makeLabel(location.name) : null;
        })
        .with({ field: "isArchived" }, () => ({
          label: "Show archived",
          action: () => onRemoveFilter("isArchived"),
        }))
        .otherwise(() => {
          return null;
        });
    })
    .flat()
    .filter(option => option !== null);

  return chips.length > 0 ? (
    <View className={"pt-6"}>
      <ClearableChip chips={chips} />
    </View>
  ) : null;
};
