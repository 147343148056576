import { useState } from "react";

import { Option } from "./adminPageTypes";
import { BigRadioProps, BigRadioValue } from "../../forms/formComponentProps";

export const useBigRadioOptions = (): Array<Option<BigRadioProps>> => {
  const [value, setValue] = useState<BigRadioValue>();
  return [
    {
      title: "defaults",
      props: [
        {
          label: "Label",
          description: "Description",
          value,
          onChange: setValue,
          options: [
            {
              label: "Option 1",
              description: "Description",
              value: "value1",
              icon: "apps",
            },
            {
              label: "Option 2",
              description: "Description",
              value: "value2",
              icon: "apps",
            },
          ],
        },
        {
          label: "Disabled",
          description: "Second option disabled",
          value,
          onChange: setValue,
          options: [
            {
              label: "Option 1",
              description: "Description",
              value: "value1",
              icon: "apps",
            },
            {
              label: "Option 2",
              description: "Description",
              value: "value2",
              icon: "apps",
              disabled: true,
            },
          ],
        },
      ],
    },
    {
      title: "Variable cols",
      props: [
        {
          label: "Label",
          description: "One col",
          cols: 1,
          value,
          onChange: setValue,
          options: [
            {
              label: "Option 1",
              description: "Description",
              value: "value1",
              icon: "apps",
            },
            {
              label: "Option 2",
              description: "Description",
              value: "value2",
              icon: "apps",
            },
            {
              label: "Option 3",
              description: "Description",
              value: "value3",
              icon: "apps",
            },
          ],
        },
        {
          label: "Label",
          description: "Two cols",
          cols: 2,
          value,
          onChange: setValue,
          options: [
            {
              label: "Option 1",
              description: "Description",
              value: "value1",
              icon: "apps",
            },
            {
              label: "Option 2",
              description: "Description",
              value: "value2",
              icon: "apps",
            },
            {
              label: "Option 3",
              description: "Description",
              value: "value3",
              icon: "apps",
            },
          ],
        },
        {
          label: "Label",
          description: "Three cols",
          cols: 3,
          value,
          onChange: setValue,
          options: [
            {
              label: "Option 1",
              description: "Description",
              value: "value1",
              icon: "apps",
            },
            {
              label: "Option 2",
              description: "Description",
              value: "value2",
              icon: "apps",
            },
            {
              label: "Option 3",
              description: "Description",
              value: "value3",
              icon: "apps",
            },
          ],
        },
      ],
    },
    {
      title: "Icons",
      props: [
        {
          label: "star",
          options: [
            {
              label: "star",
              icon: "star",
              value: "star",
            },
          ],
        },
        {
          label: "calendar",
          options: [
            {
              label: "calendar",
              icon: "calendar",
              value: "calendar",
            },
          ],
        },
        {
          label: "warning",
          options: [
            {
              label: "warning",
              icon: "warning",
              value: "warning",
            },
          ],
        },
      ],
    },
    {
      title: "Chips",
      props: [
        {
          label: "Chip",
          options: [
            {
              label: "Boring option",
              icon: "closeCircle",
              value: "boring",
            },
            {
              label: "Chip option",
              icon: "star",
              value: "chip",
              chipLabel: "Chip",
            },
          ],
        },
        {
          label: "Chip",
          description: "With description",
          options: [
            {
              label: "Boring option",
              description: "Boring option description",
              icon: "closeCircle",
              value: "boring",
            },
            {
              label: "Chip option",
              description: "Awesome option description",
              icon: "star",
              value: "chip",
              chipLabel: "Chip",
            },
          ],
        },
        {
          label: "Chip Variant",
          description: "With description",
          options: [
            {
              label: "Boring option",
              description: "Boring option description",
              icon: "closeCircle",
              value: "boring",
            },
            {
              label: "Chip option",
              description: "Awesome option description",
              icon: "star",
              value: "chip",
              chipLabel: "Chip",
              chipVariant: "success",
            },
          ],
        },
      ],
    },
  ];
};
