import { FeeDto } from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../platformSpecific";
import { AdditionalFeeForm } from "../forms/AdditionalFeeForm";

export const useAdditionalFeeActions = () => {
  const { usePlatformEntityActions } = getPlatformFunctions();
  const defaultActions = usePlatformEntityActions<FeeDto>({
    entity: "fee",
  });

  return {
    deleteOne: (
      additionalFee: FeeDto,
      removeAdditionalFee: (item: FeeDto) => void,
    ) => {
      return new Promise<void>((resolve, reject) => {
        defaultActions
          .showConfirmationDialog({
            title: `Delete additional fee`,
            description: `Are you sure you want to permanently delete this additional fee? This cannot be undone.`,
            confirmButtonText: "Delete",
            cancelText: "Cancel",
          })
          .then(result => {
            if (!result) {
              return reject(undefined);
            }

            removeAdditionalFee(additionalFee);

            return resolve(undefined);
          });
      });
    },

    showCreateForm: (updateAdditionalFee: (item: FeeDto) => FeeDto) =>
      defaultActions.showCreateForm({
        title: "Add additional fee",
        form: (
          <AdditionalFeeForm
            updateAdditionalFee={updateAdditionalFee}
            onSuccess={() => defaultActions.hideForm()}
          />
        ),
      }),

    showUpdateForm: (
      additionalFee: FeeDto,
      editAdditionalFee: (item: FeeDto) => FeeDto,
    ) =>
      defaultActions.showUpdateForm({
        title: "Edit additional fee",
        form: (
          <AdditionalFeeForm
            defaultValues={additionalFee}
            updateAdditionalFee={editAdditionalFee}
            onSuccess={() => defaultActions.hideForm()}
          />
        ),
      }),
  };
};
