export const common = {
  "alert.season.created": "Season {{name}} created successfully",
  "alert.season.updated": "Season {{name}} updated successfully",
  "banner.registrationEnded.description": "Season ended",
  "breadcrumb.seasons": "Seasons",
  "button.createSeason": "Create season",
  "button.goToSeasons": "Go to seasons",
  "description.addAdditionalFees":
    "You can add additional fees that will only apply to students enrolling in this season.",
  "description.pricingPlan":
    "Select how you’d like to define your class price: by lesson, by month, or by season, any fixed discounts will be applied to this selection. If you select by month or by season, holidays won’t affect the price.",
  "description.seasons":
    "Seasons can be used to plan weekly classes and enroll students on an annual basis.",
  "description.taxRates":
    "You can configure the tax rates you charge for everything you sell. Set a default rate to save time when setting up seasons & classes.",
  "description.unpostedTuitionForm":
    "Use this to generate unposted tuition for seasons if you think a bill may have been missed.",
  "entity.instalments_one": "installment",
  "entity.instalments_other": "installments",
  "entity.season_one": "season",
  "entity.season_other": "seasons",
  "globalSearch.description":
    "Find students, families, staff, classes, seasons and more...",
  "heading.enrolments.bySeason": "By season",
  "label.ageCutOff.firstDayOfSeason": "First day of season",
  "label.ageCutOff.firstDayOfSeason.description":
    "Each season will have a different age cut off date.",
  "label.ageCutOff.7DaysAfter": "7 days after the start of the season",
  "label.ageCutOff.7DaysAfter.description":
    "Each season will have a different age cut off date.",
  "label.ageCutOff.28DaysAfter": "28 days after the start of the season",
  "label.ageCutOff.28DaysAfter.description":
    "Each season will have a different age cut off date.",
  "label.ageCutOff.specificDate": "All seasons will use the same cut off date.",
  "label.courseFilter.season": "By season",
  "label.emailRecipients.addBySeason": "Add by season",
  "label.pricingPlan.bySeason": "By season",
  "label.registrationFeeInterval.perSeason": "Per season",
  "label.registrationFeeFormTitle.perSeason": "{{start}} per season fee",
  "label.season.payUpFront": "Pay for season upfront",
  "label.season": "Season",
  "label.seasons": "Seasons",
  "nav.mySeasons": "My seasons",
  "nav.seasons": "Seasons",
  "pageTitle.seasonArchive": "Archived seasons",
  "pageTitle.seasonCreate": "Create season",
  "pageTitle.seasonDetails": "Season details",
  "pageTitle.seasonUpdate": "Edit season",
  "pageTitle.seasonPreview": "Season preview",
  "pageTitle.seasons": "Seasons",
  "placeholder.familyEnrolments.noClassesYet":
    "You haven't enrolled for any classes in this season.",
  "placeholder.noArchivedSeasons": "No archived seasons",
  "placeholder.noSeasons": "No seasons yet",
  "placeholder.noSeasons.description":
    "You can set up your weekly calendar by creating seasons. They can be used to plan weekly classes and enroll students on an annual basis.",
  "placeholder.seasonNotFound.description":
    "The season you are looking for could not be found.",
  "placeholder.seasonNotFound.title": "Season not found",
  "paymentMethod.Ach": "ACH",
  "paymentMethod.Check": "Check",
} as const;
