import { CheckoutItem } from "./CheckoutItem";
import { cn } from "../../../../cssUtils";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { TextStyle } from "../../../text/StyledText";

export const CheckoutSection = ({
  children,
  heading,
  headingWeight = "heading",
  headingSuffix,
  spacing = "normal",
}: {
  children: React.ReactNode;
  heading?: string;
  headingWeight?: TextStyle;
  headingSuffix?: React.ReactNode;
  spacing?: "normal" | "tight" | "wide";
}) => {
  const { View } = useGenericComponents();
  return (
    <View
      className={cn("flex flex-col", {
        "gap-y-3": spacing === "normal" || spacing === "wide",
        "gap-y-2": spacing === "tight",
      })}>
      {heading && (
        <View>
          <CheckoutItem
            text={heading}
            style={headingWeight}
            suffix={headingSuffix}
          />
        </View>
      )}
      <View>
        <View
          className={cn("flex flex-col", {
            "gap-y-4": spacing === "normal",
            "gap-y-2": spacing === "tight",
            "gap-y-8": spacing === "wide",
          })}>
          {children}
        </View>
      </View>
    </View>
  );
};
