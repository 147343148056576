import { AggregateTransactionDto } from "@justraviga/classmanager-sdk";

import { AggregateTransactionDetailsList } from "./AggregateTransactionDetailsList";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const AggregateTransaction = ({
  transaction,
}: {
  transaction: AggregateTransactionDto;
}) => {
  const { View, Text } = useGenericComponents();

  return (
    <View
      key={transaction.transaction.description}
      className={"w-full space-y-2"}>
      <Text className={"overflow-hidden text-heading6-600 text-grey-900"}>
        {transaction.transaction.description}
      </Text>
      <AggregateTransactionDetailsList transaction={transaction} />
    </View>
  );
};
