import { useState } from "react";

import { FamilyDto } from "@justraviga/classmanager-sdk";
import { CreateWaitingListRequestInner } from "@justraviga/classmanager-sdk/dist/models/CreateWaitingListRequestInner";

import { showAlert } from "../../../../alertState";
import { validateStudentOnCourse } from "../../../../basket/basketUtils";
import {
  ageRangeDescription,
  displayLessonTimes,
} from "../../../../classUtils";
import { cn } from "../../../../cssUtils";
import { useCatalogueData } from "../../../../data/useCatalogueData";
import { getAgeInYears } from "../../../../dateUtils";
import { useAuthenticatedAccountEntity } from "../../../../hooks/useAuthenticatedAccountEntity";
import { getFullName } from "../../../../personUtil";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { enumLabel } from "../../../../translateUtils";
import { StudentImplementedActions } from "../../../actions/useSharedStudentActions";
import { useCatalogueContext } from "../../../catalogue/useCatalogueContext";
import { CoursePrice } from "../../../courseSearch/CoursePrice";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { MultiSelectComponent } from "../../../interfaces";
import { BasketItemStudentErrors } from "../checkout/BasketItemStudentErrors";
import { CheckoutLayout } from "../checkout/CheckoutLayout";
import { CheckoutLeftColumn } from "../checkout/CheckoutLeftColumn";
import { CheckoutRightColumn } from "../checkout/CheckoutRightColumn";
import { NoStudentsSelected } from "../checkout/NoStudentsSelected";
import { SummaryPageHeader } from "../checkout/SummaryPageHeader";

export const JoinWaitingListPage = ({
  courseId,
  useStudentActions,
  MultiSelect,
  goToSuccess,
  showSummaryPageHeader = true,
}: {
  courseId: string;
  MultiSelect: MultiSelectComponent;
  useStudentActions: () => StudentImplementedActions;
  goToSuccess: () => void;
  showSummaryPageHeader?: boolean;
}) => {
  const family = useAuthenticatedAccountEntity() as FamilyDto;
  const { Button, HorizontalSeparator, Text, View } = useGenericComponents();
  const { api, useApi } = getPlatformFunctions();
  const { data: familyMembers } = useApi("listStudent", {
    selectAll: true,
  });
  const { data: enrolments } = useApi("listEnrolment", {
    selectAll: true,
  });
  const [shouldValidate, setShouldValidate] = useState(false);
  const { courses, seasons } = useCatalogueData(useApi, true);
  const course = courses?.find(course => course.entity.id === courseId);
  const season = seasons?.find(season => season.id === course?.entity.seasonId);
  const [studentIds, setStudentIds] = useState<string[]>([]);
  const students =
    familyMembers?.data.filter(student => studentIds.includes(student.id)) ??
    [];
  const { showPortalCreateForm: showCreateStudentForm } = useStudentActions();
  const company = useCatalogueContext().company;

  if (!course || !season) {
    return <View>Course not found</View>;
  }

  const courseTime = `${enumLabel("weekDaysShort", course.entity.dayOfWeek)} • ${displayLessonTimes(
    {
      startTime: course.entity.startTime,
      durationInMinutes: course.entity.durationInMinutes,
    },
  )}`;

  const prepareDataToSubmit = (): Required<CreateWaitingListRequestInner>[] => {
    return studentIds.map(studentId => ({
      classId: course.entity.id,
      studentId,
    }));
  };

  const fail = () => {
    setShouldValidate(true);
    showAlert({
      content: "Some students need to be checked.",
      variant: "error",
    });
    return;
  };

  const submit = () => {
    const submitData = prepareDataToSubmit();

    if (studentIds.length === 0) {
      fail();
      return;
    }

    for (const student of students) {
      if (
        !validateStudentOnCourse(
          student,
          course.entity,
          season,
          enrolments?.data ?? [],
          company,
          true,
        ).valid
      ) {
        fail();
        return;
      }
    }

    api.waitingLists
      .createWaitingList({
        createWaitingListRequestInner: submitData,
      })
      .then(goToSuccess);
  };

  return (
    <CheckoutLayout>
      <CheckoutLeftColumn>
        {showSummaryPageHeader && (
          <SummaryPageHeader
            showCreateForm={() => showCreateStudentForm(family)}
            title={"Join the waiting list"}
          />
        )}
        <View className={cn({ "mt-10": showSummaryPageHeader })}>
          <View className={"mb-1 flex flex-row items-center justify-between"}>
            <Text className={"text-label-400 text-grey-600"}>{courseTime}</Text>
            <CoursePrice
              mode={"enrol"}
              course={course.entity}
              season={season}
            />
          </View>
          <View className={"md:flex mt-1"}>
            <Text>{course.entity.name}</Text>
            <Text className={"text-grey-600 md:pl-2"}>
              (
              {ageRangeDescription(
                course.entity.minAgeMonths,
                course.entity.maxAgeMonths,
              )}
              )
            </Text>
          </View>
          <View className={"grid grid-cols-5 mt-2"}>
            <View className={"col-span-5 my-2 md:col-span-3"}>
              <MultiSelect
                onSelect={values => setStudentIds(values as string[])}
                placeholder={"No student selected"}
                data={(familyMembers?.data ?? []).map(student => {
                  return {
                    label: getFullName(student),
                    value: student.id,
                    description: student.dateOfBirth
                      ? `Age ${getAgeInYears(student.dateOfBirth)}`
                      : "-",
                    avatar: student.profilePicture ?? undefined,
                    disabled: !validateStudentOnCourse(
                      student,
                      course.entity,
                      season,
                      enrolments?.data ?? [],
                      company,
                    ).valid,
                  };
                })}
              />
            </View>
          </View>
          <View className={"flex flex-col space-y-2"}>
            {shouldValidate && studentIds.length === 0 && (
              <NoStudentsSelected />
            )}
            <BasketItemStudentErrors
              students={students}
              course={course}
              season={season}
              enrolments={enrolments?.data ?? []}
              useStudentActions={useStudentActions}
              enforceValidation={shouldValidate}
            />
          </View>
        </View>
      </CheckoutLeftColumn>
      <CheckoutRightColumn>
        <View className={"md:hidden"}>
          <HorizontalSeparator spacing={4} />
        </View>
        <Button
          variant={"brand"}
          text={"Confirm"}
          size={"lg"}
          isFullWidth={true}
          onClick={submit}
        />
      </CheckoutRightColumn>
    </CheckoutLayout>
  );
};
