import React from "react";

import { cn } from "@shared/cssUtils";

import { NavLink } from "@/modules/common/nav/Nav";
import { Icon } from "@/modules/common/ui/icon/Icon";

type NavItemWithSubMenuProps = {
  item: NavLink;
  onClick: (item: NavLink) => void;
  isSelected: boolean;
  isExpanded: boolean;
};

export const NavItemWithSubMenu = React.memo(
  ({ item, onClick, isSelected, isExpanded }: NavItemWithSubMenuProps) => {
    return (
      <div
        onClick={() => onClick(item)}
        className={cn(
          "flex h-[2.5rem] cursor-pointer items-center justify-between gap-4 rounded",
          isSelected
            ? "bg-black text-white"
            : `text-brand-contrast hover:bg-grey-900/10`,
          isExpanded ? "px-4" : "px-2",
        )}>
        <div className="flex w-6 items-center justify-center">
          <Icon
            size={24}
            name={item.icon}
            className={isSelected ? "text-white" : "text-brand-contrast"}
          />
        </div>
        {isExpanded && <span className="flex-grow truncate">{item.label}</span>}
        {isExpanded && (
          <Icon
            size={16}
            name={"chevronForwardOutline"}
            className={isSelected ? "text-white" : "text-brand-contrast"}
          />
        )}
      </div>
    );
  },
);
