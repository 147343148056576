import { SortSchema } from "@justraviga/classmanager-sdk";

import { NoSeasonsPlaceholder } from "shared/components";

import { useData } from "@/lib/api/apiClient";
import {
  CardContainerContent,
  CardContainerPlaceholder,
} from "@/modules/common/cardContainer/CardContainer";
import { PrimaryActionItemProps } from "@/modules/common/cardContainer/cardContainerTypes";
import { CompanyCardPage } from "@/modules/common/cardContainer/CompanyCardPage";
import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { SeasonCard } from "@/modules/company/classPlanner/seasons/card/SeasonCard";
import { useSeasonActions } from "@/modules/company/classPlanner/seasons/useSeasonActions";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const SeasonListPage = () => {
  const { data: seasons } = useData("listSeason", {
    selectAll: true,
    sort: { startAt: SortSchema.Desc },
  });
  const isEmpty = seasons.length === 0;

  const seasonActions = useSeasonActions();

  const goToArchivedItems = () => Router.push("SeasonListArchived");
  const showCreateForm = () => seasonActions.showCreateForm();

  const primaryAction: PrimaryActionItemProps = {
    icon: "addOutline",
    title: "Create",
    onClick: showCreateForm,
    permission: "schedule:manage",
  };
  const menuItems: ActionMenuItemProps[] = [
    {
      title: "Go to archived items",
      leftIcon: "archiveOutline",
      onClick: goToArchivedItems,
      permission: "schedule:view",
    },
  ];

  return (
    <CompanyCardPage
      title={t("pageTitle.seasons")}
      isEmpty={isEmpty}
      primaryAction={primaryAction}
      menuItems={menuItems}>
      <CardContainerPlaceholder>
        <NoSeasonsPlaceholder
          goToArchivedItems={goToArchivedItems}
          onCreateClick={seasonActions.showCreateForm}
        />
      </CardContainerPlaceholder>
      <CardContainerContent maxColumns={2}>
        {seasons.map((season, key) => (
          <SeasonCard season={season} key={key} />
        ))}
      </CardContainerContent>
    </CompanyCardPage>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "schedule:view",
  SeasonListPage,
  t("pageTitle.seasons"),
);

export { PermissionChecked as SeasonListPage };
