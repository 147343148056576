import { useEffect } from "react";

import { useLocation } from "@swan-io/chicane";
import { match } from "ts-pattern";

import { showAlert } from "@shared/alertState";
import { BasketProvider } from "@shared/components/basket/BasketProvider";
import { CatalogueContextProvider } from "@shared/components/catalogue/CatalogueContextProvider";
import {
  DayOfWeek,
  routeParamsToSearchValues,
} from "@shared/components/courseSearch/courseSearchContext";
import { CourseSearchProvider } from "@shared/components/courseSearch/CourseSearchProvider";
import { CoursePreviewMode } from "@shared/components/modules/customer/coursePreview/CoursePreviewButton";

import { useApi } from "@/lib/api/apiClient";
import { basketStorageAdaptor } from "@/lib/basketStorageAdaptor";
import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";
import { AuthRedirectProvider } from "@/modules/auth/redirect/AuthRedirectProvider";
import { TemporaryStaffRedirect } from "@/modules/auth/redirect/TemporaryStaffRedirect";
import { CustomerCheckoutSuccessPage } from "@/modules/customer/checkout/CustomerCheckoutSuccessPage";
import { CustomerCheckoutSummaryPage } from "@/modules/customer/checkout/CustomerCheckoutSummaryPage";
import { MemberBasketPage } from "@/modules/customer/checkout/MemberBasketPage";
import { CoursesPage } from "@/modules/public/catalogue/CoursesPage";
import { CoursePreviewPage } from "@/modules/public/classes/preview/CoursePreviewPage";
import { CompanyLandingPage } from "@/modules/public/CompanyLandingPage";
import { CourseFilterWizardPage } from "@/modules/public/CourseFilterWizardPage";
import { MakePaymentPage } from "@/modules/public/payments/MakePaymentPage";
import { JoinWaitingListPage } from "@/modules/public/waitingList/JoinWaitingListPage";
import { JoinWaitingListSuccessPage } from "@/modules/public/waitingList/JoinWaitingListSuccessPage";
import { RouteNotFound } from "@/routing/RouteNotFound";
import { Router } from "@/routing/router";

export const PreAuthCatalogueRoutes = [
  "CompanyLandingPage",
  "CourseFilterWizard",
  "Courses",
  "CatalogueCoursePreview",
  "MemberBasket",
  "GuestPayment",
] as const;
export const CatalogueAuthRoutes = [
  "JoinWaitingList",
  "JoinWaitingListSuccess",
] as const;
export const PostAuthCatalogueRoutes = [
  "CustomerCheckoutSummary",
  "CustomerCheckoutSuccess",
] as const;
export type PreAuthCatalogueRoute = (typeof PreAuthCatalogueRoutes)[number];
export type CatalogueAuthRoute = (typeof CatalogueAuthRoutes)[number];

export const CatalogueRouter = ({ slug }: { slug: string }) => {
  const route = Router.useRoute([
    ...PreAuthCatalogueRoutes,
    ...CatalogueAuthRoutes,
    ...PostAuthCatalogueRoutes,
  ]) ?? { name: "CompanyLandingPage", params: { slug } };

  const { search } = useLocation();
  const searchValues = routeParamsToSearchValues(search);

  const {
    data: company,
    isLoading: companyLoading,
    isSuccess: companyFound,
    isError: companyError,
  } = useApi(
    "listPublicCompanyDetails",
    {
      slug,
    },
    {
      retry: false,
    },
  );

  useEffect(() => {
    if (!companyLoading) {
      if (companyError || !companyFound) {
        showAlert({
          variant: "error",
          content: `The page you are looking for does not exist.`,
        });

        Router.replace("Login");
      }
    }
  }, [companyLoading, companyError, companyFound]);

  if (companyLoading) {
    return null;
  }

  if (!company) {
    return (
      <NonAuthLayout>
        <h1 className="text-heading1-600 text-red-800">Company not found</h1>
      </NonAuthLayout>
    );
  }

  const page = match(route)
    .with({ name: "CompanyLandingPage" }, () => <CompanyLandingPage />)
    .with({ name: "CourseFilterWizard" }, ({ params: { mode, stage } }) => (
      <CourseFilterWizardPage mode={mode} stage={stage} />
    ))
    .with(
      { name: "Courses" },
      ({ params: { ages, days, locationIds, mode, query, seasonIds } }) => (
        <CoursesPage
          ages={ages?.map(Number) ?? []}
          days={(days ?? []) as DayOfWeek[]}
          locationIds={locationIds ?? []}
          mode={mode === "trial" ? "trial" : "enrol"}
          query={query ?? ""}
          seasonIds={seasonIds ?? []}
        />
      ),
    )
    .with(
      { name: "CatalogueCoursePreview" },
      ({ params: { courseId, mode } }) => (
        <CoursePreviewPage
          courseId={courseId}
          mode={mode as CoursePreviewMode}
        />
      ),
    )
    .with({ name: "JoinWaitingList" }, ({ params: { courseId } }) => (
      <JoinWaitingListPage courseId={courseId} />
    ))
    .with({ name: "JoinWaitingListSuccess" }, () => (
      <JoinWaitingListSuccessPage />
    ))
    .with({ name: "MemberBasket" }, () => <MemberBasketPage />)
    .with({ name: "CustomerCheckoutSummary" }, () => (
      <CustomerCheckoutSummaryPage />
    ))
    .with({ name: "CustomerCheckoutSuccess" }, () => (
      <CustomerCheckoutSuccessPage />
    ))
    .with({ name: "GuestPayment" }, ({ params: { paymentLinkId } }) => (
      <MakePaymentPage paymentLinkId={paymentLinkId} />
    ))
    .otherwise(() => <RouteNotFound redirectTo="Login" />);

  return (
    <CatalogueContextProvider company={company}>
      <AuthRedirectProvider>
        <TemporaryStaffRedirect company={company}>
          <CourseSearchProvider searchValues={searchValues}>
            <BasketProvider adaptor={basketStorageAdaptor}>
              {page}
            </BasketProvider>
          </CourseSearchProvider>
        </TemporaryStaffRedirect>
      </AuthRedirectProvider>
    </CatalogueContextProvider>
  );
};
