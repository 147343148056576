import {
  CreateWaiverRequest,
  UpdateWaiverRequest,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Schemas = CreateWaiverRequest | UpdateWaiverRequest;
type Action = "create" | "update" | "updateLocked";

const useDefinition = <Mode extends Schemas>({ mode }: { mode: Action }) => {
  const formBuilder = new FormDefinitionBuilder<Mode>();

  if (mode !== "updateLocked") {
    formBuilder.text("name", {
      label: "Name",
      required: true,
    });
  }

  return formBuilder
    .text("content", { label: "Content", numberOfLines: 10, required: true })
    .getDefinition() as FormDefinition<Mode>;
};

export const useWaiverCreateFormDefinition = () =>
  useDefinition<CreateWaiverRequest>({ mode: "create" });

export const useWaiverUpdateFormDefinition = () =>
  useDefinition<UpdateWaiverRequest>({ mode: "update" });

export const useWaiverUpdateLockedFormDefinition = () =>
  useDefinition<UpdateWaiverRequest>({ mode: "updateLocked" });

export const makeWaiverCreateRequest =
  ({ api }: { api: Api }) =>
  (formData: CreateWaiverRequest) => {
    return api.waivers.createWaiver({
      createWaiverRequest: formData,
    });
  };

export const makeWaiverUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateWaiverRequest) => {
    return api.waivers.updateWaiver({
      id,
      updateWaiverRequest: formData,
    });
  };
