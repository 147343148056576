import { FC, ReactNode } from "react";

import {
  AggregateClassDto,
  AttendanceDto,
  SeasonDto,
  StudentDto,
  TrialDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { getClassSubtitle, getClassTrialSubtitle } from "../../classUtils";
import { fetchTrialsData } from "../../data/trialsData";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import { FilterFormDefinitionBuilder } from "../../forms/filterFormDefinitionBuilder";
import { formatDate } from "../../intlFormatter";
import { useTrialActions } from "../actions/useTrialActions";
import { useGenericComponents } from "../GenericComponentsProvider";
import { AttendanceStatusChip } from "../trials";

type Dto = TrialDto;
type RowDto = Dto & {
  attendance?: AttendanceDto;
  class: AggregateClassDto;
  season: SeasonDto;
  student: StudentDto;
};
type FilterForm = object;

interface UseStudentTrialsDatatableParams {
  Datatable: FC<{ configuration: DatatableConfiguration<Dto, object, RowDto> }>;
  api: Api;
  studentId: string;
  goToClass: (id: string) => void;
  enrollForClass: (item: RowDto) => void;
  markAsNotContinuing: (item: RowDto) => void;
  showUpdateForm: (item: RowDto) => void;
  deleteOne: (item: RowDto) => void;
}

export const useStudentTrialsDatatable = ({
  Datatable,
  api,
  studentId,
  goToClass,
  enrollForClass,
  markAsNotContinuing,
  showUpdateForm,
  deleteOne,
}: UseStudentTrialsDatatableParams): { datatable: ReactNode } => {
  const { View, Text } = useGenericComponents();
  const trialActions = useTrialActions();

  const fetchData = async (query: DatatableQueryProps) => {
    const request = query.toRequest();
    return fetchTrialsData({ api, request, studentId });
  };

  const config: DatatableConfiguration<Dto, FilterForm, RowDto> = {
    id: `student-trials-${studentId}`,
    title: "Trials",
    createLabel: "Add",
    createAction: () =>
      trialActions.showCreateForm({ studentIds: [studentId] }),
    permissions: {
      archive: "enrolments:manage",
      create: "enrolments:manage",
      delete: "enrolments:manage",
      edit: "enrolments:manage",
      restore: "enrolments:manage",
    },
    filterForm: new FilterFormDefinitionBuilder()
      .withArchiveFilter()
      .getDefinition(),
    rowActions: {
      title: item => item.class.entity.name,
      click: item => goToClass(item.classId),
      additionalRowActions: () => [
        {
          icon: "openOutline",
          label: "Go to class",
          onClick: item => goToClass(item.classId),
          permission: "schedule:view",
        },
        {
          title: "Actions",
          actions: [
            {
              icon: "calendarOutline",
              label: "Enroll for class",
              onClick: enrollForClass,
              permission: "enrolments:manage",
            },
            {
              icon: "closeCircleOutline",
              label: "Mark as not continuing",
              onClick: markAsNotContinuing,
              permission: "enrolments:manage",
            },
          ],
        },
        {
          title: "Trial",
          actions: [
            {
              icon: "createOutline",
              label: "Edit",
              onClick: showUpdateForm,
              permission: "enrolments:manage",
            },
          ],
        },
        {
          icon: "trashOutline",
          label: "Delete",
          onClick: deleteOne,
          isDestructive: true,
          permission: "enrolments:manage",
        },
      ],
    },
    contentPlaceholders: {
      noContent: {
        icon: "helpCircleOutline",
        title: "No trials for this student yet",
        description: "",
      },
    },
    hasPagination: false,
    hasSearch: true,
    showTotalRecords: true,
    placeholdersCount: 5,
    fetchData,
    columns: [
      {
        label: "Class",
        placeholder: "tile",
        value: function (row) {
          return {
            type: "tile",
            hasImage: false,
            title: row.item.class.entity.name,
            subtitle: getClassSubtitle(row.item.class.entity, row.item.season),
          };
        },
      },
      {
        label: "Trial date",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: formatDate(row.item.trialAt, "dayMonthYear"),
        }),
      },
      {
        label: "Attendance",
        placeholder: "text",
        value: row => ({
          type: "custom",
          children: <AttendanceStatusChip attendance={row.item.attendance} />,
        }),
      },
    ],
    mobileColumn: {
      children: row => (
        <View className="flex flex-col space-y-1">
          <View>
            <Text
              className="text-body-600 font-semibold text-grey-900"
              truncate={true}>
              {row.item.class.entity.name}
            </Text>
          </View>

          <View>
            <Text className="text-label-400 text-grey-600" truncate={true}>
              {getClassTrialSubtitle(row.item.class.entity)}
            </Text>
          </View>

          <View>
            <Text className="text-label-400 text-grey-600" truncate={true}>
              Trial on {formatDate(row.item.trialAt, "dayMonthYear")}
            </Text>
          </View>

          <View className="flex flex-row items-start">
            <AttendanceStatusChip attendance={row.item.attendance} />
          </View>
        </View>
      ),
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
