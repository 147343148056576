import { StudentDto } from "@justraviga/classmanager-sdk";

import { colors, getInitials } from "shared/lib";

import { Avatar } from "@/modules/common/ui/avatar/Avatar";

export const AvatarListCell = ({ list }: { list: StudentDto[] }) => {
  if (list.length === 0) {
    return <span>-</span>;
  }

  const avatarsToShow = list.length > 4 ? list.slice(0, 3) : list;
  const extraCount = list.length - avatarsToShow.length;

  return (
    <>
      <div
        className={
          "hidden flex-shrink-0 items-center -space-x-2 overflow-hidden lg:flex"
        }>
        {avatarsToShow.map((item, index: number) => (
          <Avatar
            src={item.profilePicture ?? undefined}
            name={getInitials(item)}
            key={index}
          />
        ))}
        {extraCount > 0 && (
          <Avatar name={`+${extraCount}`} bgColor={colors.grey["400"]} />
        )}
      </div>
      <div className={"ml-2 truncate text-body-400 text-grey-600"}>
        {list.map(s => s.firstname).join(", ")}
      </div>
    </>
  );
};
