import { Breadcrumb, useEnrolmentsDatatable } from "shared/components";
import { useEnrolmentsActions } from "shared/components";
import {
  getEnrolmentEndDate,
  getEnrolmentStartDate,
  useEnrolmentsData,
} from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { Tabs } from "@/modules/common/nav/Tabs";
import { Button } from "@/modules/common/ui/button/Button";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";
import {
  CompanyBreadCrumbs,
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const EnrolmentsPage = ({ classId }: { classId: string }) => {
  const actions = useEnrolmentsActions();

  const { course, currentFilter, season, tabItems } =
    useEnrolmentsData(classId);

  const { datatable } = useEnrolmentsDatatable({
    Datatable,
    api,
    classId,
    filter: currentFilter,
    goToStudent: id => Router.push("StudentDetails", { id }),
    showCreateForm: () =>
      actions.showCreateForm({ courseId: course?.entity.id, season }),
    showUpdateStartForm: item =>
      actions.showUpdateStartForm({
        classId: item.class.entity.id,
        studentId: item.student.id,
        date: getEnrolmentStartDate(item),
      }),
    showUpdateEndForm: item =>
      actions.showUpdateEndForm({
        classId: item.class.entity.id,
        studentId: item.student.id,
        date: getEnrolmentEndDate(item),
      }),
    deleteOne: actions.deleteOne,
  });

  if (!course || !season) {
    return <LoadingSpinnerDark />;
  }

  const breadcrumbs: Breadcrumb[] = [
    {
      text: t("breadcrumb.seasons"),
      onClick: () => Router.push("SeasonList"),
    },
    {
      text: season.name,
      onClick: () => Router.push("SeasonDetails", { id: season.id }),
    },
    {
      text: course.entity.name,
      onClick: () => Router.push("ClassDetails", { id: classId }),
    },
    {
      text: "Enrollments",
    },
  ];

  return (
    <CompanyLayout datatablePage>
      <CompanyBreadCrumbs crumbs={breadcrumbs} />
      <CompanyTitleBar isTopLevel={true}>
        <CompanyTitleBarTitle title={"Enrollments"} />
        <div>
          <ProtectedOverlay permission={"enrolments:manage"}>
            <Button
              text={"Enroll"}
              size="sm"
              onClick={() =>
                actions.showCreateForm({ courseId: course.entity.id, season })
              }
              variant="brand"
            />
          </ProtectedOverlay>
        </div>
      </CompanyTitleBar>
      <Tabs items={tabItems} />
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "enrolments:view",
  EnrolmentsPage,
  "Enrolments",
);

export { PermissionChecked as EnrolmentsPage };
