import { BillItemDto } from "@justraviga/classmanager-sdk";

import { CheckoutItem } from "./CheckoutItem";
import { CheckoutSection } from "./CheckoutSection";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const CheckoutSeasonItems = ({ items }: { items: BillItemDto[] }) => {
  const { View } = useGenericComponents();
  return (
    <CheckoutSection spacing={"tight"}>
      {items.map((tuitionItem, index) => (
        <View key={index}>
          <CheckoutSection spacing={"tight"}>
            <View>
              <CheckoutItem
                text={tuitionItem.description}
                amount={tuitionItem.subtotal}
              />
            </View>
          </CheckoutSection>
        </View>
      ))}
    </CheckoutSection>
  );
};
