import { FeeDto } from "@justraviga/classmanager-sdk";

import { SeasonFormStateValues } from "./useSeasonFormSeasonDetailsDefinition";
import { useTaxRates } from "../../data/useTaxRates";
import { displayRegistrationFeeAmounts } from "../../registrationFeeUtils";
import { useAdditionalFeeActions } from "../actions/useAdditionalFeeActions";
import { useAdditionalFeeId } from "../additionalFees/useAdditionalFeeId";
import { InputDescription } from "../forms/InputDescription";
import { useGenericComponents } from "../GenericComponentsProvider";

interface AdditionalFeeFormFieldBodyProps {
  additionalFees: FeeDto[];
  setLocalFormState: (values: Partial<SeasonFormStateValues>) => void;
}

export const AdditionalFeeFormFieldBody = ({
  additionalFees,
  setLocalFormState,
}: AdditionalFeeFormFieldBodyProps) => {
  const { getNextId } = useAdditionalFeeId();
  const { Button, Text, View } = useGenericComponents();
  const additionalFeeActions = useAdditionalFeeActions();
  const { taxRates, hasTaxEnabled } = useTaxRates();

  function addAdditionalFee(additionalFee: FeeDto) {
    const newId = getNextId();
    const newAdditionalFee = { ...additionalFee, id: newId };
    setLocalFormState({
      additionalFees: [...additionalFees, newAdditionalFee],
    });

    return newAdditionalFee;
  }

  function removeAdditionalFee(additionalFee: FeeDto) {
    setLocalFormState({
      additionalFees: additionalFees.filter(fee => fee.id !== additionalFee.id),
    });
  }

  function editAdditionalFee(additionalFee: FeeDto) {
    setLocalFormState({
      additionalFees: additionalFees.map(fee =>
        fee.id === additionalFee.id ? additionalFee : fee,
      ),
    });
    return additionalFee;
  }

  return (
    <View className="py-4">
      <View className="pb-2">
        <Text className="text-heading6-600">Additional fees</Text>
      </View>
      <InputDescription
        description={t("description.addAdditionalFees")}
        variant={"md"}
      />
      <View className="flex flex-col space-y-2 pt-2">
        <View className="flex flex-col">
          {additionalFees.map((fee: FeeDto) => (
            <View
              key={fee.id}
              className="flex flex-col items-start sm:flex-row sm:justify-start sm:items-center sm:space-x-2">
              <View className="pt-1">
                <Text className="text-label-400 text-grey-600">{`${fee.description} • ${displayRegistrationFeeAmounts(fee, taxRates, hasTaxEnabled)}`}</Text>
              </View>
              <View className="flex flex-row flex-grow space-x-2">
                <View className={"-ml-2 sm:ml-0"}>
                  <Button
                    variant={"tertiaryLight"}
                    size={"xs"}
                    className={"leading-5 pt-0 sm:pt-1"}
                    text={"Edit"}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      additionalFeeActions.showUpdateForm(
                        fee,
                        editAdditionalFee,
                      );
                    }}
                  />
                </View>
                <View className={"-ml-2 sm:ml-0"}>
                  <Button
                    variant={"destructive-tertiary"}
                    size={"xs"}
                    className={"leading-5 pt-0 sm:pt-1"}
                    text={"Remove"}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      additionalFeeActions.deleteOne(fee, removeAdditionalFee);
                    }}
                  />
                </View>
              </View>
            </View>
          ))}
        </View>
        <View className={"-ml-2"}>
          <Button
            variant={"tertiaryLight"}
            size={"xs"}
            className={"leading-5"}
            text="Add additional fee"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              additionalFeeActions.showCreateForm(addAdditionalFee);
            }}
          />
        </View>
      </View>
    </View>
  );
};
