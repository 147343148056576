const lastCalls: Record<string, number> = {};
const defaultDelay = 1000;

export interface RateLimiterOptions {
  key: string;
  delay?: number;
}

export function rateLimiter(
  options: RateLimiterOptions,
  callback: () => unknown,
): void {
  const lastCall = lastCalls[options.key] ?? 0;
  const delay = options.delay ?? defaultDelay;

  if (lastCall >= Date.now() - delay) {
    return;
  }

  lastCalls[options.key] = Date.now();

  callback();
}
