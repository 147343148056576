import { useStripeStatus } from "../../payments/useStripeStatus";
import { getPlatformFunctions } from "../../platformSpecific";
import { useGenericComponents } from "../GenericComponentsProvider";
import { usePermission } from "../permission/usePermission";

export const PaymentsDashboardBanner = () => {
  const { openWebsite, useApi } = getPlatformFunctions();
  const { ActionBanner, Button, ProtectedOverlay } = useGenericComponents();
  const { hasPermission } = usePermission();
  const { protector: stripeMessage } = useStripeStatus();
  const { data: profileLink } = useApi(
    "getProfileLink",
    {},
    {
      enabled: hasPermission("settings:manage"),
    },
  );

  return (
    <ProtectedOverlay permission="settings:manage" protector={stripeMessage}>
      <ActionBanner
        title={"Express Dashboard"}
        description={
          "Put your hand up if you love payday 🙌🏾\n" +
          "\n" +
          "Our Express Dashboard makes viewing payments and updating your bank account simple, so every day can feel as good as payday."
        }
        buttons={[
          <Button
            size={"xs"}
            variant={"brand"}
            text={"Go to Dashboard"}
            onClick={profileLink && (() => openWebsite(profileLink.url))}
            rightIcon={"openOutline"}
          />,
        ]}
      />
    </ProtectedOverlay>
  );
};
