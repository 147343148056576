import { forwardRef, ReactNode } from "react";

import * as SwitchPrimitives from "@radix-ui/react-switch";

import { cn, colors } from "shared/lib";

import { Icon } from "@/modules/common/ui/icon/Icon";

export type SwitchProps = React.ComponentPropsWithoutRef<
  typeof SwitchPrimitives.Root
> & {
  onChange: (checked: boolean) => void;
  className?: string;
  description?: ReactNode;
  label?: string;
  variant?: "neutral" | "brand";
  checked: boolean;
};

export const Switch = forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(
  (
    {
      checked,
      label,
      description,
      className,
      onChange,
      variant = "neutral",
      ...props
    },
    ref,
  ) => {
    return (
      <label>
        <div className="flex w-full items-start justify-between gap-x-4">
          {(label || description) && (
            <div className={"flex flex-col space-y-1"}>
              <div className="text-label-400 text-grey-900">{label}</div>
              <div className="text-caption-400 text-grey-600">
                {description}
              </div>
            </div>
          )}
          <div>
            <SwitchPrimitives.Root
              {...props}
              checked={checked}
              onCheckedChange={checked => {
                onChange(checked);
              }}
              className={cn(
                "focus-visible:ring-ring peer inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer items-center",
                "rounded-full border-2 border-transparent transition-colors focus-visible:outline-none",
                "focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-white",
                "disabled:cursor-not-allowed disabled:opacity-50 data-[state=unchecked]:bg-grey-400",
                "data-[state=unchecked]:hover:bg-grey-500",
                className,
                {
                  "data-[state=checked]:bg-grey-800 data-[state=checked]:hover:bg-grey-700":
                    variant === "neutral",
                },
                {
                  "data-[state=checked]:bg-brand-600 data-[state=checked]:hover:bg-brand-500":
                    variant === "brand",
                },
              )}
              ref={ref}>
              <SwitchPrimitives.Thumb
                className={cn(
                  "pointer-events-none flex h-5 w-5 items-center justify-center rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0",
                )}>
                {checked && (
                  <Icon
                    name={"checkmarkOutline"}
                    className={cn("h-4 w-4")}
                    color={cn({
                      [colors.grey["900"]]: variant === "neutral",
                      [colors.brand["600"]]: variant === "brand",
                    })}
                  />
                )}
              </SwitchPrimitives.Thumb>
            </SwitchPrimitives.Root>
          </div>
        </div>
      </label>
    );
  },
);
