import { useBasket } from "../../../basket/useBasket";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { ButtonComponent } from "../../../interfaces";
import { Chip } from "../../../ui/Chip";

interface GoToSummaryButtonProps {
  Button: ButtonComponent;
  gotoBasket: () => void;
}

export const GoToSummaryButton = ({
  Button,
  gotoBasket,
}: GoToSummaryButtonProps) => {
  const { View } = useGenericComponents();
  const { count } = useBasket();

  return (
    count > 0 && (
      // A width of 384px is equivalent to "sm" breakpoint
      <View className="absolute bottom-7 max-w-[384px] w-full left-1/2 -translate-x-[192px] md:bottom-14 px-5 bg-brand-600 rounded-md py-4 flex flex-row items-center justify-between shadow">
        <Chip label={`${count} selected`} variant={"neutral"} />
        <Button
          variant={"secondary"}
          text={"Go to summary"}
          size={"xs"}
          onClick={gotoBasket}
        />
      </View>
    )
  );
};
