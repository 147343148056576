import {
  CourseDto,
  LocationDto,
  PricingSchemeValueDto,
  PublicStaffDto,
  RoomDto,
  SeasonDto,
  StaffDto,
} from "@justraviga/classmanager-sdk";
import { CompanyTrialSettingsDto } from "@justraviga/classmanager-sdk/dist/models/CompanyTrialSettingsDto";

import { CoursePreviewMode } from "./CoursePreviewButton";
import { CoursePreviewLessonList } from "./CoursePreviewLessonList";
import { SeasonPricingList } from "./SeasonPricingList";
import { IconName } from "../../../../availableIcons";
import {
  ageRangeDescription,
  displayCourseDates,
  displayLessonTimes,
} from "../../../../classUtils";
import { colors } from "../../../../colors";
import { cn } from "../../../../cssUtils";
import { formatMoneyFromInteger } from "../../../../intlFormatter";
import { getFullName } from "../../../../personUtil";
import { enumLabel } from "../../../../translateUtils";
import { CoursePrice } from "../../../courseSearch/CoursePrice";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import {
  CourseImageWithOverlayComponent,
  HorizontalSeparatorComponent,
} from "../../../interfaces";
import { Chip } from "../../../ui/Chip";

export interface CoursePreviewPageProps {
  courseId: string;
  mode?: CoursePreviewMode;
}

export const CoursePreviewRowItem = ({
  icon,
  text,
  weight = "400",
  color = "600",
  manualPadding = true,
  semibold = false,
}: {
  icon?: IconName;
  text?: string;
  weight?: "400" | "600";
  color?: "900" | "600";
  manualPadding?: boolean;
  semibold?: boolean;
}) => {
  const { Icon, Text, View } = useGenericComponents();

  return (
    <View
      className={cn("flex flex-row items-center space-x-2", {
        "pb-2": manualPadding,
      })}>
      {icon && <Icon name={icon} size={20} color={colors.grey[color]} />}
      <Text
        className={cn({
          "text-body-600": weight === "600",
          "text-body-400": weight === "400",
          "text-grey-600": color === "600",
          "text-grey-900": color === "900",
          "font-semibold": semibold,
        })}>
        {text ?? "-"}
      </Text>
    </View>
  );
};

export const CoursePreviewRowPair = ({
  leftText,
  rightText,
  manualPadding = true,
}: {
  leftText: string;
  rightText: string;
  manualPadding?: boolean;
}) => {
  const { Text, View } = useGenericComponents();

  return (
    <View
      className={cn("flex flex-row items-center justify-between", {
        "pb-2": manualPadding,
      })}>
      <Text className={"text-body-400 text-grey-600"}>{leftText}</Text>
      <Text className={"text-body-400 text-grey-900"}>{rightText}</Text>
    </View>
  );
};

export const CoursePreviewDescription = ({
  course,
  title,
  showIcon = true,
}: {
  course: CourseDto;
  title?: string;
  showIcon?: boolean;
}) => {
  const { Text, View } = useGenericComponents();

  return (
    <View className={"flex flex-col"}>
      <CoursePreviewRowItem
        icon={showIcon ? "informationCircleOutline" : undefined}
        text={title ?? "More details"}
        weight={"600"}
        color={"900"}
        semibold={true}
      />
      <Text className={"text-grey-600 text-body-400"}>
        {course.description}
      </Text>
    </View>
  );
};

export const CoursePreviewLessons = ({
  classId,
  iconName,
}: {
  classId: string;
  iconName?: IconName;
}) => {
  const { View } = useGenericComponents();

  return (
    <View className={"flex flex-col"}>
      <CoursePreviewRowItem
        icon={iconName ?? "calendarNumberOutline"}
        text={"Lessons"}
        weight={"600"}
        color={"900"}
        semibold={true}
      />
      <CoursePreviewLessonList classId={classId} />
    </View>
  );
};

export const CoursePreviewDetails = ({
  course,
  teacher,
  HorizontalSeparator,
}: {
  course: CourseDto;
  teacher?: StaffDto;
  HorizontalSeparator: HorizontalSeparatorComponent;
}) => {
  const { View } = useGenericComponents();

  return (
    <>
      {course.description && (
        <View className={"space-y-5"}>
          <CoursePreviewDescription course={course} />
          <HorizontalSeparator spacing={0} />
        </View>
      )}
      {teacher && (
        <View className={"space-y-5"}>
          <CoursePreviewTeacher staff={teacher} />
          <HorizontalSeparator spacing={0} />
        </View>
      )}
      <CoursePreviewLessons classId={course.id} />
    </>
  );
};

export const CoursePreviewTeacher = ({
  staff,
  title,
}: {
  staff?: StaffDto | PublicStaffDto;
  title?: string;
}) => {
  const { Text, View } = useGenericComponents();

  return (
    <View className={"flex flex-col"}>
      <CoursePreviewRowItem
        icon={"personOutline"}
        text={title ?? "Teacher"}
        weight={"600"}
        color={"900"}
        semibold={true}
      />
      <Text className={"text-grey-600 text-body-400"}>
        {staff ? getFullName(staff) : "-"}
      </Text>
    </View>
  );
};

export const CoursePreviewName = ({ course }: { course: CourseDto }) => {
  const { Text } = useGenericComponents();
  return <Text className={"text-body-600 font-semibold"}>{course.name}</Text>;
};

export const CoursePreviewTime = ({
  course,
  isMobile,
}: {
  course: CourseDto;
  isMobile?: boolean;
}) => {
  const courseTime = `${!isMobile ? enumLabel("weekDaysShort", course.dayOfWeek) + " • " : ""}${displayLessonTimes(
    {
      startTime: course.startTime,
      durationInMinutes: course.durationInMinutes,
    },
  )}`;

  return <CoursePreviewRowItem text={courseTime} manualPadding={false} />;
};

export const CoursePreviewSummary = ({
  course,
  season,
  location,
  isMobile = false,
}: {
  course: CourseDto;
  season: SeasonDto;
  location?: LocationDto;
  isMobile?: boolean;
}) => {
  const { View } = useGenericComponents();

  return (
    <View className={"flex flex-col"}>
      <View className={"flex flex-row items-center justify-between"}>
        <CoursePreviewTime course={course} isMobile={isMobile} />
        <CoursePrice mode={"enrol"} course={course} season={season} />
      </View>
      <CoursePreviewName course={course} />
      <CoursePreviewRowItem
        icon={"calendarClearOutline"}
        text={displayCourseDates(season.startAt, season.endAt)}
      />
      <CoursePreviewRowItem
        icon={"personOutline"}
        text={ageRangeDescription(course.minAgeMonths, course.maxAgeMonths)}
      />
      {location && (
        <CoursePreviewRowItem
          icon={"locationOutline"}
          text={location.name}
          manualPadding={false}
        />
      )}
    </View>
  );
};

export const CoursePreviewTrialPrice = ({
  trialSettings,
  mode,
}: {
  trialSettings: CompanyTrialSettingsDto;
  mode: CoursePreviewMode;
}) => {
  const { Text, View } = useGenericComponents();

  if (mode !== "trial" || !trialSettings.enableOnPortal) {
    return null;
  }
  if (trialSettings.price === 0) {
    return (
      <View className={"mt-2 flex justify-between text-body-400 text-grey-600"}>
        <Text>Trial</Text>
        <View className={"flex flex-row items-center"}>
          <Text className={"text-grey-900"}>FREE</Text>
        </View>
      </View>
    );
  }

  return (
    <View className={"mt-2 flex justify-between text-body-400 text-grey-600"}>
      <Text>Trial</Text>
      <View className={"flex flex-row items-center"}>
        <Text className={"text-grey-900"}>
          {formatMoneyFromInteger(trialSettings.price)}
        </Text>
        <Text className={"text-grey-600"}>/lesson</Text>
      </View>
    </View>
  );
};

export const CoursePreviewPriceList = ({
  season,
  pricingSchemeValues,
}: {
  season: SeasonDto;
  pricingSchemeValues: PricingSchemeValueDto[];
}) => {
  const { View } = useGenericComponents();

  return (
    <View className={"flex flex-col"}>
      <CoursePreviewRowItem
        icon={"cashOutline"}
        text={"Pricing list"}
        weight={"600"}
        color={"900"}
      />
      <SeasonPricingList
        values={pricingSchemeValues}
        scheme={season.pricingScheme}
      />
    </View>
  );
};

export const CoursePreviewImageWithOverlay = ({
  course,
  OverlayImage,
  isFull = false,
}: {
  course: CourseDto;
  OverlayImage: CourseImageWithOverlayComponent;
  isFull?: boolean;
}) => {
  return (
    <OverlayImage
      course={course}
      collapseWhenPlaceholder={course.image === null}
      left={
        <Chip
          label={enumLabel("weekDaysShort", course.dayOfWeek)}
          variant={"neutral"}
        />
      }
      right={isFull && <Chip label={"Class full"} variant={"danger"} />}
    />
  );
};

export const CoursePreviewLocationAndRoom = ({
  location,
  room,
}: {
  location: LocationDto;
  room: RoomDto;
}) => {
  const { View, Text } = useGenericComponents();

  return (
    <View className={"flex flex-col"}>
      <CoursePreviewRowItem
        icon={"locationOutline"}
        text={"Location"}
        weight={"600"}
        color={"900"}
      />
      <Text className={"text-grey-600 text-body-400"}>
        {location.name}-{room.name}
      </Text>
    </View>
  );
};
