import { AggregateTransactionDto } from "@justraviga/classmanager-sdk";

import { AggregateTransaction } from "./AggregateTransaction";
import { CheckoutSection } from "./CheckoutSection";
import { useBreakpoint } from "../../../../breakpoints";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const AggregateTransactionList = ({
  transactions,
}: {
  transactions: AggregateTransactionDto[];
}) => {
  const { HorizontalSeparator, View } = useGenericComponents();
  const { md } = useBreakpoint();

  return (
    <CheckoutSection spacing={md ? "normal" : "tight"}>
      {transactions.map(transaction => (
        <CheckoutSection key={transaction.transaction.id}>
          <View>
            <AggregateTransaction transaction={transaction} />
          </View>
          <View>
            <HorizontalSeparator spacing={2} />
          </View>
        </CheckoutSection>
      ))}
    </CheckoutSection>
  );
};
