import {
  ListStaffWhereParameter,
  StaffDto,
  StaffPermissionSet,
} from "@justraviga/classmanager-sdk";

import { DatatableFilterOperator } from "../../datatable/datatableTypes";
import { FilterFormDefinitionBuilder } from "../../forms/filterFormDefinitionBuilder";
import { enumLabel } from "../../translateUtils";

export type StaffFilterFormSchema = Pick<
  StaffDto,
  "permissionSet" | "invitationStatus" | "archivedAt"
>;

export const useStaffFilterForm = () =>
  new FilterFormDefinitionBuilder<
    StaffFilterFormSchema,
    ListStaffWhereParameter
  >()
    .group("Basic information", ["permissionSet"])
    .multiSelect(
      "permissionSet",
      {
        label: "Role",
        data: Object.values(StaffPermissionSet).map(value => ({
          label: enumLabel("permissionSet", value),
          value,
        })),
      },
      {
        filterField: "permissionSet",
        operator: DatatableFilterOperator.In,
      },
    )
    .withArchiveFilter()
    .getDefinition();
