import { ClassManagerTermsAndConditions } from "@/modules/auth/common/ViewPolicy";

export const ClassManagerRegisterTermsAndPrivacy = () => {
  return (
    <p className="text-label-400 text-grey-600">
      By clicking register you have read and agree with Class Manager{" "}
      <ClassManagerTermsAndConditions />
    </p>
  );
};
