import { AggregateMessageDto, ContactDto } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { RecipientsByClassForm } from "../forms/RecipientsByClassForm";
import { RecipientsBySeasonForm } from "../forms/RecipientsBySeasonForm";
import { RecipientsByStudentForm } from "../forms/RecipientsByStudentForm";
import { DefaultEntityActions } from "../useDefaultEntityActions";

type Model = AggregateMessageDto;

export const useSharedEmailActions = (
  defaultActions: DefaultEntityActions<Model>,
  api: Api,
) => {
  return {
    deleteOne: (item: Model) =>
      defaultActions.deleteOne(() =>
        api.messages.deleteMessage({ id: item.entity.id }),
      ),
    showRecipientsBySeasonForm: (
      onSuccess: (recipients: Array<ContactDto>) => void,
    ) => {
      defaultActions.showCreateForm({
        form: (
          <RecipientsBySeasonForm
            onSuccess={recipients => {
              onSuccess(recipients);
              defaultActions.hideForm();
            }}
          />
        ),
        title: `Add recipients`,
      });
    },
    showRecipientsByStudentForm: (
      onSuccess: (recipients: Array<ContactDto>) => void,
    ) => {
      defaultActions.showCreateForm({
        form: (
          <RecipientsByStudentForm
            onSuccess={recipients => {
              onSuccess(recipients);
              defaultActions.hideForm();
            }}
          />
        ),
        title: `Add recipients`,
      });
    },
    showRecipientsByClassForm: (
      onSuccess: (recipients: Array<ContactDto>) => void,
    ) => {
      defaultActions.showCreateForm({
        form: (
          <RecipientsByClassForm
            onSuccess={recipients => {
              onSuccess(recipients);
              defaultActions.hideForm();
            }}
          />
        ),
        title: `Add recipients`,
      });
    },
    editAndResend: (item: Model, showUpdateForm: (id: string) => void) => {
      api.messageInstances
        .listMessageInstance({
          messageId: item.entity.id,
        })
        .then(response => {
          api.messages
            .createMessage({
              createMessageRequest: {
                subject: item.entity.subject,
                body: item.entity.body,
                recipientIds: response.data.map(e => e.entityId),
                attachments: [],
              },
            })
            .then(data => {
              showUpdateForm(data.entity.id);
            });
        });
    },
  };
};
