import { CheckoutItem } from "./CheckoutItem";
import { CheckoutSection } from "./CheckoutSection";

export interface QuoteSummary {
  subtotal: number;
  discountTotal: number;
  taxTotal: number;
  total: number;
}

export const QuoteSummary = ({
  quoteSummary,
}: {
  quoteSummary: QuoteSummary;
}) => {
  return (
    <CheckoutSection heading={"Summary"} spacing={"tight"}>
      <CheckoutItem
        text={"Subtotal"}
        amount={quoteSummary.subtotal}
        style={"light"}
      />
      <CheckoutItem
        text={"Total discounts"}
        amount={quoteSummary.discountTotal}
        style={"light"}
      />
      <CheckoutItem
        text={"Total tax"}
        amount={quoteSummary.taxTotal}
        style={"light"}
      />
      <CheckoutItem text={"Total"} amount={quoteSummary.total} style={"big"} />
    </CheckoutSection>
  );
};
