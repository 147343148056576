import { useTransactionsDatatable } from "@shared/components/datatables/useTransactionsDatatable";
import { TransactionAmountText } from "@shared/components/transactions/TransactionAmountText";

import { api, useApi } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { useTransactionActions } from "@/modules/company/billing/transactions/useTransactionActions";
import { CompanyContent, CompanyLayout } from "@/modules/company/CompanyLayout";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const TransactionsListPage = () => {
  const transactionActions = useTransactionActions();
  const { setTitle: setSheetTitle } = useSheet();
  const { datatable } = useTransactionsDatatable({
    Datatable,
    api,
    useApi,
    transactionActions,
    goToFamily: item =>
      Router.push("FamilyDetails", { id: item.transaction.familyId }),
    renderTransactionAmount: (transaction, columnType) =>
      columnType === transaction.transaction.type && (
        <TransactionAmountText transaction={transaction} />
      ),
    setSheetTitle,
  });

  return (
    <CompanyLayout datatablePage>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "financial:view",
  TransactionsListPage,
  "Transactions",
);

export { PermissionChecked as TransactionsListPage };
