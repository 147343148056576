import { ReactNode } from "react";

import { cn, useBreakpoint } from "shared/lib";

import { AggregateTransactionList } from "./AggregateTransactionList";
import { CheckoutLayout } from "./CheckoutLayout";
import { CheckoutLeftColumn } from "./CheckoutLeftColumn";
import { CheckoutRightColumn } from "./CheckoutRightColumn";
import { CheckoutSuccessMessage } from "./CheckoutSuccessMessage";
import { PaymentSuccessDetails } from "./PaymentSuccessDetails";
import { QuoteSummary } from "./QuoteSummary";
import { useSuccessData } from "../../../../data/useSuccessData";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { StyledText } from "../../../text/StyledText";

interface CustomerCheckoutSuccessPageProps {
  DashboardButton: ReactNode;
  gotoDashboard: () => void;
}

export const CustomerCheckoutSuccessPage = ({
  DashboardButton,
  gotoDashboard,
}: CustomerCheckoutSuccessPageProps) => {
  const { View } = useGenericComponents();
  const { md } = useBreakpoint();

  const { paymentDetailsId, debits, stats } = useSuccessData({
    gotoDashboard,
  });

  if (!debits) {
    return null;
  }

  return (
    <View>
      <View className={"px-8 py-4"}>
        <View className={"w-full"}>
          <CheckoutSuccessMessage action={DashboardButton} />
        </View>
      </View>
      <CheckoutLayout>
        <CheckoutLeftColumn>
          <View
            className={cn("flex flex-col items-start justify-center", {
              "space-y-10": md,
              "space-y-8": !md,
            })}>
            <View className={"w-full"}>
              <StyledText style={"big"}>Transaction details</StyledText>
            </View>
            <View className={"w-full"}>
              <AggregateTransactionList transactions={debits} />
            </View>
            <View className={"flex w-full flex-col space-y-2"}>
              <QuoteSummary quoteSummary={stats} />
            </View>
          </View>
        </CheckoutLeftColumn>
        <CheckoutRightColumn>
          {paymentDetailsId && (
            <View
              className={
                "flex flex-col items-start justify-start space-y-8 py-4 md:py-0"
              }>
              <View className="flex w-full flex-row items-center justify-between">
                <StyledText style="big">Payment details</StyledText>
              </View>
              <View className={"w-full"}>
                <PaymentSuccessDetails transactionId={paymentDetailsId} />
              </View>
            </View>
          )}
        </CheckoutRightColumn>
      </CheckoutLayout>
    </View>
  );
};
