import React from "react";

import { SeasonDto, SortSchema } from "@justraviga/classmanager-sdk";

import { useApi } from "@/lib/api/apiClient";
import { SubNavItem } from "@/modules/common/nav/SubNavItem";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { NoContent } from "@/modules/company/members/student/detail/NoContent";
import { Router } from "@/routing/router";

export const SeasonsSubNav = () => {
  const { data: seasonsResponse, isLoading } = useApi("listSeason", {
    sort: { startAt: SortSchema.Desc },
  });

  const seasons = React.useMemo(
    () => seasonsResponse?.data ?? undefined,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(seasonsResponse?.data)],
  );

  if (isLoading || seasons === undefined) {
    return (
      <div className={"flex w-full items-center justify-center py-8"}>
        <LoadingSpinnerDark />
      </div>
    );
  }

  return <SeasonList seasons={seasons} />;
};

const SeasonList = React.memo(({ seasons }: { seasons: SeasonDto[] }) => (
  <div className={"space-y-2"}>
    {(seasons?.length === 0 || !seasons) && (
      <div className={"px-4 py-2"}>
        <NoContent />
      </div>
    )}
    {seasons?.map(season => {
      return (
        <SubNavItem
          key={season.id}
          path={Router.SeasonDetails({ id: season.id })}
          label={season.name}
        />
      );
    })}
  </div>
));
