import { useGenericComponents } from "../../../GenericComponentsProvider";

export const CheckoutLayout = ({
  children,
}: {
  children: React.ReactNode[];
}) => {
  const { View } = useGenericComponents();
  return (
    <View className={"md:pt-4 md:grid md:grid-cols-3 md:gap-8"}>
      {children}
    </View>
  );
};
