import {
  FeeDto,
  RegistrationFeeDto,
  RepetitionInterval,
  TaxRateDto,
} from "@justraviga/classmanager-sdk";

import { formatDate, formatMoneyFromInteger } from "./intlFormatter";
import { dayjs } from "./lib/dayjs";
import { TFunction } from "./translation/i18n";

export const getIntervalLabel = (t: TFunction, interval: RepetitionInterval) =>
  ({
    [RepetitionInterval.OneOff]: "One-off",
    [RepetitionInterval.Annual]: "Annual",
    [RepetitionInterval.PerSeason]: t(
      "label.registrationFeeInterval.perSeason",
    ),
  })[interval];

export function getRepetitionIntervalOptions(t: TFunction) {
  return Object.values(RepetitionInterval)
    .filter(interval => interval !== RepetitionInterval.PerSeason)
    .map(interval => ({
      label: getIntervalLabel(t, interval),
      value: interval,
    }));
}

export function monthDayToZeroYearDate(mmdd: string) {
  const [month, day] = mmdd.split("-");
  return dayjs(`0000-${month}-${day}`).format("YYYY-MM-DD");
}

export function zeroYearDateToMonthDay(date: string) {
  return dayjs(date).format("MM-DD");
}

export function isFeeDto(item: FeeDto | RegistrationFeeDto): item is FeeDto {
  return "taxRateId" in item;
}

export function displayRegistrationFeeAmounts(
  item: FeeDto | RegistrationFeeDto,
  taxRates: TaxRateDto[],
  hasTaxEnabled: boolean,
) {
  const perStudentValue = formatMoneyFromInteger(item.amount);
  const maxPerFamilyValue = item.maxAmountPerFamily
    ? formatMoneyFromInteger(item.maxAmountPerFamily)
    : "";
  let taxRate = "";

  if (isFeeDto(item) && hasTaxEnabled) {
    const selectedTaxRate = taxRates.find(rate => rate.id === item.taxRateId);
    if (selectedTaxRate) {
      taxRate = ` • ${selectedTaxRate.rate}% tax`;
    }
  }

  return `${perStudentValue} per student${maxPerFamilyValue ? ` • ${maxPerFamilyValue} max per family` : ""}${taxRate}`;
}

export function displayRegistrationFeeType(
  t: TFunction,
  item: RegistrationFeeDto,
) {
  const label = getIntervalLabel(t, item.repetitionInterval);
  const renewalDate =
    item.repetitionInterval === RepetitionInterval.Annual && item.dueDate
      ? formatDate(item.dueDate, "dayMonth")
      : "";

  return `${label}${renewalDate ? ` • Renews on ${renewalDate}` : ""}`;
}

export function showRegistrationFeeCreateButtons(
  registrationFees: RegistrationFeeDto[],
) {
  const { oneOffExists, annualExists } = registrationFees.reduce(
    (acc, fee) => {
      if (fee.repetitionInterval === RepetitionInterval.OneOff) {
        acc.oneOffExists = true;
      }

      if (fee.repetitionInterval === RepetitionInterval.Annual) {
        acc.annualExists = true;
      }

      return acc;
    },
    {
      oneOffExists: false,
      annualExists: false,
    },
  );

  return { showOneOff: !oneOffExists, showAnnual: !annualExists };
}

export function getRegistrationFeeFormTitle(
  t: TFunction,
  repetitionInterval: RepetitionInterval,
  formType: "create" | "update",
) {
  const start = formType === "create" ? "Create" : "Edit";

  const translations: Record<RepetitionInterval, string> = {
    [RepetitionInterval.OneOff]: `${start} one-off fee`,
    [RepetitionInterval.Annual]: `${start} annual fee`,
    [RepetitionInterval.PerSeason]: t(
      "label.registrationFeeFormTitle.perSeason",
      {
        start,
      },
    ),
  };

  return translations[repetitionInterval];
}
