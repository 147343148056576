import {
  AggregateTransactionDto,
  CheckoutOperationRequest,
  QuoteResponse,
} from "@justraviga/classmanager-sdk";

import {
  loadBasketDataFromStorage,
  saveBasketDataToStorage,
} from "./basketStorage";
import { GetQuoteFn } from "./basketUtils";

type CompanyId = string;
type CourseId = string;
type TrialDate = string;
type TrialTime = string;
export type TrialKey = `${CourseId}|${TrialDate}|${TrialTime}`;
type StudentIds = Array<string>;

export type BasketData = Map<CompanyId, BasketContents>;

export interface BasketContents {
  enrolments: Map<CourseId, StudentIds>;
  trials: Map<TrialKey, StudentIds>;
  lastInteractionAt: Date;
}

export interface BasketStorageAdaptor {
  clear(): void;

  get(): Promise<string | null>;

  set(value: string): void;
}

export interface BasketMethods {
  addEnrolment: (courseId: string) => void;
  addStudentToEnrolment: (courseId: string, studentId: string) => void;
  clearStudentsFromEnrolment: (courseId: string) => void;
  addStudentToTrial: (trial: TrialKey, studentId: string) => void;
  clearStudentsFromTrial: (trial: TrialKey) => void;
  addTrial: (trial: TrialKey) => void;
  clear: () => void;
  clearAllBaskets: () => void;
  getContents: () => BasketContents;
  getCount: () => number;
  hasEnrolment: (courseId: string) => boolean;
  hasTrial: (trial: TrialKey) => boolean;
  removeEnrolment: (courseId: string) => void;
  removeTrial: (trial: TrialKey) => void;
  getQuote: (getQuoteFn: GetQuoteFn) => Promise<QuoteResponse>;
  setSuccessfulTransaction: (
    transactions: AggregateTransactionDto[],
    checkoutOperation: CheckoutOperationRequest,
  ) => void;
  getSuccessfulTransaction: () =>
    | undefined
    | {
        transactions: AggregateTransactionDto[];
        checkoutOperation: CheckoutOperationRequest;
      };
}

export const basketTtlInMs = 1000 * 60 * 60 * 12; // 12 hours

export const makeEmptyBasketContents = (): BasketContents => ({
  enrolments: new Map(),
  trials: new Map(),
  lastInteractionAt: new Date(),
});

export const makeTrialKey = (
  courseId: string,
  date: string,
  time: string,
): TrialKey => `${courseId}|${date}|${time}`;

export class Basket implements BasketMethods {
  quotePromise: Promise<QuoteResponse> | null = null;
  successfulTransaction:
    | {
        transactions: AggregateTransactionDto[];
        checkoutOperation: CheckoutOperationRequest;
      }
    | undefined = undefined;

  constructor(
    private adaptor: BasketStorageAdaptor,
    private basketData: BasketData,
    private companyId: CompanyId,
  ) {}

  static async create(adaptor: BasketStorageAdaptor, companyId: CompanyId) {
    return loadBasketDataFromStorage(adaptor).then(dataFromStorage => {
      // Remove any expired entries from the data retrieved from storage
      const basketData = new Map(
        Array.from(dataFromStorage).filter(([_, { lastInteractionAt }]) => {
          return lastInteractionAt.getTime() + basketTtlInMs > Date.now();
        }),
      );

      return new Basket(adaptor, basketData, companyId);
    });
  }

  private get contents() {
    return this.basketData.get(this.companyId) ?? makeEmptyBasketContents();
  }

  private get enrolments() {
    return this.contents.enrolments;
  }

  private get trials() {
    return this.contents.trials;
  }

  private setEnrolments(enrolments: Map<CourseId, StudentIds>) {
    this.basketData.set(this.companyId, {
      ...this.contents,
      enrolments,
    });
    this.save();
  }

  private setTrials(trials: Map<TrialKey, StudentIds>) {
    this.basketData.set(this.companyId, {
      ...this.contents,
      trials,
    });
    this.save();
  }

  private save() {
    this.basketData.set(this.companyId, {
      ...this.contents,
      lastInteractionAt: new Date(),
    });
    saveBasketDataToStorage(this.adaptor, this.basketData);
    this.quotePromise = null;
  }

  addEnrolment(courseId: string) {
    if (!this.hasEnrolment(courseId)) {
      this.setEnrolments(this.enrolments.set(courseId, []));
      this.save();
    }
  }

  addStudentToEnrolment(courseId: string, studentId: string) {
    // if (!this.accountId) {
    //   throw new Error("Account ID not set");
    // }
    const existingIds = this.enrolments.get(courseId) ?? [];
    this.setEnrolments(
      this.enrolments.set(courseId, [...new Set([...existingIds, studentId])]),
    );
    this.save();
  }

  clearStudentsFromEnrolment(courseId: string) {
    // if (!this.accountId) {
    //   throw new Error("Account ID not set");
    // }
    this.setEnrolments(this.enrolments.set(courseId, []));
    this.save();
  }

  addStudentToTrial(trial: TrialKey, studentId: string) {
    if (!this.companyId) {
      throw new Error("Account ID not set");
    }
    const existingIds = this.trials.get(trial) ?? [];
    this.setTrials(
      this.trials.set(trial, [...new Set([...existingIds, studentId])]),
    );
    this.save();
  }

  clearStudentsFromTrial(trialKey: TrialKey) {
    // if (!this.accountId) {
    //   throw new Error("Account ID not set");
    // }
    this.setTrials(this.trials.set(trialKey, []));
    this.save();
  }

  addTrial(trialKey: TrialKey) {
    if (!this.hasTrial(trialKey)) {
      this.setTrials(this.trials.set(trialKey, []));
      this.save();
    }
  }

  /** Clear the basket for the current company */
  clear() {
    this.basketData.set(this.companyId, makeEmptyBasketContents());
    this.save();
  }

  /** Clear the basket for all companies. Typically used when logging out */
  clearAllBaskets() {
    this.basketData.clear();
    this.save();
  }

  getContents(): BasketContents {
    return this.contents;
  }

  getCount(): number {
    return this.enrolments.size + this.trials.size;
  }

  hasEnrolment(courseId: string): boolean {
    return this.enrolments.has(courseId);
  }

  hasTrial(trialKey: TrialKey): boolean {
    return this.trials.has(trialKey);
  }

  removeEnrolment(courseId: string) {
    this.enrolments.delete(courseId);
    this.setEnrolments(this.enrolments);
    this.save();
  }

  removeTrial(trialKey: TrialKey) {
    this.trials.delete(trialKey);
    this.setTrials(this.trials);
    this.save();
  }

  async getQuote(getQuoteFn: GetQuoteFn): Promise<QuoteResponse> {
    if (!this.quotePromise) {
      this.quotePromise = getQuoteFn(this.contents);
    }
    return this.quotePromise;
  }

  setSuccessfulTransaction(
    transactions: AggregateTransactionDto[],
    checkoutOperation: CheckoutOperationRequest,
  ) {
    this.successfulTransaction = { transactions, checkoutOperation };
    this.save();
  }

  getSuccessfulTransaction():
    | {
        transactions: AggregateTransactionDto[];
        checkoutOperation: CheckoutOperationRequest;
      }
    | undefined {
    return this.successfulTransaction;
  }
}
