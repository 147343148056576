import { SeasonDto } from "@justraviga/classmanager-sdk";

import { formatMoneyFromInteger } from "../../intlFormatter";
import { getPlatformFunctions } from "../../platformSpecific";
import {
  makeSeasonUpdatePricingSchemeRequest,
  useUpdatePricingSchemeDefinition,
} from "../formDefinitions/seasonUpdatePricingSchemeForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import {
  DefaultEntityActions,
  EntityActions,
} from "../useDefaultEntityActions";

export type SeasonImplementedActions = Omit<
  EntityActions<Model>,
  "deleteMany" | "archiveMany" | "unarchiveMany"
> & {
  showPricingSchemeUpdateForm: (entity: Model) => void;
  showPreview: (entity: Model) => void;
};

type Model = SeasonDto;

interface UseSharedSeasonActionsParams {
  defaultActions: DefaultEntityActions<Model>;
  goToCreateSeasonPage: () => void;
  goToUpdateSeasonPage: (season: SeasonDto) => void;
}

type SharedSeasonImplementedActions = Pick<
  SeasonImplementedActions,
  | "showCreateForm"
  | "showUpdateForm"
  | "deleteOne"
  | "archive"
  | "unarchive"
  | "showPricingSchemeUpdateForm"
>;

export const useSharedSeasonActions = ({
  defaultActions,
  goToCreateSeasonPage,
  goToUpdateSeasonPage,
}: UseSharedSeasonActionsParams): SharedSeasonImplementedActions => {
  const { Banner, GenericForm, View } = useGenericComponents();
  const { api } = getPlatformFunctions();

  return {
    showCreateForm: goToCreateSeasonPage,

    showUpdateForm: (season: SeasonDto) => goToUpdateSeasonPage(season),

    showPricingSchemeUpdateForm: (entity: Model) => {
      return defaultActions.showUpdateForm({
        title: "Edit pricing scheme",
        form: (
          <View>
            <View className={"pb-8"}>
              <Banner
                title={"Prices will reset"}
                content={`Editing this pricing scheme will reset all class prices to ${formatMoneyFromInteger(0, 0)}.`}
                variant={"warning"}
              />
            </View>
            <GenericForm
              apiRequest={makeSeasonUpdatePricingSchemeRequest({
                api,
                id: entity.id,
              })}
              defaultValues={entity}
              formDefinitionHook={useUpdatePricingSchemeDefinition}
              onSuccess={defaultActions.onUpdateSuccess}
            />
          </View>
        ),
      });
    },

    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() =>
        api.seasons.deleteSeason({ id: entity.id }),
      ),

    archive: (entity: Model) =>
      defaultActions.archive(() =>
        api.seasons.archiveSeason({ id: entity.id }),
      ),

    unarchive: (entity: Model) =>
      defaultActions.unarchive(() =>
        api.seasons.unarchiveSeason({ id: entity.id }),
      ),
  };
};
