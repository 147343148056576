import { useEffect, useState } from "react";

import { AttendanceStatus, idPrefix } from "@justraviga/classmanager-sdk";

import { Api } from "../api";
import { debounce } from "../asyncUtils";
import { cacheDependencyMap, sdkCache } from "../cache";
import { getPlatformFunctions } from "../platformSpecific";

type StudentId = string;
export type AttendanceStatuses = Record<StudentId, AttendanceStatus>;

/**
 * Keep track of what attendance status changes we need to send to the API.
 */
const apiQueue = new Map<StudentId, AttendanceStatus>();

const updateApi = (api: Api, classId: string, date: string, time: string) => {
  api.attendances
    .createAttendance({
      attendanceBlueprint: [...apiQueue.entries()].map(
        ([studentId, status]) => ({
          classId,
          studentId,
          date,
          time,
          status,
        }),
      ),
    })
    .then(() => {
      // Because the API doesn't return records for 'unknowns', the usual automatic cache-clearing behaviour
      // won't take place, so we need to do this manually.
      sdkCache.clearByPrefix(idPrefix.Attendance, cacheDependencyMap);
    });
  apiQueue.clear();
};

const debouncedUpdateApi = debounce(updateApi, 1000);

const updateApiQueue = (
  api: Api,
  studentIds: string[],
  classId: string,
  date: string,
  time: string,
  status: AttendanceStatus,
) => {
  studentIds.forEach(studentId => apiQueue.set(studentId, status));
  debouncedUpdateApi(api, classId, date, time);
};

/**
 * This is a unique hook, specific to the attendances pages on web and mobile, in that it
 * only loads the attendance data once, then keeps track of state internally, to provide immediate feedback to
 * the UI.
 *
 * It **SHOULD NOT** be used more generally, e.g. on the Lesson details page, as these can just use normal reactive
 * data.
 */
export const useAttendanceStatuses = ({
  classId,
  date,
  time,
}: {
  classId: string;
  date: string;
  time: string;
}) => {
  // Keep a local copy of the attendance state. This allows us to do immediate UI updates
  const [statuses, setStatuses] = useState<AttendanceStatuses>();

  const { api } = getPlatformFunctions();

  const numAttendances = statuses
    ? Object.values(statuses).filter(
        status => status !== AttendanceStatus.Unknown,
      ).length
    : 0;

  const attendanceProgress = statuses
    ? calculateProgressPercent(Object.keys(statuses).length, numAttendances)
    : 0;

  // On first load, and only once, fetch attendance statuses from the API
  useEffect(() => {
    // We only want to set the statuses once initially from server state.
    // After that, we'll maintain it locally for performance.
    Promise.all([
      api.attendees.listAttendee({
        where: [
          {
            classId: {
              equals: classId,
            },
            date: {
              equals: date,
            },
            time: {
              equals: time,
            },
          },
        ],
      }),
      api.attendances.listAttendance({
        selectAll: true,
        where: {
          classId: {
            equals: classId,
          },
          date: {
            equals: date,
          },
          time: {
            equals: time,
          },
        },
      }),
    ]).then(([attendees, { data: attendances }]) => {
      const studentIds = attendees.map(attendee => attendee.studentId);
      setStatuses(
        studentIds.reduce((acc: AttendanceStatuses, studentId) => {
          const attendance = attendances.find(
            attendance => attendance.studentId === studentId,
          );
          acc[studentId] = attendance?.status ?? AttendanceStatus.Unknown;
          return acc;
        }, {} as AttendanceStatuses),
      );
    });
  }, [api, classId, date, time]);

  const setAttendanceStatus = (studentId: string, status: AttendanceStatus) => {
    const newStatuses = {
      ...statuses,
      [studentId]: status,
    };
    setStatuses(newStatuses);
    updateApiQueue(api, [studentId], classId, date, time, status);
  };

  const setAttendanceStatuses = (status: AttendanceStatus) => {
    const studentIds = Object.keys(statuses ?? {});

    setStatuses(
      studentIds.reduce((acc, studentId) => {
        acc[studentId] = status;
        return acc;
      }, {} as AttendanceStatuses),
    );
    updateApiQueue(api, studentIds, classId, date, time, status);
  };

  return {
    statuses,
    attendanceProgress,
    setAttendanceStatus,
    setAttendanceStatuses,
  };
};

const calculateProgressPercent = (
  numStudents: number,
  numAttendances: number,
) => {
  if (numStudents === 0) {
    return 0;
  }

  return Math.round((numAttendances / numStudents) * 100);
};
