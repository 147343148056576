import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

interface SeasonDiscountFormData {
  discountPercent: number;
}

export function seasonDiscountFormDefinition() {
  return new FormDefinitionBuilder<SeasonDiscountFormData>()
    .integer("discountPercent", {
      label: "Discount",
      prefix: "%",
      required: true,
    })
    .getDefinition();
}

export function seasonDiscountFormSubmit(formData: SeasonDiscountFormData) {
  // temporary validation as we don't have min/max values on inputs
  if (formData.discountPercent < 0 || formData.discountPercent > 100) {
    return Promise.reject({
      statusCode: 422,
      validationErrors: {
        discountPercent: ["Discount must be between 0 and 100."],
      },
    });
  }

  return Promise.resolve(formData);
}
