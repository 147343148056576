import { StudentDto } from "@justraviga/classmanager-sdk";

import { DetailCard } from "@/modules/company/common/DetailCard";

interface MedicalInfoCardProps {
  student: StudentDto;
}

export const MedicalInfoCard = ({ student }: MedicalInfoCardProps) => {
  return (
    <DetailCard
      isFoldable={student.medicalInfo !== null}
      title={"Medical information"}
      showPlaceholder={student.medicalInfo === null}>
      <div className={"px-2 pb-2"}>{student.medicalInfo}</div>
    </DetailCard>
  );
};
