import { ReactNode } from "react";

import { StaffPermissionSet } from "@justraviga/classmanager-sdk";

import { PermissionContext } from "./permissionContext";
import { useAuthState } from "../../auth/useAuthState";
import {
  hasMinimumRole,
  Permission,
  permissionsToRoles,
} from "../../permission";

export const PermissionProvider = ({ children }: { children: ReactNode }) => {
  const { account, isLoading } = useAuthState();

  const hasPermission = (permission: Permission) => {
    if (account?.permissionSet === undefined) {
      return false;
    }

    const targetRole = permissionsToRoles[permission];
    return hasMinimumRole(
      targetRole,
      account.permissionSet as unknown as StaffPermissionSet,
    );
  };

  return (
    <PermissionContext.Provider
      value={{
        hasPermission,
        isLoading,
      }}>
      {children}
    </PermissionContext.Provider>
  );
};
