import { useGenericComponents } from "../../../GenericComponentsProvider";

export const SpaceNotGuaranteedBanner = () => {
  const { Banner } = useGenericComponents();
  return (
    <Banner
      className={"w-full text-label-400 text-grey-900"}
      content={"Your space is not guaranteed in the class until you pay."}
      variant={"warning"}
    />
  );
};
