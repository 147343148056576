import { useEffect, useState } from "react";

import {
  AggregateTransactionDto,
  BillDto,
  GetBillRequest,
  PricingPlanDto,
  SeasonDto,
} from "@justraviga/classmanager-sdk";
import { match } from "ts-pattern";

import { usePreviewTuitionFirstStepForm } from "./usePreviewTuitionFirstStepForm";
import { FormDefinition } from "../../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";
import { MultiStepsHeaderStep } from "../../../forms/multiStepFormProps";
import { dayjs } from "../../../lib/dayjs";
import { getPlatformFunctions } from "../../../platformSpecific";
import { useFormActions } from "../../FormActionsProvider";

export const previewTuitionFormSteps: MultiStepsHeaderStep[] = [
  { index: 1, label: "Select billing period" },
  { index: 2, label: "Review" },
];
export type PreviewTuitionFormStepType = 1 | 2;

export type PreviewTuitionFormSchema = GetBillRequest;

export type SubmitPreviewMonthForm = (
  formData: PreviewTuitionFormSchema,
) => Promise<PreviewTuitionFormSchema | AggregateTransactionDto>;

export type ExitTuitionPreviewForm = () => Promise<object>;

interface PreviewTuitionFormData {
  formHook: FormDefinition<PreviewTuitionFormSchema>;
  step: PreviewTuitionFormStepType;
  goBackToFirstStep: () => void;
  selectedSeason: SeasonDto | undefined;
  billingMonth: string | undefined;
  billData: BillDto | undefined;
  pricingPlan: PricingPlanDto | undefined;
  onSuccess: () => void;
  submitFunction: SubmitPreviewMonthForm | ExitTuitionPreviewForm;
}

export const usePreviewTuitionForm = (
  familyId: string,
): PreviewTuitionFormData => {
  const builder = new FormDefinitionBuilder<PreviewTuitionFormSchema>();
  const [billData, setBillData] = useState<BillDto | undefined>(undefined);
  const { api, usePlatformEntityActions } = getPlatformFunctions();
  const defaultActions = usePlatformEntityActions<AggregateTransactionDto>({
    entity: "transaction",
  });
  const { setAllowCreateAdditional, setCreateButtonText, setResetAfterSubmit } =
    useFormActions();
  const [step, setStep] = useState<PreviewTuitionFormStepType>(1);
  const { selectedSeason, billingMonth, pricingPlan } =
    usePreviewTuitionFirstStepForm(builder, step);

  const [formValues, setFormValues] =
    useState<Partial<PreviewTuitionFormSchema>>();

  const loadBillData = async ({
    seasonId,
    billingMonth,
    pricingPlanId,
  }: {
    seasonId: string;
    billingMonth: string;
    pricingPlanId: string;
  }) => {
    return await api.bills.getBill({
      familyId,
      seasonId,
      billingMonth,
      pricingPlanId,
    });
  };

  const goBackToFirstStep = () => {
    setStep(1);
    setCreateButtonText("Preview");
  };

  //it is important to use useEffect here to prevent the stack trace error
  useEffect(() => {
    setAllowCreateAdditional(false);
    setCreateButtonText("Preview");
    setResetAfterSubmit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = match(step)
    .with(1, () => {
      return {
        onSuccess: () => {
          setStep(2);
          setCreateButtonText("Exit");
        },
        submitFunction: async (formData: PreviewTuitionFormSchema) => {
          const newValues = { ...formData, ...formValues };
          setFormValues(newValues);

          setBillData(
            await loadBillData({
              seasonId: formData.seasonId,
              billingMonth:
                formData.billingMonth || dayjs().format("YYYY-MM-DD"),
              pricingPlanId: formData.pricingPlanId,
            }),
          );

          return Promise.resolve(newValues);
        },
      };
    })
    .with(2, () => {
      return {
        onSuccess: () => defaultActions.hideForm(),
        submitFunction: () => Promise.resolve({}),
      };
    })
    .run();

  return {
    formHook:
      builder.getDefinition() as FormDefinition<PreviewTuitionFormSchema>,
    billingMonth,
    billData,
    pricingPlan,
    step,
    goBackToFirstStep,
    selectedSeason,
    ...data,
  };
};
