import { useState } from "react";

import {
  AggregateTransactionDto,
  CheckoutOperationRequest,
  QuoteResponse,
} from "@justraviga/classmanager-sdk";

import { addPricingPlansForEnrolmentsToQuote } from "./addPricingPlansForEnrolmentsToQuote";
import { showAlert } from "../../../../../alertState";
import { useAuthState } from "../../../../../auth/useAuthState";
import { SelectedPricingPlanIds } from "../../../../../data/useCheckoutData";
import { checkoutPayment } from "../../../../../payments/checkoutPayment";
import { getPlatformFunctions } from "../../../../../platformSpecific";
import { useBasket } from "../../../../basket/useBasket";
import { useGenericComponents } from "../../../../GenericComponentsProvider";

export const ConfirmBookingButton = ({
  onSuccess,
  quoteResponse,
  selectedPricingPlanIds,
  allPricingPlansSelected,
}: {
  onSuccess: (
    result?: AggregateTransactionDto[],
    checkoutOperation?: CheckoutOperationRequest,
  ) => void;
  quoteResponse: QuoteResponse;
  selectedPricingPlanIds: SelectedPricingPlanIds;
  allPricingPlansSelected: boolean;
}) => {
  const { Button } = useGenericComponents();
  const { api } = getPlatformFunctions();
  const { account } = useAuthState();
  const [isPendingConfirmation, setIsPendingConfirmation] = useState(false);

  const { contents } = useBasket();

  const confirmBooking = async () => {
    if (!allPricingPlansSelected) {
      showAlert({
        content: "You need to select a payment option before continuing.",
        variant: "error",
      });
      return;
    }

    setIsPendingConfirmation(true);
    const quote = await addPricingPlansForEnrolmentsToQuote(
      contents,
      quoteResponse,
      selectedPricingPlanIds,
    );

    const checkoutOperation = {
      familyId: account!.entityId!,
      checkoutRequest: {
        paymentIntentId: "",
        items: quote,
      },
    };
    checkoutPayment(api, checkoutOperation, {
      onSuccess: result => {
        onSuccess(result, checkoutOperation);
      },
    }).finally(() => {
      setIsPendingConfirmation(false);
    });
  };

  return (
    <Button
      loading={isPendingConfirmation}
      disabled={isPendingConfirmation}
      className={"w-full"}
      variant={"brand"}
      text={"Confirm booking"}
      isFullWidth={true}
      onClick={confirmBooking}
    />
  );
};
