import { PropsWithChildren, useEffect, useState } from "react";

import { useAuthState } from "@shared/components/AuthStateProvider";

import { CatalogueCreateAccountPage } from "@/modules/auth/catalogue/CatalogueCreateAccountPage";
import { CatalogueLoginPage } from "@/modules/auth/catalogue/CatalogueLoginPage";
import { CatalogueRegisterPage } from "@/modules/auth/catalogue/CatalogueRegisterPage";

type Mode = "hidden" | "login" | "register";

/**
 * Determine when to show login/register/create account pages based on the current user state.
 */
export const CatalogueAuthController = ({
  allowCancel,
  children,
  initialMode,
  onModeChange,
}: PropsWithChildren<{
  allowCancel: boolean;
  initialMode?: Mode;
  onModeChange?: (mode: Mode) => void;
}>) => {
  const [mode, setMode] = useState<Mode>(initialMode ?? "login");
  const { user, account, isLoading, accountsLoading } = useAuthState();

  // Watch the initial mode prop for changes. This is used to explicitly request showing
  // the login or register pages (usually from the user clicking a menu item).
  useEffect(() => {
    if (initialMode !== undefined) {
      setMode(initialMode);
    }
  }, [initialMode]);

  // Whenever the mode changes, propagate it to the parent component
  useEffect(() => {
    onModeChange?.(mode);
  }, [onModeChange, mode]);

  // For certain auth scenarios, we allow the user to cancel and go back to where they came from.
  // This is usually the case where the user has decided to log in themselves, rather than it being mandatory.
  const goBack = allowCancel
    ? () => {
        setMode("hidden");
      }
    : undefined;

  // Scroll to top of page when mode changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [mode]);

  if (mode === "hidden") {
    return children;
  }

  if (isLoading || accountsLoading) {
    return null;
  }

  // If they have an account, all is well, just show the children
  if (account) {
    return children;
  }

  if (!user) {
    // They need to login / register
    return mode === "login" ? (
      <CatalogueLoginPage
        goToRegister={() => setMode("register")}
        goBack={goBack}
      />
    ) : (
      <CatalogueRegisterPage
        goToLogin={() => setMode("login")}
        goBack={goBack}
      />
    );
  }

  if (!account) {
    // They need to create an account for this company
    return <CatalogueCreateAccountPage goBack={goBack} />;
  }

  return null;
};
