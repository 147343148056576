import { LoginDto } from "@justraviga/classmanager-sdk";

import { useAuthState } from "@shared/components/AuthStateProvider";
import { useCatalogueContext } from "@shared/components/catalogue/useCatalogueContext";
import { useFormActions } from "@shared/components/FormActionsProvider";

import { CatalogueMinimalLayout } from "@/modules/auth/catalogue/CatalogueMinimalLayout";
import { AuthPageCard } from "@/modules/auth/common/AuthPageCard";
import { LoginForm } from "@/modules/auth/common/LoginForm";
import { LoginHeader } from "@/modules/auth/common/LoginHeader";
import { PoweredBy } from "@/modules/auth/common/PoweredBy";
import { SwitchAuthType } from "@/modules/auth/common/SwitchAuthType";

export const CatalogueLoginPage = ({
  goBack,
  goToRegister,
}: {
  goBack?: () => void;
  goToRegister: () => void;
}) => {
  const { company } = useCatalogueContext();
  const { setUser } = useAuthState();
  const { submit, isLoading: loginLoading } = useFormActions();

  const onLogin = (loginDto: LoginDto) => {
    setUser(loginDto.user, loginDto.token);
  };

  return (
    <CatalogueMinimalLayout>
      <AuthPageCard
        header={
          <LoginHeader companyLogo={company.logo} handleBackButton={goBack} />
        }
        title={company.name}
        subtitle={"Login to your Class Manager account"}
        content={
          <LoginForm
            variant="brand"
            submitHandler={submit}
            isLoading={loginLoading}
            defaultValues={{}}
            onSuccess={onLogin}
          />
        }
        showTerms={false}
        footer={
          <SwitchAuthType
            message="New to Class Manager?"
            onClick={goToRegister}
            linkText="Register">
            <PoweredBy />
          </SwitchAuthType>
        }
      />
    </CatalogueMinimalLayout>
  );
};
