import { match } from "ts-pattern";

import { getPlatformFunctions } from "../../../../../platformSpecific";
import { useGenericComponents } from "../../../../GenericComponentsProvider";

const getTitle = (numClasses?: number) =>
  match(numClasses)
    .with(undefined, () => "Loading")
    .with(0, () => "No classes")
    .with(1, () => "1 class")
    .otherwise(() => `${numClasses} classes`);

export const ClassCountWidget = ({ seasonId }: { seasonId: string }) => {
  const { View, Text } = useGenericComponents();
  const { useApi } = getPlatformFunctions();
  const { data: classes } = useApi("listCourse", {
    where: {
      seasonId: {
        equals: seasonId,
      },
    },
  });
  return (
    <View className={"flex flex-row items-center p-1 md:items-end"}>
      <Text className={"mr-1 text-2xl font-semibold text-grey-900"}>
        {getTitle(classes?.pagination?.total)}
      </Text>
      <Text className={"pt-1 text-grey-600"}>/week</Text>
    </View>
  );
};
