import { ReactNode } from "react";

import { IconName } from "../../availableIcons";
import { colors } from "../../colors";
import { cn } from "../../cssUtils";
import { useGenericComponents } from "../GenericComponentsProvider";

export const PreviewSheetContent = ({ children }: { children: ReactNode }) => {
  const { View } = useGenericComponents();
  return <View className="py-5 pb-20">{children}</View>;
};

export const PreviewSheetContentDetailSection = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { View } = useGenericComponents();
  return (
    <View className="flex flex-col line-clamp-1 space-y-2">{children}</View>
  );
};

export const PreviewSheetContentTitle = ({ title }: { title: string }) => {
  const { Text, View } = useGenericComponents();
  return (
    <View>
      <Text className="text-heading6-600 font-semibold text-grey-900">
        {title}
      </Text>
    </View>
  );
};

export const PreviewSheetContentSubTitle = ({
  title,
  icon,
  truncate = false,
}: {
  title: string;
  icon?: IconName;
  truncate?: boolean;
}) => {
  const { Icon, Text, View } = useGenericComponents();
  return (
    <View
      className={cn("flex flex-row items-center space-x-2", {
        "flex-1": truncate,
      })}>
      {icon && <Icon name={icon} color={colors.grey["900"]} />}
      <Text
        className={cn("text-base text-body-600 font-semibold text-grey-900")}
        truncate={truncate}>
        {title}
      </Text>
    </View>
  );
};

export const PreviewSheetContentRow = ({
  title,
  content,
}: {
  title: string | ReactNode;
  content?: string;
}) => {
  const { Text, View } = useGenericComponents();
  return (
    <View className={"flex flex-col space-y-1"}>
      {typeof title === "string" ? (
        <Text className={"text-body-400 text-grey-900"}>{title}</Text>
      ) : (
        title
      )}
      {content && (
        <Text className={"text-label-400 text-grey-600"}>{content}</Text>
      )}
    </View>
  );
};

export const PreviewSheetContentInfoRow = ({
  text,
  spacing = 2,
}: {
  text?: string;
  spacing?: 0 | 1 | 2;
}) => {
  const { Text } = useGenericComponents();
  return (
    <Text
      className={cn("text-base-400 text-base text-grey-600", {
        "mt-2": spacing === 2,
        "mt-1": spacing === 1,
        "mt-0": spacing === 0,
      })}>
      {text}
    </Text>
  );
};

export const PreviewSheetContentInfoRowStatus = ({
  content,
  status,
}: {
  content: string | ReactNode;
  status: boolean;
}) => {
  const { Text, View } = useGenericComponents();
  return (
    <View className={`mt-2 flex flex-row items-center`}>
      {typeof content === "string" ? (
        <Text className={"text-base text-grey-600"}>{content}</Text>
      ) : (
        content
      )}
      <View className={`ml-2`}>
        <PreviewSheetContentCheckboxIcon checked={status} />
      </View>
    </View>
  );
};

export const PreviewSheetContentDetailsRow = ({
  children,
}: {
  text?: string;
  children?: ReactNode;
}) => {
  const { Text } = useGenericComponents();
  return <Text className={"mt-2 text-base text-grey-600"}>{children}</Text>;
};

export const PreviewSheetContentCheckboxIcon = ({
  checked,
}: {
  checked: boolean;
}) => {
  const { Icon } = useGenericComponents();
  const iconName = checked ? "checkmarkCircle" : "closeCircle";
  const iconColor = checked ? colors.green["600"] : colors.grey["500"];

  return <Icon name={iconName} color={iconColor} />;
};

export const PreviewSheetContentInfoPairRow = ({
  left,
  right,
  justify = "between",
}: {
  left: string | ReactNode;
  right: string | ReactNode;
  justify?: "between" | "start";
}) => {
  const { Text, View } = useGenericComponents();
  return (
    <View
      className={cn("flex flex-row items-baseline mt-2 flex-wrap", {
        "justify-between": justify === "between",
        "justify-start space-x-3": justify === "start",
      })}>
      {typeof left === "string" ? (
        <PreviewSheetContentInfoRow text={left} spacing={0} />
      ) : (
        left
      )}
      {typeof right === "string" ? (
        <Text className="text-base-400 text-base text-grey-900">{right}</Text>
      ) : (
        right
      )}
    </View>
  );
};
