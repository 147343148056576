import { PropsWithChildren } from "react";

import { StripeSetupChecker } from "shared/components";

import { FamilyBackground } from "@/modules/common/layout/FamilyBackground";
import { FamilyContent } from "@/modules/common/layout/FamilyContent";
import { FamilyFooter } from "@/modules/common/layout/FamilyFooter";
import {
  FamilyHeader,
  FamilyHeaderProps,
} from "@/modules/common/layout/FamilyHeader";

interface PublicLayoutProps extends PropsWithChildren {
  branded?: boolean;
  backButton?: FamilyHeaderProps["backButton"];
}

export const PublicLayout = ({ children, backButton }: PublicLayoutProps) => {
  return (
    <FamilyBackground>
      <FamilyHeader backButton={backButton} />
      <StripeSetupChecker hasImplicitCompany={true}>
        <FamilyContent>{children}</FamilyContent>
      </StripeSetupChecker>
      <FamilyFooter />
    </FamilyBackground>
  );
};
