import {
  AggregateTransactionDetailsDto,
  AggregateTransactionDto,
  BillingTiming,
  CreditableDetailType,
  CreditDetailType,
  PricingPlanDto,
  PricingPlanType,
  SeasonDto,
  TransactionDetailType,
  TransactionType,
} from "@justraviga/classmanager-sdk";
import { idPrefix } from "@justraviga/classmanager-sdk/dist/models/IdPrefix";

import { SelectItem } from "./components/interfaces";
import { formatDateTime } from "./intlFormatter";
import { dayjs } from "./lib/dayjs";
import {
  TransactionPrimaryType,
  transactionTypeDetailMap,
} from "./transactions/transactionTypes";

export type AggregateTransactionWithPrimaryType = AggregateTransactionDto & {
  primaryType: TransactionPrimaryType;
};

export const creditDetailTypes = Object.values(CreditDetailType) as string[];
export const creditableDetailTypes = Object.values(
  CreditableDetailType,
) as string[];

const isPrimaryTransactionDetail = (type: TransactionDetailType) =>
  transactionTypeDetailMap[type] !== null;

export type TransactionTotals = {
  total: number;
  subtotal: number;
  discount: number;
  taxes: Record<string, number>;
};

type TransactionLineItem = {
  primaryId: string;
  primaryTitle: string;
  primaryAmount: number;
  additionalInfo: Array<TransactionLineAdditionalItem>;
  totalAmount: number;
};

type TransactionLineAdditionalItem = {
  title: string;
  amount: number;
};

export const isAutoPayment = (transaction: AggregateTransactionDto) =>
  transaction.details.some(
    d => d.details.type === TransactionDetailType.PaymentAuto,
  );

export const isDebit = (transaction: AggregateTransactionDto) =>
  transaction.transaction.type === "debit";

export const containsCreditableItems = (transaction: AggregateTransactionDto) =>
  transaction.details.some(d => creditableDetailTypes.includes(d.details.type));

// The function is needed to identify which icon, bg and text to use on TransactionTile widget
export const getTransactionPrimaryType = (
  transaction: AggregateTransactionDto,
): TransactionPrimaryType => {
  // Use the first available primary type
  const relevantLine = transaction.details.find(
    detail => transactionTypeDetailMap[detail.details.type] !== null,
  );
  return relevantLine
    ? transactionTypeDetailMap[relevantLine.details.type] ?? "unknown"
    : "unknown";
};

export const getTransactionDetailSubtotals = (
  details: Array<AggregateTransactionDetailsDto>,
): TransactionTotals => {
  const initialTotals: TransactionTotals = {
    total: 0,
    subtotal: 0,
    discount: 0,
    taxes: {},
  };

  return details.reduce((acc, detail) => {
    const { type, amount, description } = detail.details;

    if (type === TransactionDetailType.Discount) {
      acc.discount += amount;
      acc.total += amount;
    } else if (
      type === TransactionDetailType.Tax ||
      type === TransactionDetailType.TaxCredit
    ) {
      acc.taxes[description!] = (acc.taxes[description!] || 0) + amount;
      acc.total += amount;
    } else {
      acc.subtotal += amount;
      acc.total += amount;
    }

    return acc;
  }, initialTotals);
};

type TransactionDetailId = string;

interface SimplifiedAggregateTransactionDetailsDto {
  details: {
    type: TransactionDetailType;
    amount: number;
  };
  relatedEntities: Array<{
    entityId: string | TransactionDetailId;
  }>;
}

/**
 * Given an array of transaction detail lines (typically from a single aggregate transaction)
 * return a map of detail IDs and their total discount amounts.
 * Only includes IDs of detail lines that have discounts applied.
 */
export const getTransactionDiscountAmounts = (
  details: Array<SimplifiedAggregateTransactionDetailsDto>,
): Record<TransactionDetailId, number> => {
  const amounts: Record<TransactionDetailId, number> = {};
  details
    .filter(d => d.details.type === "discount")
    .map(discountLine => {
      const relatedTransactionDetailId = discountLine.relatedEntities.find(e =>
        e.entityId.startsWith(idPrefix.TransactionDetail + "_"),
      )!.entityId;

      if (amounts[relatedTransactionDetailId]) {
        amounts[relatedTransactionDetailId] += discountLine.details.amount;
      } else {
        amounts[relatedTransactionDetailId] = discountLine.details.amount;
      }
    });

  return amounts;
};

/**
 * Given an array of transaction detail lines (typically from multiple related transactions),
 * return a map of detail IDs and the total amounts they have already had credited.
 * Only includes IDs of detail lines that have credits applied.
 */
export const getCreditAmounts = (
  details: Array<SimplifiedAggregateTransactionDetailsDto>,
): Record<TransactionDetailId, number> => {
  const amounts: Record<TransactionDetailId, number> = {};
  const filteredDetailTypes = creditDetailTypes.filter(
    d => d !== CreditDetailType.TaxCredit,
  );
  const filteredDetails = details.filter(d =>
    filteredDetailTypes.includes(d.details.type),
  );

  filteredDetails.forEach(creditLine => {
    const relatedTransactionDetailId = creditLine.relatedEntities.find(e =>
      e.entityId.startsWith(idPrefix.TransactionDetail + "_"),
    )!.entityId;

    if (amounts[relatedTransactionDetailId]) {
      amounts[relatedTransactionDetailId] += creditLine.details.amount;
    } else {
      amounts[relatedTransactionDetailId] = creditLine.details.amount;
    }
  });

  return amounts;
};

const getRelatedTransactionDetails = ({
  details,
  entityId,
}: {
  details: Array<AggregateTransactionDetailsDto>;
  entityId: string;
}): Array<AggregateTransactionDetailsDto> => {
  return details.filter(
    detail =>
      detail.relatedEntities.filter(e => e.entityId === entityId).length > 0,
  );
};

export const getTransactionLineItems = ({
  details,
}: {
  details: Array<AggregateTransactionDetailsDto>;
}): Array<TransactionLineItem> => {
  return details
    .filter(detail => isPrimaryTransactionDetail(detail.details.type))
    .map(detail => {
      const relatedDetails = getRelatedTransactionDetails({
        details,
        entityId: detail.details.id,
      });

      // Combine all details to get the total amount for one line item
      const allDetails = [...relatedDetails, detail];
      const detailsTotals = getTransactionDetailSubtotals(allDetails);

      const primaryTitle = detail.details.description;
      const primaryAmount = detail.details.amount;

      const additionalInfo: Array<TransactionLineAdditionalItem> =
        relatedDetails.map(relatedDetail => ({
          title: relatedDetail.details.description,
          amount: relatedDetail.details.amount,
        }));

      return {
        primaryId: detail.details.id,
        primaryTitle,
        primaryAmount,
        additionalInfo,
        totalAmount: detailsTotals.total,
      };
    });
};

export const applyUnaryOperator = (isDebit: boolean, amount: number) => {
  return isDebit ? -amount : amount;
};

type TransactionDetailAmounts = Array<number>;
export type SimplifiedTransaction = [TransactionType, TransactionDetailAmounts];
type TransactionId = string;
export type SimplifiedTransactionWithId = [
  TransactionId,
  ...SimplifiedTransaction,
];

/**
 * Calculate the historic balance for each transaction in the list, using the current balance.
 */
export const calculateBalances = (
  currentBalance: number,
  transactions: Array<SimplifiedTransaction>,
) => {
  let reducingBalance = currentBalance;
  // We skip the last transaction in the list, as we're always looking at the previous transaction
  const relevantTransactions = transactions.slice(0, -1);
  return [
    currentBalance,
    ...relevantTransactions.map(([type, amounts]) => {
      const transactionTotal = amounts.reduce((acc, amount) => acc + amount, 0);
      const adjustmentAmount =
        type === "credit" ? -transactionTotal : transactionTotal;
      reducingBalance += adjustmentAmount;
      return reducingBalance;
    }),
  ];
};

export type Balances = Record<string, number>;

export const getBalances = (
  currentBalance: number,
  transactions: Array<AggregateTransactionDto>,
) => {
  // Get the running balance for all transactions, in the same order
  const balances = calculateBalances(
    currentBalance,
    transactions.map(t => [
      t.transaction.type,
      t.details.map(d => d.details.amount),
    ]),
  );

  // Build a map of transaction ID to balance
  return transactions.reduce((acc, transaction, i) => {
    acc[transaction.aggregateId] = balances[i];
    return acc;
  }, {} as Balances);
};

export function billingTimingMonthOffset(
  period: PricingPlanDto["billingTiming"],
) {
  switch (period) {
    case BillingTiming.Advance:
      return -1;
    case BillingTiming.Arrears:
      return 1;
    case BillingTiming.During:
    default:
      return 0;
  }
}

function getMonthlyPlanBillableMonths(
  season: SeasonDto,
  pricingPlan: PricingPlanDto,
) {
  const startAt = dayjs(season.startAt)
    .add(billingTimingMonthOffset(pricingPlan.billingTiming), "month")
    .set("date", pricingPlan.paymentDay ?? 1);

  const endAt = startAt.add(pricingPlan.numberOfInstallments - 1, "month");

  return {
    startAt,
    endAt,
  };
}

function getBillableMonthsStartAndEndDates(
  season: SeasonDto,
  pricingPlan?: PricingPlanDto,
) {
  if (pricingPlan?.type === PricingPlanType.OneOff) {
    return {
      startAt: dayjs(pricingPlan.firstPaymentDate),
      endAt: dayjs(pricingPlan.firstPaymentDate),
    };
  }

  if (pricingPlan?.type === PricingPlanType.Monthly) {
    return getMonthlyPlanBillableMonths(season, pricingPlan);
  }

  return {
    startAt: dayjs(season.startAt),
    endAt: dayjs(season.endAt),
  };
}

function generateBillableMonthsSelectItemOptions(
  startAt: dayjs.Dayjs,
  endAt: dayjs.Dayjs,
): SelectItem[] {
  const options: SelectItem[] = [];

  let currentDate = dayjs(startAt);
  while (currentDate.isBefore(endAt) || currentDate.isSame(endAt, "month")) {
    const currentAsDateObject = currentDate.toDate();
    const value = formatDateTime(currentAsDateObject, "isoDate");
    const label = formatDateTime(currentAsDateObject, "monthYear");

    options.push({ value, label });

    currentDate = currentDate.add(1, "month");
  }

  return options;
}

/**
 * Generate a list of relevant months for a given season, based on the selected
 * pricing plan.
 *
 */
export function generateMonthOptions(
  season: SeasonDto,
  selectedPricingPlan?: PricingPlanDto,
): SelectItem[] {
  const { startAt, endAt } = getBillableMonthsStartAndEndDates(
    season,
    selectedPricingPlan,
  );

  return generateBillableMonthsSelectItemOptions(startAt, endAt);
}
