import {
  JoinWaitingListPage as SharedJoinWaitingListPage,
  useCatalogueContext,
} from "shared/components";

import { MultiSelect } from "@/modules/common/form/select/MultiSelect";
import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { PublicLayout } from "@/modules/public/layout/PublicLayout";
import { Router } from "@/routing/router";

export const JoinWaitingListPage = ({ courseId }: { courseId: string }) => {
  const { company } = useCatalogueContext();

  return (
    <PublicLayout
      backButton={{
        text: "Back to classes",
        onClick: () => Router.push("Courses", { slug: company.slug }),
      }}>
      <SharedJoinWaitingListPage
        courseId={courseId}
        MultiSelect={MultiSelect}
        useStudentActions={useStudentActions}
        goToSuccess={() =>
          Router.push("JoinWaitingListSuccess", { slug: company.slug })
        }
      />
    </PublicLayout>
  );
};
