import { useMemo } from "react";

import {
  CustomFieldDto,
  CustomFieldEntity,
} from "@justraviga/classmanager-sdk";

import {
  ContentPlaceholder,
  NoCustomFieldsPlaceholder,
  useCustomFieldsPageData,
} from "shared/components";
import { enumLabel } from "shared/lib";

import {
  ActionMenu,
  ActionMenuItemProps,
} from "@/modules/common/ui/ActionMenu";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";
import {
  CompanyContent,
  CompanyTitleBar,
  CompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { CustomFieldCard } from "@/modules/company/customFields/card/CustomFieldCard";
import { useCustomFieldActions } from "@/modules/company/customFields/useCustomFieldActions";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";

const CustomFieldsPage = () => {
  const { customFields, hasCustomFields, entityFilter, setEntityFilter } =
    useCustomFieldsPageData();
  const customFieldActions = useCustomFieldActions();

  return (
    <>
      <CompanyTitleBar isTopLevel={true}>
        <CompanyTitleBarTitle title={"Custom fields"} />
        {hasCustomFields && <CustomFieldTitleBarCreateButton />}
      </CompanyTitleBar>
      <CompanyContent>
        {!hasCustomFields ? (
          <NoCustomFieldsPlaceholder
            onCreateClick={customFieldActions.showCreateForm}
          />
        ) : (
          <section className={"space-y-8"}>
            <EntityFilterDropdown
              value={entityFilter}
              onChange={setEntityFilter}
            />
            <ListContent customFields={customFields} />
          </section>
        )}
      </CompanyContent>
    </>
  );
};

const EntityFilterDropdown = ({
  value,
  onChange,
}: {
  value: CustomFieldEntity | undefined;
  onChange: (entity: CustomFieldEntity | undefined) => void;
}) => {
  // as this component will re-render on value change, we want to memoize the list of options to save rebuilding it
  const customFieldEntityNames: ActionMenuItemProps[] = useMemo(
    () =>
      Object.values(CustomFieldEntity)
        .sort((a, b) => a.localeCompare(b))
        .map(entity => {
          return {
            title: enumLabel("customFieldEntities", entity),
            onClick: () => onChange(entity),
          };
        }),
    [onChange],
  );

  const hasValueSelected = value !== undefined;

  return (
    <>
      <div className={"flex w-36 items-center"}>
        <ActionMenu
          align={"start"}
          trigger={
            <Button
              text={
                hasValueSelected
                  ? enumLabel("customFieldEntities", value)
                  : "View all"
              }
              size={"sm"}
              className={hasValueSelected ? "border-grey-900" : ""}
              role="entity-selector"
              rightIcon={"chevronDown"}
              variant={"secondary"}
            />
          }
          items={customFieldEntityNames}
        />
        {hasValueSelected && (
          <Button
            text={"Clear"}
            variant={"tertiary"}
            size={"sm"}
            className={"py-0 text-base font-normal text-grey-700 no-underline"}
            onClick={() => onChange(undefined)}
          />
        )}
      </div>
    </>
  );
};

const CustomFieldTitleBarCreateButton = () => {
  const { showCreateForm } = useCustomFieldActions();
  return (
    <div>
      <ProtectedOverlay permission={"customFields:manage"}>
        <div className={"max-md:hidden"}>
          <Button
            variant={"brand"}
            size={"sm"}
            onClick={() => showCreateForm()}
            text={"Create"}
          />
        </div>
        <div className={"md:hidden"}>
          <IconButton
            icon={"addOutline"}
            border={"rounded"}
            onClick={() => showCreateForm()}
            variant={"secondary-fill"}
          />
        </div>
      </ProtectedOverlay>
    </div>
  );
};

const ListContent = ({ customFields }: { customFields: CustomFieldDto[] }) => {
  if (customFields.length === 0) {
    return (
      <ContentPlaceholder
        icon={"searchOutline"}
        title={"We couldn't find what you’re looking for"}
        description={"No results match your filter criteria"}
      />
    );
  }

  return (
    <div className={"grid grid-cols-1 gap-4 md:gap-8 lg:grid-cols-2"}>
      {customFields.map(field => (
        <CustomFieldCard customField={field} key={field.id} />
      ))}
    </div>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "customFields:view",
  CustomFieldsPage,
  "Custom fields",
);

export { PermissionChecked as CustomFieldsPage };
