import { PricingSchemeValueDto, SeasonDto } from "@justraviga/classmanager-sdk";

import { SeasonPricingSchemeCard as SharedSeasonPricingSchemeCard } from "@shared/components/modules/pricingSchemes/SeasonPricingSchemeCard";
import { shouldShowPricingList } from "@shared/seasonUtils";

import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";
import { SeasonPricingListCard } from "@/modules/company/classPlanner/seasons/card/seasonPricing/SeasonPricingListCard";
import { SeasonPricingListCardWarning } from "@/modules/company/classPlanner/seasons/card/seasonPricing/SeasonPricingListCardWarning";
import { Router } from "@/routing/router";

export const SeasonPricingSchemeCard = ({
  season,
  isDesktop,
  pricingSchemeValues,
}: {
  season: SeasonDto;
  isDesktop: boolean;
  pricingSchemeValues: PricingSchemeValueDto[];
}) => {
  const showPricingList = shouldShowPricingList(season);

  if (!showPricingList) {
    return null;
  }

  const pricingSchemeBody = isDesktop ? (
    <SeasonPricingListCard season={season} values={pricingSchemeValues} />
  ) : (
    <SharedSeasonPricingSchemeCard
      season={season}
      BaseCard={BaseCard}
      shouldShowFooter={pricingSchemeValues.length === 0}
      footerSlot={
        <div className={"pt-1"}>
          <SeasonPricingListCardWarning />
        </div>
      }
      props={{
        onClick: () => Router.push("PricingTiers", { seasonId: season.id }),
        headerAction: {
          text: "View",
          onClick: () => Router.push("PricingTiers", { seasonId: season.id }),
        },
      }}
    />
  );

  return (
    <ProtectedOverlay permission={"schedule:view"}>
      <div className={"w-full space-y-5 md:space-y-8"}>{pricingSchemeBody}</div>
    </ProtectedOverlay>
  );
};
