import { ComponentType } from "react";

import { CatalogueAuthController } from "@/modules/auth/catalogue/CatalogueAuthController";

export const withFamilyAccount =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P) => {
    return (
      <CatalogueAuthController allowCancel={false}>
        <Component {...props} />
      </CatalogueAuthController>
    );
  };
