import { useState } from "react";

import {
  BillingTiming,
  FeeDto,
  PricingModel,
  PricingScheme,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

import { AdditionalFeeFormFieldBody } from "./AdditionalFeeFormFieldBody";
import { useAutomatedTuitionForm } from "./settings/automatedTuitionForm";
import { useAuthState } from "../../auth/useAuthState";
import { monthSpan } from "../../dateUtils";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
// import { getPricingModelLabel } from "../../seasonUtils";
import { AlertDialog } from "../interfaces/alertDialog";
import { Sheet } from "../interfaces/sheet";
import { MonthlyPayment } from "../modules/company/seasons/create/MonthlyPayment";
import { PricingBlurb } from "../modules/company/seasons/create/PricingBlurb";
import { UpfrontPayment } from "../modules/company/seasons/create/UpfrontPayment";

export interface SeasonFormDefinition {
  name: string;
  startAt: string;
  endAt: string;
  enableRegistrationFees: boolean;
  pricingModel: PricingModel | null;
  additionalFees: FeeDto[];
  pricingScheme: PricingScheme;

  allowUpfrontPricingPlan: boolean;
  discountPercent: number | null;

  allowMonthlyPricingPlan: boolean;
  numberOfInstallments: number | null;
  billingTiming: BillingTiming | null;
  paymentDay: number | null;
}

export interface SeasonFormStateValues {
  discountPercent: number | null;
  additionalFees: FeeDto[];
  seasonStartDate: string | null;
  seasonEndDate: string | null;
  // This value allows user to override number of installments calculated by the date range
  customNumberOfInstallments: number | null;
  // This value is the number of installments calculated by the date range
  numberOfInstallments: number | null;
  paymentStartDate: string | null;
  billingDayOfMonth: number | null;
  billingTiming: BillingTiming | null;
}

function getUpfrontPricingPlanFields(season?: SeasonDto) {
  const upfrontPricingPlan = season?.pricingPlans.find(
    plan => plan.type === "one-off",
  );

  if (!upfrontPricingPlan) {
    return {
      paymentStartDate: null,
      discountPercent: null,
    };
  }

  return {
    paymentStartDate: upfrontPricingPlan.firstPaymentDate,
    discountPercent: upfrontPricingPlan.discount,
  };
}

function getMonthlyPricingPlanFields(season?: SeasonDto) {
  const monthlyPricingPlan = season?.pricingPlans.find(
    plan => plan.type === "monthly",
  );

  if (!monthlyPricingPlan) {
    return {
      numberOfInstallments: null,
      billingTiming: null,
      billingDayOfMonth: null,
      customNumberOfInstallments: null,
    };
  }

  return {
    numberOfInstallments:
      season?.startAt && season?.endAt
        ? monthSpan(season.startAt, season.endAt)
        : null,
    customNumberOfInstallments: monthlyPricingPlan.numberOfInstallments,
    billingTiming: monthlyPricingPlan.billingTiming,
    billingDayOfMonth: monthlyPricingPlan.paymentDay,
  };
}

function buildSeasonFormStateValues(season?: SeasonDto) {
  const upfrontPricingPlanFields = getUpfrontPricingPlanFields(season);
  const monthlyPricingPlanFields = getMonthlyPricingPlanFields(season);

  return {
    seasonStartDate: season?.startAt ?? null,
    seasonEndDate: season?.endAt ?? null,
    additionalFees: season?.additionalFees ?? [],
    ...upfrontPricingPlanFields,
    ...monthlyPricingPlanFields,
  };
}

export const useSeasonFormSeasonDetailsDefinition = (
  openSheet: Sheet["openSheet"],
  closeSheet: Sheet["closeSheet"],
  showAlertDialog: AlertDialog,
  season?: SeasonDto,
) => {
  const { setAccount, account } = useAuthState();
  const showAutomatedTuitionSettingsForm = useAutomatedTuitionForm(true);

  const [stateValues, setStateValues] = useState<SeasonFormStateValues>(() =>
    buildSeasonFormStateValues(season),
  );

  const updateStateValues = (values: Partial<SeasonFormStateValues>) => {
    setStateValues(v => ({ ...v, ...values }));
  };

  const formDefinition = new FormDefinitionBuilder<SeasonFormDefinition>()
    .group(t("pageTitle.seasonDetails"), ["name", "startAt", "endAt"])
    .text("name", {
      label: "Name",
      required: true,
      description: "Recommended length: 20 characters",
    })
    .date("startAt", {
      label: "Start date",
      required: true,
      maxDate: stateValues.seasonEndDate,
    })
    .date("endAt", {
      label: "End date",
      required: true,
      minDate: stateValues.seasonStartDate,
    })
    .group("Registration fees", ["enableRegistrationFees"])
    .switch("enableRegistrationFees", {
      label: `Charge company-wide registration fees for this ${t("entity.season", { count: 1 })}. Set up annual or one-off fees in the billing section.`,
    })
    .customBlock(
      "after",
      "enableRegistrationFees",
      <AdditionalFeeFormFieldBody
        additionalFees={stateValues.additionalFees}
        setLocalFormState={updateStateValues}
      />,
    )
    .group("Billing information", ["pricingModel", "pricingScheme"])
    // todo re-enable this big radio once we've implemented the other pricing models
    // .bigRadio("pricingModel", {
    //   label: "How do you price your classes?",
    //   description: t("description.pricingPlan"),
    //   cols: 1, // set this back to 3 when we add in the options below
    //   required: true,
    //   options: [
    //     {
    //       label: getPricingModelLabel(t, PricingModel.Lesson),
    //       value: PricingModel.Lesson,
    //       icon: "calendarNumberOutline",
    //     },
    //     {
    //       label: getPricingModelLabel(t, PricingModel.Month),
    //       value: PricingModel.Month,
    //       icon: "calendarOutline",
    //     },
    //     {
    //       label: getPricingModelLabel(t, PricingModel.Season),
    //       value: PricingModel.Season,
    //       icon: "calendarClearOutline",
    //     },
    //   ],
    // })
    .conditional(["pricingScheme"], ["pricingModel", "startAt", "endAt"], v => {
      return (
        v.pricingModel === PricingModel.Month &&
        v.startAt !== undefined &&
        v.endAt !== undefined
      );
    })
    .bigRadio("pricingScheme", {
      label: "How do you want to calculate your fees?",
      description:
        "Charge for your classes in a way that works best for you, out of the following:",
      cols: 1,
      options: [
        {
          label: "Fixed monthly fee",
          description: "Charge a fixed fee for each class.",
          value: PricingScheme.None,
          icon: "cashOutline",
        },
        {
          label: "Number of classes",
          description:
            "Set a monthly fee based on how many classes a student attends weekly.",
          value: PricingScheme.ClassCount,
          icon: "personAddOutline",
        },
        {
          label: "Number of hours",
          description:
            "Monthly fee based on students' total weekly class hours. Perfect for varying lesson lengths.",
          value: PricingScheme.TotalDuration,
          icon: "timeOutline",
        },
      ],
    })
    .conditional(
      ["allowUpfrontPricingPlan", "allowMonthlyPricingPlan"],
      ["pricingModel", "startAt", "endAt"],
      v =>
        v.pricingModel !== undefined &&
        v.startAt !== undefined &&
        v.endAt !== undefined,
    )
    .customBlock("before", "allowUpfrontPricingPlan", <PricingBlurb />)
    .checkbox("allowUpfrontPricingPlan", {
      label: t("label.season.payUpFront"),
      description: (
        <UpfrontPayment
          userPaymentStartDate={stateValues.paymentStartDate}
          paymentStartDate={stateValues.seasonStartDate!}
          openSheet={openSheet}
          closeSheet={closeSheet}
          discountPercent={stateValues.discountPercent}
          setDiscountPercent={(discountPercent: number) =>
            updateStateValues({ discountPercent })
          }
          setUserPaymentStartDate={(paymentStartDate: string | null) =>
            updateStateValues({ paymentStartDate })
          }
          showAlertDialog={showAlertDialog}
        />
      ),
    })
    .checkbox("allowMonthlyPricingPlan", {
      // todo localise "installment" (us) and "instalment" (uk)
      label: `Pay in ${stateValues.customNumberOfInstallments ?? stateValues.numberOfInstallments} monthly installment${stateValues.customNumberOfInstallments ?? stateValues.numberOfInstallments === 1 ? "" : "s"}`,
      description: (
        <MonthlyPayment
          seasonStartDate={stateValues.seasonStartDate!}
          closeSheet={closeSheet}
          openSheet={openSheet}
          account={account}
          setAccount={setAccount}
          numberOfInstallments={
            stateValues.customNumberOfInstallments ??
            stateValues.numberOfInstallments
          }
          setCustomNumberOfInstallments={(numberOfInstallments: number) =>
            updateStateValues({
              customNumberOfInstallments: numberOfInstallments,
            })
          }
          setUserBillingDayOfMonth={(billingDayOfMonth: number | null) =>
            updateStateValues({ billingDayOfMonth })
          }
          setUserBillingTiming={(billingTiming: BillingTiming | null) =>
            updateStateValues({ billingTiming })
          }
          showAutomatedTuitionSettingsForm={showAutomatedTuitionSettingsForm}
          userBillingDayOfMonth={
            stateValues.billingDayOfMonth ||
            account!.company!.settings.billing.billingDayOfMonth
          }
          userBillingTiming={
            stateValues.billingTiming ||
            account!.company!.settings.billing.billingTiming
          }
        />
      ),
    })
    .onChange(form => {
      if (form.startAt) {
        if (form.startAt !== stateValues.seasonStartDate) {
          updateStateValues({ seasonStartDate: form.startAt });
        }
      }
      if (form.endAt) {
        if (form.endAt !== stateValues.seasonEndDate) {
          updateStateValues({ seasonEndDate: form.endAt });
        }
      }
      if (form.startAt && form.endAt) {
        const installments = monthSpan(form.startAt, form.endAt);
        if (installments !== stateValues.numberOfInstallments) {
          updateStateValues({ numberOfInstallments: installments });
        }
      }
    })
    .getDefinition();

  return { formDefinition, stateValues };
};
