import {
  AccountRole,
  AggregateTransactionDto,
} from "@justraviga/classmanager-sdk";

import {
  ChargeCardOnFileForm,
  CustomTransactionItem,
  ImplementedTransactionActions,
  PaymentFormAmount,
  TransactionPreviewContent,
  useAuthState,
  useSharedTransactionActions,
} from "shared/components";
import { AggregateTransactionWithPrimaryType, useAlert } from "shared/lib";

import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { CreateFooter } from "@/modules/common/form/CreateFooter";
import { UpdateFooter } from "@/modules/common/form/UpdateFooter";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { TakePayment } from "@/modules/common/payments/TakePayment";
import { CustomTransactionItemForm } from "@/modules/company/billing/transactions/forms/CustomTransactionItemForm";
import { PreviewTuitionForm } from "@/modules/company/billing/transactions/forms/tuitionForMonth/PreviewTuitionForm";
import { UnpostedTuitionForm } from "@/modules/company/billing/transactions/forms/UnpostedTuitionForm";
import { Router } from "@/routing/router";

type Model = AggregateTransactionWithPrimaryType;

export const useTransactionActions = (): ImplementedTransactionActions => {
  const { account } = useAuthState();
  const defaultActions = usePlatformEntityActions<Model>({
    entity: "transaction",
  });
  const sharedActions = useSharedTransactionActions();
  const { openSheet, closeSheet } = useSheet();
  const { showAlert } = useAlert();

  const onSuccess = () => {
    showAlert({
      content: "Payment was successful",
      variant: "success",
    });

    closeSheet();
  };

  const showRetryPaymentForm = (transaction: AggregateTransactionDto) => {
    openSheet({
      title: "Retry failed payment",
      content: (
        <div className="flex flex-col gap-4">
          <PaymentFormAmount amount={transaction.stats.total} />
          <TakePayment
            familyId={transaction.transaction.familyId}
            amount={transaction.stats.total}
            transactionId={transaction.transaction.id}
            operationType="capture"
            onSuccess={onSuccess}
          />
        </div>
      ),
      footer: <UpdateFooter saveButtonText="Retry" />,
    });
  };

  return {
    ...sharedActions,
    showRetryPaymentForm,
    showPreview: (entity: Model) => {
      const isFamilyPortal = account?.role === AccountRole.Family;

      openSheet({
        title: "Transaction details",
        content: (
          <TransactionPreviewContent
            transaction={entity}
            isFamilyPortal={isFamilyPortal}
            showRetryPaymentForm={() => {
              closeSheet();
              showRetryPaymentForm(entity);
            }}
          />
        ),
      });
    },
    showChargeCardOnFileForm: (familyId: string) => {
      openSheet({
        title: "Charge card on file",
        content: (
          <ChargeCardOnFileForm familyId={familyId} onSuccess={onSuccess} />
        ),
        footer: <CreateFooter />,
      });
    },
    showCustomTransactionCreateForm: () => {
      Router.push("TransactionCreate");
    },
    showCustomTransactionCreateItemSheet: ({
      addItem,
    }: {
      addItem: (item: CustomTransactionItem) => void;
    }) =>
      defaultActions.showCreateForm({
        title: "Add item",
        form: <CustomTransactionItemForm saveItem={addItem} />,
      }),

    showCustomTransactionUpdateItemSheet: ({
      item,
      updateItem,
    }: {
      item: CustomTransactionItem;
      updateItem: (item: CustomTransactionItem) => void;
    }) =>
      defaultActions.showCreateForm({
        title: "Update item",
        form: (
          <CustomTransactionItemForm currentItem={item} saveItem={updateItem} />
        ),
      }),

    showUnpostedTuitionForm: (familyId: string) => {
      openSheet({
        title: "Generate unposted tuition",
        content: <UnpostedTuitionForm familyId={familyId} />,
        footer: <UpdateFooter saveButtonText={"Confirm & generate"} />,
      });
    },

    showPreviewTuitionForm: (familyId: string) => {
      openSheet({
        title: "Preview tuition",
        content: <PreviewTuitionForm familyId={familyId} />,
        footer: <CreateFooter />,
      });
    },
  };
};
