import { UpdateUserEmailOrPasswordRequest } from "@justraviga/classmanager-sdk/dist/models/UpdateUserEmailOrPasswordRequest";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

type Schema = UpdateUserEmailOrPasswordRequest;

export const userPasswordUpdateFormDefinition = () => {
  const builder = new FormDefinitionBuilder<Schema>()
    .password("currentPassword", {
      label: "Current password",
      required: true,
    })
    .password("newPassword", {
      label: "New password",
      required: true,
      description:
        "Your password must be at least 10 characters long and include a mix of uppercase and lowercase letters, numbers, and special characters.",
    });

  return builder.getDefinition() as FormDefinition<Schema>;
};

export const makeUserPasswordUpdateRequest =
  ({ api }: { api: Api }) =>
  (formData: Schema) => {
    return api.users.updateUserEmailOrPassword({
      updateUserEmailOrPasswordRequest: formData,
    });
  };
