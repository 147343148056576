import { PropsWithChildren } from "react";

import { WaiverDto } from "@justraviga/classmanager-sdk";

import {
  usePrivacyPolicy,
  useTermsAndConditions,
} from "@shared/data/useWaivers";

import { useApi } from "@/lib/api/apiClient";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@/modules/common/overlays/dialog/Dialog";
import { Icon } from "@/modules/common/ui/icon/Icon";

interface StyledLinkProps extends PropsWithChildren {
  href: string;
}

const StyledLink = ({ children, href }: StyledLinkProps) => {
  return (
    <a className="cursor-pointer underline" href={href} target="_blank">
      {children}
    </a>
  );
};

export const ClassManagerTermsAndConditions = () => (
  <StyledLink href="https://classmanager.io/terms">
    Terms & conditions
  </StyledLink>
);

export const TermsAndConditions = () => {
  const { termsAndConditions } = useTermsAndConditions(useApi);

  return (
    <ViewPolicy policy={termsAndConditions} triggerText="Terms & conditions" />
  );
};

export const PrivacyPolicy = () => {
  const { privacyPolicy } = usePrivacyPolicy(useApi);

  return <ViewPolicy policy={privacyPolicy} triggerText="Privacy policy" />;
};

interface ViewPolicyProps extends PropsWithChildren {
  triggerText: string;
  policy: WaiverDto | { name: string; content: string } | null;
}

const ViewPolicy = ({
  triggerText,
  policy,
}: { triggerText: string } & ViewPolicyProps) => {
  if (!policy) {
    return <span>{triggerText}</span>;
  }

  return <ViewPolicyDialog policy={policy}>{triggerText}</ViewPolicyDialog>;
};

interface ViewPolicyDailogProps extends PropsWithChildren {
  policy: WaiverDto | { name: string; content: string };
}

export const ViewPolicyDialog = ({
  children,
  policy,
}: ViewPolicyDailogProps) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <span className="cursor-pointer underline">{children}</span>
      </DialogTrigger>
      <DialogContent
        className={`min-h-96 fixed right-0 top-0 w-full overflow-hidden rounded bg-white md:my-16 md:w-[700px]`}>
        <div
          className={`min-h-96 relative flex h-[100vh] min-w-min flex-col md:h-fit md:max-h-[calc(100vh-(2*4rem))]`}>
          <DialogHeader>
            <div className="flex flex-row items-center justify-between p-5">
              <h3 className="text-heading5-600 text-grey-900">{policy.name}</h3>
              <DialogClose>
                <Icon name="closeOutline" size={24} />
              </DialogClose>
            </div>
          </DialogHeader>
          <div className="overflow-y-auto px-5 py-4">
            <p className="text-body-400 text-grey-900">{policy.content}</p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
