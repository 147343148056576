import { SeasonFormFooter } from "./SeasonFormFooter";

interface CreateSeasonFooterProps {
  goToSeasonList: () => void;
}

export const CreateSeasonFooter = ({
  goToSeasonList,
}: CreateSeasonFooterProps) => (
  <SeasonFormFooter
    submitBtnText={"Create"}
    cancelBtnText={"Cancel"}
    onCancel={goToSeasonList}
  />
);
