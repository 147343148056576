import {
  AggregateTransactionDto,
  TransactionDetailType,
} from "@justraviga/classmanager-sdk";

import { CheckoutItem } from "./CheckoutItem";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const AggregateTransactionDetailsList = ({
  transaction,
}: {
  transaction: AggregateTransactionDto;
}) => {
  const { View } = useGenericComponents();

  const detailsWithoutTaxAndDiscounts = transaction.details.filter(
    detail =>
      ![TransactionDetailType.Tax, TransactionDetailType.Discount].includes(
        detail.details.type,
      ),
  );

  const discountsTotal = transaction.details
    .filter(({ details }) => details.type === TransactionDetailType.Discount)
    .reduce((runningTotal, next) => runningTotal + next.details.amount, 0);

  return (
    <View className={"space-y-1"}>
      {detailsWithoutTaxAndDiscounts.map(detail => (
        <View key={detail.details.id} className={"space-y-1"}>
          <CheckoutItem
            text={detail.details.description}
            amount={detail.details.amount}
            style={detail.details.type === "discount" ? "light" : "normal"}
          />
        </View>
      ))}
      {discountsTotal < 0 && (
        <View
          key={`${transaction.transaction.id}-discounts`}
          className={"space-y-1"}>
          <CheckoutItem
            text={"Discounts"}
            amount={discountsTotal}
            style={"light"}
          />
        </View>
      )}
    </View>
  );
};
