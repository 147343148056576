import { useCatalogueContext } from "@shared/components/catalogue/useCatalogueContext";

import { CatalogueMinimalLayout } from "@/modules/auth/catalogue/CatalogueMinimalLayout";
import { UserRegistrationForm } from "@/modules/auth/catalogue/UserRegistrationForm";
import { AuthPageCard } from "@/modules/auth/common/AuthPageCard";
import { LoginHeader } from "@/modules/auth/common/LoginHeader";
import { PoweredBy } from "@/modules/auth/common/PoweredBy";
import { SwitchAuthType } from "@/modules/auth/common/SwitchAuthType";

export const CatalogueRegisterPage = ({
  goBack,
  goToLogin,
}: {
  goBack?: () => void;
  goToLogin: () => void;
}) => {
  const { company } = useCatalogueContext();

  return (
    <CatalogueMinimalLayout showTerms={false}>
      <AuthPageCard
        header={
          <LoginHeader companyLogo={company.logo} handleBackButton={goBack} />
        }
        title={company.name}
        subtitle={"Register for an account on Class Manager."}
        content={<UserRegistrationForm />}
        showTerms={false}
        footer={
          <SwitchAuthType
            message="Already have an account?"
            onClick={goToLogin}
            linkText="Log in">
            <PoweredBy />
          </SwitchAuthType>
        }
      />
    </CatalogueMinimalLayout>
  );
};
