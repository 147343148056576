import { BaseSyntheticEvent, ReactNode, useState } from "react";

import { CheckboxValue } from "shared/lib";

import {
  PrivacyPolicy,
  TermsAndConditions,
} from "@/modules/auth/common/ViewPolicy";
import { Checkbox } from "@/modules/common/form/Checkbox";
import { InputErrorMessage } from "@/modules/common/form/InputErrorMessage";
import { Button } from "@/modules/common/ui/button/Button";

interface FormWithTermsProps {
  companyName: string;
  buttonText?: string;
  form: ReactNode;
  displayTerms: boolean;
  isLoading: boolean;
  onFormSubmission: (e: BaseSyntheticEvent) => void;
}

export const RegistrationWithTerms = ({
  companyName,
  buttonText = "Create account",
  displayTerms,
  form,
  isLoading,
  onFormSubmission,
}: FormWithTermsProps) => {
  const [acceptedTerms, setAcceptedTerms] = useState(!displayTerms);
  const [error, setError] = useState<string>();

  return (
    <div className="flex flex-col gap-y-4">
      {form}
      {displayTerms && (
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-row items-center justify-between gap-x-4">
            <div className="flex-grow">
              <Checkbox
                checked={acceptedTerms}
                onChange={(val: CheckboxValue) => {
                  setAcceptedTerms(val as boolean);
                  setError(undefined);
                }}
              />
            </div>
            <p
              data-testid="terms-checkbox-text"
              className="relative w-fit text-label-400 text-grey-600">
              I agree to the <TermsAndConditions /> and <PrivacyPolicy /> at{" "}
              {companyName}
            </p>
          </div>
          <div>
            <InputErrorMessage error={error} />
          </div>
        </div>
      )}
      <Button
        loading={isLoading}
        onClick={e => {
          if (acceptedTerms) {
            onFormSubmission(e);
          } else {
            setError("You must agree to the terms and conditions to continue");
          }
        }}
        variant="brand"
        text={buttonText}
        isFullWidth={true}
      />
    </div>
  );
};
