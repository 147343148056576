import {
  CompanySettingsGroupDto,
  PublicCompanySettingsGroupDto,
} from "@justraviga/classmanager-sdk";

import { setBrandColors } from "./colors";
import { setIntlFormatterOptions } from "./intlFormatter";

export const setCompanyGlobals = async (
  companySettings: CompanySettingsGroupDto | PublicCompanySettingsGroupDto,
) => {
  const generalSettings = companySettings.general;
  await setIntlFormatterOptions({
    locale: generalSettings.locale,
    timezone: generalSettings.timezone,
    use12HourClock: generalSettings.use12HourClock,
    currency: companySettings.billing.currency,
  });
  setBrandColors(generalSettings.brandColor!, generalSettings.brandColors!);
};
