import { DetailedEnrolment } from "../../enrolmentUtils";
import { getPlatformFunctions } from "../../platformSpecific";
import { UpdateEnrolmentEndForm } from "../enrolments/UpdateEnrolmentEndForm";
import {
  EnrolmentKey,
  UpdateEnrolmentStartForm,
} from "../enrolments/UpdateEnrolmentStartForm";
import { CreateFormActionSchema } from "../formDefinitions/enrolmentForm";
import { EnrolmentCreateForm } from "../modules/company/enrolments/EnrolmentCreateForm";

type Dto = DetailedEnrolment;

export const useEnrolmentsActions = () => {
  const { api, usePlatformEntityActions } = getPlatformFunctions();
  const defaultActions = usePlatformEntityActions<Dto>({
    entity: "enrolment",
  });

  return {
    deleteOne: (entity: Dto) =>
      defaultActions.deleteOne(() =>
        api.enrolments.deleteEnrolment({ id: entity.id }),
      ),

    showCreateForm: ({
      courseId,
      season,
      studentIds = [],
    }: CreateFormActionSchema) => {
      return defaultActions.showCreateForm({
        form: (
          <EnrolmentCreateForm
            courseId={courseId}
            seasonId={season?.id}
            studentIds={studentIds}
          />
        ),
      });
    },

    showUpdateStartForm: ({
      date,
      classId,
      studentId,
    }: EnrolmentKey & { date: string }) =>
      defaultActions.showUpdateForm({
        title: "Edit start date",
        form: (
          <UpdateEnrolmentStartForm
            date={date}
            classId={classId}
            studentId={studentId}
          />
        ),
      }),

    showUpdateEndForm: ({
      date,
      classId,
      studentId,
    }: EnrolmentKey & { date: string }) => {
      return defaultActions.showUpdateForm({
        title: "Edit end date",
        form: (
          <UpdateEnrolmentEndForm
            date={date}
            classId={classId}
            studentId={studentId}
          />
        ),
      });
    },
  };
};
