import { SeasonDto } from "@justraviga/classmanager-sdk";

import {
  SeasonImplementedActions,
  useSharedSeasonActions,
} from "shared/components";

import { usePlatformEntityActions } from "@/lib/usePlatformEntityActions";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { SeasonPreview } from "@/modules/company/classPlanner/seasons/preview/SeasonPreview";
import { Router } from "@/routing/router";

type Model = SeasonDto;

export const useSeasonActions = (): SeasonImplementedActions => {
  const { openSheet } = useSheet();
  const defaultActions = usePlatformEntityActions<SeasonDto>({
    entity: "season",
  });

  const sharedActions = useSharedSeasonActions({
    defaultActions,
    goToCreateSeasonPage: () => Router.push("SeasonCreate"),
    goToUpdateSeasonPage: (season: SeasonDto) =>
      Router.push("SeasonUpdate", { id: season.id }),
  });

  return {
    ...sharedActions,

    showPreview: (entity: Model) => {
      openSheet({
        title: t("pageTitle.seasonPreview"),
        content: <SeasonPreview season={entity} />,
      });
    },
  };
};
