import React from "react";

import { colors } from "@shared/colors";
import { cn } from "@shared/cssUtils";

import { Icon } from "@/modules/common/ui/icon/Icon";

type NavHideButtonProps = {
  onClick: () => void;
  isExpanded: boolean;
};

export const NavHideButton = React.memo(
  ({ onClick, isExpanded }: NavHideButtonProps) => {
    return (
      <div
        className={cn(
          "text-primary absolute top-5 z-30 h-8 w-8 translate-x-[-50%] cursor-pointer ",
          " overflow-clip rounded-md bg-brand transition-all",
          isExpanded ? "left-[224px]" : "left-[76px]",
        )}
        onClick={onClick}>
        <div className="flex h-full w-full items-center justify-center bg-overlay-white-medium hover:bg-overlay-white-heavy">
          <span className={"-m-2 p-2"}>
            <Icon name="menuOutline" color={colors.black} />
          </span>
        </div>
      </div>
    );
  },
);
