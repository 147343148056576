import { CheckoutItem } from "./CheckoutItem";
import { CheckoutSection } from "./CheckoutSection";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const PaymentSummary = ({
  registrationFees,
  tuitionFees,
  additionalFees,
  trialFees,
  tax,
  payToday,
}: {
  registrationFees: number;
  tuitionFees: number | null;
  additionalFees: number;
  trialFees: number;
  tax: number | null;
  payToday: number;
}) => {
  const { View } = useGenericComponents();
  return (
    <CheckoutSection spacing={"tight"}>
      {registrationFees > 0 && (
        <View>
          <CheckoutItem
            text={"Registration fees"}
            style={"light"}
            amount={registrationFees}
          />
        </View>
      )}
      {tuitionFees !== 0 && (
        <View>
          <CheckoutItem
            text={"Tuition fees"}
            style={"light"}
            amount={tuitionFees}
          />
        </View>
      )}
      {additionalFees > 0 && (
        <View>
          <CheckoutItem
            text={"Additional fees"}
            style={"light"}
            amount={additionalFees}
          />
        </View>
      )}
      {trialFees > 0 && (
        <View>
          <CheckoutItem
            text={"Trial Fees"}
            style={"light"}
            amount={trialFees}
          />
        </View>
      )}
      {tax !== 0 && (
        <View>
          <CheckoutItem text={"Tax"} style={"light"} amount={tax} />
        </View>
      )}
      <View>
        <CheckoutItem text={"Pay today"} style={"big"} amount={payToday} />
      </View>
    </CheckoutSection>
  );
};
