import {
  AddStudentToEmptyFamily,
  MemberBasketPage,
  useCatalogueContext,
  useCourseSearch,
  useGenericComponents,
} from "shared/components";
import { useFamilyMembers } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { CatalogueMinimalLayout } from "@/modules/auth/catalogue/CatalogueMinimalLayout";
import { MultiSelect } from "@/modules/common/form/select/MultiSelect";
import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { Button } from "@/modules/common/ui/button/Button";
import { CustomerLayout } from "@/modules/customer/CustomerLayout";
import { Router } from "@/routing/router";

export const FamilyChecksForBasket = () => {
  const { searchValues } = useCourseSearch();
  const { company } = useCatalogueContext();
  const { Loader, View } = useGenericComponents();

  const { members, isFamilyMembersLoading, goNext, setGoNext } =
    useFamilyMembers(useApi);

  if (isFamilyMembersLoading) {
    return (
      <View>
        <Loader />
      </View>
    );
  }

  if (!goNext) {
    return (
      <CatalogueMinimalLayout>
        <AddStudentToEmptyFamily Button={Button} setGoNext={setGoNext} />
      </CatalogueMinimalLayout>
    );
  }

  return (
    <CustomerLayout
      hideNav
      showBackButton
      backButtonText={"Add more classes"}
      backButtonOnClick={() => {
        Router.push("Courses", {
          ...searchValues,
          query: searchValues.query || "",
          ages: searchValues.ages.map(String),
          slug: company.slug,
        });
      }}>
      <MemberBasketPage
        MultiSelect={MultiSelect}
        useStudentActions={useStudentActions}
        goToCheckout={() =>
          Router.push("CustomerCheckoutSummary", { slug: company.slug })
        }
        familyMembers={members}
      />
    </CustomerLayout>
  );
};
