import { ReactNode } from "react";

import {
  AggregateEnrolmentDto,
  StudentDto,
} from "@justraviga/classmanager-sdk";

import { BasketItem } from "./MemberBasketPage";
import { RemoveFromBasketButton } from "./RemoveFromBasketButton";
import { ValidateBasketItem } from "./ValidateBasketItem";
import { makeTrialKey } from "../../../../basket/basket";
import { ageRangeDescription } from "../../../../classUtils";
import { getFullName } from "../../../../personUtil";
import { displayStudentAge } from "../../../../studentUtils";
import { StudentImplementedActions } from "../../../actions/useSharedStudentActions";
import { useAuthState } from "../../../AuthStateProvider";
import { useBasket } from "../../../basket/useBasket";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { MultiSelectComponent } from "../../../interfaces";

interface AddStudentsToBasketItemWidgetProps {
  basketItem: BasketItem;
  familyMembers: StudentDto[];
  enrolments: AggregateEnrolmentDto[];
  MultiSelect: MultiSelectComponent;
  useStudentActions: () => StudentImplementedActions;
  shouldValidate: boolean;
  title: (basketItem: BasketItem) => ReactNode;
  subtitle: (basketItem: BasketItem) => ReactNode;
}

export const AddStudentsToBasketItemWidget = ({
  basketItem,
  familyMembers,
  enrolments,
  MultiSelect,
  useStudentActions,
  shouldValidate,
  title,
  subtitle,
}: AddStudentsToBasketItemWidgetProps) => {
  const { Text, View } = useGenericComponents();
  const {
    addStudentToEnrolment,
    addStudentToTrial,
    clearStudentsFromEnrolment,
    clearStudentsFromTrial,
    removeEnrolment,
    removeTrial,
  } = useBasket();

  const { course, season, students } = basketItem;

  // we know the company is set here, we can't get here without a company.
  const company = useAuthState().account!.company!;

  const removeFromBasket = () => {
    if (basketItem.type === "enrolment") {
      removeEnrolment(course.entity.id);
    } else {
      removeTrial(
        makeTrialKey(
          course.entity.id,
          basketItem.trial.date,
          basketItem.trial.time,
        ),
      );
    }
  };

  return (
    <View>
      <View
        className={
          "flex flex-row items-center justify-between pb-1 text-label-400 text-grey-600"
        }>
        {title(basketItem)}
        {subtitle(basketItem)}
      </View>
      <Text className={"pb-2 text-body-400 text-grey-900"}>
        {course.entity.name}
        <Text className={"text-grey-600"}>
          {" "}
          (
          {ageRangeDescription(
            course.entity.minAgeMonths,
            course.entity.maxAgeMonths,
          )}
          )
        </Text>
      </Text>
      <View className={"space-y-2"}>
        <View className={"flex flex-row items-center justify-between gap-4"}>
          <View className={"w-64"}>
            <MultiSelect
              onSelect={value => {
                if (basketItem.type === "enrolment") {
                  clearStudentsFromEnrolment(course.entity.id);
                  value.map(studentId => {
                    addStudentToEnrolment(
                      course.entity.id,
                      studentId as string,
                    );
                  });
                } else {
                  clearStudentsFromTrial(
                    makeTrialKey(
                      course.entity.id,
                      basketItem.trial.date,
                      basketItem.trial.time,
                    ),
                  );
                  value.map(studentId => {
                    addStudentToTrial(
                      makeTrialKey(
                        course.entity.id,
                        basketItem.trial.date,
                        basketItem.trial.time,
                      ),
                      studentId as string,
                    );
                  });
                }
              }}
              data={familyMembers.map(member => {
                return {
                  label: getFullName(member),
                  value: member.id,
                  description: member.dateOfBirth
                    ? displayStudentAge(
                        { dateOfBirth: member.dateOfBirth },
                        "Age ",
                      )
                    : "-",
                  avatar: member.profilePicture ?? undefined,
                };
              })}
              value={students.map(student => student.id)}
              placeholder={"No student selected"}
              entityName={"students"}
            />
          </View>
          <View>
            <RemoveFromBasketButton onClick={removeFromBasket} />
          </View>
        </View>
        <ValidateBasketItem
          enforceValidation={shouldValidate}
          course={course}
          useStudentActions={useStudentActions}
          season={season}
          students={students}
          enrolments={enrolments}
          company={company}
        />
      </View>
    </View>
  );
};
