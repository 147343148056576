import { useState } from "react";

import {
  Sheet,
  SheetFooter,
  SheetProps,
} from "@/modules/common/overlays/dialog/Sheet";
import { Button } from "@/modules/common/ui/button/Button";

export interface SheetExampleProps {
  side: SheetProps["side"];
}

export const SheetExample = ({ side }: SheetExampleProps) => {
  const [isOpen, setIsOpen] = useState(false);

  function onOpenChange(newIsOpen: boolean) {
    setIsOpen(newIsOpen);
  }

  return (
    <>
      <Button onClick={() => setIsOpen(true)} text={"Open"} variant={"brand"} />
      <Sheet
        side={side}
        open={isOpen}
        onOpenChange={onOpenChange}
        title={"Update details"}
        footer={
          <SheetFooter
            leftContent={
              <Button
                text={"Save"}
                onClick={() => alert("Saved!")}
                variant={"primary"}
              />
            }
            rightContent={
              <Button
                text={"Save and Continue"}
                onClick={() => alert("Saved and continued!")}
                variant="brand"
              />
            }
          />
        }
        content={
          <div>
            <h1>Sheet Content</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        }
      />
    </>
  );
};
