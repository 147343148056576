import { PricingScheme } from "@justraviga/classmanager-sdk";
import type { PricingSchemeSeasonRequest } from "@justraviga/classmanager-sdk/dist/models";

import { Api } from "../../api";
import { colors } from "../../colors";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";

export const useUpdatePricingSchemeDefinition = () => {
  return new FormDefinitionBuilder<PricingSchemeSeasonRequest>()
    .bigRadio("pricingScheme", {
      cols: 1,
      options: [
        {
          icon: "cash",
          iconStyle: "branded",
          iconColor: colors.brand[800],
          label: "Price per class per month",
          description: "Charge a fixed monthly fee for each class.",
          value: PricingScheme.None,
        },
        {
          icon: "personAdd",
          iconStyle: "branded",
          iconColor: colors.brand[800],
          label: "Number of classes",
          description:
            "Charge per month based on the number of classes a student takes per week.",
          value: PricingScheme.ClassCount,
        },
        {
          icon: "time",
          iconStyle: "branded",
          iconColor: colors.brand[800],
          label: "Number of hours",
          description:
            "Charge per month based on the number of hours of classes a student takes per week.",
          value: PricingScheme.TotalDuration,
        },
      ],
    })
    .getDefinition();
};

export const makeSeasonUpdatePricingSchemeRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: PricingSchemeSeasonRequest) => {
    return api.seasons.pricingSchemeSeason({
      id,
      pricingSchemeSeasonRequest: formData,
    });
  };
