import { BillDto } from "@justraviga/classmanager-sdk";

import { TransactionDetails } from "./TransactionDetails";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const Transaction = ({ transaction }: { transaction: BillDto }) => {
  const { View, Text } = useGenericComponents();

  return (
    <View key={transaction.description} className={"w-full space-y-2"}>
      <Text className={"overflow-hidden text-heading6-600 text-grey-900"}>
        {transaction.description}
      </Text>
      <TransactionDetails transaction={transaction} />
    </View>
  );
};
