import { forwardRef, Ref } from "react";

import {
  GenericComponents,
  type GenericFormComponent,
  PermissionLayoutProps,
} from "shared/components";
import {
  ActionMenuProps,
  BannerProps,
  cn,
  ImageProps,
  PressableProps,
  TextProps,
  ViewProps,
} from "shared/lib";

import { ActionMenu } from "@/components/action-menu/ActionMenu";
import { GenericForm } from "@/modules/common/form/GenericForm";
import { Link } from "@/modules/common/nav/Link";
import { ActionBanner } from "@/modules/common/ui/ActionBanner";
import { Banner } from "@/modules/common/ui/Banner";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { CircularIndicator } from "@/modules/common/ui/CircularIndicator";
import { Icon } from "@/modules/common/ui/icon/Icon";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";

// eslint-disable-next-line react-refresh/only-export-components
const ActionMenuComponent = (props: ActionMenuProps) => (
  <ActionMenu {...props} />
);
// eslint-disable-next-line react-refresh/only-export-components
const BannerComponent = ({ ...rest }: BannerProps) => <Banner {...rest} />;

// eslint-disable-next-line react-refresh/only-export-components
const GenericFormComponent: GenericFormComponent = props => (
  <GenericForm {...props} />
);

// eslint-disable-next-line react-refresh/only-export-components
const ImageComponent = ({ source, alt, ...rest }: ImageProps) => (
  <img {...rest} src={source} alt={alt} />
);

// eslint-disable-next-line react-refresh/only-export-components
const PressableComponent = ({ children, ...props }: PressableProps) => {
  const { className, ...rest }: PressableProps = props;
  return (
    <div className={cn("cursor-pointer", className)} {...rest}>
      {children}
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
const ProtectedOverlayComponent = ({
  children,
  ...props
}: PermissionLayoutProps) => {
  return <ProtectedOverlay {...props}>{children}</ProtectedOverlay>;
};

// eslint-disable-next-line react-refresh/only-export-components
const TextComponent = ({ children, truncate, style, ...rest }: TextProps) => (
  <div
    {...rest}
    style={{
      ...style,
      ...(truncate
        ? {
            overflow: "hidden",
            display: "-webkit-box",
            lineClamp: 1,
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
          }
        : {}),
    }}>
    {children}
  </div>
);
// eslint-disable-next-line react-refresh/only-export-components
const ViewComponent = forwardRef(
  ({ children, ...rest }: ViewProps, ref: Ref<HTMLDivElement>) => (
    <div {...rest} ref={ref}>
      {children}
    </div>
  ),
);

export const genericComponents: GenericComponents = {
  ActionBanner,
  ActionMenu: ActionMenuComponent,
  Banner: BannerComponent,
  BaseCard: BaseCard,
  Button: Button,
  GenericForm: GenericFormComponent,
  HorizontalSeparator,
  Icon,
  IconButton: IconButton,
  Image: ImageComponent,
  Link,
  Loader: LoadingSpinnerDark,
  Pressable: PressableComponent,
  ProtectedOverlay: ProtectedOverlayComponent,
  CircularIndicator: CircularIndicator,
  Text: TextComponent,
  View: ViewComponent,
};
