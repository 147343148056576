import { useBigRadioOptions } from "@shared/admin/docs/useBigRadioOptions";

import { BigRadio } from "shared/components";

import { DocWrapper } from "@/modules/admin/docs/DocWrapper";

export const BigRadioPage = () => {
  const options = useBigRadioOptions();
  return (
    <DocWrapper title={"BigRadio"} component={BigRadio} options={options} />
  );
};
