import { customIcons } from "./data/customIcons";
import {
  addCircle,
  addCircleOutline,
  addOutline,
  airplane,
  airplaneOutline,
  alertCircle,
  alertCircleOutline,
  apps,
  appsOutline,
  archiveOutline,
  arrowDownCircle,
  arrowDownOutline,
  arrowForwardCircleOutline,
  arrowForwardOutline,
  arrowUndoOutline,
  arrowUpCircle,
  arrowUpCircleOutline,
  arrowUpOutline,
  bagCheckOutline,
  bagHandleOutline,
  barChartOutline,
  book,
  bookOutline,
  buildOutline,
  business,
  businessOutline,
  calendar,
  calendarClear,
  calendarClearOutline,
  calendarNumber,
  calendarNumberOutline,
  calendarOutline,
  callOutline,
  callSharp,
  camera,
  cameraOutline,
  card,
  cardOutline,
  cash,
  cashOutline,
  chatbubbleOutline,
  chatbubblesOutline,
  checkboxOutline,
  checkmark,
  checkmarkCircle,
  checkmarkCircleOutline,
  checkmarkDone,
  checkmarkOutline,
  checkmarkSharp,
  checkmarkThick,
  chevronBack,
  chevronBackOutline,
  chevronDown,
  chevronForwardOutline,
  chevronUp,
  closeCircle,
  closeCircleOutline,
  closeOutline,
  codeSlashOutline,
  colorPalette,
  copyOutline,
  create,
  createOutline,
  documentLock,
  documentText,
  documentTextOutline,
  ellipse,
  ellipseOutline,
  ellipseSharp,
  ellipsisHorizontal,
  ellipsisHorizontalCircle,
  ellipsisHorizontalCircleOutline,
  exitOutline,
  eyeOffOutline,
  eyeOutline,
  filter,
  filterOutline,
  folder,
  globeOutline,
  grid,
  gridOutline,
  helpCircleOutline,
  home,
  homeOutline,
  imageOutline,
  informationCircle,
  informationCircleOutline,
  laptop,
  linkOutline,
  listOutline,
  location,
  locationOutline,
  lockClosed,
  logInOutline,
  logOutOutline,
  mail,
  mailOutline,
  mailUnreadOutline,
  medkit,
  medkitOutline,
  menu,
  menuOutline,
  notificationsOutline,
  openOutline,
  optionsOutline,
  paperPlane,
  paperPlaneOutline,
  people,
  peopleOutline,
  person,
  personAdd,
  personAddOutline,
  personOutline,
  pricetag,
  push,
  pushOutline,
  reader,
  readerOutline,
  receiptOutline,
  refreshCircle,
  reloadOutline,
  removeCircle,
  removeOutline,
  reorderFour,
  reorderThreeOutline,
  reorderTwoOutline,
  repeat,
  returnDownBack,
  school,
  schoolOutline,
  searchOutline,
  send,
  sendOutline,
  sendSharp,
  settings,
  settingsOutline,
  shareSocialOutline,
  shieldCheckmarkOutline,
  star,
  storefrontOutline,
  swapHorizontal,
  swapHorizontalOutline,
  syncOutline,
  time,
  timeOutline,
  todayOutline,
  trashOutline,
  warning,
} from "./data/icons";
import { stripeIcons } from "./third-party/stripe/stripeIcons";

/**
 * Add to this list as you come across icons in the design which aren't included here.
 *
 * The purpose of this is to avoid accidentally including ~800KB of SVGs in the bundle
 * whilst still allowing us to pass simple string names for icons around between components
 * in a typesafe manner.
 */
export const ionicons = {
  addCircle,
  addCircleOutline,
  addOutline,
  airplane,
  airplaneOutline,
  alertCircle,
  alertCircleOutline,
  apps,
  appsOutline,
  archiveOutline,
  arrowDownCircle,
  arrowDownOutline,
  arrowForwardCircleOutline,
  arrowForwardOutline,
  arrowUndoOutline,
  arrowUpCircle,
  arrowUpCircleOutline,
  arrowUpOutline,
  bagCheckOutline,
  bagHandleOutline,
  barChartOutline,
  book,
  bookOutline,
  buildOutline,
  business,
  businessOutline,
  calendar,
  calendarClear,
  calendarClearOutline,
  calendarNumber,
  calendarNumberOutline,
  calendarOutline,
  callOutline,
  callSharp,
  camera,
  cameraOutline,
  card,
  cardOutline,
  cash,
  cashOutline,
  chatbubbleOutline,
  chatbubblesOutline,
  checkboxOutline,
  checkmark,
  checkmarkCircle,
  checkmarkCircleOutline,
  checkmarkDone,
  checkmarkOutline,
  checkmarkSharp,
  checkmarkThick,
  chevronBack,
  chevronBackOutline,
  chevronDown,
  chevronForwardOutline,
  chevronUp,
  closeCircle,
  closeCircleOutline,
  closeOutline,
  codeSlashOutline,
  colorPalette,
  copyOutline,
  create,
  createOutline,
  documentLock,
  documentText,
  documentTextOutline,
  ellipse,
  ellipseOutline,
  ellipseSharp,
  ellipsisHorizontal,
  ellipsisHorizontalCircle,
  ellipsisHorizontalCircleOutline,
  exitOutline,
  eyeOffOutline,
  eyeOutline,
  filter,
  filterOutline,
  folder,
  globeOutline,
  grid,
  gridOutline,
  helpCircleOutline,
  home,
  homeOutline,
  imageOutline,
  informationCircle,
  informationCircleOutline,
  laptop,
  linkOutline,
  listOutline,
  location,
  locationOutline,
  lockClosed,
  logInOutline,
  logOutOutline,
  mail,
  mailOutline,
  mailUnreadOutline,
  medkit,
  medkitOutline,
  menu,
  menuOutline,
  notificationsOutline,
  openOutline,
  optionsOutline,
  paperPlane,
  paperPlaneOutline,
  people,
  peopleOutline,
  person,
  personAdd,
  personAddOutline,
  personOutline,
  pricetag,
  push,
  pushOutline,
  reader,
  readerOutline,
  receiptOutline,
  refreshCircle,
  reloadOutline,
  removeCircle,
  removeOutline,
  reorderFour,
  reorderThreeOutline,
  reorderTwoOutline,
  repeat,
  returnDownBack,
  school,
  schoolOutline,
  searchOutline,
  send,
  sendOutline,
  sendSharp,
  settings,
  settingsOutline,
  shareSocialOutline,
  shieldCheckmarkOutline,
  star,
  storefrontOutline,
  swapHorizontal,
  swapHorizontalOutline,
  syncOutline,
  time,
  timeOutline,
  todayOutline,
  trashOutline,
  warning,
};

export const availableIcons = {
  ...ionicons,
  ...customIcons,
  /**
   * Stripe icons are generated using the `npm run generateStripeIcons` command from the SVGs
   * found in the shared/src/third-party/stripe/icons directory and use the script at
   * shared/src/third-party/stripe/generateIcons.ts
   */
  ...stripeIcons,
};

export type IconName = keyof typeof availableIcons;
