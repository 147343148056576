import {
  ContactDto,
  FamilyDto,
  StudentDto,
} from "@justraviga/classmanager-sdk";

import { getFirstLetters } from "shared/lib";

import {
  DetailProfileAvatarContainer,
  DetailProfileContactList,
  DetailProfileContainer,
} from "@/modules/company/common/DetailProfile";
import { AvatarWithUpload } from "@/modules/company/common/details/profile/AvatarWithUpload";
import { InvitationStatusIcon } from "@/modules/company/common/invitation/InvitationStatusIcon";

const StudentCount = ({ count }: { count: number }) => {
  return (
    count > 0 && (
      <span className={"text-body-400 text-grey-600"}>
        {count === 1 ? `${count} student` : `${count} students`}
      </span>
    )
  );
};

const PrimaryContact = ({ primaryContact }: { primaryContact: ContactDto }) => {
  return (
    <div className={"flex flex-col space-y-2"}>
      <span className={"text-body-600 text-grey-900"}>Primary contact</span>
      <DetailProfileContactList
        person={primaryContact}
        email={primaryContact.email}
        phone={primaryContact.phone}
      />
    </div>
  );
};

export const Profile = ({
  family,
  students,
  contacts,
  isDesktop,
  uploadAction,
}: {
  family: FamilyDto;
  students: StudentDto[];
  contacts: ContactDto[];
  isDesktop: boolean;
  uploadAction: () => void;
}) => {
  const primaryContact = contacts.find(c => c.isPrimary)!;
  const studentCount = students.length;

  return (
    <DetailProfileContainer>
      <DetailProfileAvatarContainer>
        <AvatarWithUpload
          src={family.profilePicture}
          name={getFirstLetters(family.name)}
          showUploadIcon={!family.archivedAt}
          uploadAction={uploadAction}
          permission="members:manage"
        />
        <div className={"flex flex-col"}>
          <div className={"flex min-w-0 flex-row items-center space-x-1"}>
            <p
              aria-label="family name"
              className={"line-clamp-1 text-heading5-600 text-grey-900"}>
              {family.name}
            </p>
            <InvitationStatusIcon
              status={family.invitationStatus}
              size={"lg"}
            />
          </div>
          {isDesktop && <StudentCount count={studentCount} />}
        </div>
      </DetailProfileAvatarContainer>
      {!isDesktop && <StudentCount count={studentCount} />}
      {primaryContact && <PrimaryContact primaryContact={primaryContact} />}
    </DetailProfileContainer>
  );
};
