import { Api } from "../../api";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { formatMoneyFromInteger } from "../../intlFormatter";
import { useGenericComponents } from "../GenericComponentsProvider";

type TransactionDetailId = string;
type AmountField = `amount_${TransactionDetailId}`;

interface Schema {
  description: string;

  [key: AmountField]: number;
}

export interface CreditableTransactionDetail {
  id: TransactionDetailId;
  description: string;
  discountedAmount: number;
  availableToCredit: number;
}

export const makeCreditNoteForm =
  (lines: CreditableTransactionDetail[]) => () => {
    const { View, Text } = useGenericComponents();

    const linesWithFields = lines.map(line => ({
      ...line,
      field: `amount_${line.id}` as AmountField,
    }));
    const amountFields = linesWithFields.map(line => line.field);

    const builder = new FormDefinitionBuilder<Schema>()
      .text("description", {
        label: "Description",
        description:
          "You can provide a reason for the credit note here if you need.",
      })
      .group("Credit amount", amountFields, {
        description: "Amounts include any discounts applied.",
      });

    for (const line of linesWithFields) {
      builder
        .customBlock(
          "before",
          line.field,
          <View className="flex flex-col space-y-1 pb-3">
            <View className="flex flex-row items-center justify-between space-x-4">
              <View className="flex-1">
                <Text className="text-body-400 text-grey-900" truncate={true}>
                  {line.description}
                </Text>
              </View>
              <Text className="text-body-400 text-grey-900">
                {formatMoneyFromInteger(line.discountedAmount)}
              </Text>
            </View>
            <View className="flex flex-row items-center justify-between">
              <Text className="text-label-400 text-grey-600">
                Available to credit
              </Text>
              <Text className="text-label-400 text-grey-600">
                {formatMoneyFromInteger(line.availableToCredit)}
              </Text>
            </View>
          </View>,
        )
        .money(line.field, {
          label: "Credit amount",
          disabled: line.availableToCredit <= 0,
        });
    }

    return builder.getDefinition();
  };

export const makeCreateCreditNoteRequest =
  (api: Api, transactionId: string) => (formData: Schema) => {
    const { description, ...amounts } = formData;

    const items = Object.entries(amounts)
      .filter(([_, amount]) => Number(amount) > 0)
      .map(([field, amount]) => ({
        transactionDetailId: field.replace("amount_", ""),
        amount,
      }));

    return api.transactions.createCreditNote({
      transaction: transactionId,
      createCreditNoteRequest: {
        description,
        items,
      },
    });
  };
