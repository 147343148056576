import { externalLink } from "@justraviga/classmanager-sdk";

interface FooterLinksProps {
  showTerms?: boolean;
}

export const FooterLinks = ({ showTerms = true }: FooterLinksProps) => {
  if (!showTerms) {
    return null;
  }

  return (
    <div className="flex flex-row items-center justify-center gap-x-2">
      <a href={externalLink.termsAndConditions} target="_blank">
        Terms &amp; conditions
      </a>
    </div>
  );
};
