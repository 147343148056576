import { useState } from "react";

import { RegisterUserRequest } from "@justraviga/classmanager-sdk";

import { useAuthState } from "@shared/components/AuthStateProvider";
import { useUserRegisterForm } from "@shared/components/formDefinitions/userRegisterForm";
import { displayApiValidationErrors } from "@shared/forms/formHelpers";

import { api } from "@/lib/api/apiClient";
import { ClassManagerRegisterTermsAndPrivacy } from "@/modules/auth/common/ClassManagerRegisterTermsAndPrivacy";
import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";
import { Button } from "@/modules/common/ui/button/Button";

export const UserRegistrationForm = () => {
  const { setUser } = useAuthState();

  const [isLoading, setLoading] = useState(false);
  const registerUser = async (registerUserRequest: RegisterUserRequest) => {
    setLoading(true);

    api.auth
      .registerUser({
        registerUserRequest,
      })
      .then(({ user, token }) => {
        if (user && user.id && token) {
          setUser(user, token);
        }
      })
      .catch(e => {
        // @ts-expect-error - This is probably fixable, but it's not worth the effort
        displayApiValidationErrors(formHandlers)(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { form, formHandlers } = useUserRegisterForm(
    useFormBuilder,
    registerUser,
    {
      email: "",
      password: "",
    },
    false,
  );

  return (
    <>
      {form}
      <div className="flex flex-col gap-3">
        <ClassManagerRegisterTermsAndPrivacy />
        <Button
          loading={isLoading}
          onClick={formHandlers.handleSubmit(registerUser)}
          variant="brand"
          text="Register"
          isFullWidth={true}
        />
      </div>
    </>
  );
};
