import { useCatalogueContext } from "@shared/components/catalogue/useCatalogueContext";
import { GoToSummaryButton } from "@shared/components/modules/customer/checkout/GoToSummaryButton";
import {
  CoursePreviewDescription,
  CoursePreviewDetails,
  CoursePreviewImageWithOverlay,
  CoursePreviewLessons,
  CoursePreviewPageProps,
  CoursePreviewPriceList,
  CoursePreviewSummary,
  CoursePreviewTeacher,
  CoursePreviewTrialPrice,
} from "@shared/components/modules/customer/coursePreview/CoursePreview";
import { CoursePreviewButton } from "@shared/components/modules/customer/coursePreview/CoursePreviewButton";
import { useCoursePreview } from "@shared/components/modules/customer/coursePreview/useCoursePreview";

import { useBreakpoint } from "shared/lib";

import { CourseImage } from "@/modules/common/classes/CourseImage";
import { CourseImageWithOverlay } from "@/modules/common/classes/CourseImageWithOverlay";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { Button } from "@/modules/common/ui/button/Button";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";
import { PublicLayout } from "@/modules/public/layout/PublicLayout";
import { Router } from "@/routing/router";

export const CoursePreviewPage = ({
  courseId,
  mode = "enrol",
}: CoursePreviewPageProps) => {
  const sheet = useSheet();
  const { md: isDesktop } = useBreakpoint();
  const { company } = useCatalogueContext();
  const {
    proceed,
    course,
    season,
    location,
    teacher,
    seasonPricingValues,
    shouldRenderPriceList,
    trialSettings,
    previewMode,
    isFull,
  } = useCoursePreview({ courseId, mode });

  if (!proceed) {
    return <div>Course not found</div>;
  }

  const trialPriceContent = (
    <CoursePreviewTrialPrice trialSettings={trialSettings} mode={previewMode} />
  );

  const redirectToWaitingList = (courseId: string) => {
    Router.push("JoinWaitingList", {
      slug: company.slug,
      courseId,
    });
  };

  const DesktopView = () => (
    <>
      <CoursePreviewSummary
        course={course.entity}
        season={season}
        location={location}
      />
      <div className={"mt-8 grid h-full grid-cols-4"}>
        <section className={"col-span-3 space-y-5 pr-10"}>
          <CourseImage
            course={course.entity}
            collapseWhenPlaceholder={course.entity.image === null}
          />
          <CoursePreviewDetails
            course={course.entity}
            teacher={teacher}
            HorizontalSeparator={HorizontalSeparator}
          />
        </section>
        <section className={"col-span-1"}>
          <CoursePreviewButton
            course={course}
            sheet={sheet}
            mode={previewMode}
            Button={Button}
            onClickWaitingList={isFull ? redirectToWaitingList : undefined}
          />
          {trialPriceContent}
          {shouldRenderPriceList && (
            <div className={"mt-8"}>
              <CoursePreviewPriceList
                season={season}
                pricingSchemeValues={seasonPricingValues}
              />
            </div>
          )}
        </section>
      </div>
    </>
  );

  const MobileView = () => (
    <section className={"flex flex-col space-y-4"}>
      <div className={"flex flex-col space-y-2"}>
        <CoursePreviewImageWithOverlay
          course={course.entity}
          OverlayImage={CourseImageWithOverlay}
          isFull={isFull}
        />
        <CoursePreviewSummary
          course={course.entity}
          season={season}
          location={location}
          isMobile={true}
        />
      </div>
      <CoursePreviewButton
        course={course}
        sheet={sheet}
        mode={previewMode}
        Button={Button}
        onClickWaitingList={isFull ? redirectToWaitingList : undefined}
      />
      {trialPriceContent}
      {trialPriceContent !== null && <HorizontalSeparator spacing={0} />}
      <CoursePreviewTeacher staff={teacher} />
      <HorizontalSeparator spacing={0} />
      <CoursePreviewDescription course={course.entity} />
      <HorizontalSeparator spacing={0} />
      {shouldRenderPriceList && (
        <CoursePreviewPriceList
          season={season}
          pricingSchemeValues={seasonPricingValues}
        />
      )}
      <HorizontalSeparator spacing={0} />
      <CoursePreviewLessons classId={course.entity.id} />
    </section>
  );

  return (
    <PublicLayout
      backButton={{
        text: "Back to classes",
        onClick: () => Router.push("Courses", { slug: company.slug, mode }),
      }}>
      {isDesktop ? <DesktopView /> : <MobileView />}
      <GoToSummaryButton
        Button={Button}
        gotoBasket={() => Router.push("MemberBasket", { slug: company.slug })}
      />
    </PublicLayout>
  );
};
