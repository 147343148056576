import { useEffect } from "react";

import { FeeDto } from "@justraviga/classmanager-sdk";

import { useTaxRates } from "../../data/useTaxRates";
import { useFormActions } from "../FormActionsProvider";
import {
  makeAddAdditionalFeeRequest,
  useAdditionalFeeFormDefinition,
} from "../formDefinitions/additionalFeeForm";
import { useGenericComponents } from "../GenericComponentsProvider";

interface AdditionalFeeFormProps {
  updateAdditionalFee: (item: FeeDto) => FeeDto;
  defaultValues?: Partial<FeeDto>;
  onSuccess: (item: FeeDto) => void;
}

export const AdditionalFeeForm = ({
  updateAdditionalFee,
  defaultValues,
  onSuccess,
}: AdditionalFeeFormProps) => {
  const { GenericForm } = useGenericComponents();
  const { setAllowCreateAdditional, setCreateButtonText } = useFormActions();
  const { hasTaxEnabled, taxRates, isLoading } = useTaxRates();
  const formDefinitionHook = useAdditionalFeeFormDefinition;

  function getDefaultTaxRateId() {
    return taxRates.find(rate => rate.isDefault)?.id;
  }

  useEffect(() => {
    setAllowCreateAdditional(false);
    setCreateButtonText("Add");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <GenericForm
      onSuccess={onSuccess}
      apiRequest={makeAddAdditionalFeeRequest({
        addAdditionalFee: updateAdditionalFee,
        id: defaultValues?.id,
      })}
      defaultValues={{
        taxRateId: hasTaxEnabled ? getDefaultTaxRateId() : null,
        ...defaultValues,
      }}
      formDefinitionHook={formDefinitionHook}
    />
  );
};
