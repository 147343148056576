import { CourseDto } from "@justraviga/classmanager-sdk";

// import { showAlert } from "../../../../alertState";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export interface CourseAtCapacityBannerProps {
  course: CourseDto;
}
export const CourseAtCapacityBanner = ({
  course,
}: CourseAtCapacityBannerProps) => {
  const { Banner } = useGenericComponents();
  return (
    <Banner
      className={"text-label-400 text-grey-900"}
      content={`${course.name} has now reached capacity.`}
      variant={"error"}
      // action={{
      //   text: "Join waiting list",
      //   onClick: () => {
      //     showAlert({
      //       content: "Not implemented yet",
      //     });
      //   },
      // }}
    />
  );
};
