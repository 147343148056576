import { dateToday } from "../../dateUtils";
import {
  getEnrolmentEndDate,
  getEnrolmentStartDate,
} from "../../enrolmentUtils";
import { getFullName } from "../../personUtil";
import { getPlatformFunctions } from "../../platformSpecific";
import {
  makeCancellationRequest,
  useUpdateEndFormDefinition,
} from "../formDefinitions/enrolmentForm";
import { useGenericComponents } from "../GenericComponentsProvider";

export interface EnrolmentKey {
  classId: string;
  studentId: string;
}

export const UpdateEnrolmentEndForm = ({
  date,
  classId,
  studentId,
}: EnrolmentKey & { date: string }) => {
  const { GenericForm, View, Text } = useGenericComponents();
  const { usePlatformEntityActions, renderMarkdown, useApi } =
    getPlatformFunctions();
  const defaultActions = usePlatformEntityActions<EnrolmentKey>({
    entity: "enrolment",
  });

  const { data: student } = useApi("getStudent", { id: studentId });
  const { data: course } = useApi("getCourse", { id: classId });
  const { data: season } = useApi(
    "getSeason",
    { id: course?.entity.seasonId ?? "" },
    {
      enabled: !!course,
    },
  );
  const { data: enrolments } = useApi("listEnrolment", {
    where: {
      studentId: { equals: studentId },
      classId: { equals: classId },
    },
    selectAll: true,
  });

  if (!student || !course || !season || !enrolments) {
    return null;
  }

  const now = dateToday();
  const enrolmentAdjustments = enrolments.data.flatMap(
    enrolment => enrolment.adjustments,
  );

  const isCurrentlyEnrolled =
    getEnrolmentStartDate({
      season,
      enrolmentAdjustments,
    }) <= now &&
    getEnrolmentEndDate({
      season,
      enrolmentAdjustments,
    }) >= now;

  return (
    <View className="flex flex-col space-y-8">
      {isCurrentlyEnrolled && (
        <View>
          <Text>
            {renderMarkdown(
              `When would you like to remove **${getFullName(student)}** from **${course.entity.name}**?`,
            )}
          </Text>
        </View>
      )}
      <View>
        <GenericForm
          apiRequest={makeCancellationRequest({
            classId,
            studentId,
          })}
          defaultValues={{ date }}
          formDefinitionHook={useUpdateEndFormDefinition}
          onSuccess={defaultActions.onUpdateSuccess}
        />
      </View>
    </View>
  );
};
