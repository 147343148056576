import { ReactNode } from "react";

import { cn } from "../../cssUtils";
import { useGenericComponents } from "../GenericComponentsProvider";

export interface InputDescriptionProps {
  description?: ReactNode;
  variant?: "sm" | "md";
}

export const InputDescription = ({
  variant = "sm",
  description,
}: InputDescriptionProps) => {
  const { Text } = useGenericComponents();

  if (!description) {
    return null;
  }

  return (
    <Text
      className={cn(`mt-2 whitespace-pre-line text-grey-600`, {
        "text-caption-400": variant === "sm",
        "text-body-400": variant === "md",
      })}>
      {description}
    </Text>
  );
};
