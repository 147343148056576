import { useEffect, useState } from "react";

import {
  InvitationStatus,
  LinkDto,
  UserDto,
} from "@justraviga/classmanager-sdk";

import { InviteBanner } from "./InviteBanner";
import { colors } from "../../../../colors";
import { Permission } from "../../../../permission";
import { getFullName } from "../../../../personUtil";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const InvitationWidget = ({
  invitationStatus,
  getLinkData,
  getUserData,
  sendInvite,
  cancelInvite,
  revokeAccess,
  accountPermission,
}: {
  invitationStatus: InvitationStatus;
  getLinkData(): Promise<LinkDto>;
  getUserData(): Promise<UserDto>;
  sendInvite(): Promise<LinkDto>;
  cancelInvite(): void;
  revokeAccess(): void;
  accountPermission: Permission;
}) => {
  const { BaseCard, ProtectedOverlay, View } = useGenericComponents();

  const [linkData, setLinkData] = useState<LinkDto | null>(null);
  const [userData, setUserData] = useState<UserDto | null>(null);

  useEffect(() => {
    if (invitationStatus === "invited") {
      getLinkData().then(setLinkData);
      setUserData(null);
    }
    if (invitationStatus === "accepted") {
      getUserData().then(setUserData);
      setLinkData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationStatus]);

  if (invitationStatus === "not_invited") {
    return (
      <ProtectedOverlay permission={accountPermission}>
        <View className={"w-full"}>
          <BaseCard
            title={"Invite to Class Manager"}
            titleColor={"grey-900"}
            titleSize={16}
            titleWeight={600}
            iconPosition={"left-framed"}
            icon={"sendSharp"}
            onClick={sendInvite}
          />
        </View>
      </ProtectedOverlay>
    );
  }

  if (invitationStatus === "invited") {
    if (!linkData) {
      return null;
    }

    return (
      <InviteBanner
        title={"Invitation pending"}
        icon={{ name: "time", color: colors.grey["900"] }}
        name={getFullName(linkData)}
        email={linkData.email}
        actions={[
          {
            title: "Resend invitation email",
            onClick: () => sendInvite().then(setLinkData),
            permission: accountPermission,
          },
          {
            title: "Cancel invitation",
            onClick: cancelInvite,
            permission: accountPermission,
          },
        ]}
      />
    );
  }

  if (invitationStatus === "accepted") {
    if (!userData) {
      return null;
    }

    return (
      <InviteBanner
        title={"Account activated"}
        name={getFullName({
          firstname: userData.firstname!,
          lastname: userData.lastname,
        })}
        email={userData.email!}
        actions={[
          {
            title: "Remove account access",
            onClick: revokeAccess,
            permission: "staff:removeAccess",
          },
        ]}
        icon={{ name: "checkmarkCircle", color: colors.green["600"] }}
      />
    );
  }
};
