import {
  AgeCutOffType,
  CompanyEnrolmentSettingsDto,
  PricingModel,
  PricingScheme,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

import {
  calculateWeeksBetweenDates,
  dateAdd,
  dateToday,
  monthSpan,
} from "./dateUtils";
import { formatDate } from "./intlFormatter";
import { TFunction } from "./translation/i18n";

export const getSeasonTimeFrame = (
  season: SeasonDto,
): { dateRange: string; weeksCount: number } => {
  const dateRange = `${formatDate(season.startAt, "dayMonthYear")} - ${formatDate(season.endAt, "dayMonthYear")}`;
  const weeksCount = calculateWeeksBetweenDates(
    new Date(season.startAt),
    new Date(season.endAt),
  );

  return { dateRange, weeksCount };
};

// selected item should come from current, then upcoming then past
export const seasonPriority = (season: SeasonDto): number => {
  const status = getSeasonStatus(season);

  return status === "upcoming" ? 1 : status === "past" ? 2 : 0;
};

export const getSeasonCutOffDate = (
  enrolmentSettings: Pick<
    CompanyEnrolmentSettingsDto,
    "ageCutOffType" | "ageCutOffDays" | "ageCutOffDate"
  >,
  startAt: string,
) => {
  switch (enrolmentSettings.ageCutOffType) {
    case AgeCutOffType.DaysAfterStart:
      return dateAdd(startAt, enrolmentSettings.ageCutOffDays!, "day");
    case AgeCutOffType.OnSpecificDate: {
      const [year, month, day] = startAt.split("-");
      const seasonStartYear = Number.parseInt(year, 10);
      // We need to be taking the date before the season starts, therefore, If
      // the age cutoff date is after the season starts, we need use the previous year
      return `${month}-${day}` >= enrolmentSettings.ageCutOffDate!
        ? `${seasonStartYear}-${enrolmentSettings.ageCutOffDate}`
        : `${seasonStartYear - 1}-${enrolmentSettings.ageCutOffDate}`;
    }
    case AgeCutOffType.FirstDayOfSeason:
    default:
      return startAt;
  }
};

export type SeasonStatus = "current" | "upcoming" | "past";

export const getSeasonStatus = (
  season: Pick<SeasonDto, "startAt" | "endAt">,
): SeasonStatus => {
  const today = dateToday();

  return season.startAt > today
    ? "upcoming"
    : season.endAt < today
      ? "past"
      : "current";
};

export const getPricingModelLabel = (
  t: TFunction,
  pricingModel: PricingModel,
) =>
  ({
    [PricingModel.Lesson]: "By lesson",
    [PricingModel.Month]: "By month",
    [PricingModel.Season]: t("label.registrationFeeInterval.perSeason"),
  })[pricingModel];

export const pricingSchemeLabel: Record<PricingScheme, string> = {
  [PricingScheme.None]: "Price",
  [PricingScheme.ClassCount]: "Number of classes",
  [PricingScheme.TotalDuration]: "Number of hours",
};

export function getPricingSchemeText(
  pricingModel: PricingModel,
  pricingScheme: PricingScheme,
) {
  if (
    pricingModel !== PricingModel.Month ||
    pricingScheme === PricingScheme.None
  ) {
    return undefined;
  }

  if (pricingScheme === PricingScheme.ClassCount) {
    return "Number of classes per week";
  }

  return "Number of hours per week";
}

export function getTuitionPricingSchemeDescription(season: SeasonDto) {
  if (season.pricingModel !== PricingModel.Month) {
    return "";
  }

  if (season.pricingScheme === PricingScheme.ClassCount) {
    return "Number of classes";
  }

  return "Number of hours";
}

export function shouldShowPricingList(season: SeasonDto) {
  return (
    season.pricingModel === PricingModel.Month &&
    season.pricingScheme !== PricingScheme.None
  );
}

export function monthsLeftInSeason(season: SeasonDto) {
  const today = dateToday();
  const start = season.startAt > today ? season.startAt : today;
  return monthSpan(start, season.endAt);
}
