import { NoItemsContentPlaceholder } from "@shared/components/ui/NoItemsContentPlaceholder";
import { useDatatable } from "@shared/datatable";

export const NoContentPlaceholder = () => {
  const { datatable } = useDatatable();
  const placeholder = datatable.contentPlaceholders?.noContent;

  return (
    <section>
      <NoItemsContentPlaceholder
        icon={placeholder?.icon ?? "peopleOutline"}
        title={placeholder?.title ?? "No records created yet"}
        description={placeholder?.description}
        createAction={
          datatable.createAction && {
            text: datatable.createLabel ?? "Create",
            onClick: datatable.createAction,
            permission: datatable.permissions?.create,
          }
        }
        navigationAction={datatable.navigationAction}
      />
    </section>
  );
};
