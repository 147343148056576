import { BillItemDto } from "@justraviga/classmanager-sdk";

import { CheckoutItem } from "./CheckoutItem";
import { CheckoutSection } from "./CheckoutSection";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const CheckoutSeasonAdditionalFees = ({
  additionalFees,
  seasonName,
}: {
  additionalFees: BillItemDto[];
  seasonName: string;
}) => {
  const { View } = useGenericComponents();

  return (
    <CheckoutSection
      heading={`Additional fees (${seasonName})`}
      headingWeight={"bold"}
      spacing={"tight"}>
      {additionalFees.map((additionalFee, index) => (
        <View key={index}>
          <CheckoutItem
            text={additionalFee.description}
            amount={additionalFee.subtotal}
          />
        </View>
      ))}
    </CheckoutSection>
  );
};
