import React, { useState } from "react";

import { useInfiniteQuery } from "@tanstack/react-query";
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useInView } from "react-intersection-observer";

import {
  DatatableContentState,
  DatatableRow,
  Dto,
  transformDataIntoPaginatedData,
  useDatatable,
} from "shared/lib";

import { ErrorContentPlaceholder } from "@/modules/common/datatable/content/ErrorContentPlaceholder";
import { MobileGroupActionsModalTopSheet } from "@/modules/common/datatable/content/mobile/MobileGroupActionsModalTopSheet";
import { MobileHeader } from "@/modules/common/datatable/content/mobile/MobileHeader";
import { MobileTableBody } from "@/modules/common/datatable/content/mobile/MobileTableBody";
import { NoContentPlaceholder } from "@/modules/common/datatable/content/NoContentPlaceholder";
import { NoResultsContentPlaceholder } from "@/modules/common/datatable/content/NoResultsContentPlaceholder";

export const MobileDatatableContainer = <ModelDto extends Dto>({
  columns,
}: {
  columns: ColumnDef<DatatableRow<ModelDto>>[];
}) => {
  const {
    query,
    filters,
    setQuery,
    globalFilter,
    datatable,
    pageSize,
    dataLoadedCount,
  } = useDatatable();

  const [hasFirstPageFetched, setHasFirstPageFetched] = useState(false);

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const { data, fetchNextPage, isFetching, error, isLoading } =
    useInfiniteQuery({
      queryKey: [
        datatable.id,
        "loadedCount-" + dataLoadedCount,
        globalFilter,
        filters,
      ],
      queryFn: async params => {
        query.pageSize = pageSize;
        query.search = globalFilter;
        query.filters = filters;
        query.page = params.pageParam;
        setQuery(query);

        const rawData = await datatable.fetchData(query);

        return transformDataIntoPaginatedData({
          rawData,
          onClick: datatable.rowActions?.click,
        });
      },
      initialPageParam: 1,
      getNextPageParam: lastPage => {
        // check it loads same page twice
        return lastPage?.pagination?.currentPage
          ? lastPage?.pagination?.currentPage + 1
          : 1;
      },
      refetchOnWindowFocus: false,
    });

  //we must flatten the array of arrays from the useInfiniteQuery hook
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const flatData: DatatableRow<ModelDto>[] = React.useMemo(
    () => data?.pages?.flatMap(page => page.data) ?? [],
    [data],
  );

  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (flatData.length === 0) {
      fetchNextPage();
    }
    setHasFirstPageFetched(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (inView) {
      fetchNextPageData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNextPage, inView]);

  const table = useReactTable({
    data: flatData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row: DatatableRow<ModelDto>) => row.item.id,
  });

  const { rows } = table.getRowModel();

  //Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 20,
    overscan: 20,
  });
  const virtualItems = rowVirtualizer.getVirtualItems();

  const getContentState = () => {
    if (isLoading || isFetching) return DatatableContentState.Loading;

    if (error) return DatatableContentState.Error;

    if (virtualItems.length !== 0) return DatatableContentState.Loaded;

    if (query.page === 1 && globalFilter === "" && filters.length === 0)
      return DatatableContentState.NoContent;

    return DatatableContentState.NoResults;
  };

  const contentState = getContentState();

  const totalResults = data?.pages[0]?.pagination?.total ?? 0;
  const totalPages = data?.pages[0]?.pagination?.totalPages ?? 1;

  const fetchNextPageData = () => {
    if (!hasFirstPageFetched) {
      return;
    }

    const nextPage = query.page + 1;

    if (nextPage > totalPages) {
      return;
    }

    query.page = nextPage;
    setQuery(query);

    fetchNextPage();
  };

  return (
    <article ref={tableContainerRef} className="max-md:-mx-5">
      <div className="flex flex-col">
        <MobileGroupActionsModalTopSheet contentState={contentState} />

        <div className="px-5">
          <MobileHeader
            contentState={contentState}
            totalResults={totalResults}
          />
        </div>

        {contentState === DatatableContentState.NoResults ? (
          <NoResultsContentPlaceholder />
        ) : contentState == DatatableContentState.NoContent ? (
          <NoContentPlaceholder />
        ) : contentState == DatatableContentState.Error ? (
          <ErrorContentPlaceholder />
        ) : (
          <section ref={ref}>
            <table className={"h-full w-full table-fixed"}>
              <tbody>
                <MobileTableBody table={table} data={virtualItems} />
              </tbody>
            </table>
          </section>
        )}
      </div>

      <div ref={ref} className={"pb-5"}></div>
    </article>
  );
};
