import { ActionBannerProps } from "@shared/components/interfaces/actionBanner";

import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { ActionBanner } from "@/modules/common/ui/ActionBanner";
import { Button } from "@/modules/common/ui/button/Button";

const title = "Invite to portal";
const description =
  "Lorem ipsum dolor sit amet consectetur. Aenean vulputate enim fermentum tellus sed amet non. Eget odio metus auctor leo nec sit tortor.";

const options: Option<ActionBannerProps>[] = [
  {
    title: "Basic",
    props: [
      {
        title: title,
        description: description,
      },
    ],
  },
  {
    title: "Closeable",
    props: [
      {
        title: title,
        description: description,
        onClose: () => {},
      },
    ],
  },
  {
    title: "With buttons",
    props: [
      {
        title: title,
        description: description,
        buttons: [<Button variant={"primary"} text={"Invite to portal"} />],
      },
      {
        title: title,
        description: description,
        buttons: [
          <Button variant={"primary"} text={"Invite to portal"} />,
          <Button variant={"secondary"} text={"Do something else"} />,
        ],
      },
    ],
  },
];
export const ActionBannerPage = () => {
  return (
    <DocWrapper
      title="Action Banner"
      component={ActionBanner}
      options={options}
    />
  );
};
