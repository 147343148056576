import { QuoteDto, TransactionDetailType } from "@justraviga/classmanager-sdk";

import { CheckoutItem } from "./CheckoutItem";
import { CheckoutSection } from "./CheckoutSection";

export const CheckoutTrials = ({ seasons }: { seasons: QuoteDto[] }) => {
  return (
    <CheckoutSection heading={"Trials"} spacing={"tight"}>
      {seasons.map(season =>
        season.items
          .filter(item => item.type === TransactionDetailType.Trial)
          .map((trial, index) => (
            <CheckoutItem
              key={index}
              text={trial.description}
              amount={trial.subtotal}
            />
          )),
      )}
    </CheckoutSection>
  );
};
