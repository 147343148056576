import {
  AggregateTransactionDto,
  CheckoutOperationRequest,
} from "@justraviga/classmanager-sdk";

import { useBasket } from "@shared/components/basket/useBasket";
import { useCatalogueContext } from "@shared/components/catalogue/useCatalogueContext";
import { CustomerCheckoutSummaryPage as SharedCustomerCheckoutSummaryPage } from "@shared/components/modules/customer/checkout/CustomerCheckoutSummaryPage";

import { usePaymentMethods } from "@/modules/common/payments/usePaymentMethodActions";
import { withFamilyAccount } from "@/modules/customer/checkout/withFamilyAccount";
import { CustomerLayout } from "@/modules/customer/CustomerLayout";
import { Router } from "@/routing/router";

const CustomerCheckoutSummaryPage = () => {
  const { setSuccessfulTransaction, clear: clearBasket } = useBasket();
  const gotoSuccess = (
    result?: AggregateTransactionDto[],
    checkoutOperation?: CheckoutOperationRequest,
  ) => {
    setSuccessfulTransaction(result!, checkoutOperation!);
    clearBasket();
    Router.push("CustomerCheckoutSuccess", {
      slug: company.slug,
    });
  };
  const { showCheckoutPaymentForm } = usePaymentMethods(gotoSuccess);
  const { company } = useCatalogueContext();

  return (
    <CustomerLayout
      hideNav
      showBackButton
      backButtonText={"Back"}
      backButtonOnClick={() => {
        Router.push("MemberBasket", { slug: company.slug });
      }}>
      <SharedCustomerCheckoutSummaryPage
        openPaymentForm={async (...args) => showCheckoutPaymentForm(...args)}
        onCheckoutSuccess={gotoSuccess}
      />
    </CustomerLayout>
  );
};

const WithAccount = withFamilyAccount(CustomerCheckoutSummaryPage);

export { WithAccount as CustomerCheckoutSummaryPage };
