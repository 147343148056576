import { Option } from "./adminPageTypes";
import { CollapsibleContainerProps } from "../../components/collapsibleContainer/CollapsibleContainer";
import { useGenericComponents } from "../../components/GenericComponentsProvider";

export const useCollapsibleContainerExamples = (): Array<
  Option<CollapsibleContainerProps>
> => {
  const { Icon, View, Text } = useGenericComponents();
  // Children on the collapsible container are required, it's a container. Our
  // docswrapper et al pulls children out of the options not the props, and
  // therefore we need to just send an empty children prop to the component to
  // keep it happy
  return [
    {
      title: "Default",
      children: <Text>This is a collapsible container</Text>,
      props: [
        {
          children: undefined,
        },
      ],
    },
    {
      title: "Big content",
      children: (
        <View>
          <Text>This is a collapsible container</Text>
          <Text>This is a collapsible container</Text>
          <Icon name={"calendar"} size={24} />
          <Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non
            risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing
            nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas
            ligula massa, mattis quis, hendrerit ut, pharetra vitae, mi. Fusce
            eros. Sed aliquam ultrices mauris. Donec nisi. Duis lectus.
            Suspendisse potenti. In eleifend quam a odio. In hac habitasse
            platea dictumst. Maecenas ut massa quis augue luctus tincidunt.
            Nulla mollis molestie lorem. Quisque ut erat. Curabitur gravida nisi
            at nibh. In hac habitasse platea dictumst. Aliquam augue quam,
            sollicitudin vitae, consectetuer eget, rutrum at, lorem. Integer
            tincidunt ante vel ipsum. Praesent blandit lacinia erat. Vestibulum
            sed magna at nunc commodo placerat. Praesent blandit. Nam nulla.
            Integer pede justo, lacinia eget, tincidunt eget, tempus vel, pede.
            Morbi porttitor lorem id ligula. Suspendisse ornare consequat
            lectus. In est risus, auctor sed, tristique in, tempus sit amet,
            sem. Fusce consequat. Nulla nisl. Nunc nisl. Duis bibendum, felis
            sed interdum venenatis, turpis enim pretium quis, sem.
          </Text>
        </View>
      ),
      props: [
        {
          children: undefined,
        },
      ],
    },
    {
      title: "Changed button texts",
      children: <Text>This is a collapsible container</Text>,
      props: [
        {
          expandText: "Open the collapsible container",
          collapseText: "Close the collapsible container",
          children: undefined,
        },
      ],
    },
    {
      title: "Button position",
      children: <Text>This is a collapsible container</Text>,
      props: [
        {
          buttonPosition: "top",
          children: undefined,
        },
        {
          buttonPosition: "bottom",
          children: undefined,
        },
      ],
    },
    {
      title: "Different button variants",
      children: <Text>This is a collapsible container</Text>,
      props: [
        {
          buttonVariant: "tertiaryLight",
          children: undefined,
        },
        {
          buttonVariant: "tertiary",
          children: undefined,
        },
        {
          buttonVariant: "subtle",
          children: undefined,
        },
      ],
    },
    {
      title: "Different open start states",
      children: <Text>This is a collapsible container</Text>,
      props: [
        {
          startOpen: true,
          children: undefined,
        },
        {
          startOpen: false,
          children: undefined,
        },
      ],
    },
  ];
};
