import { PropsWithChildren } from "react";

import { NonAuthLayout } from "@/modules/auth/layout/NonAuthLayout";

export const CatalogueMinimalLayout = ({
  children,
  showTerms,
}: PropsWithChildren<{ showTerms?: boolean }>) => {
  return (
    <NonAuthLayout branded={true} showTerms={showTerms}>
      {children}
    </NonAuthLayout>
  );
};
