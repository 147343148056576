import { SeasonDto } from "@justraviga/classmanager-sdk";

import { FormActionsProvider } from "@shared/components/FormActionsProvider";
import { useGenericComponents } from "@shared/components/GenericComponentsProvider";
import { CreateSeasonFooter } from "@shared/components/modules/company/seasons/CreateSeasonFooter";
import { SeasonPageForm } from "@shared/components/modules/company/seasons/SeasonPageForm";

import { useAlertDialog } from "@/modules/common/overlays/alertDialog/AlertDialogContext";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { CompanyContent, CompanyLayout } from "@/modules/company/CompanyLayout";
import { Router } from "@/routing/router";

export const SeasonCreatePage = () => {
  const { showAlert: showAlertDialog } = useAlertDialog();
  const { openSheet, closeSheet } = useSheet();
  const { Text, View } = useGenericComponents();

  function goToSeasonList() {
    Router.push("SeasonList");
  }

  function goToSeasonDetails(id: SeasonDto["id"]) {
    Router.push("SeasonDetails", { id: id });
  }

  return (
    <FormActionsProvider source="SeasonCreatePage">
      <CompanyLayout
        footer={<CreateSeasonFooter goToSeasonList={goToSeasonList} />}>
        <CompanyContent>
          <View
            className={
              "flex flex-row items-center justify-between pb-5 md:px-8 md:py-5"
            }>
            <Text className={"text-heading5-600 text-grey-900"}>
              {t("pageTitle.seasonCreate")}
            </Text>
          </View>
          <SeasonPageForm
            goToSeasonDetails={goToSeasonDetails}
            openSheet={openSheet}
            closeSheet={closeSheet}
            showAlertDialog={showAlertDialog}
          />
        </CompanyContent>
      </CompanyLayout>
    </FormActionsProvider>
  );
};
