import { IconName } from "../../availableIcons";
import { cn } from "../../cssUtils";
import { BigRadioProps } from "../../forms/formComponentProps";
import { useGenericComponents } from "../GenericComponentsProvider";
import { Chip } from "../ui/Chip";

export const BigRadio = ({
  label,
  description,
  value,
  name,
  onChange,
  options,
  cols = 2,
}: BigRadioProps) => {
  const { Text, View } = useGenericComponents();

  return (
    <View data-name={name}>
      {(label || description) && (
        <View className={"pb-4 pt-2 flex flex-col space-y-1"}>
          {label && (
            <View>
              <Text className={"text-body-600 text-grey-900 font-semibold"}>
                {label}
              </Text>
            </View>
          )}
          {description && (
            <View>
              <Text className={"text-body-400 text-grey-600"}>
                {description}
              </Text>
            </View>
          )}
        </View>
      )}

      <View
        className={cn("flex flex-col gap-5 md:grid", {
          "md:grid-cols-1": cols === 1,
          "md:grid-cols-2": cols === 2,
          "md:grid-cols-3": cols === 3,
        })}>
        {options.map(option => (
          <View key={option.value}>
            <BigRadioOption
              option={option}
              onChange={onChange}
              isSelected={value === option.value}
            />
          </View>
        ))}
      </View>
    </View>
  );
};

const BigRadioOption = ({
  option,
  onChange,
  isSelected,
}: {
  option: BigRadioProps["options"][number];
  onChange: BigRadioProps["onChange"];
  isSelected: boolean;
}) => {
  const { View, Pressable } = useGenericComponents();

  return (
    <Pressable
      onClick={() => onChange && !option.disabled && onChange(option.value)}
      className={cn(
        "relative flex flex-row items-start space-x-3 rounded-md border p-4 cursor-pointer border-grey-300 hover:bg-grey-100",
        {
          "border-grey-900 hover:border-grey-900": isSelected,
          "pointer-events-none": option.disabled,
          "cursor-default": option.disabled,
        },
      )}>
      <View className={"flex flex-row items-center justify-center"}>
        <BigRadioIcon
          icon={option.icon}
          iconStyle={option.iconStyle}
          iconColor={option.iconColor}
        />
      </View>
      <View className={"flex-1"}>
        <View className={"flex flex-row space-x-4 justify-between"}>
          <BigRadioTextAndDescription
            label={option.label}
            description={option.description}
          />
          {option.chipLabel ? (
            <View>
              <Chip
                variant={option.chipVariant}
                label={option.chipLabel}
                size={"sm"}
              />
            </View>
          ) : null}
        </View>
      </View>
      {option.disabled && (
        <View
          className={
            "absolute top-0 left-0 bottom-0 right-0 bg-overlay-white-medium"
          }></View>
      )}
    </Pressable>
  );
};

const BigRadioIcon = ({
  icon,
  iconStyle,
  iconColor,
}: {
  icon: IconName;
  iconStyle?: "branded" | "default";
  iconColor?: string;
}) => {
  const { View, Icon } = useGenericComponents();

  return iconStyle === "branded" ? (
    <View className={"rounded-sm bg-brand-100 p-3"}>
      <Icon name={icon} size={24} color={iconColor} />
    </View>
  ) : (
    <Icon name={icon} size={24} color={iconColor} />
  );
};

const BigRadioTextAndDescription = ({
  label,
  description,
}: {
  label: string;
  description: string | undefined;
}) => {
  const { Text, View } = useGenericComponents();

  return (
    <View className={"flex flex-col space-y-1"}>
      <Text className={"text-body-400 text-grey-900"}>{label}</Text>
      {description && (
        <Text className={"text-label-400 text-grey-600"}>{description}</Text>
      )}
    </View>
  );
};
