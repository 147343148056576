import { useState } from "react";

export const localAdditionalFeeIdPrefix = "LOCAL_ADDITIONAL_FEE_ID_";

export function useAdditionalFeeId() {
  const [additionalFeeId, setAdditionalFeeId] = useState(1);

  const getNextId = () => {
    const nextId = additionalFeeId + 1;
    const newId = `${localAdditionalFeeIdPrefix}${nextId}`;
    setAdditionalFeeId(nextId);
    return newId;
  };

  return { getNextId };
}
