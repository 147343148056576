import { FC, ReactElement } from "react";

import { SeasonDto } from "@justraviga/classmanager-sdk";

import { getTuitionPricingSchemeDescription } from "../../../seasonUtils";

export const SeasonPricingSchemeCard = <T,>({
  season,
  BaseCard,
  props,
  footerSlot,
  shouldShowFooter,
}: {
  season: SeasonDto;
  BaseCard: FC;
  props?: T;
  footerSlot?: ReactElement;
  shouldShowFooter?: boolean;
}) => {
  const defaultProps = {
    title: "Tuition & pricing",
    description: getTuitionPricingSchemeDescription(season),
    descriptionColor: "grey-900",
    descriptionWeight: 600,
    descriptionSize: 16,
    footerSlot: shouldShowFooter ? footerSlot : null,
  } as T;

  return <BaseCard {...defaultProps} {...props} />;
};
