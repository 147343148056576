import { ReactNode } from "react";

import { formatMoneyFromInteger } from "../../../../intlFormatter";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { StyledText, TextStyle } from "../../../text/StyledText";

export const CheckoutItem = ({
  text,
  amount,
  style = "normal",
  suffix,
}: {
  text: string;
  amount?: number | null;
  style?: TextStyle;
  suffix?: ReactNode;
}) => {
  const { View } = useGenericComponents();
  return (
    <View className={"flex flex-row items-start justify-between gap-x-4"}>
      <View className={"flex-1"}>
        <StyledText style={style} text={text} />
      </View>
      {amount !== undefined ? (
        <View className={"flex-initial"}>
          <StyledText
            style={style}
            text={amount === null ? "-" : formatMoneyFromInteger(amount)}
          />
        </View>
      ) : null}
      {suffix}
    </View>
  );
};
