import { useState } from "react";

import { PricingPlanType } from "@justraviga/classmanager-sdk";

import {
  PreviewTuitionFormSchema,
  PreviewTuitionFormStepType,
} from "./previewTuitionForm";
import { usePreviewTuitionBillData } from "./usePreviewTuitionBillData";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";
import { dayjs } from "../../../lib/dayjs";
import { pricingPlanIcons, pricingPlanLabels } from "../../../pricingPlanUtils";

export const usePreviewTuitionFirstStepForm = (
  builder: FormDefinitionBuilder<PreviewTuitionFormSchema>,
  step: PreviewTuitionFormStepType,
) => {
  const {
    billableMonths,
    selectedSeason,
    setSelectedSeason,
    seasons,
    pricingPlan,
    setPricingPlan,
  } = usePreviewTuitionBillData();
  const [billingMonth, setBillingMonth] = useState<string | undefined>(
    undefined,
  );

  builder
    .conditional(["seasonId"], ["seasonId"], () => {
      return step === 1;
    })
    .conditional(["pricingPlanId"], ["pricingPlanId"], () => {
      return Boolean(step === 1 && selectedSeason?.pricingPlans);
    })
    .conditional(["billingMonth"], ["billingMonth"], () => {
      return step === 1 && pricingPlan?.type === PricingPlanType.Monthly;
    })
    .select("seasonId", {
      label: t("label.season"),
      required: step === 1,
      data: (seasons?.data ?? []).map(season => ({
        value: season.id,
        label: season.name,
      })),
    })
    .bigRadio("pricingPlanId", {
      label: "Payment option",
      cols: 2,
      required: true,
      options:
        selectedSeason?.pricingPlans
          .filter(plan => plan.enabled)
          .map(plan => ({
            label: pricingPlanLabels[plan.type],
            value: plan.id,
            icon: pricingPlanIcons[plan.type],
          })) ?? [],
    })
    .select("billingMonth", {
      label: "Billing month",
      required: step === 1 && pricingPlan?.type !== PricingPlanType.OneOff,
      data: billableMonths,
    })
    .onChange(form => {
      if (form.billingMonth) {
        setBillingMonth(form.billingMonth as string | undefined);
      }
      if (form.pricingPlanId) {
        const selectedPricingPlan = selectedSeason?.pricingPlans.find(
          plan => plan.id === form.pricingPlanId,
        );
        setPricingPlan(selectedPricingPlan);

        if (selectedPricingPlan?.type === PricingPlanType.OneOff) {
          const today = dayjs().format("YYYY-MM-DD");
          setBillingMonth(today);
        }
      }
      if (form.seasonId) {
        const findSeason = seasons?.data.find(
          season => season.id === form.seasonId,
        );
        setSelectedSeason(findSeason);
      }
    });

  return {
    selectedSeason,
    billingMonth,
    pricingPlan,
  };
};
