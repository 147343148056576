import { useFormActions } from "../../../FormActionsProvider";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface SeasonFooterProps {
  submitBtnText: string;
  cancelBtnText: string;
  onCancel: () => void;
}

export const SeasonFormFooter = ({
  submitBtnText,
  cancelBtnText,
  onCancel,
}: SeasonFooterProps) => {
  const { Button, View } = useGenericComponents();
  const { submit, isLoading, isSubmitDisabled } = useFormActions();

  return (
    <View className={"flex flex-row items-center justify-between px-8 py-4"}>
      <View>
        <Button
          variant={"secondary"}
          size={"lg"}
          text={cancelBtnText}
          onClick={onCancel}
        />
      </View>
      <View>
        <Button
          variant={"brand"}
          size={"lg"}
          text={submitBtnText}
          onClick={submit}
          disabled={isSubmitDisabled || isLoading}
          loading={isLoading}
        />
      </View>
    </View>
  );
};
