import { PricingPlanDto, QuoteDto } from "@justraviga/classmanager-sdk";
import { PricingPlanType } from "@justraviga/classmanager-sdk/dist/models/PricingPlanType";

import { seasonTuitionSubTotalForPlan } from "../../../../checkoutUtils";
import { BigRadioOption } from "../../../../forms/formComponentProps";
import { formatMoneyFromInteger } from "../../../../intlFormatter";
import { numberToOrdinal } from "../../../../numberUtils";
import { BigRadio } from "../../../forms/BigRadio";

export const PricingPlanSelector = ({
  pricingPlans,
  seasonQuote,
  selectedPricingPlanId,
  setSelectedPricingPlanId,
}: {
  pricingPlans: PricingPlanDto[];
  seasonQuote: QuoteDto;
  selectedPricingPlanId: string | undefined;
  setSelectedPricingPlanId: (selectedPricingPlanId: string) => void;
}) => {
  function pricingPlanLabel(planType: PricingPlanType, amount: string) {
    switch (planType) {
      case "monthly":
        return `Pay monthly ${amount}`;
      case "one-off":
        return `Pay upfront ${amount}`;
      default:
        throw new Error(`Unsupported pricing plan: ${planType}`);
    }
  }
  function pricingPlanDescription(
    planType: PricingPlanType,
    instalments: number,
    day: string,
  ) {
    switch (planType) {
      case "monthly":
        return `${instalments} ${t("entity.instalments", { count: instalments })} every ${day} of the month`;
      case "one-off":
        return "Due today";
      default:
        throw new Error(`Unsupported pricing plan: ${planType}`);
    }
  }

  const options: BigRadioOption[] = pricingPlans
    // always show monthly first
    .sort((a, _) => (a.type === "monthly" ? -1 : 1))
    .map(plan => ({
      label: pricingPlanLabel(
        plan.type,
        formatMoneyFromInteger(seasonTuitionSubTotalForPlan(seasonQuote, plan)),
      ),
      description: pricingPlanDescription(
        plan.type,
        seasonQuote.remainingInstalments,
        numberToOrdinal(plan.paymentDay || 0),
      ),
      value: plan.id,
      icon: "calendarOutline",
      chipLabel:
        plan.type === "one-off" && (plan.discount ?? 0) > 0
          ? `${plan.discount!}% off`
          : undefined,
      chipVariant: "success",
    }));

  return (
    <BigRadio
      onChange={id => setSelectedPricingPlanId(id as string)}
      value={selectedPricingPlanId}
      options={options}
    />
  );
};
