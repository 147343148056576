import { ReactNode } from "react";

import { BillingTiming, UserAccountDto } from "@justraviga/classmanager-sdk";

import { useCreateSeasonSettingsSheet } from "./useCreateSeasonSettingsSheet";
import { formatDate } from "../../../../../intlFormatter";
import { dayjs } from "../../../../../lib/dayjs";
import { numberToOrdinal } from "../../../../../numberUtils";
import { billingTimingMonthOffset } from "../../../../../transactionUtils";
import { useAutomatedTuitionForm } from "../../../../formDefinitions/settings/automatedTuitionForm";
import { useGenericComponents } from "../../../../GenericComponentsProvider";
import { useSettings } from "../../../../useSettings";

interface Props {
  seasonStartDate: string;
  openSheet: ({
    title,
    content,
    footer,
  }: {
    title: string;
    content: ReactNode;
    footer: ReactNode;
  }) => void;
  closeSheet: () => void;
  setAccount: (account: UserAccountDto | null) => void;
  account: UserAccountDto | null;
  numberOfInstallments: number | null;
  setCustomNumberOfInstallments: (installments: number) => void;
  showAutomatedTuitionSettingsForm: ReturnType<typeof useAutomatedTuitionForm>;
  userBillingDayOfMonth: number | null;
  setUserBillingDayOfMonth: (userBillingDayOfMonth: number | null) => void;
  userBillingTiming: BillingTiming | null;
  setUserBillingTiming: (userBillingTiming: BillingTiming | null) => void;
}

export const MonthlyPayment = ({
  seasonStartDate,
  userBillingDayOfMonth,
  userBillingTiming,
  numberOfInstallments,
  setCustomNumberOfInstallments,
  setUserBillingDayOfMonth,
  setUserBillingTiming,
}: Props) => {
  const { Button, Text, View } = useGenericComponents();
  const billingSettings = useSettings("billing");
  const { showAutomatedTuitionSettingsForm, showInstallmentsForm } =
    useCreateSeasonSettingsSheet();

  const formattedPaymentStartDate = formatDate(
    dayjs(seasonStartDate)
      .add(
        billingTimingMonthOffset(
          userBillingTiming || billingSettings.billingTiming,
        ),
        "month",
      )
      .set("date", userBillingDayOfMonth || billingSettings.billingDayOfMonth)
      .format("YYYY-MM-DD"),
    "dayMonthYear",
  );

  const dayOfMonth = numberToOrdinal(
    userBillingDayOfMonth || billingSettings.billingDayOfMonth,
  );

  return (
    <View className={"flex flex-col space-y-2"}>
      <View className={"flex flex-col sm:flex-row"}>
        <View>
          <Text className={"text-label-400 text-grey-600"}>
            On {formattedPaymentStartDate} and the {dayOfMonth} of every month.
          </Text>
        </View>
        <View>
          <View className={"-ml-2 sm:ml-0 -mt-1.5"}>
            <Button
              text={"Edit"}
              variant={"tertiaryLight"}
              size={"xs"}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                showAutomatedTuitionSettingsForm({
                  userBillingDayOfMonth,
                  setUserBillingDayOfMonth,
                  userBillingTiming,
                  setUserBillingTiming,
                });
              }}
            />
          </View>
        </View>
      </View>
      <View>
        <View className={"-ml-2"}>
          <Button
            text={"Change number of installments"}
            variant={"tertiaryLight"}
            size={"xs"}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              showInstallmentsForm({
                numberOfInstallments,
                setCustomNumberOfInstallments,
              });
            }}
          />
        </View>
      </View>
    </View>
  );
};
