import { EnrolmentDto } from "@justraviga/classmanager-sdk";

import { createEnrolmentStartDate } from "../../../../enrolmentUtils";
import { getPlatformFunctions } from "../../../../platformSpecific";
import {
  makeEnrolmentAddForm,
  makeEnrolmentAddRequest,
} from "../../../formDefinitions/enrolmentForm";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const EnrolmentCreateForm = ({
  courseId,
  seasonId,
  studentIds,
}: {
  courseId?: string;
  seasonId?: string;
  studentIds?: Array<string>;
}) => {
  const { GenericForm } = useGenericComponents();
  const { useApi, usePlatformEntityActions } = getPlatformFunctions();
  const defaultActions = usePlatformEntityActions<EnrolmentDto>({
    entity: "enrolment",
  });

  const { data: seasonProvided, isLoading: seasonIsLoading } = useApi(
    "getSeason",
    { id: seasonId! },
    { enabled: !!seasonId },
  );

  const { data: course, isLoading: courseIsLoading } = useApi(
    "getCourse",
    { id: courseId! },
    { enabled: !!courseId },
  );

  const { data: seasonsResponse, isLoading: seasonListIsLoading } = useApi(
    "listSeason",
    { selectAll: true },
  );

  if (seasonIsLoading || courseIsLoading || seasonListIsLoading) {
    return null;
  }

  const season = course
    ? seasonsResponse?.data.find(season => season.id === course.entity.seasonId)
    : seasonProvided;

  const dateStart = season ? createEnrolmentStartDate(season) : undefined;

  const pricingPlans = (season?.pricingPlans ?? []).filter(
    plan => plan.enabled,
  );
  const pricingPlanId =
    pricingPlans.length === 1 ? pricingPlans[0].id : undefined;

  const showStudentSelector =
    studentIds === undefined || studentIds.length === 0;

  return (
    <GenericForm
      apiRequest={makeEnrolmentAddRequest(courseId, season)}
      defaultValues={{
        seasonId: season?.id,
        classId: courseId,
        startAt: dateStart,
        endAt: season?.endAt,
        pricingPlanId,
        studentIds,
      }}
      formDefinitionHook={makeEnrolmentAddForm({
        classId: courseId,
        season: season,
        showStudentSelector,
      })}
      onSuccess={defaultActions.onCreateSuccess}
    />
  );
};
