import { colors } from "shared/lib";

import { DocWrapper, Option } from "@/modules/admin/docs/DocWrapper";
import { ActionMenuItemProps } from "@/modules/common/ui/ActionMenu";
import { Avatar } from "@/modules/common/ui/avatar/Avatar";
import { BaseCard, WebBaseCardProps } from "@/modules/common/ui/card/BaseCard";
import { Chip } from "@/modules/common/ui/chip/Chip";
import { Icon } from "@/modules/common/ui/icon/Icon";

const actions: Array<ActionMenuItemProps> = [
  {
    title: "Edit",
    onClick: () => {
      alert("Edit button was clicked!");
    },
  },
  {
    title: "Delete",
    destructive: true,
    onClick: () => {
      alert("Delete button was clicked!");
    },
  },
];

const editAction: WebBaseCardProps["headerAction"] = {
  text: "Edit",
  onClick: () => {
    alert("Edit button was clicked!");
  },
};

const lessonTitleSlot: WebBaseCardProps["title"] = (
  <div className="flex flex-row items-start justify-between gap-x-4">
    <div className="flex-initial">
      <p className="text-body-600 text-grey-900">Some class</p>
    </div>
    <div className="grow">
      <p className="text-body-400 text-grey-900">Grade 6</p>
    </div>
    <div className="flex-initial">
      <p className="text-body-400 text-grey-900">£0.00</p>
    </div>
  </div>
);

const lessonTimesAndPrices: WebBaseCardProps["bodySlot"] = (
  <div className="flex flex-col gap-y-1">
    <div className="flex flex-row items-center justify-between text-label-400 text-grey-900">
      <div>4:00 PM - 5:00 PM</div>
      <div>$40.00</div>
    </div>
    <div className="flex flex-row items-center justify-between text-label-400 text-grey-900">
      <div>4:00 PM - 5:00 PM</div>
      <div>$40.00</div>
    </div>
  </div>
);

const options: Array<Option<WebBaseCardProps>> = [
  {
    title: "Complete examples",
    props: [
      {
        title: "Alice",
        titleWeight: 600,
        titleSize: 16,
        description: "4 years old",
        descriptionSize: 14,
        descriptionWeight: 400,
        headerActions: actions,
        leftSlot: (
          <Avatar
            size="lg"
            name="Alice"
            src="https://images.unsplash.com/photo-1507036066871-b7e8032b3dea?w=200&h=200&auto=format&fit=crop&q=60"
          />
        ),
      },
      {
        title: "Account activated",
        titleWeight: 600,
        titleSize: 16,
        titleColor: "grey-900",
        bodySlot: (
          <div className="flex flex-col gap-y-1 text-caption-400 text-grey-900">
            <span>Phillip Siphron</span>
            <span>philip2@gmail.com</span>
          </div>
        ),
        headerActions: actions,
        leftSlot: (
          <div className="mr-1 flex h-full">
            <Icon name="checkmarkCircle" color={colors.green[600]} size={24} />
          </div>
        ),
      },
      {
        title: "Tuition & pricing",
        titleColor: "grey-600",
        titleSize: 14,
        description: "Class count",
        descriptionSize: 16,
        descriptionWeight: 600,
        headerAction: editAction,
        icon: "person",
        iconPosition: "left",
      },
      {
        padding: 2,
        fillColor: "#D5D2FD",
        title: "Ballet Grade 6",
        titleWeight: 600,
        titleSize: 16,
        titleColor: "grey-900",
        headerRightSlot: (
          <div className="text-body-400 leading-relaxed text-grey-900">
            5:00 PM - 6:00 PM
          </div>
        ),
        headerActions: actions,
        footerSlot: (
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row gap-x-3">
              <Icon name="calendarOutline" color={colors.grey[900]} size={16} />
              <Icon name="cash" color={colors.grey[900]} size={16} />
            </div>
            <div className="flex flex-row gap-x-3">
              <div className="flex flex-row items-center gap-x-1">
                <Icon name="peopleOutline" color={colors.grey[900]} size={16} />
                <span>Full</span>
              </div>
              <div className="flex flex-row items-center gap-x-1">
                <Icon name="timeOutline" color={colors.grey[900]} size={16} />
                <span>4</span>
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Custom fields",
        titleSize: 14,
        titleColor: "grey-600",
        collapsible: true,
        bodySlot: (
          <div className="my-2 flex flex-col gap-2 text-label-400 text-grey-600">
            <span>What is the first rule of Fight Club?</span>
            <span> - </span>
          </div>
        ),
        descriptionWeight: 400,
        descriptionSize: 14,
        headerAction: editAction,
        footerAction: {
          text: "Create new",
          onClick: () => {
            alert("Create new button was clicked!");
          },
        },
      },
    ],
  },
  {
    title: "Titles",
    props: [
      {
        title: "Small title (light)",
        titleSize: 14,
        titleWeight: 400,
        titleColor: "grey-600",
      },
      {
        title: "Small title (dark)",
        titleSize: 14,
        titleWeight: 400,
        titleColor: "grey-900",
      },
      {
        title: "Small bold title (light)",
        titleSize: 14,
        titleWeight: 600,
        titleColor: "grey-600",
      },
      {
        title: "Small bold title (dark)",
        titleSize: 14,
        titleWeight: 600,
        titleColor: "grey-900",
      },
      {
        title: "Large title (light)",
        titleSize: 16,
        titleWeight: 400,
        titleColor: "grey-600",
      },
      {
        title: "Large title (dark)",
        titleSize: 16,
        titleWeight: 400,
        titleColor: "grey-900",
      },
      {
        title: "Large bold title (light)",
        titleSize: 16,
        titleWeight: 600,
        titleColor: "grey-600",
      },
      {
        title: "Large bold title (dark)",
        titleSize: 16,
        titleWeight: 600,
        titleColor: "grey-900",
      },
      {
        title: "Very long title that will be truncated with an ellipsis",
        titleSize: 16,
        titleWeight: 600,
        titleColor: "grey-900",
        titleTruncate: true,
      },
    ],
  },
  {
    title: "Wrapping",
    props: [
      {
        title: "This is a long title that might be too long to display",
        titleWeight: 600,
        titleColor: "grey-900",
        titleTruncate: false,
        description: "The above title should wrap onto multiple lines",
      },
      {
        title: "This is a long title that might be too long to display",
        titleWeight: 600,
        titleColor: "grey-900",
        titleTruncate: true,
        description: "The above title should be truncated",
      },
    ],
  },
  {
    title: "Descriptions",
    props: [
      {
        title: "Title",
        description: "Small description",
        descriptionWeight: 400,
        descriptionSize: 14,
      },
      {
        title: "Title",
        description: "Small bold description",
        descriptionWeight: 600,
        descriptionSize: 14,
      },
      {
        title: "Title",
        description: "Large description",
        descriptionWeight: 400,
        descriptionSize: 16,
      },
      {
        title: "Title",
        description: "Large bold description",
        descriptionWeight: 600,
        descriptionSize: 16,
      },
    ],
  },
  {
    title: "Fills, borders, and padding",
    props: [
      {
        title: "Rounded border",
        borderRadius: "rounded",
      },
      {
        title: "Rounded md border",
        borderRadius: "rounded-md",
      },
      {
        title: "Green fill (border)",
        fillColor: "#D2FDD4",
      },
      {
        title: "Green fill (no border)",
        border: false,
        fillColor: "#D2FDD4",
      },
      {
        title: "Padding 2",
        padding: 2,
      },
      {
        title: "Padding 4",
        padding: 4,
      },
    ],
  },
  {
    title: "Hover states",
    description:
      "Hover states are only active when the card has an onClick handler.",
    props: [
      {
        title: "Hover state when no fill",

        onClick: () => {
          alert("Card was clicked!");
        },
      },
      {
        title: "Hover state with fill",

        fillColor: "#D2FDD4",
        onClick: () => {
          alert("Card was clicked!");
        },
      },
    ],
  },
  {
    title: "Icons",
    props: [
      {
        title: "Icon header",
        icon: "business",
        iconPosition: "top",
        description:
          "The header icon should be brand-coloured, with a light brand frame, and sit above the title, in the header. It should be 20px in size.",
        descriptionSize: 14,
      },
      {
        title: "Icon left",
        titleWeight: 600,
        titleColor: "grey-900",
        icon: "informationCircle",
        iconColor: colors.blue[600],
        iconPosition: "left",
        description:
          "The icon should float to the left of the content and be blue.",
        descriptionSize: 14,
      },
      {
        title: "Icon left, framed",
        icon: "apps",
        iconPosition: "left-framed",
        descriptionSize: 14,
      },
      {
        title: "Icon left, framed, with description",
        icon: "apps",
        iconPosition: "left-framed",
        description:
          "The icon should float to the left of the content and be brand-coloured, with a light brand frame around it.",
        descriptionSize: 14,
      },
      {
        title: "Title icon",
        titleSize: 14,
        icon: "locationOutline",
        iconPosition: "title",
        description:
          "The icon should sit up next to the title, and be grey-600 coloured. It should be 16px in size.",
        descriptionSize: 14,
      },
    ],
  },
  {
    title: "Custom content",
    description:
      'Card exposes several "slots" which can have custom content placed in them.',
    props: [
      {
        title: "Header right slot",
        description: "This card has an icon in the header right slot.",
        icon: "create",
        iconPosition: "top",
        headerRightSlot: (
          <Icon name="checkmarkCircle" color={colors.green[600]} size={25} />
        ),
      },
      {
        description: "This card has a custom multi-column title.",
        title: lessonTitleSlot,
      },
      {
        title: "Left slot",
        description: "This card has an avatar in the left slot.",
        leftSlot: <Avatar size="lg" name="CB" />,
      },
      {
        title: "Body slot",
        description: "This has some custom content below",
        bodySlot: lessonTimesAndPrices,
      },
      {
        title: "Right slot",
        description: "This card has a chip in the right slot.",
        rightSlot: (
          <div className="flex h-full flex-col justify-center">
            <Chip leftIcon="arrowDownOutline" label="20" variant="success" />
          </div>
        ),
      },
      {
        title: "Footer slot",
        description: "This card has a chip in the footer slot.",
        footerSlot: (
          <div className="flex flex-row justify-start">
            <Chip label="Active" variant="success" />
          </div>
        ),
      },
    ],
  },
  {
    title: "Actions",
    description:
      "In the header, cards can have either a single text-based action, or multiple actions in a dropdown. " +
      "They can also have a single text-based action in the footer.",
    props: [
      {
        title: "Single action",
        headerAction: editAction,
      },
      {
        title: lessonTitleSlot,
        description: "Action with custom title slot",
        headerAction: editAction,
      },
      {
        title: "Multiple actions",
        headerActions: actions,
      },
      {
        title: lessonTitleSlot,
        description: "Multiple actions with custom title slot",
        headerActions: actions,
      },
      {
        title: "Actions with custom header right content",
        description:
          "This card has an icon in the header right slot. " +
          "This is probably an unusual combination though.",
        headerRightSlot: (
          <div className="flex h-full">
            <Icon name="checkmarkCircle" color={colors.green[600]} size={25} />
          </div>
        ),
        headerActions: actions,
      },
      {
        title: "Actions with custom right content",
        description:
          "This card has content in the right slot. " +
          "We're not expecting this combination to be used though!",
        rightSlot: (
          <div className="flex h-full flex-col justify-center">
            <Chip leftIcon="arrowDownOutline" label="20" variant="success" />
          </div>
        ),
        headerActions: actions,
      },
      {
        title: "Action in footer (padding 2)",
        padding: 2,
        footerAction: {
          text: "Create new",
          onClick: () => {
            alert("Create new button was clicked!");
          },
        },
      },
      {
        title: "Action in footer (padding 4)",
        padding: 4,
        footerAction: {
          text: "Create new",
          onClick: () => {
            alert("Create new button was clicked!");
          },
        },
      },
    ],
  },
  {
    title: "Visibility",
    description: "Cards can optionally be collapsible.",
    props: [
      {
        title: "Collapsible",
        collapsible: true,
        description: "Some description here",
      },
      {
        title: lessonTitleSlot,
        description: "Collapsible with custom title",
        collapsible: true,
      },
      {
        title: "Collapsible with title icon",
        titleSize: 14,
        titleWeight: 400,
        description: "This has an icon sitting alongside the title",
        collapsible: true,
        icon: "locationOutline",
        iconPosition: "title",
      },
      {
        title: "Collapsible with top icon",
        icon: "business",
        iconPosition: "top",
        description:
          "This is probably not a combination that will ever be used though.",
        collapsible: true,
      },
    ],
  },
];

const WrappedBaseCard = (props: WebBaseCardProps) => {
  return (
    <div className="w-full sm:w-[45%] lg:w-[30%] xl:w-[20%]">
      <BaseCard {...props} />
    </div>
  );
};

export const CardPage = () => {
  return (
    <>
      <DocWrapper title="Card" component={WrappedBaseCard} options={options} />
    </>
  );
};
