/**
 * Build an easily testable structure representing the rows and groups of a form.
 *
 * A 'null' group represents a row not explicitly assigned to a group.
 * Each field exists in a row, and more than one field can be in a row.
 *
 * Example:
 *     fields: ["firstName", "lastName", "dob", "email", "phone", "age"],
 *     groups: [{ heading: "Contact details", fields: ["email", "phone"] }],
 *     rows: [["firstName", "lastName"]]
 *     expected: [
 *         { heading: null, fields: [["firstName", "lastName", "dob"]] },
 *         { heading: "Contact details", fields: [["email", "phone"]] },
 *         { heading: null, fields: [["age"]] },
 *     ],
 */
export function generateFormStructure<
  FieldName extends string,
  Heading extends string,
  Group extends {
    heading: Heading;
    description?: string;
    fields: FieldName[];
    advanced?: boolean;
  },
>(
  fields: FieldName[],
  groups: Array<Group>,
  rows: Array<FieldName[]>,
): Array<{
  heading: Heading | null;
  description?: string;
  fields: FieldName[][];
  advanced?: boolean;
}> {
  const result: Array<{
    heading: Heading | null;
    description?: string;
    fields: FieldName[][];
    advanced?: boolean;
  }> = [];

  const lastGroupIs = (heading: Heading | null) =>
    result[result.length - 1].heading === heading;

  const getGroupForField = (fieldName: FieldName) =>
    groups.find(group => group.fields.includes(fieldName));

  const groupIsInResult = (heading: Heading | null) =>
    result.some(g => g.heading === heading);

  const getLastGroupInstanceIndex = (heading: Heading | null) =>
    result.length -
    1 -
    [...result].reverse().findIndex(g => g.heading === heading);

  const getRowForField = (fieldName: FieldName) =>
    rows.find(row => row.includes(fieldName));

  const getGroupAndRowIndex = (fieldName: FieldName) => {
    const [firstField] = getRowForField(fieldName) ?? [];
    if (!firstField) {
      return;
    }
    const groupIndex = result.findIndex(g =>
      g.fields.flat().includes(firstField),
    );
    if (groupIndex === -1) {
      return;
    }
    const rowIndex = result[groupIndex].fields.findIndex(row =>
      row.includes(firstField),
    );

    return [groupIndex, rowIndex];
  };

  fields.forEach(fieldName => {
    const group = getGroupForField(fieldName);
    const heading = group?.heading ?? null;
    // Find out if we need to add a new group to the result
    if (
      !groupIsInResult(heading) ||
      (heading === null && !lastGroupIs(heading))
    ) {
      result.push({
        ...group,
        heading,
        fields: [],
      });
    }
    // Find out if there is an existing row that this field should be part of
    const existingRow = getGroupAndRowIndex(fieldName);
    if (existingRow) {
      const [groupIndex, rowIndex] = existingRow;
      result[groupIndex].fields[rowIndex].push(fieldName);
    } else {
      result[getLastGroupInstanceIndex(heading)].fields.push([fieldName]);
    }
  });

  // Push 'advanced' groups to bottom
  result.sort((a, b) => {
    if (a?.advanced && !b?.advanced) {
      return 1;
    }
    if (!a?.advanced && b?.advanced) {
      return -1;
    }
    return 0;
  });

  return result;
}
