import { BannerIcon } from "../../../../bannerTypes";
import { colors } from "../../../../colors";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import {
  MobileActionMenuItemProps,
  WebActionMenuItemProps,
} from "../../../interfaces";

export const InviteBanner = ({
  title,
  name,
  email,
  actions,
  icon,
}: {
  title: string;
  name: string;
  email: string;
  actions?: WebActionMenuItemProps[] | MobileActionMenuItemProps[];
  icon: BannerIcon;
}) => {
  const { BaseCard, Icon, View, Text } = useGenericComponents();
  return (
    <BaseCard
      gap={1}
      title={title}
      titleColor={"grey-900"}
      titleSize={16}
      titleWeight={600}
      headerActions={actions}
      fillColor={colors.grey["100"]}
      bodySlot={
        <View className="flex flex-col gap-y-1">
          <Text
            className={"text-label-400 text-grey-900 break-all"}
            truncate={true}>
            {name}
          </Text>
          <Text
            className={"text-label-400 text-grey-900 break-all"}
            truncate={true}>
            {email}
          </Text>
        </View>
      }
      leftSlot={
        <View className="mr-1 flex h-full">
          <Icon name={icon.name} color={icon.color} size={24} />
        </View>
      }
    />
  );
};
