import { StaffPermissionSet } from "@justraviga/classmanager-sdk";

export type InstructorPermissions =
  | "instructor"
  | "attendance:manage"
  | "attendance:view"
  | "customFields:view"
  | "enrolments:view"
  | "members:view"
  | "schedule:view"
  | "waivers:view";

export type ManagerPermissions =
  | InstructorPermissions
  | "manager"
  | "customFields:manage"
  | "discounts:manage"
  | "enrolments:manage"
  | "financial:manage"
  | "financial:view"
  | "members:manage"
  | "messaging:manage"
  | "schedule:manage"
  | "staff:view"
  | "waivers:manage";

export type AdminPermissions =
  | ManagerPermissions
  // TODO: This will become Admin when ready
  | "assistant"
  | "financial:delete"
  | "settings:manage"
  | "staff:manage"
  | "staff:removeAccess"
  | "staff:manageAdminAccess";

export type OwnerPermissions = AdminPermissions | "owner" | "staff:manageOwner";

/**
 * These should be kept in sync with:
 * https://docs.google.com/spreadsheets/d/1YToQyGHhIuJs7GuY5W1LFEMJ3ndbguf4sHfY_tBcm1o/edit?usp=sharing
 */
export type Permission = OwnerPermissions;

type Role = `${StaffPermissionSet}`;

const roleLevels: Role[] = [
  StaffPermissionSet.Instructor,
  StaffPermissionSet.Manager,
  // TODO: This will become Admin when ready
  StaffPermissionSet.Assistant,
  StaffPermissionSet.Owner,
] as const;

export function hasMinimumRole(targetRole: Role, userRole: Role) {
  const userRoleIndex = roleLevels.indexOf(userRole);
  const targetRoleIndex = roleLevels.indexOf(targetRole);
  return userRoleIndex >= targetRoleIndex;
}

/**
 * This is a mapping of permission sets to the minimum level role that have access to them.
 * If a user has a higher role then they will have access to the permissions of the lower roles.
 *
 * We will incrementally remove the specific named permissions and use the role levels instead.
 *
 * Remember to keep this in sync with:
 * https://docs.google.com/spreadsheets/d/1YToQyGHhIuJs7GuY5W1LFEMJ3ndbguf4sHfY_tBcm1o/edit?usp=sharing
 *
 * NOTES:
 *  - 'enrolments' includes trials, waiting lists, make-up lessons
 *  - 'financial:view' includes anything company-performance related, like student counts, enrolment charts, etc
 *  - 'members' includes all directly-related entities, like families, contacts, addresses, custom field responses, etc
 *  - 'schedule' includes seasons, holidays, locations, rooms
 */
export const permissionsToRoles: Record<Permission, Role> = {
  instructor: "instructor",
  manager: "manager",
  // TODO: This will become Admin when ready
  assistant: "assistant",
  owner: "owner",
  "attendance:manage": "instructor",
  "attendance:view": "instructor",
  "customFields:view": "instructor",
  "enrolments:view": "instructor",
  "members:view": "instructor",
  "schedule:view": "instructor",
  "waivers:view": "instructor",
  "customFields:manage": "manager",
  "discounts:manage": "manager",
  "enrolments:manage": "manager",
  "financial:manage": "manager",
  "financial:view": "manager",
  "members:manage": "manager",
  "messaging:manage": "manager",
  "schedule:manage": "manager",
  "staff:view": "manager",
  "waivers:manage": "manager",
  "financial:delete": "assistant",
  "settings:manage": "assistant",
  "staff:manage": "assistant",
  "staff:removeAccess": "assistant",
  "staff:manageAdminAccess": "assistant",
  "staff:manageOwner": "owner",
};
