import { useEffect, useState } from "react";

import {
  AggregateClassDto,
  SeasonDto,
  SortSchema,
} from "@justraviga/classmanager-sdk";

import { getClassSubtitle } from "../../classUtils";
import { getPlatformFunctions } from "../../platformSpecific";
import { useGenericComponents } from "../GenericComponentsProvider";
import {
  SelectComponent,
  SelectItem,
  SelectProps,
  SelectValue,
} from "../interfaces";

export type CourseSelectValue = string | null;

export type CourseOptionsTransform = (
  course: AggregateClassDto,
) => Partial<SelectProps>;

export interface CourseSelectProps {
  Select: SelectComponent;
  error?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange: (value: CourseSelectValue) => void;
  required?: boolean;
  value: CourseSelectValue;
  transformCourseOptions?: CourseOptionsTransform;
  onSeasonSelect?: (season?: SeasonDto) => void;
}

export const CourseSelect = ({
  Select,
  error,
  name,
  onBlur,
  onChange,
  required,
  value,
  transformCourseOptions,
  onSeasonSelect,
}: CourseSelectProps) => {
  const { useApi } = getPlatformFunctions();
  const { View } = useGenericComponents();

  const { data: seasons } = useApi("listSeason", {
    selectAll: true,
    sort: { startAt: SortSchema.Desc },
  });
  const { data: courses } = useApi("listCourse", {});

  const [seasonId, setSeasonId] = useState<string | null>(null);

  // Set the season whenever value changes, or when course data becomes available
  useEffect(() => {
    if (value) {
      const course = courses?.data.find(c => c.entity.id === value);
      if (course) {
        setSeasonId(course.entity.seasonId);
      }
    }
  }, [value, courses?.data]);

  const handleSeasonSelect = (seasonSelectValue: SelectValue) => {
    const seasonId =
      seasonSelectValue === null ? null : String(seasonSelectValue);

    if (seasonId) {
      const season = seasons?.data.find(s => s.id === seasonId);
      if (season) {
        onSeasonSelect?.(season);
      }
    } else {
      onSeasonSelect?.();
    }

    setSeasonId(seasonId);
    onChange(null);
  };

  const seasonOptions =
    seasons?.data.map(season => ({
      value: season.id,
      label: season.name,
    })) ?? [];

  const courseOptions =
    seasons && courses
      ? courses.data
          .filter(c => c.entity.seasonId === seasonId)
          .map(course => {
            const additionalData = transformCourseOptions
              ? transformCourseOptions(course)
              : {};

            return {
              value: course.entity.id,
              label: course.entity.name,
              description: getClassSubtitle(course.entity),
              ...additionalData,
            } as SelectItem;
          })
      : [];

  return (
    <View>
      <View className="pb-5">
        <Select
          data={seasonOptions}
          error={error}
          label={t("label.season")}
          localSearch={true}
          onSelect={handleSeasonSelect}
          required={required}
          value={seasonId}
        />
      </View>
      <View>
        <Select
          name={name}
          data={courseOptions}
          error={error}
          label={"Class"}
          localSearch={true}
          onBlur={onBlur}
          onSelect={v => onChange(v === null ? null : String(v))}
          required={required}
          value={value}
        />
      </View>
    </View>
  );
};
