import { enumLabel, getFullName } from "shared/lib";

import { useApi } from "@/lib/api/apiClient";
import { PreviewSheetContent } from "@/modules/common/sheet/preview/PreviewSheetContent";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";

export const StudentEmergencyContactsSection = ({
  familyId,
}: {
  familyId: string;
}) => {
  const { data: contacts, isLoading } = useApi("listContact", {
    where: {
      familyId: {
        equals: familyId,
      },
      isEmergency: {
        equals: 1,
      },
    },
    selectAll: true,
  });

  if (isLoading) {
    return <LoadingSpinnerDark />;
  }

  const emergencyContacts = contacts?.data ?? [];

  return (
    <PreviewSheetContent.detailCard>
      <PreviewSheetContent.subTitle title="Emergency contacts" />
      <div className={"space-y-6"}>
        {emergencyContacts.length > 0 ? (
          emergencyContacts?.map(contact => (
            <div className={"space-y-2"} key={contact.id}>
              <div className={"flex items-center justify-between space-x-2"}>
                <span className="truncate text-body-400 text-grey-900 ">
                  {getFullName(contact)}
                </span>
                <span className="text-body-400 text-grey-900">
                  {enumLabel("contactRelation", contact.relation)}
                </span>
              </div>
              {contact.phone && (
                <div>
                  <span className={"text-body-400 text-grey-600"}>
                    {contact.phone}
                  </span>
                </div>
              )}
              {contact.email && (
                <div>
                  <span className={"text-body-400 text-grey-600"}>
                    {contact.email}
                  </span>
                </div>
              )}
            </div>
          ))
        ) : (
          <PreviewSheetContent.infoRow text="-" />
        )}
      </div>
    </PreviewSheetContent.detailCard>
  );
};
