import { useEffect, useState } from "react";

import { QuoteResponse } from "@justraviga/classmanager-sdk";

import { showAlert } from "../alertState";
import { useAuthState } from "../auth/useAuthState";
import { getQuoteFn } from "../basket/basketUtils";
import {
  additionalFeesSubTotal,
  getRegistrationFeesSubTotalForQuote,
  quoteHasRegistrationFees,
  quoteHasTrials,
  quoteHasTuitionFees,
  taxTotal,
  trialFeesSubTotal,
  tuitionFeesSubTotal,
} from "../checkoutUtils";
import { useBasket } from "../components/basket/useBasket";
import { moneyFloatToInteger, moneyIntegerToFloat } from "../moneyUtils";
import { getPlatformFunctions } from "../platformSpecific";

type PricingPlan = string;
type SeasonId = string;
export type SelectedPricingPlanIds = Record<SeasonId, PricingPlan>;

export const useCheckoutData = () => {
  const { api } = getPlatformFunctions();
  const { account } = useAuthState();

  const [isLoadingBasket, setIsLoadingBasket] = useState(true);
  const [quote, setQuote] = useState<QuoteResponse | null>(null);

  const { getQuote } = useBasket();

  useEffect(() => {
    getQuote(getQuoteFn(api, account!.entityId!))
      .then(q => {
        setQuote(q);
        setIsLoadingBasket(false);
        return q;
      })
      .catch(e => {
        setIsLoadingBasket(false);
        e.messages?.length > 0 &&
          showAlert({ variant: "error", content: e.messages[0] });
      });
  }, [account, api, getQuote]);

  const hasRegistrationFees =
    (quote && quoteHasRegistrationFees(quote)) || false;

  const registrationFees =
    (quote && getRegistrationFeesSubTotalForQuote(quote)) || 0;

  const hasTuition = (quote && quoteHasTuitionFees(quote)) || false;

  const [selectedPricingPlanIds, setSelectedPricingPlanIds] =
    useState<SelectedPricingPlanIds>({});

  const tuitionFees =
    (quote && tuitionFeesSubTotal(quote, selectedPricingPlanIds)) || 0;

  const allPricingPlansSelected =
    !hasTuition ||
    (quote?.seasons.reduce((a, c) => {
      return (
        a &&
        (c.enabledPricingPlans.length === 1 ||
          selectedPricingPlanIds[c.season.id] !== undefined)
      );
    }, true) ??
      false);

  const setSeasonPricingPlan = (pricingPlanId: string, seasonId: string) => {
    setSelectedPricingPlanIds(orig => {
      return pricingPlanId === orig[seasonId]
        ? orig
        : {
            ...orig,
            [seasonId]: pricingPlanId,
          };
    });
  };

  const hasTrials = (quote && quoteHasTrials(quote)) || false;

  const trialFees = (quote && trialFeesSubTotal(quote)) || 0;

  const additionalFees = (quote && additionalFeesSubTotal(quote)) || 0;

  const tax = (quote && taxTotal(quote, selectedPricingPlanIds)) || 0;

  const payToday = moneyFloatToInteger(
    moneyIntegerToFloat(
      registrationFees + tuitionFees + trialFees + additionalFees + tax,
    ),
  );

  return {
    isLoadingBasket,
    quote,
    hasRegistrationFees,
    registrationFees,
    hasTuition,
    tuitionFees,
    allPricingPlansSelected,
    selectedPricingPlanIds,
    setSeasonPricingPlan,
    hasTrials,
    trialFees,
    additionalFees,
    tax,
    payToday,
  };
};
