import { ReactNode, useEffect, useRef, useState } from "react";

import { Button } from "@/modules/common/ui/button/Button";

export interface UploadedFile {
  file: File;
  src: string;
}

export interface AvatarUploadProps {
  defaultPath?: string | undefined | null;
  preview: (path?: string | null | undefined, isLoading?: boolean) => ReactNode;
  onUpload: (file: File) => Promise<void>;
  onLoading?: (isLoading: boolean) => void;
  onRemove?: () => void;
}

export const AvatarUploader = ({
  defaultPath,
  preview,
  onUpload,
  onLoading,
  onRemove,
}: AvatarUploadProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [selectedFilePath, setSelectedFilePath] = useState<
    string | null | undefined
  >(defaultPath);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onLoading && onLoading(isLoading);
  }, [isLoading, onLoading]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      setSelectedFilePath(URL.createObjectURL(file));

      setIsLoading(true);
      await onUpload(file);
      setIsLoading(false);
    }
  };

  const handleUpload = () => {
    // Trigger file input click when the upload button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemove = () => {
    setSelectedFilePath(undefined);
    onRemove && onRemove();
  };

  return (
    <div className={"flex flex-col"}>
      <div className={"flex items-center py-2"}>
        <div>{preview(selectedFilePath, isLoading)}</div>
        {!isLoading && (
          <div className={"flex items-center space-x-4"}>
            <input
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <Button
              text={selectedFilePath ? "Edit" : "Select photo"}
              onClick={handleUpload}
              variant={"secondary"}
            />
            {selectedFilePath && (
              <Button
                text={"Remove"}
                onClick={handleRemove}
                variant={"destructive-outline"}
              />
            )}
          </div>
        )}
      </div>
      <div className={"py-1"}>
        <p className={"text-caption-400 text-grey-600"}>
          For best results, please upload a square image. We accept PNG and JPG
          format (maximum 8MB).
        </p>
      </div>
    </div>
  );
};
