import { AggregateClassDto, SeasonDto } from "@justraviga/classmanager-sdk";

import { useFamilyCoursePreview } from "../../data/useFamilyCoursePreview";
import { formatDate } from "../../intlFormatter";
import { getSeasonTimeFrame } from "../../seasonUtils";
import { useGenericComponents } from "../GenericComponentsProvider";
import {
  CoursePreviewDescription,
  CoursePreviewLessons,
  CoursePreviewLocationAndRoom,
  CoursePreviewName,
  CoursePreviewRowItem,
  CoursePreviewRowPair,
  CoursePreviewTeacher,
  CoursePreviewTime,
} from "../modules/customer/coursePreview/CoursePreview";

export const FamilyCoursePreview = ({
  course,
  season,
}: {
  course: AggregateClassDto;
  season: SeasonDto;
}) => {
  const { HorizontalSeparator, View } = useGenericComponents();

  const { room, location, staff } = useFamilyCoursePreview(course);

  const { weeksCount } = getSeasonTimeFrame(season);

  return (
    <View className={"pb-10"}>
      <CoursePreviewName course={course.entity} />
      <CoursePreviewTime course={course.entity} isMobile={false} />
      <HorizontalSeparator spacing={4} />
      <CoursePreviewRowItem
        icon={"calendarClearOutline"}
        text={season.name}
        weight={"600"}
        color={"900"}
      />
      <CoursePreviewRowPair
        leftText={"Start date"}
        rightText={formatDate(season.startAt, "dayMonthYear")}
      />
      <CoursePreviewRowPair
        leftText={"End date"}
        rightText={formatDate(season.endAt, "dayMonthYear")}
      />
      <CoursePreviewRowPair
        leftText={"Length"}
        rightText={`${weeksCount} weeks`}
      />
      {location && room && (
        <>
          <HorizontalSeparator spacing={4} />
          <CoursePreviewLocationAndRoom location={location} room={room} />
        </>
      )}
      {course.entity.staffId && (
        <>
          <HorizontalSeparator spacing={4} />
          <CoursePreviewTeacher staff={staff} title={"Teacher"} />
        </>
      )}
      <HorizontalSeparator spacing={4} />
      <CoursePreviewLessons
        classId={course.entity.id}
        iconName={"calendarOutline"}
      />
      <HorizontalSeparator spacing={4} />
      <CoursePreviewDescription
        course={course.entity}
        title={"More information"}
        showIcon={false}
      />
    </View>
  );
};
