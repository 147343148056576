import { ComponentType, memo, ReactNode } from "react";

import { PermissionLayout } from "./PermissionLayout";
import { usePermission } from "./usePermission";
import { Permission } from "../../permission";

export const withPermissionCheck = <P extends object>(
  permission: Permission,
  Component: ComponentType<P>,
  options: {
    renderPermissionError?: (children: ReactNode) => ReactNode;
  } = {},
) => {
  const WrappedComponent = (props: P) => {
    const { hasPermission, isLoading } = usePermission();

    if (isLoading) {
      return null;
    }

    if (!hasPermission(permission)) {
      if (options.renderPermissionError) {
        return options.renderPermissionError(
          <PermissionLayout permission={permission}>
            <></>
          </PermissionLayout>,
        );
      } else {
        return null;
      }
    }

    return <Component {...props} />;
  };

  // Use React memo function to avoid re-rendering the component if its props have not changed.
  // The main reason for this is to help Vite's fast refresh to work, when in development.
  return memo(WrappedComponent);
};
