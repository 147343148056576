import { SeasonDto } from "@justraviga/classmanager-sdk";

import { SeasonPreview as SharedSeasonPreview } from "shared/components";

import { HorizontalSeparator } from "@/modules/common/ui/Separator";

export const SeasonPreview = ({ season }: { season: SeasonDto }) => {
  return (
    <SharedSeasonPreview
      season={season}
      HorizontalSeparator={HorizontalSeparator}
    />
  );
};
