import { match } from "ts-pattern";

import { TransactionTileIcon } from "./TransactionTileIcon";
import { cn } from "../../cssUtils";
import { formatDate } from "../../intlFormatter";
import { AggregateTransactionWithPrimaryType } from "../../transactionUtils";
import { useGenericComponents } from "../GenericComponentsProvider";

interface TransactionDescriptionAndIconProps {
  transaction: AggregateTransactionWithPrimaryType;
  showFamilyName?: boolean;
}

export const TransactionDescriptionAndIcon = ({
  transaction,
  showFamilyName = true,
}: TransactionDescriptionAndIconProps) => {
  const { Text, View } = useGenericComponents();

  const appendText = match(transaction.primaryType)
    .with("paymentFailed", () => " • Failed payment")
    .with("paymentFailedReversal", () => " • Reversal (failed payment)")
    .with("paymentPending", () => " • Pending payment")
    .otherwise(() => "");

  const description =
    (showFamilyName
      ? transaction.transaction.familyName
      : formatDate(transaction.transaction.date, "dayMonthYear")) +
    " " +
    appendText;

  return (
    <View className={"flex flex-1 flex-row items-center space-x-3"}>
      <TransactionTileIcon transaction={transaction} />
      <View className={"flex-1 space-y-1"}>
        <Text
          className={"text-body-600 text-grey-900 break-all"}
          truncate={true}>
          {transaction.transaction.description}
        </Text>

        <View className="pr-2">
          <Text
            truncate={true}
            className={cn(
              "text-label-400 break-all",
              transaction.primaryType === "paymentFailed"
                ? "text-red-600"
                : "text-grey-600",
            )}>
            {description}
          </Text>
        </View>
      </View>
    </View>
  );
};
