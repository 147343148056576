import { PricingPlanType } from "@justraviga/classmanager-sdk/dist/models/PricingPlanType";

import { IconName } from "./availableIcons";

export const pricingPlanIcons: Record<PricingPlanType, IconName> = {
  [PricingPlanType.Monthly]: "calendarNumberOutline",
  [PricingPlanType.OneOff]: "todayOutline",
};

export const pricingPlanLabels: Record<PricingPlanType, string> = {
  [PricingPlanType.Monthly]: "Pay monthly",
  [PricingPlanType.OneOff]: "Pay upfront",
};
