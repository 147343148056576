import { IdPrefix } from "@justraviga/classmanager-sdk";
import { idPrefix } from "@justraviga/classmanager-sdk/dist/models/IdPrefix";
import { Query } from "@tanstack/react-query";

import {
  AnyEntity,
  CacheDependencyMap,
  Collection,
  CollectionCacheEntry,
  Entity,
  EntityCacheEntry,
  Id,
  MultiEntityResponse,
} from "./cacheTypes";
import { sdkCache } from "./sdkCache";
import { removeDomainFromURL } from "../stringUtils";
import { queryClient } from "../tanstackQuery";

export type DefaultRequest = () => Promise<{
  body: object | null;
  status: number;
}>;

const entityIdRegex = /[a-z]+_[0-9a-z]+$/;
const singleEntityEndpointRegex = /\/[a-z]+_[0-9a-z]+$/;

type UrlWithId = `${string}/${Id}`;

const makeResponse = (body: null | object, status = 200) => {
  const responseBody = status === 204 ? null : JSON.stringify(body);
  return new Response(responseBody, {
    status,
    headers: { "Content-Type": "application/json" },
  });
};

/**
 * Some of the objects returned by the API are "aggregate" objects, where the ID for the record
 * actually is located at `entity.id`. If the given object looks like an aggregate, then this
 * function will copy the ID from the `entity` prop to the `id` prop.
 * This function should handle both single entity and collection responses.
 */
export const makeAggregatesLookLikeEntities = <T>(record: T): T => {
  if (typeof record === "object" && record !== null) {
    if ("id" in record) {
      // Don't modify records that already have an ID
      return record;
    }
    if ("aggregateId" in record) {
      const { aggregateId } = record;
      if (typeof aggregateId === "string") {
        return { ...record, id: aggregateId };
      }
    } else if ("data" in record) {
      const { data } = record;
      if (Array.isArray(data)) {
        return {
          ...record,
          data: data.map(makeAggregatesLookLikeEntities),
        };
      }
    }
  }
  return record;
};

const looksLikeId = (id: unknown): id is Id =>
  typeof id === "string" && entityIdRegex.test(id);

const looksLikeSingleEntityUrl = (url: string): url is UrlWithId =>
  singleEntityEndpointRegex.test(url);

/**
 * Check if a parsed JSON response looks like a collection of entities
 * Typically, this will have a 'data' prop, which is an array containing objects
 * and optionally, a 'pagination' prop.
 */
const looksLikeCollectionResponse = (
  response: unknown,
): response is Collection => {
  if (typeof response === "object" && response !== null && "data" in response) {
    const { data } = response;
    return (
      Array.isArray(data) &&
      data.length > 0 &&
      data.every(e => looksLikeId(e.id))
    );
  }
  return false;
};

/**
 * Check if a parsed JSON response looks like a single entity
 * Typically, this will have a 'id' prop, which matches our entity ID regex
 */
const looksLikeSingleEntityResponse = (
  response: unknown,
): response is Entity => {
  if (typeof response === "object" && response !== null && "id" in response) {
    return looksLikeId(response.id);
  }
  return false;
};

/**
 * Check if a parsed JSON response looks like a single entity
 * Typically, this will have a 'id' prop, which matches our entity ID regex
 */
const looksLikeMultiEntityResponse = (
  response: unknown,
): response is MultiEntityResponse =>
  Array.isArray(response) &&
  response.length > 0 &&
  response.every(e => looksLikeSingleEntityResponse(e));

/**
 * Convert our typical API 'list' responses into a format that we can cache.
 * This will replace the 'data' array with an array of IDs.
 */
const collectionResponseToCache = (
  expiresAt: Date,
  responseBody: {
    data: Array<{ id: Id }>;
  },
): CollectionCacheEntry => {
  const { data, ...rest } = responseBody;
  return {
    expiresAt,
    data: {
      ...rest,
      ids: data.map(e => e.id),
    },
  };
};

/**
 * This function is purely for developer experience, when logging and debugging, to avoid them thinking that
 * our aggregates actually have a top level id property. It means that what we push into the cache matches the
 * same shape as the API returns.
 */
const removeTemporaryTopLevelIdProperty = (entity: Entity): AnyEntity => {
  if ("aggregateId" in entity) {
    const { id, aggregateId, ...rest } = entity;
    if (id && looksLikeId(aggregateId)) {
      return { aggregateId, ...rest };
    }
  }
  return entity;
};

/**
 * Convert our typical API 'detail' responses into a format that we can cache.
 */
const entityResponseToCache = (
  expiresAt: Date,
  entity: Entity,
): EntityCacheEntry => {
  return {
    expiresAt,
    data: removeTemporaryTopLevelIdProperty(entity),
  };
};

const idFromUrl = (url: UrlWithId) => {
  return url.split("/").pop() as Id;
};

const idFromSingleEntityResponse = ({ id }: Entity): Id => id;

export const getIdPrefixFromResponse = (url: string, body: object | null) => {
  const id = looksLikeSingleEntityUrl(url)
    ? idFromUrl(url)
    : looksLikeSingleEntityResponse(body)
      ? idFromSingleEntityResponse(body)
      : looksLikeCollectionResponse(body) && body.data.length > 0
        ? idFromSingleEntityResponse(body.data[0])
        : looksLikeMultiEntityResponse(body) && body.length > 0
          ? idFromSingleEntityResponse(body[0])
          : undefined;
  return id ? (id.split("_")[0] as IdPrefix) : undefined;
};

interface ApplyCachingOptions {
  cacheDependencyMap: CacheDependencyMap;
  cacheDurationInMs: number;
  defaultRequest: DefaultRequest;
  method: string;
  url: string;
}

export const applyCaching = async ({
  cacheDependencyMap,
  cacheDurationInMs,
  defaultRequest,
  method,
  url,
}: ApplyCachingOptions) => {
  sdkCache.deleteExpiredCacheEntries();

  const collectionCacheKey = removeDomainFromURL(url);
  const expiresAt = new Date(Date.now() + cacheDurationInMs);

  // If the URL ends in a prefixed ID string, then we're looking for a single entity

  // Check cache first, for GET requests, and early return if found
  if (method === "GET") {
    // Check the cache first
    if (looksLikeSingleEntityUrl(url)) {
      const cached = sdkCache.getEntity(idFromUrl(url));
      if (cached) {
        return Promise.resolve(makeResponse(cached.data));
      }
    } else {
      // This could be a collection
      const cached = sdkCache.getCollection(collectionCacheKey);
      if (cached) {
        const { ids, ...otherProps } = cached.data;
        // Check that none of the related entity entries are missing (maybe already expired and cleared, etc)
        if (ids.some(id => !sdkCache.getEntity(id))) {
          // If any are missing, then clear the collection cache and continue
          sdkCache.deleteCollection(collectionCacheKey);
        } else {
          const responseBody = {
            ...otherProps,
            data: ids.map(id => sdkCache.getEntity(id)!.data),
          };
          return Promise.resolve(makeResponse(responseBody));
        }
      }
    }
  }

  // No cache hit, so make the request
  const { body: rawBody, status } = await defaultRequest();
  const body = makeAggregatesLookLikeEntities(rawBody);

  const primaryPrefix = getIdPrefixFromResponse(url, body);
  clearAppropriateCaches(method, url, primaryPrefix, cacheDependencyMap);

  if (method === "GET" && body && status === 200) {
    if (looksLikeSingleEntityResponse(body)) {
      sdkCache.setEntity(
        idFromSingleEntityResponse(body),
        entityResponseToCache(expiresAt, body),
      );
    } else if (looksLikeCollectionResponse(body)) {
      const cacheData = collectionResponseToCache(expiresAt, body);
      sdkCache.setCollection(collectionCacheKey, cacheData);
      body.data.forEach(entity => {
        sdkCache.setEntity(entity.id, entityResponseToCache(expiresAt, entity));
      });
    }
  } else if (["POST", "PATCH", "PUT", "DELETE"].includes(method)) {
    // Cache any obvious single or multi entity responses
    if (looksLikeSingleEntityResponse(body)) {
      sdkCache.setEntity(body.id, entityResponseToCache(expiresAt, body));
    } else if (looksLikeMultiEntityResponse(body)) {
      body.forEach(entity => {
        sdkCache.setEntity(entity.id, entityResponseToCache(expiresAt, entity));
      });
    }
  }

  // Return a Response object for the SDK to use
  return makeResponse(rawBody, status);
};

const isIdPrefix = (string: string): string is IdPrefix => {
  return Object.values(idPrefix).includes(string as IdPrefix);
};

export const getAllIdPrefixesFromUrl = (url: string): IdPrefix[] => {
  return url
    .split("/")
    .filter(part => part.includes("_"))
    .map(part => part.split("_")[0])
    .filter(isIdPrefix);
};

export const clearAppropriateCaches = (
  method: string,
  url: string,
  primaryPrefix: IdPrefix | undefined,
  cacheDependencyMap: CacheDependencyMap,
) => {
  if (method === "GET") {
    // Do nothing; GET requests should never mutate data
    return;
  }
  if (
    method === "DELETE" ||
    url.endsWith("/archive") ||
    url.endsWith("/unarchive") ||
    url.endsWith("/restore")
  ) {
    // Always clear everything
    sdkCache.clear();
  } else {
    // Only clear the cache for the primary entity (if detected), and any other entities that are referenced
    // in the URL (e.g. POST /v1/foo/fam_123/bar/stf_123 - would clear both families and staff)
    const idPrefixes = getAllIdPrefixesFromUrl(url);
    if (primaryPrefix) {
      idPrefixes.push(primaryPrefix);
    }
    idPrefixes.forEach(prefix =>
      sdkCache.clearByPrefix(prefix, cacheDependencyMap),
    );
  }

  clearTanstackCaches(method, url);
};

const whereKeyMatchesId = (id: string) => (q: Query) =>
  Array.isArray(q.queryKey) && q.queryKey[1]?.id === id;

const clearTanstackCaches = (method: string, url: string) => {
  // Clear Tanstack Query's cache (re-fetch all active queries, and mark all inactive ones as stale)
  // *except* where this is a DELETE of a single entity, in which case we want to invalidate but *not* refetch.
  // This avoids 404 issues, where queries may still be active for the item we've just deleted.
  const id = looksLikeSingleEntityUrl(url) ? idFromUrl(url) : null;
  if (method === "DELETE" && id) {
    queryClient.invalidateQueries({
      predicate: whereKeyMatchesId(id),
      refetchType: "none",
    });
    queryClient.invalidateQueries({
      predicate: q => !whereKeyMatchesId(id)(q),
    });
  } else {
    // Re-fetch all active queries
    queryClient.refetchQueries({
      type: "active",
    });

    // Remove all inactive queries so that they are re-fetched next time they are needed.
    // The main reason for doing this is so that we don't have to worry about stale data being used to populate forms,
    // which don't have the ability to update their default values once the form is rendered.
    queryClient.removeQueries({
      type: "inactive",
    });
  }
};
