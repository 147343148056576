import { ReactNode, useEffect } from "react";

import { useWatch } from "react-hook-form";

import { useFormActions } from "./FormActionsProvider";
import { FormBuilder, FormDefinition } from "../forms/formBuilderTypes";

export type GenericFormProps<
  Dto extends object,
  FormSchema extends object,
  SubmitResponse extends object,
> = {
  apiRequest: (formData: FormSchema) => Promise<SubmitResponse>;
  defaultValues: Partial<Dto> | Partial<FormSchema>;
  formDefinitionHook: () => FormDefinition<FormSchema>;
  onSuccess: (record: SubmitResponse) => void;
  onChange?: (formData: FormSchema) => void;
};

export type GenericFormComponent = <
  Dto extends object,
  FormSchema extends object,
  SubmitResponse extends object,
>({
  apiRequest,
  formDefinitionHook,
  defaultValues,
  onSuccess,
}: {
  apiRequest: (formData: FormSchema) => Promise<SubmitResponse>;
  defaultValues: Partial<Dto> | Partial<FormSchema>;
  formDefinitionHook: () => FormDefinition<FormSchema>;
  onSuccess: (record: SubmitResponse) => void;
  onChange?: (formData: FormSchema) => void;
}) => ReactNode;

export const useGenericForm = <Item extends object, FormSchema extends object>({
  apiRequest,
  defaultValues,
  formDefinition,
  onSuccess,
  useFormBuilder,
  onChange,
}: {
  apiRequest: (formData: FormSchema) => Promise<Item>;
  defaultValues: object;
  formDefinition: FormDefinition<FormSchema>;
  onSuccess?: (entity: Item) => void;
  useFormBuilder: FormBuilder;
  onChange?: (formData: FormSchema) => void;
}) => {
  const { setSubmit, setIsLoading, resetAfterSubmit } = useFormActions();

  const save = (data: FormSchema) => {
    setIsLoading(true);
    apiRequest(data)
      .then(entity => {
        resetAfterSubmit && reset();
        if (onSuccess) {
          onSuccess(entity);
        }
      })
      .catch(displayApiValidationErrors)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const { form, formHandlers, displayApiValidationErrors } = useFormBuilder(
    formDefinition,
    save,
    defaultValues,
  );

  const { reset, handleSubmit, control } = formHandlers;

  const changedData = useWatch({
    control,
  });

  const formChange = formDefinition.onChange;
  useEffect(() => {
    if (onChange) {
      onChange(changedData as FormSchema);
    }
    formChange(changedData as FormSchema, formHandlers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedData, onChange, formChange]);

  const submit = handleSubmit(save);
  setSubmit(submit);

  return form;
};
