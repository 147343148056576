import { IconName } from "../../availableIcons";
import { colors } from "../../colors";
import { cn } from "../../cssUtils";
import { useGenericComponents } from "../GenericComponentsProvider";

type QuickActionButtonVariant =
  | "brand"
  | "secondary"
  | "default"
  | "secondary-fill";

export interface QuickActionButtonProps {
  icon: IconName;
  text?: string;
  onClick: () => void;
  variant: QuickActionButtonVariant;
  forceTextWrap?: boolean;
  size?: "sm" | "md";
}

interface Variant {
  buttonStyle: string;
  textColor: string;
}

const variantConfig: Record<QuickActionButtonVariant, Variant> = {
  brand: {
    buttonStyle: "bg-brand-800 hover:bg-brand-700",
    textColor: colors.white,
  },
  secondary: {
    buttonStyle:
      "bg-white border border-grey-400 hover:bg-grey-100 border-grey-400",
    textColor: colors.grey[900],
  },
  default: {
    buttonStyle: "bg-grey-200 hover:bg-grey-300",
    textColor: colors.grey[900],
  },
  "secondary-fill": {
    buttonStyle:
      "bg-grey-200 enabled:hover:bg-grey-300 border border-grey-400 border-grey-400",
    textColor: colors.black,
  },
};

export const QuickActionButton = ({
  icon,
  text,
  onClick,
  variant,
  forceTextWrap = false,
  size = "md",
}: QuickActionButtonProps) => {
  const { View, Icon, Pressable, Text } = useGenericComponents();

  const { buttonStyle, textColor } = variantConfig[variant];

  return (
    <Pressable onClick={onClick} className="cursor-pointer">
      <View
        className={cn("flex flex-col items-center justify-center", {
          "w-20": forceTextWrap,
        })}>
        <View
          className={cn(
            "rounded-full flex items-center justify-center mb-2 mx-auto",
            buttonStyle,
            {
              "w-12 h-12": size === "md",
              "w-10 h-10": size === "sm",
            },
          )}>
          <Icon name={icon} size={24} color={textColor} />
        </View>
        {text && (
          <View className="w-full">
            <Text className="text-grey-900 text-label-400 text-center hover:text-grey-700">
              {text}
            </Text>
          </View>
        )}
      </View>
    </Pressable>
  );
};
