import { WebActionMenuItemProps } from "@shared/components/interfaces/actionMenu";
import { DatatableAdditionalAction } from "@shared/datatable/datatableTypes";

/**
 * Turn an instance of DatatableAdditionalAction into a WebActionMenuItemProps
 *
 * This is mostly useful for sharing definitions of actions in a datatable configuration
 * with the web-specific action menu.
 */
export const datatableAdditionalActionToWebActionMenuItem = (
  additionalAction: DatatableAdditionalAction,
): WebActionMenuItemProps => {
  const { items, ...rest } = additionalAction;
  return {
    ...rest,
    ...(items && {
      group: {
        items: items.map(subItem => ({
          ...subItem,
          leftIcon: subItem.icon,
        })),
      },
    }),
  };
};
