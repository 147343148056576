import {
  CreateStaffRequest,
  StaffDto,
  StaffPermissionSet,
  UpdateStaffRequest,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { enumLabel } from "../../translateUtils";
import { useAuthState } from "../AuthStateProvider";
import { useGenericComponents } from "../GenericComponentsProvider";

type CreateSchema = CreateStaffRequest;
type UpdateSchema = UpdateStaffRequest;
type Schema = CreateSchema & UpdateSchema;

const getPermissionSetData = (isSameUser: boolean) => {
  return Object.values(StaffPermissionSet)
    .filter(value => isSameUser || value !== "owner")
    .map(value => ({
      label: enumLabel("permissionSet", value),
      value,
    }));
};

export const useStaffFormDefinition = () => {
  const { account } = useAuthState();
  const { Banner } = useGenericComponents();

  return (staff?: StaffDto) => {
    const isSameUser = account?.entityId === staff?.id;
    const isOwner = staff?.permissionSet === "owner";

    const builder = new FormDefinitionBuilder<Schema>()
      .group("Basic information", [
        "firstname",
        "lastname",
        "email",
        "phone",
        "permissionSet",
      ])
      .text("firstname", { label: "First name", required: true })
      .text("lastname", { label: "Last name", required: true })
      .text("email", {
        label: "Email",
        type: "email",
      })
      .text("phone", {
        label: "Phone number",
        type: "tel",
      });

    if (isOwner && !isSameUser) {
      builder.customBlock(
        "after",
        "phone",
        <Banner variant="info" content="You cannot change an owner's role" />,
      );
    }

    builder
      .conditional(["permissionSet"], ["permissionSet"], () => {
        return !isOwner || isSameUser;
      })
      .select("permissionSet", {
        label: "Role",
        disabled: isSameUser,
        description: isSameUser ? "You cannot change your own role" : undefined,
        data: getPermissionSetData(isSameUser),
      });

    return builder.getDefinition() as FormDefinition<Schema>;
  };
};

export const makeStaffCreateRequest =
  ({ api }: { api: Api }) =>
  (formData: CreateSchema) => {
    return api.staff.createStaff({
      createStaffRequest: formData,
    });
  };

export const makeStaffUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateSchema) => {
    return api.staff.updateStaff({
      id,
      updateStaffRequest: formData,
    });
  };
