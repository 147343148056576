import { useCatalogueContext } from "@shared/components/catalogue/useCatalogueContext";

import { CatalogueMinimalLayout } from "@/modules/auth/catalogue/CatalogueMinimalLayout";
import { CreateAccountForm } from "@/modules/auth/catalogue/CreateAccountForm";
import { AuthPageCard } from "@/modules/auth/common/AuthPageCard";
import { LoginHeader } from "@/modules/auth/common/LoginHeader";
import { PoweredBy } from "@/modules/auth/common/PoweredBy";

export const CatalogueCreateAccountPage = ({
  goBack,
}: {
  goBack?: () => void;
}) => {
  const { company } = useCatalogueContext();

  return (
    <CatalogueMinimalLayout>
      <AuthPageCard
        header={
          <LoginHeader companyLogo={company.logo} handleBackButton={goBack} />
        }
        title={company.name}
        subtitle={`Provide your details for ${company.name}`}
        content={<CreateAccountForm company={company} />}
        footer={<PoweredBy />}
      />
    </CatalogueMinimalLayout>
  );
};
