import {
  UpdateCompanyAddressRequest,
  UserAccountDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../../api";
import { AuthContextInterface } from "../../../authState";
import { FormDefinition } from "../../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";

export const useCompanyAddressFormDefiniton = () => {
  const form = new FormDefinitionBuilder<UpdateCompanyAddressRequest>()
    .row(["state", "zip"])
    .text("address1", { label: "Address line 1" })
    .text("address2", { label: "Address line 2" })
    .text("town", { label: "Town/city" })
    .text("state", { label: "State" })
    .text("zip", { label: "Zip no." });

  return form.getDefinition() as FormDefinition<UpdateCompanyAddressRequest>;
};

export const makeCompanyAddressUpdateRequest =
  ({
    api,
    setAccount,
    account,
  }: {
    api: Api;
    setAccount: AuthContextInterface["setAccount"];
    account: UserAccountDto;
  }) =>
  async (formData: UpdateCompanyAddressRequest) => {
    const company = await api.companies.updateCompanyAddress({
      id: account?.company?.id || "",
      updateCompanyAddressRequest: formData,
    });
    setAccount({
      ...account,
      company,
    });
    return company;
  };

export const makeCompanyAddressDeleteRequest = async ({
  api,
  setAccount,
  account,
}: {
  api: Api;
  setAccount: AuthContextInterface["setAccount"];
  account: UserAccountDto;
}) => {
  await api.companies.companyAddressDelete({
    id: account.company?.id || "",
  });
  setAccount({
    ...account,
    company: {
      ...account.company!,
      address: null,
    },
  });
};
