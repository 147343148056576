import { AggregateClassDto } from "@justraviga/classmanager-sdk";

import { useGenericComponents } from "../../../GenericComponentsProvider";

export interface TooManyStudentsBannerProps {
  course: AggregateClassDto;
}
export const TooManyStudentsBanner = ({
  course,
}: TooManyStudentsBannerProps) => {
  const { Banner } = useGenericComponents();
  return (
    <Banner
      className={"text-label-400 text-grey-900"}
      content={`${course.entity.name} has ${course.entity.capacity - (course.stats.enrolments.currentCount + course.stats.enrolments.upcomingCount + course.stats.waitingLists.totalCount)} spaces remaining.`}
      variant={"error"}
    />
  );
};
