import { RegistrationFeeQuote } from "@justraviga/classmanager-sdk";

import { CheckoutItem } from "./CheckoutItem";
import { CheckoutSection } from "./CheckoutSection";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const RegistrationFeesSummary = ({
  registrationFees,
}: {
  registrationFees: RegistrationFeeQuote[];
}) => {
  const { View } = useGenericComponents();
  return (
    <CheckoutSection heading={"Registration fees"} spacing={"tight"}>
      {registrationFees.map((registrationFee, index) => (
        <View key={index}>
          <CheckoutItem
            text={registrationFee.name}
            amount={registrationFee.subTotal}
          />
        </View>
      ))}
    </CheckoutSection>
  );
};
