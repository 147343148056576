import { PropsWithChildren, useEffect } from "react";

import { PublicCompanyDto } from "@justraviga/classmanager-sdk";

import { useAuthState } from "@shared/auth/useAuthState";

import { useApi } from "@/lib/api/apiClient";
import { Router } from "@/routing/router";

/**
 * Redirect back to company portal, if they have a staff account, but not a family account.
 * We're planning to replace this with an appropriate message on the create account page, explaining to the
 * staff member that they need to create a family account to continue.
 */
export const TemporaryStaffRedirect = ({
  children,
  company,
}: PropsWithChildren<{
  company?: PublicCompanyDto;
}>) => {
  const { user } = useAuthState();

  const { data: accounts, isLoading: accountsLoading } = useApi(
    "listAccounts",
    {},
    { enabled: !!user },
  );

  useEffect(() => {
    if (!company?.id || !accounts) {
      return;
    }
    const companyAccounts = accounts.data.filter(
      a => a.company?.id === company.id,
    );
    const familyAccount = companyAccounts.find(a => a.role === "family");
    const staffAccount = companyAccounts.find(a => a.role === "staff");

    if (staffAccount && !familyAccount) {
      Router.push("CompanyHome");
    }
  }, [company?.id, accounts]);

  if (accountsLoading) {
    return null;
  }

  return children;
};
