import {
  UpdateCompanySettingsRequest,
  UserAccountDto,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../../api";
import { AuthContextInterface } from "../../../authState";
import { FormDefinitionBuilder } from "../../../forms/formDefinitionBuilder";

type UpdateTrialsSchema = Required<UpdateCompanySettingsRequest>["trial"];

export const useTrialSettingsFormDefinition = () => {
  return new FormDefinitionBuilder<UpdateTrialsSchema>()
    .switch("enableOnPortal", {
      label: "Family Portal trial registration",
      description:
        "Let your members sign up for trials through the Family Portal.",
    })
    .money("price", {
      label: "Price",
      required: true,
      description: "Set price as 0 if trials are free",
    })
    .switch("limitedCapacity", {
      label: "Limit trial capacity",
      description:
        "Set a limit for the number of trial students that each class can accommodate.",
    })
    .integer("maxCapacityPerLesson", {
      label: "Trial capacity",
      required: true,
    })
    .conditional(["maxCapacityPerLesson"], ["limitedCapacity"], v =>
      Boolean(v.limitedCapacity),
    )
    .getDefinition();
};

export const makeTrialSettingsFormRequest =
  ({
    api,
    setAccount,
    account,
  }: {
    api: Api;
    setAccount: AuthContextInterface["setAccount"];
    account: UserAccountDto;
  }) =>
  async (formData: UpdateTrialsSchema) =>
    api.companies
      .updateCompanySettings({
        updateCompanySettingsRequest: {
          trial: {
            ...formData,
            price: formData.price ?? undefined,
          },
        },
      })
      .then(company => {
        setAccount({
          ...account,
          company,
        });
        return company;
      });
