import {
  Breadcrumb,
  useClassTrialsDatatable,
  useEnrolmentsActions,
  useTrialActions,
} from "shared/components";
import { useBreakpoint } from "shared/lib";

import { api, useApi } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";
import {
  CompanyBreadCrumbs,
  CompanyContent,
  CompanyLayout,
  CompanyTitleBar,
  ResponsiveCompanyTitleBarTitle,
} from "@/modules/company/CompanyLayout";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const ClassTrialsPage = ({ classId }: { classId: string }) => {
  const { md: isDesktop } = useBreakpoint();
  const actions = useTrialActions();
  const enrolmentActions = useEnrolmentsActions();

  const { data: course } = useApi("getCourse", { id: classId });
  const { data: season } = useApi(
    "getSeason",
    { id: course?.entity.seasonId ?? "" },
    {
      enabled: !!course,
    },
  );

  const { datatable } = useClassTrialsDatatable({
    Datatable,
    api,
    classId,
    showCreateForm: isDesktop
      ? () => actions.showCreateForm({ classId })
      : undefined,
    goToStudent: id => Router.push("StudentDetails", { id }),
    showUpdateForm: item => actions.showUpdateForm(item),
    enrollForClass: item =>
      enrolmentActions.showCreateForm({
        courseId: item.classId,
        studentIds: [item.studentId],
      }),
    markAsNotContinuing: actions.archive,
    deleteOne: actions.deleteOne,
  });

  if (!course || !season) {
    return <LoadingPlaceholder />;
  }

  const breadcrumbs: Breadcrumb[] = [
    {
      text: t("breadcrumb.seasons"),
      onClick: () => Router.push("SeasonList"),
    },
    {
      text: season.name,
      onClick: () => {
        Router.push("SeasonDetails", { id: course.entity.seasonId });
      },
    },
    {
      text: course.entity.name,
      onClick: () => Router.push("ClassDetails", { id: course.entity.id }),
    },
    {
      text: "Trials",
    },
  ];

  return (
    <CompanyLayout alwaysShowGlobalHeader={false} datatablePage>
      <CompanyBreadCrumbs crumbs={breadcrumbs} />
      <CompanyTitleBar isTopLevel={true}>
        <ResponsiveCompanyTitleBarTitle title={"Trials"} />
        {!isDesktop && (
          <div>
            <ProtectedOverlay permission={"enrolments:manage"}>
              <IconButton
                icon={"addOutline"}
                border={"rounded"}
                onClick={() => actions.showCreateForm({ classId })}
                variant={"secondary-fill"}
              />
            </ProtectedOverlay>
          </div>
        )}
      </CompanyTitleBar>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "enrolments:view",
  ClassTrialsPage,
  "Class trials",
);

export { PermissionChecked as ClassTrialsPage };
