import { colors } from "shared/lib";

import { Banner } from "@/modules/common/ui/Banner";

export const SeasonPricingListCardWarning = () => {
  return (
    <div>
      <Banner
        icon={{ name: "warning", color: colors.white }}
        variant={"important"}
        content={
          <p className={"text-label-400"}>
            Update your pricing list before your{" "}
            {t("entity.season", { count: 1 })} goes live.
          </p>
        }
      />
    </div>
  );
};
