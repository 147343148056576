import { idPrefix } from "@justraviga/classmanager-sdk";

import { CacheDependencyMap } from "./cacheTypes";

/**
 * Our cache dependency map is used to determine what *other* entities should be cleared when a mutation occurs.
 * For example, if a trial is modified, then all courses should be also be cleared from the cache.
 */
export const cacheDependencyMap: CacheDependencyMap = {
  [idPrefix.Enrolment]: [idPrefix.Course, idPrefix.Trial, idPrefix.WaitingList],
  [idPrefix.EnrolmentAdjustment]: [idPrefix.Enrolment],
  [idPrefix.File]: [
    idPrefix.Staff,
    idPrefix.Student,
    idPrefix.Course,
    idPrefix.Family,
  ],
  [idPrefix.Season]: [idPrefix.PricingSchemeValue],
  [idPrefix.Trial]: [idPrefix.Course],
  [idPrefix.WaitingList]: [idPrefix.Course],
} as const;
