import { PropsWithChildren, useEffect, useState } from "react";

import {
  AggregateTransactionDto,
  CheckoutOperationRequest,
} from "@justraviga/classmanager-sdk";

import { BasketContext } from "./basketContext";
import { useAuthState } from "../../auth/useAuthState";
import {
  Basket,
  BasketContents,
  BasketStorageAdaptor,
  makeEmptyBasketContents,
  TrialKey,
} from "../../basket/basket";
import { GetQuoteFn } from "../../basket/basketUtils";
import { useCatalogueContext } from "../catalogue/useCatalogueContext";

export const BasketProvider = ({
  children,
  adaptor,
}: PropsWithChildren<{ adaptor: BasketStorageAdaptor }>) => {
  const [basket, setBasket] = useState<Basket>();
  const [contents, setContents] = useState<BasketContents>(
    basket?.getContents() ?? makeEmptyBasketContents(),
  );

  const { company } = useCatalogueContext();
  const { user } = useAuthState();

  // If company changes, create a new basket
  useEffect(() => {
    Basket.create(adaptor, company.id).then(b => {
      setBasket(b);
      setContents(b.getContents());
    });
  }, [adaptor, company.id]);

  // If a user logs out, clear all basket data, regardless of company.
  // This avoids any issues on shared computers, where a user might have built up baskets for more than one
  // company, then they log out and someone else logs in, and also tries booking for one of those companies.
  useEffect(() => {
    if (!user && basket) {
      basket.clearAllBaskets();
      setContents(basket.getContents());
    }
  }, [basket, user]);

  if (!basket) {
    return null;
  }

  const updateContents = () => {
    setContents({ ...basket.getContents() });
  };

  // Wrap all mutating basket methods so that we can trigger re-renders when changes in state occur
  // Non-mutating methods can come directly from the Basket class
  const { hasEnrolment, hasTrial } = basket;

  const basketMethods: BasketContext = {
    addEnrolment: (courseId: string) => {
      basket.addEnrolment(courseId);
      updateContents();
    },
    addStudentToEnrolment: (courseId: string, studentId: string) => {
      basket.addStudentToEnrolment(courseId, studentId);
      updateContents();
    },
    clearStudentsFromEnrolment: (courseId: string) => {
      basket.clearStudentsFromEnrolment(courseId);
      updateContents();
    },
    addStudentToTrial: (trial, studentId) => {
      basket.addStudentToTrial(trial, studentId);
      updateContents();
    },
    clearStudentsFromTrial: (trialId: TrialKey) => {
      basket.clearStudentsFromTrial(trialId);
      updateContents();
    },
    addTrial: trial => {
      basket.addTrial(trial);
      updateContents();
    },
    clear: () => {
      basket.clear();
      updateContents();
    },
    clearAllBaskets: () => {
      basket.clearAllBaskets();
      updateContents();
    },
    contents,
    count: basket.getCount(),
    hasEnrolment: hasEnrolment.bind(basket),
    hasTrial: hasTrial.bind(basket),
    removeEnrolment: courseId => {
      basket.removeEnrolment(courseId);
      updateContents();
    },
    removeTrial: trial => {
      basket.removeTrial(trial);
      updateContents();
    },
    getQuote: (getQuoteFn: GetQuoteFn) => basket.getQuote(getQuoteFn),
    getSuccessfulTransaction: () => basket.getSuccessfulTransaction(),
    setSuccessfulTransaction: (
      transactions: AggregateTransactionDto[],
      checkoutOperation: CheckoutOperationRequest,
    ) => {
      basket.setSuccessfulTransaction(transactions, checkoutOperation);
      updateContents();
    },
  };

  return (
    <BasketContext.Provider value={basketMethods}>
      {children}
    </BasketContext.Provider>
  );
};
