import React, { Suspense, useEffect } from "react";

import { QueryClientProvider } from "@tanstack/react-query";

import { AppcuesAdapter } from "@shared/appcues/AppcuesAdapter";
import { AuthenticatedAccountEntityProvider } from "@shared/components/AuthenticatedAccountEntityProvider";
import { AuthStateProvider } from "@shared/components/AuthStateProvider";
import { FeatureProvider } from "@shared/components/features/FeatureProvider";
import { FormActionsProvider } from "@shared/components/FormActionsProvider";
import { GenericComponentsProvider } from "@shared/components/GenericComponentsProvider";
import { LoggerProvider } from "@shared/components/logger";
import { PermissionProvider } from "@shared/components/permission/PermissionProvider";
import { SearchProvider } from "@shared/components/SearchProvider";
import { setCompanyGlobals } from "@shared/setCompanyGlobals";
import { queryClient } from "@shared/tanstackQuery";
import { StripeProvider } from "@shared/third-party/stripe/StripeProvider";

import { api } from "@/lib/api/apiClient";
import { appcuesAdapter } from "@/lib/Appcues/appcuesAdapter";
import { logger } from "@/lib/logger/logger";
import { stripeLoader } from "@/lib/stripe";
import { authState } from "@/modules/auth/authState";
import { genericComponents } from "@/modules/common/genericComponents";
import { ClarityIntegration } from "@/modules/common/integrations/ClarityIntegration";
import { NavProvider } from "@/modules/common/nav/contexts/navContext";
import { AlertProvider } from "@/modules/common/overlays/alert/AlertProvider";
import { AlertDialogProvider } from "@/modules/common/overlays/alertDialog/AlertDialogContext";
import { SheetProvider } from "@/modules/common/overlays/dialog/context/SheetProvider";
import { searchConfig } from "@/modules/common/search/searchConfig";
import { ToasterProvider } from "@/modules/common/toast/ToasterProvider";
import { CenteredLoadingSpinner } from "@/modules/common/ui/CenteredLoadingSpinner";
import { LoadingSpinnerGrey } from "@/modules/common/ui/LoadingSpinnerGrey";
import { AccountSelectionDialogProvider } from "@/modules/company/switcher/AccountSelectionDialogProvider";
import { AppRouter } from "@/routing/AppRouter";
import "./lib/platformSpecific";
import "@/lib/setup";

export const App = () => {
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    // On page load, see if we have a company set, and if so, set the default date/time format options
    authState
      .load()
      .then(async ({ account }) => {
        if (account?.company?.settings) {
          await setCompanyGlobals(account.company.settings);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <LoadingSpinnerGrey />
      </div>
    );
  }

  return (
    <React.StrictMode>
      <LoggerProvider logger={logger}>
        <Suspense fallback={<CenteredLoadingSpinner />}>
          <QueryClientProvider client={queryClient}>
            <AuthStateProvider authState={authState}>
              <GenericComponentsProvider {...genericComponents}>
                <NavProvider>
                  <FormActionsProvider source="App">
                    <AlertProvider>
                      <ToasterProvider>
                        <AlertDialogProvider>
                          <AuthenticatedAccountEntityProvider>
                            <FeatureProvider>
                              <PermissionProvider>
                                <SearchProvider api={api} config={searchConfig}>
                                  <StripeProvider stripeLoader={stripeLoader}>
                                    <AccountSelectionDialogProvider>
                                      <SheetProvider>
                                        <ClarityIntegration />
                                        <AppcuesAdapter
                                          adapter={appcuesAdapter}
                                        />
                                        <AppRouter />
                                      </SheetProvider>
                                    </AccountSelectionDialogProvider>
                                  </StripeProvider>
                                </SearchProvider>
                              </PermissionProvider>
                            </FeatureProvider>
                          </AuthenticatedAccountEntityProvider>
                        </AlertDialogProvider>
                      </ToasterProvider>
                    </AlertProvider>
                  </FormActionsProvider>
                </NavProvider>
              </GenericComponentsProvider>
            </AuthStateProvider>
          </QueryClientProvider>
        </Suspense>
      </LoggerProvider>
    </React.StrictMode>
  );
};
