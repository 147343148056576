import { FC, ReactNode } from "react";

import { WaitingListDto } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { getClassSubtitle } from "../../classUtils";
import { fetchWaitingLists } from "../../data/waitingListsData";
import { DatatableConfiguration, DatatableQueryProps } from "../../datatable";
import { formatDateTime } from "../../intlFormatter";
import { useWaitingListActions } from "../actions/useWaitingListActions";
import { ExtendedWaitingListDto } from "../formDefinitions/waitingListForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { Chip } from "../ui/Chip";

type Dto = WaitingListDto;
type RowDto = ExtendedWaitingListDto & {
  id: string;
};
type FilterForm = object;

interface UseStudentWaitingListDatatableParams {
  Datatable: FC<{ configuration: DatatableConfiguration<Dto, object, RowDto> }>;
  api: Api;
  studentId: string;
  goToClass: (id: string) => void;
  enrollForClass: (item: RowDto) => void;
  deleteOne: (item: RowDto) => void;
}

export const useStudentWaitingListDatatable = ({
  Datatable,
  api,
  studentId,
  goToClass,
  enrollForClass,
  deleteOne,
}: UseStudentWaitingListDatatableParams): { datatable: ReactNode } => {
  const { View, Text } = useGenericComponents();
  const waitingListActions = useWaitingListActions();

  const fetchData = async (query: DatatableQueryProps) => {
    const request = query.toRequest();
    return fetchWaitingLists({ api, request, studentId, selectAll: true });
  };

  const config: DatatableConfiguration<Dto, FilterForm, RowDto> = {
    id: `student-waiting-list-${studentId}`,
    title: "Waiting list",
    createLabel: "Add",
    createAction: () => {
      waitingListActions.showCreateForm({ studentId });
    },
    permissions: {
      archive: "enrolments:manage",
      create: "enrolments:manage",
      delete: "enrolments:manage",
      edit: "enrolments:manage",
      restore: "enrolments:manage",
    },
    rowActions: {
      title: item => item.class.entity.name,
      click: item => goToClass(item.classId),
      additionalRowActions: () => [
        {
          icon: "openOutline",
          label: "Go to class",
          onClick: item => goToClass(item.classId),
          permission: "schedule:view",
        },
        {
          title: "Enroll",
          actions: [
            {
              icon: "calendarOutline",
              label: "Enroll for class",
              onClick: enrollForClass,
              permission: "enrolments:manage",
            },
          ],
        },
        {
          icon: "trashOutline",
          label: "Remove from waiting list",
          onClick: deleteOne,
          isDestructive: true,
          permission: "enrolments:manage",
        },
      ],
    },
    contentPlaceholders: {
      noContent: {
        icon: "helpCircleOutline",
        title: "This student is not currently waiting for any classes.",
        description: "",
      },
    },
    hasPagination: false,
    hasSearch: true,
    showTotalRecords: true,
    placeholdersCount: 2,
    fetchData,
    columns: [
      {
        label: "Class",
        placeholder: "tile",
        value: row => ({
          type: "tile",
          hasImage: false,
          title: row.item.class.entity.name,
          subtitle: getClassSubtitle(row.item.class.entity, row.item.season),
        }),
      },
      {
        label: "Date requested",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: formatDateTime(row.item.createdAt, "dayMonthYear"),
        }),
      },
    ],
    mobileColumn: {
      children: row => (
        <View className="flex flex-col space-y-1">
          <View>
            <Text
              className="text-body-600 font-semibold text-grey-900"
              truncate={true}>
              {row.item.class.entity.name}
            </Text>
          </View>

          <View>
            <Text className="text-label-400 text-grey-600" truncate={true}>
              {getClassSubtitle(row.item.class.entity, row.item.season)}
            </Text>
          </View>

          <View className="flex flex-row items-start">
            <Chip
              size="sm"
              variant="secondary"
              label={formatDateTime(row.item.createdAt, "dayMonthYear")}
            />
          </View>
        </View>
      ),
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
