/**
 * @see https://swan-io.github.io/chicane/route-pattern-syntax
 */
export const routes = {
  Home: "/",

  // Public routes
  Login: "/login",
  Logout: "/logout",
  RegisterCompany: `/register/?:email`,
  AddNewCompany: "/add-new-company",
  Invitation: "/invitation/:id",
  LinkedLogin: "/invitation/:id/login?:email",
  LinkedRegister: "/invitation/:id/register?:email",
  ForgottenPassword: "/forgot-password",
  PasswordResetLinkSent: "/reset-link-sent",
  ResetPassword: "/reset-password/:token/:email",

  Accounts: "/accounts",

  // Admin routes
  AdminPortal: "/admin/*",
  AdminHome: "/admin",
  DocsHome: "/admin/docs",
  AdminAccount: "/admin/account",

  // Admin doc routes
  ActionBannerDoc: "/admin/docs/action-banner",
  AlertDialogDoc: "/admin/docs/alert-dialog",
  AlertDoc: "/admin/docs/alert",
  AvatarDoc: "/admin/docs/avatar",
  BannerDoc: "/admin/docs/banner",
  BigRadioDoc: "/admin/docs/big-radio",
  BreadcrumbsDoc: "/admin/docs/breadcrumbs",
  ButtonDoc: "/admin/docs/button",
  QuickActionButtonDoc: "/admin/docs/quick-action-button",
  CardDoc: "/admin/docs/card",
  CardContainerDoc: "/admin/docs/card-container",
  CheckboxDoc: "/admin/docs/checkbox",
  ChipDoc: "/admin/docs/chip",
  CollapsibleContainerDoc: "/admin/docs/collapsible-container",
  ColorPaletteDoc: "/admin/docs/color-palette",
  ColorPickerDoc: "/admin/docs/color-picker",
  ContentPlaceholderDoc: "/admin/docs/content-placeholder",
  DatatableDoc: "/admin/docs/datatable",
  DayMonthPickerDoc: "/admin/docs/day-month-picker",
  DecimalInputDoc: "/admin/docs/decimal-input",
  FormBuilderDoc: "/admin/docs/form-builder",
  HorizontalSeparatorDoc: "/admin/docs/horizontal-separator",
  IconButtonDoc: "/admin/docs/icon-button",
  IntegerInputDoc: "/admin/docs/integer-input",
  ListDoc: "/admin/docs/list",
  ActionMenuDoc: "/admin/docs/action-menu",
  MultiSelectDoc: "/admin/docs/multi-select",
  PasswordInputDoc: "/admin/docs/password-input",
  RangeDatePickerDoc: "/admin/docs/range-date-picker",
  SearchInputDoc: "/admin/docs/search-input",
  SelectDoc: "/admin/docs/select",
  SheetDoc: "/admin/docs/sheet",
  SingleDatePickerDoc: "/admin/docs/single-date-picker",
  SwitchDoc: "/admin/docs/switch",
  TabsDoc: "/admin/docs/tabs",
  TextInputDoc: "/admin/docs/text-input",
  TimePickerDoc: "/admin/docs/time-picker",
  ToastDoc: "/admin/docs/toast",
  ToolTipDoc: "/admin/docs/tooltip",
  VerticalSeparatorDoc: "/admin/docs/vertical-separator",

  // Company routes
  CompanyCustomFields: "/company/custom-fields",
  CompanyHome: "/company",
  CompanyPortal: "/company/*",
  CompanyRegistrationFees: "/company/registration-fees",
  CompanySettings: "/company/settings",
  CompanyWaiverDetails: "/company/waivers/:id?:sourcePage",
  CompanyWaivers: "/company/waivers",
  CompanyWaiversArchived: "/company/waivers/archived",
  CompanyFamilyWaivers: "/company/families/:familyId/waivers",
  CompanyStudentWaivers: "/company/students/:studentId/waivers",
  ContactsList: "/company/families/:id/contacts",
  FamilyPaymentMethods: "/company/families/:familyId/payment-methods",
  Enrolments: "/company/classes/:classId/enrolments",
  FamilyDetails: "/company/families/:id",
  AccountStatement: "/company/families/:familyId/account-statement",
  FamilyList: "/company/families",
  Holidays: "/company/holidays",
  LocationList: "/company/locations",
  LocationListArchived: "/company/locations/archived",
  LocationDetails: "/company/locations/:id",
  StaffDetails: "/company/staff/:id",
  StaffList: "/company/staff",
  StudentDetails: "/company/students/:id",
  ClassTrials: "/company/classes/:classId/trials",
  StudentEnrolments: "/company/students/:studentId/enrolments",
  ClassWaitingList: "/company/classes/:classId/waiting-list",
  StudentTrials: "/company/students/:studentId/trials",
  StudentWaitingList: "/company/students/:studentId/waiting-list",
  StudentList: "/company/students",
  SeasonCreate: "/company/season/create",
  SeasonList: "/company/seasons",
  SeasonListArchived: "/company/seasons/archived",
  SeasonUpdate: "/company/seasons/:id/update",
  SeasonDetails: "/company/seasons/:id",
  TaxSettings: "/company/settings/tax",
  Trials: "/company/trials?:classId",
  CompanyAccount: "/company/account",
  PricingTiers: "/company/seasons/:seasonId/prices",
  ClassDetails: "/company/classes/:id",
  LessonDetails: "/company/classes/:id/lessons/:date/:time",
  ClassLessonAttendances:
    "/company/classes/:id/lessons/:date/:time/attendances",
  SeasonPricingSchemeDetails: "/company/seasons/:id/pricing-scheme",
  Emails: "/company/emails",
  WriteEmail: "/company/emails/write?:id&:contactId",
  ViewEmail: "/company/emails/:id",
  ActivityLogList: "/company/activity-log",

  // Enrolment Queue list
  WaitingList: "/company/waiting-list",

  // Billing
  Billing: "/company/billing",
  DiscountSchemeList: "/company/discount-schemes",
  DiscountSchemeDetails: "/company/discount-schemes/:id",
  TransactionList: "/company/transactions",
  TransactionCreate: "/company/transactions/create-custom?:familyId",
  TransactionCreateFromLesson:
    "/company/transactions/create-from-lesson/:lessonId",

  // Customer routes
  CustomerPortal: "/customer/*",
  CustomerHome: "/customer",
  CustomerAccount: "/customer/account",
  CustomerClasses: "/customer/classes/:id",
  CustomerEnrol: "/customer/enrol/:id",
  CustomerBilling: "/customer/billing",
  CustomerPaymentMethods: "/customer/paymentmethods",
  CustomerWaivers: "/customer/waivers",
  CustomerStudents: "/customer/students",
  CustomerContacts: "/customer/contacts",
  FamilyCourseList: "/customer/classes",

  // Family Trials
  FamilyTrials: "/customer/trials",
  FamilyWaitingList: "/customer/waiting-list",
  FamilyMakeUpLessons: "/customer/make-up-lessons",

  // Integration
  // @TODO: re-enable when we have quickbooks integration ready
  // Integrations: "/company/integrations",
  // QuickbooksCallback: "/integrations/quickbooks/callback",

  // Public catalogue routes but we don't want them to be in the catalogue router for this piece...

  // Public catalogue routes (where customers can search for courses)
  Catalogue: "/:slug/*",
  CompanyLandingPage: "/:slug",
  CatalogueLogin: "/:slug/login",
  CatalogueRegister: "/:slug/register",
  CatalogueCreateAccount: "/:slug/create-account",
  CataloguePostAuthWizard: "/:slug/post-auth",
  CatalogueForgottenPassword: "/:slug/forgotten-password",
  CourseFilterWizard: "/:slug/classes/wizard/:mode?:stage",
  Courses:
    "/:slug/classes?:ages[]&:days[]&:locationIds[]&:query&:seasonIds[]&:mode",
  CatalogueCoursePreview: "/:slug/classes/:courseId?:mode",
  JoinWaitingList: "/:slug/classes/:courseId/join-waiting-list",
  JoinWaitingListSuccess: "/:slug/classes/join-waiting-list/success",
  MemberBasket: "/:slug/basket",
  CustomerCheckoutSummary: "/:slug/checkout/summary",
  CustomerCheckoutSuccess: "/:slug/checkout/success",
  GuestPayment: "/:slug/pay/:paymentLinkId",
} as const;
