export const common = {
  "alert.season.created": "Term {{name}} created successfully",
  "alert.season.updated": "Term {{name}} updated successfully",
  "banner.registrationEnded.description": "Term ended",
  "breadcrumb.seasons": "Terms",
  "button.createSeason": "Create term",
  "button.goToSeasons": "Go to terms",
  "description.addAdditionalFees":
    "You can add additional fees that will only apply to students enrolling in this term.",
  "description.pricingPlan":
    "Select how you’d like to define your class price: by lesson, by month, or by term, any fixed discounts will be applied to this selection. If you select by month or by term, holidays won’t affect the price.",
  "description.seasons":
    "Terms can be used to plan weekly classes and enroll students on an annual basis.",
  "description.taxRates":
    "You can configure the tax rates you charge for everything you sell. Set a default rate to save time when setting up terms & classes.",
  "description.unpostedTuitionForm":
    "Use this to generate unposted tuition for terms if you think a bill may have been missed.",
  "entity.instalments_one": "instalment",
  "entity.instalments_other": "instalments",
  "entity.season_one": "term",
  "entity.season_other": "terms",
  "globalSearch.description":
    "Find students, families, staff, classes, terms and more...",
  "heading.enrolments.bySeason": "By term",
  "label.ageCutOff.firstDayOfSeason": "First day of term",
  "label.ageCutOff.firstDayOfSeason.description":
    "Each term will have a different age cut off date.",
  "label.ageCutOff.7DaysAfter": "7 days after the start of the term",
  "label.ageCutOff.7DaysAfter.description":
    "Each term will have a different age cut off date.",
  "label.ageCutOff.28DaysAfter": "28 days after the start of the term",
  "label.ageCutOff.28DaysAfter.description":
    "Each term will have a different age cut off date.",
  "label.ageCutOff.specificDate": "All terms will use the same cut off date.",
  "label.courseFilter.season": "By term",
  "label.emailRecipients.addBySeason": "Add by term",
  "label.pricingPlan.bySeason": "By term",
  "label.registrationFeeInterval.perSeason": "Per term",
  "label.registrationFeeFormTitle.perSeason": "{{start}} per term fee",
  "label.season.payUpFront": "Pay for term upfront",
  "label.season": "Term",
  "label.seasons": "Terms",
  "nav.mySeasons": "My terms",
  "nav.seasons": "Terms",
  "pageTitle.seasonArchive": "Archived terms",
  "pageTitle.seasonCreate": "Create term",
  "pageTitle.seasonDetails": "Term details",
  "pageTitle.seasonPreview": "Term preview",
  "pageTitle.seasonUpdate": "Edit term",
  "pageTitle.seasons": "Terms",
  "placeholder.familyEnrolments.noClassesYet":
    "You haven't enrolled for any classes in this term.",
  "placeholder.noSeasons": "No terms yet",
  "placeholder.noSeasons.description":
    "You can set up your weekly calendar by creating terms. They can be used to plan weekly classes and enroll students on an annual basis.",
  "placeholder.noArchivedSeasons": "No archived terms",
  "placeholder.seasonNotFound.description":
    "The term you are looking for could not be found.",
  "placeholder.seasonNotFound.title": "Term not found",
  "paymentMethod.Ach": "Direct debit",
  "paymentMethod.Check": "Cheque",
} as const;
