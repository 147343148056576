import { StudentDto } from "@justraviga/classmanager-sdk";

import {
  displayStudentAge,
  enumLabel,
  formatDate,
  getFullName,
  getInitials,
} from "shared/lib";

import { PreviewSheetContent } from "@/modules/common/sheet/preview/PreviewSheetContent";
import { Avatar } from "@/modules/common/ui/avatar/Avatar";
import { HorizontalSeparator } from "@/modules/common/ui/Separator";

export const StudentPreview = ({ student }: { student: StudentDto }) => {
  return (
    <PreviewSheetContent>
      <div>
        <div className={"flex items-center space-x-3"}>
          <div>
            <Avatar
              size="xl"
              src={student.profilePicture ?? undefined}
              name={getInitials(student)}
            />
          </div>

          <div className={"space-y-1"}>
            <h3 className={"text-body-600 text-grey-900"}>
              {getFullName(student)}
            </h3>

            <PreviewSheetContent.infoPairRow
              left="Age"
              justify={"start"}
              right={student.dateOfBirth ? displayStudentAge(student, "") : "-"}
            />
          </div>
        </div>

        <HorizontalSeparator spacing={4} />

        <div className={"space-y-2"}>
          <PreviewSheetContent.subTitle title="Personal information" />

          <PreviewSheetContent.infoPairRow
            left="Date of birth"
            justify={"start"}
            right={
              student.dateOfBirth
                ? formatDate(student.dateOfBirth, "dayMonthYear")
                : "-"
            }
          />

          <PreviewSheetContent.infoPairRow
            left="Gender"
            justify={"start"}
            right={
              student.gender ? enumLabel("studentGender", student.gender) : "-"
            }
          />
        </div>

        <HorizontalSeparator spacing={4} />

        <div className={"space-y-4"}>
          <PreviewSheetContent.subTitle title="Medical & consent" />
          <div className={"flex flex-col space-y-1"}>
            <p className={"text-body-400 text-grey-600"}>Medical information</p>
            <span className={"text-body-400 text-grey-900"}>
              {student.medicalInfo || "-"}
            </span>
          </div>

          <PreviewSheetContent.infoRowStatus
            content={
              <p className={"text-body-400 text-grey-600"}>
                Photo & video consent
              </p>
            }
            status={student.mediaConsent}
          />
        </div>
      </div>
    </PreviewSheetContent>
  );
};
