import { useEffect, useState } from "react";

import { UseApi } from "../components/apiQueryFactory";

export const useFamilyMembers = (useApi: UseApi) => {
  const [goNext, setGoNext] = useState(false);
  const [hasSetFamilyMember, setHasSetFamilyMember] = useState(false);

  const { data: unsortedMembers, isPending: isFamilyMembersLoading } = useApi(
    "listStudent",
    {
      selectAll: true,
    },
  );

  const members = (unsortedMembers?.data ?? []).sort((a, b) =>
    a.firstname.localeCompare(b.firstname),
  );

  useEffect(() => {
    if (!hasSetFamilyMember && !isFamilyMembersLoading) {
      if (members.length > 0) {
        setGoNext(true);
      }
      setHasSetFamilyMember(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFamilyMembersLoading]);

  return {
    members,
    isFamilyMembersLoading,
    goNext,
    setGoNext,
  };
};
