import { FC, ReactNode } from "react";

import {
  ContactDto,
  FamilyDto,
  InviteFamilyRequest,
  StudentDto,
} from "@justraviga/classmanager-sdk";
import { SortSchema } from "@justraviga/classmanager-sdk/dist/models/SortSchema";
import { match } from "ts-pattern";

import { Api } from "../../api";
import {
  DatatableConfiguration,
  DatatablePaginatedData,
  DatatableQueryProps,
} from "../../datatable";
import { formatDateTime } from "../../intlFormatter";
import { getFullName } from "../../personUtil";
import {
  FamilyFilterFormSchema,
  useFamilyFilterForm,
} from "../formDefinitions/familyFilterForm";

type Dto = FamilyDto;
type RowDto = Dto & {
  students: StudentDto[];
  contacts: ContactDto[];
  primaryContact: ContactDto;
};
type FilterForm = FamilyFilterFormSchema;

export const useFamilyDatatable = ({
  Datatable,
  api,
  archive,
  archiveMany,
  avatarListCell,
  deleteMany,
  deleteOne,
  invitationStatus,
  onRowClick,
  showCreateForm,
  showInviteForm,
  showUpdateForm,
  unarchive,
}: {
  Datatable: FC<{
    configuration: DatatableConfiguration<Dto, FilterForm, RowDto>;
  }>;
  api: Api;
  archive: (item: Dto) => Promise<void | Dto>;
  archiveMany: (ids: string[]) => Promise<void>;
  avatarListCell: (students: Array<StudentDto>) => ReactNode;
  deleteMany: (ids: string[]) => Promise<void>;
  deleteOne: (item: Dto) => Promise<void>;
  invitationStatus: (item: Dto) => ReactNode;
  onRowClick: (item: Dto) => void;
  showCreateForm: () => void;
  showInviteForm: (item: Dto, defaultValues: InviteFamilyRequest) => void;
  showUpdateForm: (item: RowDto) => void;
  unarchive: (item: Dto) => Promise<Dto>;
}): { datatable: ReactNode } => {
  const filterForm = useFamilyFilterForm();

  const fetchData = async (
    query: DatatableQueryProps,
  ): Promise<DatatablePaginatedData<RowDto>> => {
    const familyResponse = await api.families.listFamily({
      ...query.toRequest(),
      sort: { createdAt: SortSchema.Desc },
    });

    const familyIds = familyResponse.data.map(family => family.id);

    const studentQuery = api.students.listStudent({
      where: { familyId: { in: familyIds } },
      sort: {
        firstname: SortSchema.Asc,
        lastname: SortSchema.Asc,
      },
      selectAll: true,
      includeArchived: query.filters.some(
        f => f.field === "isArchived" && f.value === true,
      ),
    });

    const contactQuery = api.contacts.listContact({
      where: { familyId: { in: familyIds } },
      selectAll: true,
    });

    const [{ data: students }, { data: contacts }] = await Promise.all([
      studentQuery,
      contactQuery,
    ]);

    const data = familyResponse.data.map(family => ({
      ...family,
      students: students.filter(s => s.familyId === family.id) ?? [],
      contacts: contacts.filter(c => c.familyId === family.id) ?? [],
      primaryContact: contacts.find(
        c => c.familyId === family.id && c.isPrimary,
      )!,
    }));

    return {
      ...familyResponse,
      data,
    };
  };

  const config: DatatableConfiguration<Dto, FilterForm, RowDto> = {
    id: "family",
    title: "Families",
    createAction: showCreateForm,
    permissions: {
      create: "members:manage",
      archive: "members:manage",
      delete: "members:manage",
      edit: "members:manage",
    },
    rowActions: {
      archive: archive,
      click: onRowClick,
      delete: deleteOne,
      edit: showUpdateForm,
      restore: unarchive,
      additionalRowActions: () => [
        {
          label: `Invite to Class Manager`,
          onClick: item => {
            return showInviteForm(item, {
              firstname: item.primaryContact?.firstname,
              lastname: item.primaryContact?.lastname as string | undefined, // ContactDto has this as string | null
              email: item.primaryContact?.email as string | undefined, // ContactDto has this as string | null
            });
          },
          enabled: item => item.invitationStatus === "not_invited",
          permission: "members:manage",
        },
      ],
    },
    groupActions: {
      archiveMany: archiveMany,
      deleteMany: deleteMany,
    },
    contentPlaceholders: {
      noContent: {
        icon: "peopleOutline",
        title: "No families yet",
        description: "Create a family and invite them to join your company",
      },
    },
    hasPagination: true,
    placeholdersCount: 5,
    fetchData,
    columns: [
      {
        label: "Family",
        width: "33%",
        placeholder: "tile",
        value: row => {
          const primaryContact = row.item.primaryContact;
          return {
            type: "tile",
            hasImage: false,
            title: row.item.name,
            subtitle: primaryContact ? getFullName(primaryContact) : "",
            icon: invitationStatus(row.item),
          };
        },
        sortableField: "name",
      },
      {
        label: "Students",
        placeholder: "tile",
        value: row => {
          return {
            type: "custom",
            children: avatarListCell(row.item.students),
          };
        },
      },
      {
        label: "Date created",
        placeholder: "text",
        value: row => {
          return {
            type: "text",
            text: row.item.createdAt
              ? formatDateTime(row.item.createdAt, "dayMonthYear")
              : "-",
          };
        },
        sortableField: "createdAt",
      },
    ],
    mobileColumn: {
      title: row => row.item.name,
      icon: row => invitationStatus(row.item),
      subtitle: row => {
        const studentCount = row.item.students.length;
        const primaryContactName = getFullName(row.item.primaryContact);
        const studentText = match(studentCount)
          .with(0, () => undefined)
          .with(1, () => `${studentCount} student`)
          .otherwise(() => `${studentCount} students`);
        // TODO: this dot is not the right design, but current mobile column design makes it hard, fix this later
        return `${primaryContactName} ${primaryContactName && studentText ? "⋅" : ""} ${studentText ?? ""}`;
      },
      isPendingDeleted: row => !!row.item.deletedAt,
    },
    filterForm,
  };

  return {
    datatable: <Datatable configuration={config} />,
  };
};
