import { Fragment, ReactNode } from "react";

import {
  FeeDto,
  PricingModel,
  PricingPlanType,
  PricingScheme,
  SeasonDto,
} from "@justraviga/classmanager-sdk";

import { SeasonStatusChip } from "./SeasonStatusChip";
import { useTaxRates } from "../../../../data/useTaxRates";
import { calculateWeeksBetweenDates } from "../../../../dateUtils";
import { formatDate } from "../../../../intlFormatter";
import { numberToOrdinal } from "../../../../numberUtils";
import { displayRegistrationFeeAmounts } from "../../../../registrationFeeUtils";
import {
  getPricingModelLabel,
  getPricingSchemeText,
} from "../../../../seasonUtils";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { HorizontalSeparatorComponent } from "../../../interfaces";
import {
  PreviewSheetContent,
  PreviewSheetContentDetailSection,
  PreviewSheetContentInfoPairRow,
  PreviewSheetContentInfoRowStatus,
  PreviewSheetContentRow,
  PreviewSheetContentSubTitle,
  PreviewSheetContentTitle,
} from "../../../preview/PreviewSheetContent";

const SeasonPreviewHeader = ({ season }: { season: SeasonDto }) => {
  const { View } = useGenericComponents();

  return (
    <PreviewSheetContentDetailSection>
      <View className="flex flex-row justify-between space-x-1">
        <PreviewSheetContentTitle title={season.name} />
        <SeasonStatusChip season={season} />
      </View>
    </PreviewSheetContentDetailSection>
  );
};

const SeasonPreviewSchedule = ({
  startAt,
  endAt,
}: {
  startAt: string;
  endAt: string;
}) => (
  <PreviewSheetContentDetailSection>
    <PreviewSheetContentSubTitle title="Schedule" icon="calendarClearOutline" />
    <PreviewSheetContentInfoPairRow
      left={"Start date"}
      right={formatDate(startAt, "dayMonthYear")}
    />
    <PreviewSheetContentInfoPairRow
      left={"End date"}
      right={formatDate(endAt, "dayMonthYear")}
    />
    <PreviewSheetContentInfoPairRow
      left={"Length"}
      right={
        calculateWeeksBetweenDates(new Date(startAt), new Date(endAt)) +
        " weeks"
      }
    />
  </PreviewSheetContentDetailSection>
);

const SeasonPreviewChargeRegistrationFees = ({
  enableRegistrationFees,
}: {
  enableRegistrationFees: boolean;
}) => (
  <PreviewSheetContentDetailSection>
    <PreviewSheetContentSubTitle title="Registration" />
    <PreviewSheetContentInfoRowStatus
      content={"Charge registration fees"}
      status={enableRegistrationFees}
    />
  </PreviewSheetContentDetailSection>
);

const SeasonPreviewAdditionalFees = ({
  additionalFees,
}: {
  additionalFees: FeeDto[];
}) => {
  const { View } = useGenericComponents();
  const { taxRates, hasTaxEnabled } = useTaxRates();

  const emptyAdditionalFees = additionalFees.length === 0;

  return (
    <PreviewSheetContentDetailSection>
      <PreviewSheetContentSubTitle title="Additional fees" />
      {emptyAdditionalFees ? (
        <PreviewSheetContentRow title="-" />
      ) : (
        additionalFees.map(fee => (
          <View key={fee.id}>
            <PreviewSheetContentRow
              title={fee.description}
              content={displayRegistrationFeeAmounts(
                fee,
                taxRates,
                hasTaxEnabled,
              )}
            />
          </View>
        ))
      )}
    </PreviewSheetContentDetailSection>
  );
};

const SeasonPreviewTuitionAndPricing = ({
  pricingModel,
  pricingScheme,
}: {
  pricingModel: PricingModel;
  pricingScheme: PricingScheme;
}) => (
  <PreviewSheetContentDetailSection>
    <PreviewSheetContentSubTitle title="Tuition & pricing" />
    <PreviewSheetContentRow
      title={getPricingModelLabel(t, pricingModel)}
      content={getPricingSchemeText(pricingModel, pricingScheme)}
    />
  </PreviewSheetContentDetailSection>
);

const SeasonPreviewPaymentOptions = ({ season }: { season: SeasonDto }) => {
  const monthlyPricingPlan = season.pricingPlans.find(
    plan => plan.type === PricingPlanType.Monthly,
  );

  const upfrontPricingPlan = season.pricingPlans.find(
    plan => plan.type === PricingPlanType.OneOff,
  );

  return (
    <PreviewSheetContentDetailSection>
      <PreviewSheetContentSubTitle title="Payment options" />

      {upfrontPricingPlan && upfrontPricingPlan.enabled && (
        <PreviewSheetContentRow
          title={
            <PreviewSheetContentInfoPairRow
              left={
                <PreviewSheetContentRow title={t("label.season.payUpFront")} />
              }
              right={
                upfrontPricingPlan.discount
                  ? `${upfrontPricingPlan.discount}% off`
                  : ""
              }
            />
          }
          content={`On ${formatDate(upfrontPricingPlan.firstPaymentDate, "dayMonthYear")}`}
        />
      )}

      {monthlyPricingPlan && monthlyPricingPlan.enabled && (
        <PreviewSheetContentRow
          title={`Pay in ${monthlyPricingPlan.numberOfInstallments} monthly installment${monthlyPricingPlan.numberOfInstallments === 1 ? "" : "s"}`}
          content={`On ${formatDate(monthlyPricingPlan.firstPaymentDate, "dayMonthYear")} and every ${numberToOrdinal(monthlyPricingPlan.paymentDay ?? 0)} of the month.`}
        />
      )}
    </PreviewSheetContentDetailSection>
  );
};

interface SeasonPreviewProps {
  season: SeasonDto;
  HorizontalSeparator: HorizontalSeparatorComponent;
}

export const SeasonPreview = ({
  season,
  HorizontalSeparator,
}: SeasonPreviewProps) => {
  const previewSheetContent: ReactNode[] = [
    <SeasonPreviewHeader season={season} />,
    <SeasonPreviewSchedule startAt={season.startAt} endAt={season.endAt} />,
    <SeasonPreviewChargeRegistrationFees
      enableRegistrationFees={season.enableRegistrationFees}
    />,
    <SeasonPreviewAdditionalFees additionalFees={season.additionalFees} />,
    <SeasonPreviewTuitionAndPricing
      pricingModel={season.pricingModel}
      pricingScheme={season.pricingScheme}
    />,
    <SeasonPreviewPaymentOptions season={season} />,
  ];

  return (
    <PreviewSheetContent>
      {previewSheetContent.map((content, index) => (
        <Fragment key={index}>
          {content}
          {index < previewSheetContent.length - 1 && (
            <HorizontalSeparator spacing={4} />
          )}
        </Fragment>
      ))}
    </PreviewSheetContent>
  );
};
