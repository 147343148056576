import { BillingTiming } from "@justraviga/classmanager-sdk";

import {
  makeAutomatedTuitionFormRequest,
  useAutomatedTuitionForm,
} from "./settings/automatedTuitionForm";
import { showAlert } from "../../alertState";
import { useAuthState } from "../../auth/useAuthState";
import { getPlatformFunctions } from "../../platformSpecific";
import { useFormActions } from "../FormActionsProvider";
import { useGenericComponents } from "../GenericComponentsProvider";
import { useSettings } from "../useSettings";

export interface AutomatedTuitionSettingsFormProps {
  userBillingDayOfMonth: number | null;
  setUserBillingDayOfMonth: (userBillingDayOfMonth: number | null) => void;
  userBillingTiming: BillingTiming | null;
  setUserBillingTiming: (userBillingTiming: BillingTiming | null) => void;
  hideForm?: () => void;
}

export const AutomatedTuitionSettingsForm = ({
  userBillingDayOfMonth,
  setUserBillingDayOfMonth,
  userBillingTiming,
  setUserBillingTiming,
  hideForm,
}: AutomatedTuitionSettingsFormProps) => {
  const { api } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();
  const { account, setAccount } = useAuthState();
  const billingSettings = useSettings("billing");
  const { setIsLoading } = useFormActions();
  const showAutomatedTuitionSettingsForm = useAutomatedTuitionForm(true);

  return (
    <GenericForm
      apiRequest={form => {
        if (form.saveDefault) {
          setIsLoading(true);
          return makeAutomatedTuitionFormRequest({
            api,
            setAccount,
            account: account!,
          })(form).then(() => {
            showAlert({
              content: "Default settings saved successfully",
            });
            return form;
          });
        } else {
          return Promise.resolve(form);
        }
      }}
      defaultValues={{
        billingDayOfMonth:
          userBillingDayOfMonth || billingSettings.billingDayOfMonth,
        billingTiming: userBillingTiming || billingSettings.billingTiming,
        saveDefault: false,
      }}
      formDefinitionHook={() => showAutomatedTuitionSettingsForm}
      onSuccess={result => {
        setUserBillingDayOfMonth(result.billingDayOfMonth as number);
        setUserBillingTiming(result.billingTiming as BillingTiming);
        hideForm?.();
      }}
    />
  );
};
