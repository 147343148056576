import {
  CheckoutRequestItems,
  QuoteResponse,
} from "@justraviga/classmanager-sdk";

import { BasketContents } from "../../../../../basket/basket";
import { basketToCheckoutRequest } from "../../../../../basket/basketUtils";
import { SelectedPricingPlanIds } from "../../../../../data/useCheckoutData";
import { getPlatformFunctions } from "../../../../../platformSpecific";

export function addPricingPlansForEnrolmentsToQuote(
  contents: BasketContents,
  quoteResponse: QuoteResponse,
  selectedPricingPlanIds: SelectedPricingPlanIds,
): Promise<CheckoutRequestItems> {
  const { api } = getPlatformFunctions();
  /**
   * Temporary solution to get this over the line.
   * Rob will come back and refactor this so we have the season Id available here for each course
   */
  return api.courses
    .listCourse({
      where: {
        id: {
          in: [...contents.enrolments.keys()].map(courseId => courseId),
        },
      },
    })
    .then(response =>
      basketToCheckoutRequest(
        contents,
        quoteResponse,
        selectedPricingPlanIds,
        response.data.map(agg => agg.entity),
      ),
    );
}
