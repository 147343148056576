import { ReactNode } from "react";

import {
  AggregateEnrolmentDto,
  StudentDto,
} from "@justraviga/classmanager-sdk";

import { AddStudentsToBasketItemWidget } from "./AddStudentsToBasketItemWidget";
import { BasketItem } from "./MemberBasketPage";
import { StudentImplementedActions } from "../../../actions/useSharedStudentActions";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { MultiSelectComponent } from "../../../interfaces";

interface ItemListWidgetProps {
  basketItems: BasketItem[];
  familyMembers: StudentDto[];
  enrolments: AggregateEnrolmentDto[];
  MultiSelect: MultiSelectComponent;
  useStudentActions: () => StudentImplementedActions;
  shouldValidate: boolean;
  header: ReactNode;
  itemTitle: (basketItem: BasketItem) => ReactNode;
  itemSubtitle: (basketItem: BasketItem) => ReactNode;
}

export const ItemListWidget = ({
  basketItems,
  familyMembers,
  enrolments,
  MultiSelect,
  useStudentActions,
  shouldValidate,
  header,
  itemTitle,
  itemSubtitle,
}: ItemListWidgetProps) => {
  const { HorizontalSeparator, View } = useGenericComponents();

  return (
    <View className={"mb-4"}>
      {header}
      {basketItems.map((basketItem, index: number) => (
        <View key={basketItem.course.entity.id}>
          <AddStudentsToBasketItemWidget
            title={itemTitle}
            subtitle={itemSubtitle}
            basketItem={basketItem}
            familyMembers={familyMembers}
            enrolments={enrolments.filter(
              ({ enrolment }) =>
                enrolment.classId === basketItem.course.entity.id,
            )}
            MultiSelect={MultiSelect}
            useStudentActions={useStudentActions}
            shouldValidate={shouldValidate}
          />
          {index < basketItems.length - 1 && (
            <HorizontalSeparator spacing={5} />
          )}
        </View>
      ))}
    </View>
  );
};
