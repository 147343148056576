import { PropsWithChildren, useEffect } from "react";

import { AccountRole, PublicCompanyDto } from "@justraviga/classmanager-sdk";

import { CatalogueContext } from "./catalogueContext";
import { setPublicCompanyId } from "../../api";
import { useAuthState } from "../../auth/useAuthState";
import { setCompanyGlobals } from "../../setCompanyGlobals";
import { useStripeLib } from "../../third-party/stripe/useStripeLib";

export const CatalogueContextProvider = ({
  company,
  children,
}: PropsWithChildren<{ company: PublicCompanyDto }>) => {
  const { setStripeIntegration } = useStripeLib();
  const { user, account, setAccount, accounts } = useAuthState();
  const companyAccounts = accounts.filter(a => a.company?.id === company.id);
  const familyAccount = companyAccounts.find(a => a.role === "family");
  const staffAccount = companyAccounts.find(a => a.role === "staff");

  // Set the global variable that will be used by the SDK middleware
  setPublicCompanyId(company.id);

  // Try to select an account for this company automatically.
  // This also makes sure that authState only contains the family account for this company
  // (not another company that they might have previously visited, or a staff account).
  useEffect(() => {
    if (!user) {
      // They're not logged in yet, so we can't select an account for them
      return;
    }
    if (
      account &&
      account.company?.id === company.id &&
      account.role === AccountRole.Family
    ) {
      // They already have a family account for this company selected
      return;
    }

    if (familyAccount) {
      setAccount(familyAccount);
    } else if (staffAccount) {
      // Do nothing. We'll let the CatalogueRouter do a redirect
    } else {
      setAccount(null);
    }
    // We do however want to watch for when they login (via 'user') so that we can select an account for them.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, company.id, familyAccount, staffAccount, user]);

  useEffect(() => {
    setStripeIntegration(company.settings.integrations.stripe!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    company.settings.integrations.stripe?.connectAccountId,
    company.settings.integrations.stripe?.region,
  ]);

  useEffect(() => {
    setCompanyGlobals(company.settings);
  }, [company.settings]);

  const value: CatalogueContext = {
    company: company,
  };

  return (
    <CatalogueContext.Provider value={value}>
      {children}
    </CatalogueContext.Provider>
  );
};
