import { useAuthState } from "@shared/auth/useAuthState";
import { useSettings } from "@shared/components/useSettings";
import { useFamilyTrialsList } from "@shared/data/useFamilyTrialsList";

import { api } from "@/lib/api/apiClient";
import { CardContainerPlaceholder } from "@/modules/common/cardContainer/CardContainer";
import { PrimaryActionItemProps } from "@/modules/common/cardContainer/cardContainerTypes";
import { CustomerCardPage } from "@/modules/common/cardContainer/CustomerCardPage";
import { FamilyTrialsList } from "@/modules/customer/trials/FamilyTrialsList";
import { Router } from "@/routing/router";

export const FamilyTrialsPage = () => {
  const {
    upcomingTrials,
    pastTrials,
    students,
    classes,
    seasons,
    isLoading,
    isEmpty,
  } = useFamilyTrialsList(api);

  const company = useAuthState().account?.company;

  const { enableOnPortal } = useSettings("trial");

  const primaryAction: PrimaryActionItemProps | undefined = enableOnPortal
    ? {
        icon: "addCircleOutline",
        title: "Book a trial",
        onClick: () => {
          Router.push("CourseFilterWizard", {
            slug: company!.slug,
            mode: "trial",
          });
        },
      }
    : undefined;

  return (
    <CustomerCardPage
      title={"Trials"}
      isEmpty={isEmpty || upcomingTrials.length === 0}
      isLoading={isLoading}
      forceBackButton={true}
      primaryAction={primaryAction}>
      <div className={"mb-8"}>
        <FamilyTrialsList
          trials={upcomingTrials}
          students={students}
          classes={classes}
          seasons={seasons}
          title={"Upcoming"}
        />
      </div>
      <CardContainerPlaceholder title={"You have not booked any trials"} />
      {!isEmpty && pastTrials.length > 0 && (
        <FamilyTrialsList
          trials={pastTrials}
          students={students}
          classes={classes}
          seasons={seasons}
          title={"Past"}
          collapsible={true}
        />
      )}
    </CustomerCardPage>
  );
};
