import { useEffect } from "react";

import {
  AggregateTransactionDetailsDto,
  AggregateTransactionDto,
} from "@justraviga/classmanager-sdk";

import { useAuthState } from "../auth/useAuthState";
import { useBasket } from "../components/basket/useBasket";
import { QuoteSummary } from "../components/modules/customer/checkout/QuoteSummary";

export const useSuccessData = ({
  gotoDashboard,
}: {
  gotoDashboard: () => void;
}) => {
  const { isLoggedIn } = useAuthState();
  const { getSuccessfulTransaction } = useBasket();
  const { transactions: aggregateTransactions } =
    getSuccessfulTransaction() ?? {};

  useEffect(() => {
    // if there are no transactions we should go to the dashboard
    if (!aggregateTransactions || !isLoggedIn) {
      gotoDashboard();
    }
    // gotoDashboard is not going to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregateTransactions, isLoggedIn]);

  if (!aggregateTransactions) {
    return {};
  }

  const paymentDetailsId = aggregateTransactions
    .find(transaction => transaction.transaction.type === "credit")
    ?.details.find(
      (details: AggregateTransactionDetailsDto) =>
        details.details.type === "payment-auto",
    )?.details.id;

  const debitTransactions = aggregateTransactions.filter(
    transaction => transaction.transaction.type === "debit",
  );

  const stats: QuoteSummary = debitTransactions.reduce(
    (a: QuoteSummary, season: AggregateTransactionDto) => {
      return {
        subtotal: a.subtotal + season.stats.subtotal,
        discountTotal: a.discountTotal + season.stats.discountTotal,
        taxTotal: a.taxTotal + season.stats.taxTotal,
        total: a.total + season.stats.total,
      };
    },
    {
      subtotal: 0,
      discountTotal: 0,
      taxTotal: 0,
      total: 0,
    },
  );

  return {
    paymentDetailsId,
    debits: debitTransactions,
    stats,
  };
};
