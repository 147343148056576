import { QuoteDto } from "@justraviga/classmanager-sdk";

import { CheckoutSeason } from "./CheckoutSeason";
import { CheckoutSection } from "./CheckoutSection";
import { SelectedPricingPlanIds } from "../../../../data/useCheckoutData";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const CheckoutSeasons = ({
  seasons,
  setSeasonPricingPlan,
  selectedPricingPlanIds,
}: {
  seasons: QuoteDto[];
  selectedPricingPlanIds: SelectedPricingPlanIds;
  setSeasonPricingPlan: (pricingPlanId: string, seasonId: string) => void;
}) => {
  const { HorizontalSeparator, View } = useGenericComponents();
  return (
    <CheckoutSection>
      {seasons.map((seasonQuote, index) => (
        <View key={seasonQuote.season.id}>
          <CheckoutSection>
            <View>
              <CheckoutSeason
                seasonQuote={seasonQuote}
                pricingPlanId={selectedPricingPlanIds[seasonQuote.season.id]}
                setPricingPlanId={setSeasonPricingPlan}
              />
            </View>
            {index < seasons.length - 1 && (
              <View>
                <HorizontalSeparator spacing={4} />
              </View>
            )}
          </CheckoutSection>
        </View>
      ))}
    </CheckoutSection>
  );
};
