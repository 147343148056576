import { useBreakpoint } from "../../../../breakpoints";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface AddStudentButtonProps {
  showCreateForm: () => void;
}
export const AddStudentButton = ({ showCreateForm }: AddStudentButtonProps) => {
  const { md: isDesktop } = useBreakpoint();
  const { Button } = useGenericComponents();

  return (
    <Button
      variant={"secondary"}
      text={"Add student"}
      leftIcon={"addCircleOutline"}
      onClick={showCreateForm}
      size={isDesktop ? "lg" : "xs"}
    />
  );
};
