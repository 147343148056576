import { StaffDto, StaffPermissionSet } from "@justraviga/classmanager-sdk";

import { showAlert } from "../../alertState";
import { Api } from "../../api";
import {
  makeStaffCreateRequest,
  makeStaffUpdateRequest,
  useStaffFormDefinition,
} from "../formDefinitions/staffForm";
import { useGenericComponents } from "../GenericComponentsProvider";
import { DefaultEntityActions } from "../useDefaultEntityActions";

type Model = StaffDto;

/**
 * These actions don't need any frontend so can be shared
 */
export const useSharedStaffActions = (
  defaultActions: DefaultEntityActions<Model>,
  api: Api,
) => {
  const { GenericForm } = useGenericComponents();
  const staffFormDefinition = useStaffFormDefinition();

  return {
    showCreateForm: () =>
      defaultActions.showCreateForm({
        form: (
          <GenericForm
            apiRequest={makeStaffCreateRequest({ api })}
            defaultValues={{
              // TEMPORARY: default to 'Owner' role for initial release
              permissionSet: StaffPermissionSet.Owner,
            }}
            formDefinitionHook={() => staffFormDefinition()}
            onSuccess={defaultActions.onCreateSuccess}
          />
        ),
      }),

    showUpdateForm: (entity: Model) =>
      defaultActions.showUpdateForm({
        form: (
          <GenericForm
            apiRequest={makeStaffUpdateRequest({ api, id: entity.id })}
            defaultValues={entity}
            formDefinitionHook={() => staffFormDefinition(entity)}
            onSuccess={defaultActions.onUpdateSuccess}
          />
        ),
      }),

    deleteOne: (entity: Model) =>
      defaultActions.deleteOne(() => api.staff.deleteStaff({ id: entity.id })),

    deleteMany: (ids: string[]) =>
      defaultActions.deleteMany(
        () => api.staff.deleteManyStaff({ deleteManyStaffRequest: { ids } }),
        ids.length,
      ),

    archive: (entity: Model) =>
      defaultActions.archive(() => api.staff.archiveStaff({ id: entity.id })),

    unarchive: (entity: Model) =>
      defaultActions.unarchive(() =>
        api.staff.unarchiveStaff({ id: entity.id }),
      ),

    archiveMany: (ids: string[]) =>
      defaultActions.archiveMany(
        () =>
          api.staff.archiveStaffMembers({
            archiveStaffMembersRequest: { ids },
          }),
        ids.length,
        {
          extendedDescription:
            "They will no longer be able to access Class Manager.",
        },
      ),

    unarchiveMany: (ids: string[]) =>
      defaultActions.unarchiveMany(() =>
        api.staff.unarchiveStaffMembers({
          unarchiveStaffMembersRequest: { ids },
        }),
      ),

    cancelInvite: (entity: Model) =>
      api.staff.inviteCancelStaff({ id: entity.id }).then(() => {
        showAlert({
          content: `Invitation cancelled successfully`,
        });
      }),

    revokeAccess: (entity: Model) =>
      api.auth
        .revokeAccounts({ revokeAccountsRequest: { entityId: entity.id } })
        .then(() =>
          showAlert({ content: "Account access removed successfully" }),
        ),

    getLinkData: (entity: Model) =>
      api.staff.getStaffInvitation({ id: entity.id }),
    getUserData: (entity: Model) => api.staff.getStaffUser({ id: entity.id }),
  };
};
