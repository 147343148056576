import { FC } from "react";

import { UserAccountDto } from "@justraviga/classmanager-sdk";

import { AccountCard } from "./AccountCard";
import { NoAccountsPlaceholder } from "./NoAccountsPlaceholder";
import { useAuthState } from "../../../../auth/useAuthState";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface Props {
  autoSelectSingleAccount: boolean;
  selectAccount: (account: UserAccountDto) => Promise<void>;
  CompanyLogo: FC<{ logo: string | null | undefined }>;
}

export const AccountList = ({
  autoSelectSingleAccount,
  selectAccount,
  CompanyLogo,
}: Props) => {
  const { View } = useGenericComponents();
  const { accounts, accountsLoading } = useAuthState();

  if (accountsLoading) {
    return null;
  }

  if (accounts && accounts.length === 1 && autoSelectSingleAccount) {
    // If user only has access to a single account, select it automatically
    selectAccount(accounts[0]).then();
  }

  if (accounts.length === 0) {
    return <NoAccountsPlaceholder />;
  }

  return (
    <View className={"flex w-full flex-col space-y-4 md:space-y-6"}>
      {accounts.map(account => (
        <View key={account.id}>
          <AccountCard
            rowClick={selectAccount}
            account={account}
            CompanyLogo={CompanyLogo}
          />
        </View>
      ))}
    </View>
  );
};
