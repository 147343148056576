import { useEffect, useState } from "react";

import {
  AggregatePaymentLinkDto,
  AggregatePaymentLinkSuccessDto,
  BillDto,
  BillItemDto,
  StripePaymentMethodCardDataDto,
} from "@justraviga/classmanager-sdk";

import { QuoteSummary } from "@shared/components/modules/customer/checkout/QuoteSummary";

import {
  CardPaymentDetails,
  CheckoutLeftColumn,
  CheckoutRightColumn,
  CheckoutSuccessMessage,
  TransactionList,
  useCatalogueContext,
  useFormActions,
  useGenericComponents,
} from "shared/components";
import { formatMoneyFromInteger } from "shared/lib";

import { api } from "@/lib/api/apiClient";
import { Button } from "@/modules/common/ui/button/Button";
import { CustomerLayout } from "@/modules/customer/CustomerLayout";
import { PublicTakePaymentContainer } from "@/modules/public/payments/PublicTakePaymentContainer";
import { TakePayment } from "@/modules/public/payments/TakePayment";

export const MakePaymentPage = ({
  paymentLinkId,
}: {
  paymentLinkId: string;
}) => {
  const { Loader, Text, View } = useGenericComponents();
  const { company } = useCatalogueContext();
  const { submit, isLoading } = useFormActions();
  const [data, setPaymentLinkData] = useState<AggregatePaymentLinkDto>();
  const [readyForPayment, setReadyForPayment] = useState(false);
  const [paymentLinkSuccess, setPaymentLinkSuccess] =
    useState<AggregatePaymentLinkSuccessDto>();

  useEffect(() => {
    api.pay.retrievePaymentLink({ paymentLinkId }).then(data => {
      setPaymentLinkData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transaction = {
    description: data?.aggregateTransaction.transaction.description,
    items: data?.aggregateTransaction.details
      .filter(detail => detail.details.type !== "discount")
      .filter(detail => detail.details.type !== "tax")
      .map(detail => {
        return {
          type: detail.details.type,
          description: detail.details.description,
          subtotal: detail.details.amount,
        } as BillItemDto;
      }),
    subtotal: data?.aggregateTransaction.stats.subtotal,
    discountTotal: data?.aggregateTransaction.stats.discountTotal,
    taxTotal: data?.aggregateTransaction.stats.taxTotal,
    total: data?.aggregateTransaction.stats.total,
  } as BillDto;

  return (
    <CustomerLayout hideNav company={company}>
      {!data ? (
        <View className="flex w-full flex-row items-center justify-center py-8">
          <Loader />
        </View>
      ) : (
        <View>
          {paymentLinkSuccess ? (
            <View className={"px-8 py-4"}>
              <View className={"w-full"}>
                <CheckoutSuccessMessage message="Your payment was successful" />
              </View>
            </View>
          ) : null}
          <PublicTakePaymentContainer>
            <CheckoutLeftColumn>
              <View
                className={
                  "flex flex-col items-start justify-center space-y-10"
                }>
                <View className={"w-full"}>
                  <Text className="text-heading5-600 text-grey-900">
                    Transaction details
                  </Text>
                </View>
                <View className={"w-full"}>
                  <TransactionList transactions={[transaction]} />
                </View>
                <View className={"flex w-full flex-col space-y-2"}>
                  <QuoteSummary quoteSummary={transaction} />
                </View>
              </View>
            </CheckoutLeftColumn>
            <CheckoutRightColumn>
              {!paymentLinkSuccess ? (
                <View
                  className={
                    "flex flex-col items-start justify-center space-y-8"
                  }>
                  <Text className="text-heading5-600 text-grey-900">
                    Pay by card
                  </Text>
                  <TakePayment
                    paymentSession={data.paymentSession}
                    stripeIntegration={company.settings.integrations.stripe!}
                    onSuccess={result => setPaymentLinkSuccess(result!)}
                    paymentLinkId={data.paymentLink.id}
                    setReadyForPayment={setReadyForPayment}
                  />
                  <Button
                    variant={"brand"}
                    text={`Pay ${formatMoneyFromInteger(transaction.total)}`}
                    disabled={!readyForPayment || isLoading}
                    onClick={() => {
                      submit();
                    }}
                    isFullWidth={true}
                  />
                </View>
              ) : (
                <View
                  className={
                    "flex flex-col items-start justify-start space-y-8 py-4 md:py-0"
                  }>
                  <View className="flex w-full flex-row items-center justify-between">
                    <Text className="text-heading5-600 text-grey-900">
                      Payment details
                    </Text>
                  </View>
                  <View className={"w-full"}>
                    <View className={"w-full"}>
                      {paymentLinkSuccess.paymentIntent.paymentMethod?.type ==
                      "card" ? (
                        <CardPaymentDetails
                          cardDetails={
                            paymentLinkSuccess.paymentIntent.paymentMethod
                              .data as StripePaymentMethodCardDataDto
                          }
                          id={paymentLinkSuccess.paymentIntent.paymentMethod.id}
                        />
                      ) : null}
                    </View>
                  </View>
                </View>
              )}
            </CheckoutRightColumn>
          </PublicTakePaymentContainer>
        </View>
      )}
    </CustomerLayout>
  );
};
