import { cn } from "shared/lib";

import { Tooltip } from "@/modules/common/overlays/Tooltip";

export const TrimmableText = ({
  text,
  multipleLines = false,
}: {
  text: string;
  multipleLines?: boolean;
}) => {
  return (
    <Tooltip
      trigger={
        <span className={cn({ "line-clamp-1 break-all": !multipleLines })}>
          {text}
        </span>
      }>
      <span>{text}</span>
    </Tooltip>
  );
};
