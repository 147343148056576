import { useEffect, useState } from "react";

import { CustomFieldType } from "@justraviga/classmanager-sdk";

import { colors } from "../../colors";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { customFieldTypeLabel } from "../../translateUtils";
import { useGenericComponents } from "../GenericComponentsProvider";
import { SelectItem } from "../interfaces";

export const exampleGenders = ["Male", "Female", "Other"] as const;
export const exampleColors = [
  "Red",
  "Orange",
  "Yellow",
  "Green",
  "Blue",
  "Purple",
] as const;

const onChange = () => {};

export interface ExampleFormSchema {
  name: string;
  message: string;
  password: string;
  age: number;
  time: string;
  gender: "male" | "female" | "other";
  favouriteColors: string[];
  favouriteColor: string;
  enableNotifications: boolean;
  sendInvite: boolean;
  email: string | null;
  price: number;
  dob: Date;
  type: CustomFieldType;
  holidayPeriod: {
    start: Date;
    end: Date;
  };
}

export const formBuildingIntro =
  `FormDefinitionBuilder allows you to create platform-independent ` +
  `definitions for your forms. You then pass these definitions to a` +
  `platform-specific form builder (usually called "useFormBuilder") to` +
  `turn them into a renderable form. The form builders return the form` +
  `node + the results of [React Hook Form's useForm hook](https://react-hook-form.com/docs/useform)` +
  `which you can use to trigger submission/validation etc.`;
export const formBuildingExample =
  `To see how this is done, have a look at ` +
  `shared/src/components/formDefinitions/exampleForm.tsx and the corresponding ` +
  `FormBuilderPage on either platform.`;

/**
 * Create a form that uses all possible methods of FormDefinitionBuilder
 */
export const useExampleFormDefinition = () => {
  const [genderData, setGenderData] = useState<SelectItem[]>([]);
  const { Banner } = useGenericComponents();

  // Simulate loading data from an external source, to populate a dropdown
  useEffect(() => {
    setTimeout(() => {
      setGenderData(exampleGenders.map(name => ({ label: name, value: name })));
    }, 3000);
  }, []);

  return (
    new FormDefinitionBuilder<ExampleFormSchema>()
      // Personal details
      .group(
        "Personal details",
        ["name", "message", "password", "age", "dob", "gender"],
        {
          description: "Information about the customer goes here...",
        },
      )
      .text("name", { label: "Name" })
      .richText("message", { value: "hello!", label: "Message" })
      .password("password", { label: "Password" })
      .integer("age", {
        label: "Age",
        description: "Please state your age \n as of 1st September this year",
      })
      .time("time", {
        label: "Time",
        onChange,
        locale: "en-GB",
        clockType: "12-hour",
      })
      .date("dob", { label: "Date of birth", onChange, required: true })
      .select("gender", {
        label: "Gender",
        data: genderData,
        description: "How do you identify?",
        onSelect: onChange,
      })

      // Extra details
      .group("Extra details", ["favouriteColors", "favouriteColor", "price"], {
        advanced: true,
        description:
          "Some more advanced information we don't want to show by default.",
      })
      .decimal("price", { label: "Price", prefix: "£", decimalPlaces: 2 })
      .multiSelect("favouriteColors", {
        label: "Colors",
        data: exampleColors.map(name => ({ label: name, value: name })),
        onSelect: onChange,
      })
      .color("favouriteColor", { label: "Color" })
      .row(["favouriteColors", "favouriteColor"])
      .customBlock(
        "after",
        "favouriteColors",
        <Banner
          variant="warning"
          content="We don't take your colors seriously!"
          icon={{ name: "warning", color: colors.yellow["600"] }}
        />,
      )

      .checkbox("sendInvite", { label: "Send invite" })

      .customBlock(
        "before",
        "enableNotifications",
        <Banner
          variant="info"
          content="Manage your notifications"
          icon={{ name: "informationCircle", color: colors.blue["600"] }}
        />,
      )

      .switch("enableNotifications", {
        label: "Notifications",
        description: "Enable email notifications",
      })
      .group("Recipient", ["email"])
      .text("email", {
        label: "Email",
        required: true,
        type: "email",
        prependIcon: "mailOutline",
      })

      .bigRadio("type", {
        label: "Answer type",
        options: [
          {
            label: customFieldTypeLabel(CustomFieldType.Text),
            value: CustomFieldType.Text.toString(),
            icon: "reorderTwoOutline",
          },
          {
            label: customFieldTypeLabel(CustomFieldType.Textarea),
            value: CustomFieldType.Textarea.toString(),
            icon: "reorderThreeOutline",
          },
          {
            label: customFieldTypeLabel(CustomFieldType.Number),
            value: CustomFieldType.Number.toString(),
            icon: "barChartOutline",
          },
        ],
      })
      .conditional(["email"], ["enableNotifications"], v => {
        return !!v.enableNotifications;
      })
      .getDefinition()
  );
};
