import React, { PropsWithChildren, ReactNode } from "react";

import { SignWaiversContent } from "./SignWaiversContent";
import { SignWaiversHeader } from "./SignWaiversHeader";
import { usePendingWaivers } from "../../../../../data/usePendingWaivers";
import { FormBuilder } from "../../../../../forms/formBuilderTypes";
import { useAuthState } from "../../../../AuthStateProvider";
import { useSignPendingWaiversForm } from "../../../../formDefinitions/signPendingWaiversForm";
import { useGenericComponents } from "../../../../GenericComponentsProvider";
import { BaseCardComponent, ButtonComponent } from "../../../../interfaces";

interface SignWaiversProps extends PropsWithChildren {
  formBuilder: FormBuilder;
  loadingSpinner: ReactNode;
  Layout: React.FC<PropsWithChildren>;
  Button: ButtonComponent;
  BaseCard: BaseCardComponent;
}
export const SignWaivers = ({
  children,
  formBuilder,
  Layout,
  loadingSpinner,
  BaseCard,
  Button,
}: SignWaiversProps) => {
  const { View } = useGenericComponents();
  const { account } = useAuthState();
  const { pendingWaivers, isLoading } = usePendingWaivers(account);

  const { companyName, form, formState, handleFormSubmission } =
    useSignPendingWaiversForm(pendingWaivers, formBuilder);

  if (isLoading) {
    return (
      <Layout>
        <View className="flex h-full w-full items-center justify-center">
          {loadingSpinner}
        </View>
      </Layout>
    );
  }

  if (pendingWaivers.length === 0) {
    return children;
  }

  return (
    <Layout>
      <View
        className={
          "w-full flex flex-col rounded bg-white md:my-16 md:max-w-2xl"
        }>
        <View className="h-16 pt-5 px-5 pb-6">
          <SignWaiversHeader />
        </View>
        <View className="px-5 grow overflow-y-scroll">
          <SignWaiversContent
            companyName={companyName}
            pendingWaivers={pendingWaivers}
            form={form}
            BaseCard={BaseCard}
          />
        </View>
        <View className="h-20 w-full p-5 border-t border-grey-300">
          <Button
            disabled={!formState.isValid}
            loading={formState.isSubmitting}
            text="Confirm"
            variant={"brand"}
            onClick={handleFormSubmission}
            isFullWidth
          />
        </View>
      </View>
    </Layout>
  );
};
