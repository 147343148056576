import { useEffect, useState } from "react";

import {
  PricingPlanDto,
  PricingPlanType,
  SeasonDto,
  SortSchema,
} from "@justraviga/classmanager-sdk";

import { getPlatformFunctions } from "../../../platformSpecific";
import { generateMonthOptions } from "../../../transactionUtils";
import { SelectItem } from "../../interfaces";

export const usePreviewTuitionBillData = () => {
  const { useApi } = getPlatformFunctions();
  const [billableMonths, setBillableMonths] = useState<SelectItem[]>([]);
  const [selectedSeason, setSelectedSeason] = useState<SeasonDto | undefined>(
    undefined,
  );
  const [pricingPlan, setPricingPlan] = useState<PricingPlanDto | undefined>(
    undefined,
  );

  const { data: seasons } = useApi("listSeason", {
    selectAll: true,
    sort: { startAt: SortSchema.Desc },
  });

  useEffect(() => {
    if (selectedSeason && pricingPlan?.type === PricingPlanType.Monthly) {
      setBillableMonths(generateMonthOptions(selectedSeason, pricingPlan));
    }
  }, [selectedSeason, pricingPlan]);

  return {
    billableMonths,
    seasons,
    selectedSeason,
    setSelectedSeason,
    setBillableMonths,
    pricingPlan,
    setPricingPlan,
  };
};
