import { IconName } from "../availableIcons";

export interface ClickableSettingItem {
  icon: IconName;
  name: string;
  description?: string;
  onClick: () => void;
}

export const settingGroups = {
  company: {
    icon: "business",
    name: "Company",
  },
  locationAndTime: {
    icon: "location",
    name: "Location & Time",
  },
  billing: {
    icon: "card",
    name: "Billing",
  },
  enrolments: {
    icon: "school",
    name: "Enrollments",
  },
} as const;

export type SettingGroupKey = keyof typeof settingGroups;

export const settingItems = {
  companyInfo: {
    icon: "business",
    name: "Company information",
    description: "Name, industry and contact information",
    group: "company",
  },
  companyAddress: {
    icon: "location",
    name: "Company address",
    description: undefined,
    group: "company",
  },
  lookAndFeel: {
    icon: "colorPalette",
    name: "Look & feel",
    description: "Edit your branding and logo",
    group: "company",
  },
  languageAndCurrency: {
    icon: "globeOutline",
    name: "Language & currency",
    description: undefined,
    group: "locationAndTime",
  },
  timeFormat: {
    icon: "time",
    name: "Time format",
    description: "Time zone, clock format",
    group: "locationAndTime",
  },
  startDayOfWeek: {
    icon: "calendarClear",
    name: "Start day for weekly calendar",
    description: "Specify a day for your week to begin on",
    group: "locationAndTime",
  },
  ageCutOff: {
    icon: "calendarNumber",
    name: "Age cut-off date",
    description: "Configure your age cut-off date for classes",
    group: "enrolments",
  },
  automatedTuitionSettings: {
    icon: "calendarNumber",
    name: "Automated tuition settings",
    description: "Configure when you take automatic monthly payments",
    group: "billing",
  },
  taxSettings: {
    icon: "cash",
    name: "Tax configuration",
    description: "Tax settings and rates",
    group: "billing",
  },
} as const;

export type SettingItemKey = keyof typeof settingItems;
