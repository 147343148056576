import { useState } from "react";

import {
  CheckoutRequestItems,
  QuoteResponse,
} from "@justraviga/classmanager-sdk";

import { addPricingPlansForEnrolmentsToQuote } from "./addPricingPlansForEnrolmentsToQuote";
import { showAlert } from "../../../../../alertState";
import { useAuthState } from "../../../../../auth/useAuthState";
import { SelectedPricingPlanIds } from "../../../../../data/useCheckoutData";
import { useBasket } from "../../../../basket/useBasket";
import { useGenericComponents } from "../../../../GenericComponentsProvider";

export const ContinueToPayButton = ({
  allPricingPlansSelected,
  isTakingPayment = false,
  total,
  quoteResponse,
  selectedPricingPlanIds,
  openPaymentForm,
}: {
  allPricingPlansSelected: boolean;
  isTakingPayment?: boolean;
  total: number;
  quoteResponse: QuoteResponse;
  selectedPricingPlanIds: SelectedPricingPlanIds;
  openPaymentForm: (
    familyId: string,
    balance: number,
    requestItems: CheckoutRequestItems,
  ) => Promise<void>;
}) => {
  const { Button } = useGenericComponents();
  const { account } = useAuthState();
  const [isPendingTransaction, setIsPendingTransaction] = useState(false);

  const { contents } = useBasket();

  function continueToPay() {
    setIsPendingTransaction(true);

    addPricingPlansForEnrolmentsToQuote(
      contents,
      quoteResponse,
      selectedPricingPlanIds,
    ).then(quote => {
      openPaymentForm(account?.entityId || "", total || 0, quote).finally(
        () => {
          setIsPendingTransaction(false);
        },
      );
    });
  }

  function onClick() {
    if (!allPricingPlansSelected) {
      showAlert({
        content: "You need to select a payment option before continuing.",
        variant: "error",
      });
      return;
    }

    continueToPay();
  }

  return (
    <Button
      loading={isTakingPayment || isPendingTransaction}
      disabled={isTakingPayment || isPendingTransaction}
      className={"w-full"}
      variant={"brand"}
      text={"Continue to pay"}
      isFullWidth={true}
      onClick={onClick}
    />
  );
};
