import { CourseDto, SeasonDto } from "@justraviga/classmanager-sdk";

import { getPricingForCourse, getPricingForTrial } from "../../classUtils";
import { formatMoneyFromInteger } from "../../intlFormatter";
import { getPlatformFunctions } from "../../platformSpecific";
import { useCatalogueContext } from "../catalogue/useCatalogueContext";
import { useGenericComponents } from "../GenericComponentsProvider";

export const CoursePrice = ({
  season,
  course,
  mode,
}: {
  season: SeasonDto;
  course: CourseDto;
  mode: "enrol" | "trial";
}) => {
  const { Text, View } = useGenericComponents();
  const { company } = useCatalogueContext();
  const { useApi } = getPlatformFunctions();

  const { data: pricingSchemeValuesResponse } = useApi(
    "listPricingSchemeValue",
    {
      where: {
        seasonId: {
          equals: season.id,
        },
      },
    },
  );

  if (!pricingSchemeValuesResponse) {
    return null;
  }

  const pricing =
    mode === "trial"
      ? getPricingForTrial(company.settings.trial.price)
      : getPricingForCourse({
          coursePrice: course.price,
          lessonDuration: course.durationInMinutes,
          pricingScheme: season.pricingScheme,
          pricingSchemeValues: pricingSchemeValuesResponse.data,
        });

  if (typeof pricing === "string") {
    return <Text className="text-label-400 text-grey-600">{pricing}</Text>;
  }

  return (
    <View>
      <View className="flex flex-row items-baseline space-x-0.5">
        <Text className="text-label-600 text-grey-900">
          {formatMoneyFromInteger(pricing.amount)}
        </Text>
        <Text className="text-label-400 text-grey-600">/</Text>
        <Text className="text-label-400 text-grey-600">{pricing.unit}</Text>
      </View>
    </View>
  );
};
