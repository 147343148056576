import { SignWaivers } from "@shared/components/modules/customer/checkout/signWaivers/SignWaivers";

import { CatalogueMinimalLayout } from "@/modules/auth/catalogue/CatalogueMinimalLayout";
import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";
import { Button } from "@/modules/common/ui/button/Button";
import { BaseCard } from "@/modules/common/ui/card/BaseCard";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { FamilyChecksForBasket } from "@/modules/customer/checkout/FamilyChecksForBasket";
import { withFamilyAccount } from "@/modules/customer/checkout/withFamilyAccount";

const MemberBasketPage = () => {
  return (
    <SignWaivers
      formBuilder={useFormBuilder}
      loadingSpinner={<LoadingSpinnerDark />}
      Layout={CatalogueMinimalLayout}
      Button={Button}
      BaseCard={BaseCard}>
      <FamilyChecksForBasket />
    </SignWaivers>
  );
};

const WithLogin = withFamilyAccount(MemberBasketPage);

export { WithLogin as MemberBasketPage };
