import {
  CreateCustomFieldRequest,
  CustomFieldEntity,
  CustomFieldType,
  UpdateCustomFieldRequest,
} from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import {
  customFieldEntityLabel,
  customFieldTypeLabel,
} from "../../translateUtils";

type Schemas = CreateCustomFieldRequest;
type Action = "create" | "update";

const useDefinition = <Mode extends Schemas>({
  mode,
  defaultValues = {},
}: {
  mode: Action;
  defaultValues?: {
    type?: string;
  };
}) => {
  const entityOptions = Object.entries(CustomFieldEntity)
    .sort(([, a], [, b]) => a.localeCompare(b))
    .map(entity => {
      return {
        value: entity[1],
        label: customFieldEntityLabel(entity[1])!,
      };
    });

  const builder = new FormDefinitionBuilder<Mode>()
    .select("entity", {
      label: "Applies to",
      description:
        "Which member or item should this custom field be assigned to?",
      data: entityOptions,
    })
    .text("title", {
      label: "Question",
      description: "It is best to provide a concise, direct question.",
    })
    .bigRadio("type", {
      label: "Answer type",
      options: [
        {
          label: customFieldTypeLabel(CustomFieldType.Text),
          value: CustomFieldType.Text.toString(),
          icon: "reorderTwoOutline",
        },
        {
          label: customFieldTypeLabel(CustomFieldType.Textarea),
          value: CustomFieldType.Textarea.toString(),
          icon: "reorderThreeOutline",
        },
        {
          label: customFieldTypeLabel(CustomFieldType.Number),
          value: CustomFieldType.Number.toString(),
          icon: "barChartOutline",
        },
        {
          label: customFieldTypeLabel(CustomFieldType.Date),
          value: CustomFieldType.Date.toString(),
          icon: "calendarClearOutline",
        },
        {
          label: customFieldTypeLabel(CustomFieldType.Select),
          value: CustomFieldType.Select.toString(),
          icon: "checkmarkOutline",
        },
        {
          label: customFieldTypeLabel(CustomFieldType.Multiselect),
          value: CustomFieldType.Multiselect.toString(),
          icon: "checkmarkDone",
        },
        {
          label: customFieldTypeLabel(CustomFieldType.Bool),
          value: CustomFieldType.Bool.toString(),
          icon: "checkboxOutline",
        },
      ],
    })
    .text("options", {
      label: "Add options",
      description: "Create a new line for each.",
      placeholder: "Press return to create a new line",
      numberOfLines: 5,
    })
    .conditional(["options"], ["type"], v => {
      return (
        v.type === CustomFieldType.Select.toString() ||
        v.type === CustomFieldType.Multiselect.toString() ||
        mode === "update"
      );
    });

  if (mode === "update") {
    builder.without(["type"]);
    if (
      defaultValues?.type !== CustomFieldType.Select.toString() &&
      defaultValues?.type !== CustomFieldType.Multiselect.toString()
    ) {
      builder.without(["options"]);
    }
  }

  return builder.getDefinition() as FormDefinition<Mode>;
};

export const useCustomFieldCreateFormDefinition = () =>
  useDefinition<CreateCustomFieldRequest>({ mode: "create" });
export const useCustomFieldUpdateFormDefinition = () =>
  useDefinition<CreateCustomFieldRequest>({ mode: "update" });

export const makeCustomFieldCreateRequest =
  ({ api }: { api: Api }) =>
  (formData: CreateCustomFieldRequest) => {
    formData.options = prepareOptionsForRequest(formData.options);

    return api.customFields.createCustomField({
      createCustomFieldRequest: formData,
    });
  };

export const makeCustomFieldUpdateRequest =
  ({ api, id }: { api: Api; id: string }) =>
  (formData: UpdateCustomFieldRequest) => {
    formData.options = prepareOptionsForRequest(formData.options);

    return api.customFields.updateCustomField({
      id,
      updateCustomFieldRequest: formData,
    });
  };

function prepareOptionsForRequest(
  options: string | string[] | null | undefined,
): string[] {
  if (!options) {
    return [];
  }

  if (Array.isArray(options)) {
    return options;
  }

  return options
    .split("\n")
    .map(o => o.trim())
    .filter(s => !!s);
}
