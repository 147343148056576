import { useBreakpoint } from "@shared/breakpoints";
import { useAccountStatementDatatable } from "@shared/components/datatables/useAccountStatementDatatable";

import { datatableAdditionalActionToWebActionMenuItem } from "@/lib/actionMenuUtils";
import { api, useApi } from "@/lib/api/apiClient";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { useTransactionActions } from "@/modules/company/billing/transactions/useTransactionActions";
import { DetailPageLayout } from "@/modules/company/common/DetailPageLayout";
import { CompanyLayout } from "@/modules/company/CompanyLayout";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const AccountStatementPage = ({ familyId }: { familyId: string }) => {
  const { data: family } = useApi("getFamily", { id: familyId });
  const { md: isDesktop } = useBreakpoint();
  const { setTitle: setSheetTitle } = useSheet();
  const transactionActions = useTransactionActions();
  const { datatable, additionalActions } = useAccountStatementDatatable({
    familyId,
    Datatable,
    api,
    transactionActions,
    goToFamily: () => Router.push("FamilyDetails", { id: familyId }),
    isDesktop,
    setSheetTitle,
  });
  if (!family) {
    return <LoadingPlaceholder />;
  }
  return (
    <CompanyLayout alwaysShowGlobalHeader={false} datatablePage>
      <DetailPageLayout
        title="Account statement"
        crumbs={[
          {
            text: "Families",
            onClick: () => {
              Router.push("FamilyList");
            },
          },
          {
            text: family?.name,
            onClick: () => {
              Router.push("FamilyDetails", { id: familyId });
            },
          },
          {
            text: "Account statement",
          },
        ]}
        primaryAction={{
          title: "Add transaction",
          icon: "addOutline",
          onClick: transactionActions.showCustomTransactionCreateForm,
          size: "sm",
        }}
        actions={
          isDesktop
            ? undefined
            : additionalActions.items.map(
                datatableAdditionalActionToWebActionMenuItem,
              )
        }
        isDesktop={isDesktop}
        archivedEntityName="">
        <div>{datatable}</div>
      </DetailPageLayout>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "financial:view",
  AccountStatementPage,
  "Account statement",
);

export { PermissionChecked as AccountStatementPage };
