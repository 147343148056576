import { useState } from "react";

import {
  CompanyDto,
  LinkFamilyToCompanyRequest,
  PublicCompanyDto,
} from "@justraviga/classmanager-sdk";

import { useAuthState } from "@shared/components/AuthStateProvider";
import { useNameAndPhoneForm } from "@shared/components/formDefinitions/userNameAndPhoneForm";
import { displayApiValidationErrors } from "@shared/forms/formHelpers";

import { api } from "@/lib/api/apiClient";
import { RegistrationWithTerms } from "@/modules/auth/common/RegistrationWithTerms";
import { useFormBuilder } from "@/modules/common/formBuilder/useFormBuilder";

interface CreateAccountFormProps {
  company: PublicCompanyDto | CompanyDto;
  onSuccess?: () => void;
}

export const CreateAccountForm = ({ company }: CreateAccountFormProps) => {
  const { user } = useAuthState();
  const [isLoading, setLoading] = useState(false);

  const createAccount = async (
    linkFamilyToCompanyRequest: LinkFamilyToCompanyRequest,
  ) => {
    setLoading(true);
    try {
      await api.auth.linkFamilyToCompany({
        linkFamilyToCompanyRequest,
      });
    } catch (e) {
      displayApiValidationErrors(
        // @ts-expect-error We need to figure out these 'watch' types at some point
        formHandlers,
      )(e);
    } finally {
      setLoading(false);
    }
  };

  const { form, formHandlers } = useNameAndPhoneForm(
    useFormBuilder,
    createAccount,
    {
      firstname: user?.firstname ?? "",
      lastname: user?.lastname ?? "",
      phone: user?.phone ?? "",
    },
  );

  return (
    <RegistrationWithTerms
      companyName={company.name}
      displayTerms={true}
      form={form}
      isLoading={isLoading}
      onFormSubmission={formHandlers.handleSubmit(createAccount)}
    />
  );
};
