import { SeasonFormFooter } from "./SeasonFormFooter";

interface UpdateSeasonFooterProps {
  goToSeasonDetails: () => void;
}

export const UpdateSeasonFooter = ({
  goToSeasonDetails,
}: UpdateSeasonFooterProps) => (
  <SeasonFormFooter
    submitBtnText={"Save"}
    cancelBtnText={"Cancel"}
    onCancel={goToSeasonDetails}
  />
);
