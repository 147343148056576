import { PropsWithChildren, useState } from "react";

import { CatalogueAuthController } from "@/modules/auth/catalogue/CatalogueAuthController";
import { AuthRedirectContext } from "@/modules/auth/redirect/authRedirectContext";

type Mode = "hidden" | "login" | "register";

export const AuthRedirectProvider = ({ children }: PropsWithChildren) => {
  const [mode, setMode] = useState<Mode>("hidden");

  return (
    <AuthRedirectContext.Provider
      value={{
        goToLogin: () => setMode("login"),
        goToRegister: () => setMode("register"),
      }}>
      <CatalogueAuthController
        allowCancel={true}
        initialMode={mode}
        onModeChange={setMode}>
        {children}
      </CatalogueAuthController>
    </AuthRedirectContext.Provider>
  );
};
