import { cn } from "../../cssUtils";
import { useGenericComponents } from "../GenericComponentsProvider";

export type TextStyle =
  | "small"
  | "light"
  | "normal"
  | "bold"
  | "heading"
  | "subheading"
  | "big";

export const StyledText = ({
  text,
  style = "normal",
  children,
}: {
  text?: string;
  style?: TextStyle;
  children?: string;
}) => {
  const { Text } = useGenericComponents();
  return (
    <Text
      className={cn({
        "text-label-400 text-grey-600": style === "small",
        "text-body-400 text-grey-600": style === "light",
        "text-body-400 text-grey-900": style === "normal",
        "text-body-600 text-grey-900": style === "bold",
        "text-heading6-600 text-grey-900": style === "heading",
        "text-heading6-400 text-grey-600": style === "subheading",
        "text-heading5-600 text-grey-900": style === "big",
      })}>
      {text}
      {children}
    </Text>
  );
};
