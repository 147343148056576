import { useMemo } from "react";

import {
  CustomFieldEntity,
  FamilyDto,
  SortSchema,
} from "@justraviga/classmanager-sdk";

import { IconName } from "@shared/availableIcons";
import { useBreakpoint } from "@shared/breakpoints";
import {
  WebActionMenuItemProps,
  WebActionMenuProps,
} from "@shared/components/interfaces/actionMenu";
import { CustomFieldResponsesCard } from "@shared/components/modules/common/customFields/CustomFieldResponsesCard";
import { InvitationWidget } from "@shared/components/modules/company/common/InvitationWidget";
import { RecentTransactionsCard } from "@shared/components/transactions/RecentTransactionsCard";
import { Breadcrumb } from "@shared/components/ui/Breadcrumbs";
import { useStripeStatus } from "@shared/payments/useStripeStatus";

import { useApi } from "@/lib/api/apiClient";
import { LoadingPlaceholder } from "@/modules/common/search/LoadingPlaceholder";
import { useStudentActions } from "@/modules/common/students/useStudentActions";
import { Button } from "@/modules/common/ui/button/Button";
import { LoadingSpinnerDark } from "@/modules/common/ui/LoadingSpinnerDark";
import { AddressCard } from "@/modules/company/addresses/AddressCard";
import { useTransactionActions } from "@/modules/company/billing/transactions/useTransactionActions";
import {
  DetailPageGrid,
  DetailPageGridLeftColumn,
  DetailPageGridRightColumn,
  DetailPageLayout,
} from "@/modules/company/common/DetailPageLayout";
import { ClickableNavItem } from "@/modules/company/common/details/ClickableNavItem";
import { NavItemContainer } from "@/modules/company/common/details/NavItemContainer";
import { CompanyLayout } from "@/modules/company/CompanyLayout";
import { CustomFieldResponsesContent } from "@/modules/company/customFields/entityResponses/card/CustomFieldResponsesContent";
import { useCustomFieldActions } from "@/modules/company/customFields/useCustomFieldActions";
import { Profile } from "@/modules/company/members/family/detail/Profile";
import { FamilyStudentGrid } from "@/modules/company/members/family/FamilyStudentGrid";
import { useFamilyActions } from "@/modules/company/members/family/useFamilyActions";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const FamilyDetailsPage = ({ id }: { id: FamilyDto["id"] }) => {
  const studentActions = useStudentActions();
  const familyActions = useFamilyActions();
  const customFieldActions = useCustomFieldActions();
  const transactionActions = useTransactionActions();
  const { protector: stripeProtector } = useStripeStatus();
  const breakpoints = useBreakpoint();
  const isSmallScreen = !breakpoints.md;

  const { data: family } = useApi("getFamily", { id });

  const { data: students } = useApi(
    "listStudent",
    {
      where: { familyId: { equals: family?.id } },
      selectAll: true,
      sort: {
        firstname: SortSchema.Asc,
        lastname: SortSchema.Asc,
      },
    },
    { enabled: !!family },
  );

  const { data: contacts } = useApi(
    "listContact",
    {
      where: { familyId: { equals: family?.id } },
      selectAll: true,
    },
    { enabled: !!family },
  );

  // must have exactly 1 primary contact, except when contacts haven't loaded yet 🙄
  const primaryContact = (contacts?.data ?? []).find(c => c.isPrimary);

  const actions = useMemo(() => {
    const items: WebActionMenuProps["items"] = [];

    if (family === undefined || primaryContact === undefined) {
      return items;
    }

    const editAction: WebActionMenuItemProps = {
      title: "Edit",
      leftIcon: "createOutline" as IconName,
      onClick: () => familyActions.showUpdateForm(family, { primaryContact }),
      permission: "members:manage",
    };

    const inviteAction: WebActionMenuItemProps = {
      title: "Invite to Class Manager",
      leftIcon: "sendOutline" as IconName,
      onClick: () => {
        familyActions.showInviteForm(family, {
          firstname: primaryContact.firstname,
          lastname: primaryContact.lastname as string | undefined, // ContactDto has this as string|null
          email: primaryContact.email as string | undefined, // ContactDto has this as string|null
        });
      },
      permission: "members:manage",
    };

    const familyItems: Array<WebActionMenuItemProps> = [
      ...(isSmallScreen ? [editAction] : []),
      ...(family.invitationStatus === "not_invited" ? [inviteAction] : []),
    ];

    items.push({
      title: family.name,
      group: {
        items: familyItems,
      },
    });

    items.push(
      {
        title: "Tuition & fees",
        group: {
          items: [
            {
              title: "Preview tuition",
              onClick: () =>
                transactionActions.showPreviewTuitionForm(family.id),
              leftIcon: "eyeOutline",
              permission: "financial:view",
            },
            {
              title: "Generate unposted tuition",
              onClick: () =>
                transactionActions.showUnpostedTuitionForm(family.id),
              leftIcon: "schoolOutline",
              permission: "financial:manage",
            },
          ],
        },
      },
      {
        title: "Billing",
        group: {
          items: [
            {
              title: "Add custom transaction",
              onClick: () =>
                transactionActions.showCustomTransactionCreateForm(),
              leftIcon: "cashOutline",
              permission: "financial:manage",
            },
            {
              title: "Charge card on file",
              onClick: () =>
                transactionActions.showChargeCardOnFileForm(family.id),
              leftIcon: "cardOutline",
              protector: stripeProtector,
              permission: "financial:manage",
            },
            {
              title: "Record payment",
              onClick: () =>
                transactionActions.showRecordPaymentForm({
                  familyId: family.id,
                }),
              protector: stripeProtector,
              leftIcon: "receiptOutline",
              permission: "financial:manage",
            },
          ],
        },
      },
      {
        title: family.archivedAt ? "Restore" : "Archive",
        leftIcon: family.archivedAt ? "syncOutline" : "archiveOutline",
        destructive: true,
        hasDestructiveStyle: false,
        onClick: () => {
          family.archivedAt
            ? familyActions.unarchive(family)
            : familyActions.archive(family);
        },
        permission: "members:manage",
      },
      {
        title: "Delete",
        destructive: true,
        leftIcon: "trashOutline",
        onClick: () => {
          familyActions.deleteOne(family).then(() => Router.push("FamilyList"));
        },
        permission: "members:manage",
      },
    );

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallScreen, family, familyActions, primaryContact]);

  if (
    family === undefined ||
    students === undefined ||
    contacts === undefined ||
    primaryContact === undefined
  ) {
    return (
      <div className={"flex w-full items-center justify-center py-8"}>
        <LoadingSpinnerDark />
      </div>
    );
  }

  const crumbs: Breadcrumb[] = [
    {
      text: "Families",
      onClick: () => {
        Router.push("FamilyList");
      },
    },
    {
      text: family?.name,
    },
  ];

  return (
    <CompanyLayout alwaysShowGlobalHeader={true}>
      <DetailPageLayout
        isDesktop={breakpoints.md}
        archivedEntityName={"family"}
        restoreAction={() => familyActions.unarchive(family)}
        editAction={() =>
          familyActions.showUpdateForm(family, { primaryContact })
        }
        additionalActions={
          <Button
            onClick={() => studentActions.showCreateWithFamilyForm(family)}
            size={"sm"}
            text={"Add student"}
            variant={"brand"}
          />
        }
        actions={actions}
        crumbs={crumbs}
        isArchived={family.archivedAt !== null}
        permissions={{
          edit: "members:manage",
        }}
        header={
          <Profile
            family={family}
            contacts={contacts.data}
            students={students.data}
            isDesktop={breakpoints.md}
            uploadAction={() => familyActions.showAvatarUploadSheet(family)}
          />
        }>
        <FamilyStudentGrid
          students={students}
          createStudentWithFamily={() =>
            studentActions.showCreateWithFamilyForm(family)
          }
        />
        <DetailPageGrid>
          <DetailPageGridLeftColumn>
            <RecentTransactionsCard
              family={family}
              LoadingPlaceholder={LoadingPlaceholder}
              showPreview={transactionActions.showPreview}
              goToAccountStatement={familyId =>
                Router.push("AccountStatement", { familyId })
              }
            />
          </DetailPageGridLeftColumn>
          <DetailPageGridRightColumn>
            <InvitationWidget
              invitationStatus={family.invitationStatus}
              getLinkData={() => familyActions.getLinkData(family)}
              getUserData={() => familyActions.getUserData(family)}
              sendInvite={() => {
                return familyActions.showInviteForm(family, {
                  firstname: primaryContact.firstname,
                  lastname: primaryContact.lastname as string | undefined,
                  email: primaryContact.email as string | undefined,
                });
              }}
              cancelInvite={() => familyActions.cancelInvite(family)}
              revokeAccess={() => familyActions.revokeAccess(family)}
              accountPermission="members:manage"
            />
            <NavItemContainer>
              <ClickableNavItem
                text={"Contacts"}
                iconName={"callSharp"}
                onClick={() =>
                  Router.push("ContactsList", {
                    id: family?.id,
                  })
                }
                permission="members:view"
              />
              <ClickableNavItem
                text={"Payment methods"}
                iconName={"card"}
                onClick={() =>
                  Router.push("FamilyPaymentMethods", {
                    familyId: family.id,
                  })
                }
                permission="financial:manage"
              />
              <ClickableNavItem
                text={"Waivers & policies"}
                iconName={"documentText"}
                onClick={() =>
                  Router.push("CompanyFamilyWaivers", {
                    familyId: family.id,
                  })
                }
                permission="members:view"
              />
            </NavItemContainer>

            <div className={"w-full"}>
              <CustomFieldResponsesCard
                entityId={family.id}
                defaultEntity={CustomFieldEntity.Family}
                CustomFieldResponsesContent={CustomFieldResponsesContent}
                customFieldActions={customFieldActions}
                managePermission="members:manage"
              />
            </div>

            <AddressCard
              entityId={family.id}
              updatePermission="members:manage"
            />
          </DetailPageGridRightColumn>
        </DetailPageGrid>
      </DetailPageLayout>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "members:view",
  FamilyDetailsPage,
  "Family details",
);

export { PermissionChecked as FamilyDetailsPage };
