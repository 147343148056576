import { FeeDto } from "@justraviga/classmanager-sdk";

import { makeTaxRateSelectOptions } from "../../data/makeTaxRateSelectOptions";
import { useTaxRates } from "../../data/useTaxRates";
import { FormDefinition } from "../../forms/formBuilderTypes";
import { FormDefinitionBuilder } from "../../forms/formDefinitionBuilder";
import { ContentPlaceholder } from "../ui/ContentPlaceholder";

export const useAdditionalFeeFormDefinition = () => {
  const { hasTaxEnabled, taxRates } = useTaxRates();

  const builder = new FormDefinitionBuilder<FeeDto>()
    .text("description", { label: "Name", required: true })
    .money("amount", { label: "Per student", required: true })
    .money("maxAmountPerFamily", {
      label: "Max per family",
      description:
        "This will cap the amount charged when enrolling multiple students from the same family.",
    });

  if (hasTaxEnabled) {
    builder.select("taxRateId", {
      label: "Tax rate",
      data: makeTaxRateSelectOptions(taxRates),
      required: true,
      notFoundLabel: (
        <ContentPlaceholder
          icon={"helpCircleOutline"}
          title={"No tax rates yet"}
          description={"Go to settings to add tax rates"}
        />
      ),
    });
  }

  return builder.getDefinition() as FormDefinition<FeeDto>;
};

export const makeAddAdditionalFeeRequest = ({
  addAdditionalFee,
  id,
}: {
  addAdditionalFee: (item: FeeDto) => FeeDto;
  id?: string;
}) => {
  return async (formData: FeeDto) => {
    if (id) {
      formData.id = id;
    }
    return addAdditionalFee(formData);
  };
};
