import {
  AggregateClassDto,
  AggregateEnrolmentDto,
  CompanyDto,
  SeasonDto,
  StudentDto,
} from "@justraviga/classmanager-sdk";

import { BasketItemStudentErrors } from "./BasketItemStudentErrors";
import { CourseAtCapacityBanner } from "./CourseAtCapacityBanner";
import { NoStudentsSelected } from "./NoStudentsSelected";
import { TooManyStudentsBanner } from "./TooManyStudentsBanner";
import { validateBasketItem } from "../../../../basket/basketUtils";
import { StudentImplementedActions } from "../../../actions/useSharedStudentActions";
import { useGenericComponents } from "../../../GenericComponentsProvider";

export const ValidateBasketItem = ({
  enforceValidation,
  course,
  season,
  students,
  enrolments,
  company,
  useStudentActions,
}: {
  enforceValidation: boolean;
  course: AggregateClassDto;
  season: SeasonDto;
  students: StudentDto[];
  enrolments: AggregateEnrolmentDto[];
  company: CompanyDto;
  useStudentActions: () => StudentImplementedActions;
}) => {
  const { View } = useGenericComponents();

  const { valid, error: validationError } = validateBasketItem(
    course,
    season,
    students,
    enrolments,
    company,
    enforceValidation,
  );
  return (
    <View className={"space-y-2"}>
      {enforceValidation &&
      !valid &&
      validationError === "courseHasNoStudents" ? (
        <View>
          <NoStudentsSelected />
        </View>
      ) : null}
      {!valid && validationError === "courseAtCapacity" ? (
        <View>
          <CourseAtCapacityBanner course={course.entity} />
        </View>
      ) : null}
      {!valid && validationError === "tooManyStudents" ? (
        <View>
          <TooManyStudentsBanner course={course} />
        </View>
      ) : null}
      <View>
        <BasketItemStudentErrors
          students={students}
          course={course}
          season={season}
          enrolments={enrolments}
          useStudentActions={useStudentActions}
          enforceValidation={enforceValidation}
        />
      </View>
    </View>
  );
};
