import { useSharedWaiversMembersListPage } from "@shared/entities/waiver/useSharedWaiversMembersListPage";

import { useApi } from "@/lib/api/apiClient";
import { WaiversMemberPageProps } from "@/modules/company/waivers/WaiversMemberPage";

export function useWaiversMembersListPage({
  memberId,
  source,
}: WaiversMemberPageProps) {
  return useSharedWaiversMembersListPage(useApi, memberId, source);
}
