import { AggregateClassDto } from "@justraviga/classmanager-sdk";

import { CoursePrice } from "./CoursePrice";
import { useCourseSnippetTrialAction } from "./courseSnippetTrialAction";
import { useBreakpoint } from "../../breakpoints";
import {
  ageRangeDescription,
  courseIsFull,
  displayCourseDates,
  displayLessonTimes,
} from "../../classUtils";
import { colors } from "../../colors";
import { dayLabel } from "../../courseFilterLabels";
import { useCatalogueData } from "../../data/useCatalogueData";
import { stopPropagation } from "../../eventUtils";
import { getPlatformFunctions } from "../../platformSpecific";
import { useBasket } from "../basket/useBasket";
import { useGenericComponents } from "../GenericComponentsProvider";
import {
  ButtonComponent,
  CourseImageComponent,
  FullScreenModal,
  IconButtonComponent,
  Sheet,
} from "../interfaces";
import { Chip } from "../ui/Chip";

export const CourseSnippet = ({
  bookingMode,
  course,
  sheet,
  Button,
  CourseImage,
  IconButton,
}: {
  bookingMode: "enrol" | "trial";
  course: AggregateClassDto;
  sheet: FullScreenModal | Sheet;
  Button: ButtonComponent;
  CourseImage: CourseImageComponent;
  IconButton: IconButtonComponent;
}) => {
  const { Icon, Text, View } = useGenericComponents();
  const { useApi } = getPlatformFunctions();
  const { contents: basket } = useBasket();
  const { showTrialForm } = useCourseSnippetTrialAction({
    course,
    sheet,
    Button,
    basket,
  });

  const breakpoint = useBreakpoint();
  const { addEnrolment, removeEnrolment, contents } = useBasket();
  const { seasons, locations, rooms } = useCatalogueData(useApi, true);

  if (!seasons || !locations || !rooms) {
    return null;
  }

  const season = seasons.find(season => season.id === course.entity.seasonId)!;
  const room = rooms.find(room => room.id === course.entity.roomId);
  const location = locations.find(l => l.id === room?.locationId);

  const isInBasket =
    bookingMode === "enrol"
      ? contents.enrolments.has(course.entity.id)
      : [...contents.trials.keys()].some(k => k.startsWith(course.entity.id));

  const addToBasket = (e: unknown) => {
    stopPropagation(e); // needed for web
    addEnrolment(course.entity.id);
  };

  const removeFromBasket = (e: unknown) => {
    stopPropagation(e); // needed for web
    removeEnrolment(course.entity.id);
  };

  const chooseTrialDate = (e: unknown) => {
    stopPropagation(e); // needed for web
    showTrialForm();
  };

  const action = courseIsFull(course) ? (
    <Chip label="Class full" variant="danger" />
  ) : bookingMode === "trial" ? (
    <Button variant="secondary" text="Book trial" onClick={chooseTrialDate} />
  ) : isInBasket ? (
    <IconButton
      icon="checkmarkCircle"
      variant="success"
      size="lg"
      onClick={removeFromBasket}
    />
  ) : (
    <IconButton
      icon="addToBagOutline"
      variant="brand"
      size="lg"
      onClick={addToBasket}
    />
  );

  return (
    <View
      key={course.entity.id}
      className="cursor-pointer flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-8 md:items-start">
      <View className="relative md:w-[165px] flex-initial">
        <CourseImage
          course={course.entity}
          collapseWhenPlaceholder={!breakpoint.md}
        />
        <View className="absolute top-4 left-4">
          <Chip
            label={dayLabel(course.entity.dayOfWeek).substring(0, 3)}
            variant="outline"
          />
        </View>

        {!breakpoint.md && (
          <View className="absolute top-4 right-4">{action}</View>
        )}
      </View>

      <View className="flex-grow flex flex-col">
        <View className="flex flex-row justify-between space-x-2 items-baseline pt-1">
          <Text className="text-label-400 text-grey-600">
            {displayLessonTimes(course.entity)}
          </Text>
          <CoursePrice
            season={season}
            course={course.entity}
            mode={bookingMode}
          />
        </View>

        <View className="pt-1">
          <Text className="text-body-600 text-grey-900 font-semibold">
            {course.entity.name}
          </Text>
        </View>

        <View className="pt-1 flex flex-col space-y-1 md:space-y-0 md:flex-row md:flex-wrap md:gap-x-8">
          <View className="flex flex-row space-x-1 items-center h-5">
            <Icon
              name="calendarClearOutline"
              color={colors.grey[600]}
              size={16}
            />
            <Text className="pl-1 text-grey-600 text-label-400">
              {displayCourseDates(season.startAt, season.endAt)}
            </Text>
          </View>

          <View className="flex flex-row space-x-1 items-center h-5">
            <Icon name="personOutline" color={colors.grey[600]} size={16} />
            <Text className="pl-1 text-grey-600 text-label-400">
              {ageRangeDescription(
                course.entity.minAgeMonths,
                course.entity.maxAgeMonths,
              )}
            </Text>
          </View>

          {location && (
            <View className="flex flex-row space-x-1 items-center h-5">
              <Icon name="locationOutline" color={colors.grey[600]} size={16} />
              <Text className="pl-1 text-grey-600 text-label-400">
                {location.name}
              </Text>
            </View>
          )}
        </View>
      </View>

      {breakpoint.md && <View className="">{action}</View>}
    </View>
  );
};
