import React from "react";

import { cn } from "../../cssUtils";
import { useGenericComponents } from "../GenericComponentsProvider";
import { ButtonVariant } from "../interfaces";

export interface CollapsibleContainerProps {
  children: React.ReactNode;
  startOpen?: boolean;
  buttonPosition?: "top" | "bottom";
  expandText?: string;
  collapseText?: string;
  buttonVariant?: Extract<
    ButtonVariant,
    "tertiaryLight" | "tertiary" | "subtle"
  >;
}

export const CollapsibleContainer = ({
  children,
  startOpen = false,
  buttonPosition = "bottom",
  expandText = "Show more",
  collapseText = "Show less",
  buttonVariant = "tertiaryLight",
}: CollapsibleContainerProps) => {
  const { Button, View } = useGenericComponents();
  const [isOpen, setIsOpen] = React.useState(startOpen);

  const buttonText = isOpen ? collapseText : expandText;

  const button = (
    <View className={"flex flex-row items-center justify-between -ml-4"}>
      <Button
        variant={buttonVariant}
        className={cn({
          "py-2": isOpen,
          "py-0": !isOpen,
        })}
        text={buttonText}
        onClick={() => setIsOpen(!isOpen)}
        rightIcon={isOpen ? "chevronUp" : "chevronDown"}
      />
    </View>
  );

  return (
    <View className={"p-0"}>
      {buttonPosition === "top" && button}
      <View
        className={cn(
          "transition-height duration-300 ease-in-out overflow-hidden",
          {
            "max-h-0": !isOpen,
            "max-h-screen": isOpen, // Max height to a large value that covers potential content size
          },
        )}>
        <View className={"py-4"}>{children}</View>
      </View>
      {buttonPosition === "bottom" && button}
    </View>
  );
};
