import { useCollapsibleContainerExamples } from "@shared/admin/docs/useCollapsibleContainerExamples";
import { CollapsibleContainer } from "@shared/components/collapsibleContainer/CollapsibleContainer";

import { DocWrapper } from "@/modules/admin/docs/DocWrapper";

export const CollapsibleContainerPage = () => {
  const examples = useCollapsibleContainerExamples();
  return (
    <DocWrapper
      title="Collapsible Container"
      component={CollapsibleContainer}
      options={examples}
    />
  );
};
